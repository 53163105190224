import React, { useState, useEffect, useRef } from 'react';
import { Box } from "@mui/system";
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { updateFormateurBatch } from "./helpers/batches"
import { API, graphqlOperation } from 'aws-amplify';
import { getCompanyAccount, listPublications } from '../graphql/queries';
import { updateCompanyAccount } from '../graphql/mutations';

const ProfileAdvancement = (props) => {
    const { profileRef, descRef, publiRef, fetchData, user, isCompany } = props
    var [profileColor, setProfileColor] = useState('red')
    var [descColor, setDescColor] = useState('orange')
    var [publiColor, setPubliColor] = useState('orange')
    var [publishColor, setPublishColor] = useState('red')
    const [companyData, setCompanyData] = useState({})
    const [publiData, setPubliData] = useState([])
    const data = useSelector(state => state.dataReducer)

    useEffect(() => {
        fetchInfo();
    }, []);

    const isProfileComplete = () => {
        if (isCompany) {
            if (companyData.fullName && companyData.description && companyData.hashList && companyData.imageId) {
                return true
            }
        } else {
            if (data.prenom && data.nom && data.intro && data.hashList && data.hasPhoto) {
                return true
            }
        }
        return false
    }

	const fetchInfo = async () => {
		if (data) {
			API.graphql(graphqlOperation(getCompanyAccount, { id: data.companies[0].id}))
				.then((res) => {
					setCompanyData(res.data.getCompanyAccount)
				})
				.catch(err => console.log(err))
		}
        if (isCompany && data) {
            API.graphql(graphqlOperation(listPublications, { filter: { organisateurId: { eq: data.companies[0].id }}}))
                .then((res) => {
                    setPubliData(res.data.listPublications.items)
                })
        } else {
            API.graphql(graphqlOperation(listPublications, { filter: { formateurId: { eq: data.id }}}))
                .then((res) => {
                    setPubliData(res.data.listPublications.items)
                })
        }
	}

    const isDescComplete = () => {
        if (isCompany) {
            if (companyData.documentHtml != '<div><br></div>' && companyData.documentHtml != null) {
                return true
            }
        } else {
            if (data.documentHtml != '<div><br></div>' && data.documentHtml != null) {
                return true
            }
        }
        return false
    }

    const publishProfile = () => {
        let batch = null

        if (isProfileComplete()) {
            if (isCompany) {
                API.graphql(graphqlOperation(updateCompanyAccount, { input: { id: data.companies[0].id, state: "published" } }))
                    .then(() => fetchInfo())
                    .catch((err) => console.log(err))
            } else {
                batch = updateFormateurBatch({ id: data.id, state: "published" })
                    .then(() => { fetchData(user) })
                    .catch(err => console.log(err))
            }
        }
    }

    const unpublishProfile = () => {
        let batch = null

        if (isCompany) {
            if (companyData.state === 'published') {
                API.graphql(graphqlOperation(updateCompanyAccount, { input: { id: data.companies[0].id, state: "unpublished" } }))
                    .then(() => fetchInfo())
                    .catch((err) => console.log(err))
            }
        } else {
            if (data.state === 'published') {
                batch = updateFormateurBatch({ id: data.id, state: "unpublished" })
                    .then(() => { fetchData(user) })
                    .catch(err => console.log(err))
            }
        }
    }

    useEffect(() => {
        if (isProfileComplete()) {
            setProfileColor('green')
            setPublishColor('green')
        } else {
            setProfileColor('red')
            setPublishColor('red')
        }
        if (isDescComplete()) {
            setDescColor('green')
        } else {
            setDescColor('orange')
        }
        if (publiData.length > 0) {
            setPubliColor('green')
        } else {
            setPubliColor('orange')
        }
    }, [data, companyData, publiData])

    const handleProfileClick = () => {
        profileRef.current?.scrollIntoView({behavior: 'smooth'})
    }

    const handleDescClick = () => {
        descRef.current?.scrollIntoView({behavior: 'smooth'})
    }

    const handlePubliClick = () => {
        publiRef.current?.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <Box margin='20px' marginLeft='40px' display='flex'>
            <Box width='18%' marginRight='40px'>
                <Box borderTop={`6px solid ${profileColor}`} width="100%">
                    <Box marginTop="3px" width="100%" position="relative">
                        <Box
                            onClick={handleProfileClick}
                            style={{
                                width: '100%',
                                color: 'black',
                                height: 30,
                                textDecoration: `none`,
                                cursor: 'pointer'

                            }}
                        >
                            <Typography style={{ color: 'black', fontWeight: '400', marginLeft: '5px' }}>
                                {isCompany ? '1. Informations' : '1. Profil'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box width='18%' marginRight='40px'>
                <Box borderTop={`6px solid ${descColor}`} width="100%">
                    <Box marginTop="3px" width="100%" position="relative">
                        <Box
                            onClick={handleDescClick}
                            style={{
                                width: '100%',
                                color: 'black',
                                height: 30,
                                textDecoration: `none`,
                                cursor: 'pointer'

                            }}
                        >
                            <Typography style={{ color: 'black', fontWeight: '100', marginLeft: '5px' }}>
                                {isCompany ? '2. A propos' : '2. Description'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box width='18%' marginRight='40px'>
                <Box borderTop={`6px solid ${publiColor}`} width="100%">
                    <Box marginTop="3px" width="100%" position="relative">
                        <Box
                            onClick={handlePubliClick}
                            style={{
                                width: '100%',
                                color: 'black',
                                height: 30,
                                textDecoration: `none`,
                                cursor: 'pointer'

                            }}
                        >
                            <Typography style={{ color: 'black', fontWeight: '100', marginLeft: '5px' }}>
                                3. Publications
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box width='19%' marginRight='20px'>
                {/* <Box borderTop={`6px solid ${publishColor}`} width="100%">
                    <Box marginTop="3px" width="100%" position="relative"> */}
                    <ThemeProvider theme={{backgroundColor: publishColor}}>
                        {isCompany ? (companyData.state == 'unpublished' || companyData.state == null) ?
                        <StyledButton onClick={publishProfile}>
                            <Typography style={{ color: 'white', fontWeight: '100'}}>
                                Publier l'organisme
                            </Typography>
                        </StyledButton> : 
                        <StyledButton onClick={unpublishProfile}>
                            <Typography style={{ color: 'white', fontWeight: '100'}}>
                                Dépublier l'organisme
                            </Typography>
                        </StyledButton> : (data.state == 'unpublished' || data.state == null) ?
                        <StyledButton onClick={publishProfile}>
                            <Typography style={{ color: 'white', fontWeight: '100'}}>
                                Publier le profil
                            </Typography>
                        </StyledButton> : 
                        <StyledButton onClick={unpublishProfile}>
                            <Typography style={{ color: 'white', fontWeight: '100'}}>
                                Dépublier le profil
                            </Typography>
                        </StyledButton>}
                    </ThemeProvider>
                    {/* <Box
                            // onClick={e => handleLinkClick(e, '#experience')}
                            style={{
                                width: '100%',
                                color: 'black',
                                height: 30,
                                textDecoration: `none`,
                                cursor: 'pointer'

                            }}
                        >
                            <Typography style={{ color: 'black', fontWeight: '400', marginLeft: '5px' }}>
                                4. Publier mon profil
                            </Typography>
                        </Box> */}
                    {/* </Box>
                </Box> */}
            </Box>
        </Box>
    )
}

export default ProfileAdvancement

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    backgroundColor: theme.backgroundColor,
    // backgroundColor: "#343434",
    color: '#CDCDCD',
    // height: '35px',
    borderRadius: '20px',
  
    '&:hover': {
      backgroundColor: grey[700]
    }
  }))
