import { API, graphqlOperation, Hub, Auth } from "aws-amplify"
import { listFormateurs, formateurByCognitoId, listDocumentHtmls, eventsbyFormateur, publicationsbyFormateur, cyclesbyFormateur, getCompanyAccount, memberShipByFormateurId, formateursByFollow } from "../../graphql/queries"
import { createFormateur } from "../../graphql/mutations"
import { Storage } from "aws-amplify"
import { downloadImageBatch } from "./batches"
import store from "../../redux/store"
import { setPublications } from "../../features/publications/publicationsSlice"
import { setEvents } from "../../features/events/eventsSlice"
import { setCycles } from "../../features/cycles/cyclesSlice"
import { lemonwayId } from '../../lemonway/helpers/migration'
import { getAccountDetails, getDocuments } from '../../lemonway/sandbox/createIndividuialAccount'
import hash from 'object-hash'

import { FilterCenterFocusOutlined, IndeterminateCheckBoxTwoTone, NearMeDisabled, PanoramaFishEyeSharp, RestoreFromTrashOutlined } from "@mui/icons-material"
const getImageInternalRef = (conference) => {
    // carefull 
    // at inceptoon InternalRef was event.id now is event.modelId
    return (conference.key.split('/')[2].split('.')[0].substring(1))
}
const getConferenceSize = (conference) => {
    return (conference.key.split('/')[2].split('.')[0][0])
}

const getConferenceType = (conference) => {
    return (conference.key.split('/')[2].split('.')[1])
}

const filterfilesList = (filesListRaw, conferencesList) => {
    // filter only files that are:
    // 1/ small format : 'S'
    // 2/ present in the :conferencesList:



    const filesListSorted = filesListRaw
        .filter(file => getConferenceSize(file) === 'S')
        .sort((a, b) => (b.lastModified - a.lastModified))
    const conferences = []
    filesListSorted.forEach(fileRaw => {
        const imageInternalRef = getImageInternalRef(fileRaw)
        if (!conferences.map(conference => conference.imageInternalRef).includes(imageInternalRef))
            conferences.push({
                file: fileRaw,
                imageInternalRef: imageInternalRef
            })
    })

    const conferencesIdList = conferencesList.map(conference => conference.id)
    const conferencesImageKeyList = conferencesList.map(conference => conference.imageKey)


    // return only files that are contained in :conferenceList:
    const filesList = conferences
        .filter(conference => conferencesIdList.includes(conference.imageInternalRef) || conferencesImageKeyList.includes(conference.imageInternalRef))
        .map(conference => conference.file)
    return (filesList)
}


const fetchAddtionalData = ( dispatch, setData, setFormateurData, finalData) => {

    const formateurId = finalData.id
    if (formateurId){

        API.graphql(graphqlOperation(memberShipByFormateurId, { formateurId: formateurId }))
        .then(({data}) =>{

            const memberships = data?.memberShipByFormateurId?.items

          
            if (memberships && memberships.length)
            {

                const companyAccountIds = memberships.map(membership=>membership.companyAccountId)
                const batches = companyAccountIds.map(companyId => API.graphql(graphqlOperation(getCompanyAccount, { id: companyId })))
                Promise.all(batches)
                .then(companies => {
                    
                    const result = companies.map((c, i) => ({...c.data.getCompanyAccount, membership : memberships[i]
                    }))

                    
                    
                    const storeData = {companies: result, ...finalData}
           


                    setData(storeData)
                    dispatch(setFormateurData(storeData))
                                
                })
                .catch(err => console.log(err))



            }
            
        })
        .catch(err => console.log(err))
        dispatch(setFormateurData(finalData))

    }
}


export const MakefetchData = (dispatch, setData, setFormateurData) => (userData) => {

    if (userData) {
        const cognitoId = userData.attributes.sub
        API.graphql(graphqlOperation(formateurByCognitoId, { cognitoId: cognitoId }))
            .then(({ data }) => {
                const items = data.formateurByCognitoId.items

                if (items.length > 0) {
                    const found = items[0]
                    if (found[lemonwayId]) {
                        getAccountDetails(found[lemonwayId], found.id, (accountData) => {



                            const intermediaryData = { ...found, ...accountData }
                            getDocuments(found[lemonwayId], found.id, (documentsData) => {
                                API.graphql(graphqlOperation(formateursByFollow, { formateurId: found.id }))
                                .then(({ data }) => {
                                    if ( data !== undefined) {
                                        const items = data.formateursByFollow.items
                    
                                        const nbFollower = {followers: items.length}

                                        const middleData = { ...intermediaryData, ...documentsData }
                                        const finalData = { ...middleData, ...nbFollower }
                                        dispatch(setFormateurData(finalData))
                                        setData(finalData)
                                    } else {
                                        const data = { ...intermediaryData, ...documentsData }
                                        dispatch(setFormateurData(data))
                                        setData(data)
                                    }
                                })
                                .catch(err => console.log(err))

                                const finalData = { ...intermediaryData, ...documentsData }
                                fetchAddtionalData( dispatch, setData, setFormateurData, finalData)
                                
                    

                            })

                        })
                    }
                    else {
                        API.graphql(graphqlOperation(formateursByFollow, { formateurId: found.id }))
                        .then(({data}) => {
                            if (data !== undefined) {
                                const items = data.formateursByFollow.items
            
                                const nbFollower = items.length

                                const finalFound = { ...found, ...nbFollower}
                                dispatch(setFormateurData(finalFound))
                                setData(finalFound)
                            } else {
                                dispatch(setFormateurData(found))
                                setData(found)
                            }
                        })
                        .catch(err => console.log(err))
                        fetchAddtionalData( dispatch, setData, setFormateurData, found)
                                
                        // dispatch(setFormateurData(found))
                        // setData(found)

                    }




                }
                else {
                    const input = {
                        cognitoId: cognitoId,
                        publicationList: JSON.stringify([])
                    }
                    API.graphql(graphqlOperation(createFormateur, { input: input }))
                        .then(({ data }) => {
    
                            const newFormateur = data.createFormateur

                            const finalNewFormateur = newFormateur
                            dispatch(setFormateurData(finalNewFormateur))
                            setData(finalNewFormateur)
                            fetchAddtionalData( dispatch, setData, setFormateurData, newFormateur)
                                
                            //dispatch(setFormateurData(newFormateur))
                            //setData(newFormateur)


                        })
                        .catch(err => console.log(err))

                }

            })
            .catch(err => console.log(err))
    }

}

export const fetchDataAbstraction = (userData, DBquery, storeSetter, imagePath, mapper = elem => elem) => {

    // DBQuery : name of the graphQL query that should be used
    // storeSetter: reducer used to store fetched data into the redux store
    // imagePath: name of the path in the S3 bucket - conferences/publications
    // mapper: maps the data extracted from graphQL into an object that contains an imageKey field which will be used to extract image data from S3


    const queryField = extractQueryField(DBquery)

    if (userData) {

        const formateurId = userData.id



        const fetcher1 = API.graphql(graphqlOperation(DBquery, {
            formateurId: formateurId
        }))
            .then(({ data }) => {


                const DBdata = data[queryField].items.map(mapper)


                const batch = Storage.list(`${formateurId}/${imagePath}`, { level: 'private' })
                batch
                    .then(data => {


                        let batches = []
                        const regex = new RegExp(imagePath + '\/(.+)')
                        const images = data
                            .map(row => ({ key: row.key, fileName: row.key.match(regex)[1] }))
                            .filter(row => (row.fileName[0] === 'S'))
                        DBdata.forEach(row => {
                            let miniBatch = null

                            if (row.imageKey) {
                                const image = images.find(image => image.fileName.includes(row.imageKey))
                                if (image) {
                                    const split = image.fileName.split('.')
                                    if (split.length > 1) {
                                        const fileName = image.fileName.split('.')[0]
                                        const ext = image.fileName.split('.')[1]
                                        miniBatch = downloadImageBatch(formateurId, fileName, ext, imagePath)
                                    }

                                }

                            }
                            else {
                                miniBatch = Promise.resolve(null)
                            }
                            batches.push(miniBatch)
                        })

                        Promise.all(batches)
                            .then(result => {

                                const fetchedData = DBdata
                                    .map((row, index) => ({ ...row, base64Image: result[index] }))


                                store.dispatch(storeSetter(fetchedData))
                            })
                            .catch(err => console.log(err))


                    })
                    .catch(err => console.log(err))


            })
            .catch(err => console.log(err))

    }

}

export const fetchPublicationsData = (userData) => {

    if (userData) {

        const formateurId = userData.id

        const fetcher1 = API.graphql(graphqlOperation(publicationsbyFormateur, {
            formateurId: formateurId
        }))
            .then(({ data }) => {

                const DBpublications = data.publicationsbyFormateur.items

                const imagePublications = DBpublications
                    .filter(publication => publication.type === "IMAGE")
                    .sort((a, b) => a.index - b.index)


                let batches = []
                imagePublications.forEach(publication => {
                    const key = publication.imageId
                    const ext = publication.imageExt
                    const fileName = "S" + key
                    const miniBatch = downloadImageBatch(formateurId, fileName, ext, "publications")
                    batches.push(miniBatch)
                })
                Promise.all(batches)
                    .then(values => {
                        const newPublications = DBpublications
                            .map((publication) => {
                                if (publication.type === "IMAGE") {
                                    const index = imagePublications.map(publication => publication.index).indexOf(publication.index)
                                    return ({ ...publication, base64Image: values[index] })

                                }
                                else
                                    return (publication)

                            })

                        store.dispatch(setPublications(newPublications))


                    })
                    .catch(err => console.log(err))




            })
            .catch(err => console.log(err))

    }

}


export const fetchConferenceData = () => {

    const data = store.getState().dataReducer
    fetchDataAbstraction(data, eventsbyFormateur, setEvents, 'conferences', row => ({ ...row, imageKey: row.imageKey }))

}

export const fetchCycleData = () => {

    const data = store.getState().dataReducer
    fetchDataAbstraction(data, cyclesbyFormateur, setCycles, 'cycles', row => ({ ...row, imageKey: row.imageKey }))

}


export const MakeFetchConferenceData = (conferenceDataHook) =>

    (userData, Refreshers = []) => {

        if (userData) {

            console.log('started fetching events data...')

            const setConferenceData = conferenceDataHook[1]
            const formateurId = userData.id


            const fetcher1 = API.graphql(graphqlOperation(eventsbyFormateur, {
                formateurId: formateurId
            }))

            // definition of promises used during the fetch

            //const fetcher2a = (filter) => API.graphql(graphqlOperation(listDocumentHtmls, { filter: filter }))
            const fetcher2b = (conferences) => Storage.list(`${formateurId}/conferences`, { level: 'private' })
                .then((filesListRaw) => {
                    const filesGetter = []

                    const filesList = filterfilesList(filesListRaw, conferences)
                    filesList.forEach(file => {
                        const type = getConferenceType(file)
                        const imageInternalRef = getImageInternalRef(file)
                        const fileGetter = Storage.get(file.key, { contentType: `image/${type}`, level: 'private' })
                            .then(url => fetch(url))
                            .then(response => response.blob())
                            .then(blob => {
                                const reader = new FileReader()
                                const result = reader.readAsDataURL(blob)
                                const finalPromise = new Promise((resolve) => {
                                    reader.onloadend = () => {
                                        const base64data = reader.result
                                        resolve({
                                            type: type,
                                            imageInternalRef: imageInternalRef,
                                            base64data: base64data
                                        })
                                    }
                                })
                                return (finalPromise)
                            })
                        filesGetter.push(fileGetter)
                    })
                    const result = Promise.all(filesGetter)
                    return (result)
                })
                .catch(err => console.log(err))

            // fetching data:

            fetcher1
                .then(({ data }) => {


                    const conferences = data.eventsbyFormateur.items
                    const filter = null
                    Promise.all([fetcher2b(conferences)])
                        .then(values => {


                            const filesData = values[0]

                            let newConferences = []
                            conferences.forEach((conference) => {

                                const contentHtml = conference.documentHtml
                                const imageData = filesData
                                    .find(file => file.imageInternalRef === conference.imageKey)



                                const newConference = {
                                    ...conference,
                                    ...imageData,
                                    documentHtml: contentHtml
                                }
                                newConferences.push(newConference)

                            })


                            store.dispatch(setEvents(newConferences))
                            setConferenceData(newConferences)
                            Refreshers.forEach(refresher => { refresher() })
                        })
                        .then(() => Refreshers.forEach(refresher => { refresher() }))
                        .catch(err => console.log(err))
                })
                .then(() => Refreshers.forEach(refresher => { refresher() }))

        }
    }



export const fetchOpenGraph = async (siteUrl) => {


    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken



    const requestInfo = {
        headers: {
            Authorization: token
        },
        queryStringParameters: {
            url: siteUrl,
        }

    }


    const data = await API.get('apifetcherNew', '/fetcher', requestInfo)




    return (data)

}

export default fetchOpenGraph

const extractQueryField = (text) => {
    const regex1 = /{.+?}/gs
    const regex2 = /{(.+)\(/
    const regex3 = /[a-z|A-Z]+/

    const match1 = text.match(regex1)[0]
    const match2 = match1.replace('\n', '').match(regex2)[1]
    const match3 = match2.match(regex3)

    return (match3[0])
}


export const sanboxPush = async (data, callback) => {

    // example: 
    // data  = {
    //     method: 'POST',
    //     body: {
    //         path: '/accounts/000-000',
    //         payload : { ... },    // optional in case of 'GET' 
    //         claim: lemonWayAccountId,  //optional only in case of account creation
    //         id: formateurId
    //     }
    // }


    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken



    const requestInfo = {
        headers: {
            Authorization: token
        },
        body: data
    }

    const initialTrial = 0
    let sandboxResponse =  null

    const requestTry = async (trial) => {
        if (trial < 10)
        try {
            sandboxResponse = await API.put('sandboxNew', '/createIndividualAccount ', requestInfo)
            callback(sandboxResponse)

        }
        catch(err){
            trial += 1
            console.log('@ERROR TRIAL: ' + trial)
            console.log(err)
            requestTry(trial)
           


        }
       
    }

    requestTry(0)






    callback(sandboxResponse)












    return (sandboxResponse)

}
