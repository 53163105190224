const FILTRE_ART = 'arts'
const FILTRE_DEV = 'développement personnel'
const FILTRE_HISTOIRE = 'histoire'
const FILTRE_GEOPOLIQUE = 'géopolitique'
const FILTRE_PHILO = 'philosophie'
const FILTRE_LITTERATURE = 'littérature'
const FILTRE_GEOGRAPHIE = 'géographie'
const FILTRE_DECOUVERTE = 'découverte'
const FILTRE_SCIENCES = 'sciences'
const FILTRE_ECOLOGIE = 'écologie'
const FILTRE_LANGUES = 'langues'
const FILTRE_NUMERIQUE = 'numérique'
const FILTRE_MUSIQUE = 'musique'
const FILTRE_SOCIALES = 'sciences sociales'
const FILTRE_SPORT = 'sports'
const FILTRE_LOISIRS = 'loisirs'

// do not change order in 'eventCategories' as the same order is used in Front office to revocerf catregories
export const eventCategories = [
    FILTRE_ART,
    FILTRE_DEV,
    FILTRE_HISTOIRE,
    FILTRE_GEOPOLIQUE,
    FILTRE_PHILO,
    FILTRE_LITTERATURE,
    FILTRE_GEOGRAPHIE,
    FILTRE_DECOUVERTE,
    FILTRE_SCIENCES,
    FILTRE_ECOLOGIE,
    FILTRE_LANGUES,
    FILTRE_NUMERIQUE,
    FILTRE_MUSIQUE,
    FILTRE_SOCIALES,
    FILTRE_SPORT,
    FILTRE_LOISIRS
]


export const choix = [

    {
        value: 'ATELIER',
        label: 'atelier',
    },
    {
        value: 'CLASSE',
        label: 'classe à distance',
    },
    {
        value: 'WEBINAR',
        label: 'webinar',
    }
];

export const outilsChoix = [
    {
        value: 'ZOOM',
        label: 'Zoom',
    },
    {
        value: 'MEET',
        label: 'Google Meet',
    },
    {
        value: 'TEAMS',
        label: 'Microsoft Teams',
    },
    {
        value: 'YOUTUBE',
        label: 'Youtube',
    },
    {
        value: 'FACEBOOKLIVE',
        label: 'Facebook live',
    },
    // {
    //     value: 'TWITCH',
    //     label: 'Twitch',
    // },
    // {
    //     value: 'TIKTOK',
    //     label: 'Tiktok',
    // }
];

export const niveauxChoix = [
    {
        value: 'TOUS',
        label: 'tous niveaux',
    },
    {
        value: 'DEBUTANT',
        label: 'débutant',
    },
    {
        value: 'MOYEN',
        label: 'intermédiaire',
    },
    {
        value: 'CONFIRME',
        label: 'confirmé',
    },
    {
        value: 'EXPERT',
        label: 'expert',
    }
];