import {
  Typography,
  Button,
  Modal,
  Fade,
  TextField,
  Backdrop,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getFormateur,
  getMemberShip,
  listFormateurs,
  listMemberShips,
} from "../../graphql/queries";
import { localeText, regexList, cleanText, cutText } from "../general";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";
import { Adjust, Api } from "@mui/icons-material";
import {
  createInvitation,
  deleteFormateur,
  updateCompanyAccount,
  updateMemberShip,
} from "../../graphql/mutations";
import { green, grey, orange } from "@mui/material/colors";
import FicheForm from "./ficheForm";
import IconButton from "@mui/material/IconButton";
import { ColorButton } from "../../components/BO-style";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const emailRegex = new RegExp(
  /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
);

const CompanyUsers = (props) => {
  const { fetchData, setRefresh } = props;
  const data = useSelector((state) => state.dataReducer);
  const companies = data.companies;
  const [formateursPublished, setFormateursPublished] = useState([]);
  const [formateursPrivate, setFormateursPrivate] = useState([]);
  const [rows, setRows] = useState([]);
  const [confRows, setConfRows] = useState([]);
  const [openMember, setOpenMember] = useState(false);
  const [openConf, setOpenConf] = useState(false);
  const [openFiche, setOpenFiche] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [ficheId, setFicheId] = useState("");

  const getList = async () => {
    const tmpRows = [];
    const { data } = await API.graphql(
      graphqlOperation(listMemberShips, {
        filter: { companyAccountId: { eq: companies[0].id } },
      })
    );

    for (const e of data.listMemberShips.items) {
      let res = await API.graphql(
        graphqlOperation(getFormateur, { id: e.formateurId })
      );
      res = res.data.getFormateur;
      const obj = {
        id: res ? res.id : null,
        url: "",
        nom: res.nom,
        prenom: res.prenom,
        membershipId: e.id,
        administratorPrivileges: e.administratorPrivileges,
        accountantPrivileges: e.accountantPrivileges,
        editorPrivileges: e.editorPrivileges,
        communicationPrivileges: e.communicationPrivileges,
      };
      tmpRows.push(obj);
    }
    setRows(tmpRows);
  };

  const fetchAll = () => {
    API.graphql(
      graphqlOperation(listFormateurs, {
        filter: { companyAccountId: { eq: companies[0].id } },
      })
    )
      .then(({ data }) => {
        setFormateursPrivate(data.listFormateurs.items);
      })
      .catch((err) => console.log(err));
    getList();
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchFormateurs = () => {
    getList();
  };

  useEffect(() => {
    const tmpArr = [];
    formateursPrivate.forEach((e) => {
      const obj = {
        id: e.id,
        prenom: e.prenom,
        nom: e.nom,
        intro: e.intro,
      };
      tmpArr.push(obj);
    });
    setConfRows(tmpArr);
  }, [formateursPrivate]);

  const handleClose = () => {
    setOpen(false);
  };

  const setAdmin = (event, id, name) => {
    const input = { id: id, administratorPrivileges: event.target.checked };
    API.graphql(graphqlOperation(updateMemberShip, { input: input }))
      .then((res) => {
        setMessage(
          !event.target.checked
            ? `Vous avez donné les droits administrateurs à ${name}`
            : `Vous avez retiré les droits administrateurs à ${name}`
        );
        setOpen(true);
        fetchFormateurs();
        setRefresh(true);
      })
      .catch((err) => console.log(err));
  };

  const setAccountant = (event, id, name) => {
    const input = { id: id, accountantPrivileges: event.target.checked };
    API.graphql(graphqlOperation(updateMemberShip, { input: input }))
      .then((res) => {
        setMessage(
          !event.target.checked
            ? `Vous avez donné les droits de finances à ${name}`
            : `Vous avez retiré les droits de finances à ${name}`
        );
        setOpen(true);
        fetchFormateurs();
        setRefresh(true);
      })
      .catch((err) => console.log(err));
  };

  const setEditor = (event, id, name) => {
    const input = { id: id, editorPrivileges: event.target.checked };
    API.graphql(graphqlOperation(updateMemberShip, { input: input }))
      .then((res) => {
        setMessage(
          !event.target.checked
            ? `Vous avez donné les droits de plannification à ${name}`
            : `Vous avez retiré les droits de plannification à ${name}`
        );
        setOpen(true);
        fetchFormateurs();
        setRefresh(true);
      })
      .catch((err) => console.log(err));
  };

  const setCommunication = (event, id, name) => {
    const input = { id: id, communicationPrivileges: event.target.checked };
    API.graphql(graphqlOperation(updateMemberShip, { input: input }))
      .then((res) => {
        setMessage(
          !event.target.checked
            ? `Vous avez donné les droits de communication à ${name}`
            : `Vous avez retiré les droits de communication à ${name}`
        );
        setOpen(true);
        fetchFormateurs();
        setRefresh(true);
      })
      .catch((err) => console.log(err));
  };

  const deleteSingleFormateur = (id) => {
    API.graphql(graphqlOperation(deleteFormateur, { input: { id: id } }))
      .then((res) => {
        fetchAll();
      })
      .catch((err) => console.log(err));
  };
  // const rows = formateursPublished.map((formateur) => {

  // });

  const columns = [
    {
      field: "nom",
      headerName: "Nom",
      flex: 2,
      renderHeader: () => <strong>{"Nom"}</strong>,
      cellClassName: "theme--cell",
      renderCell: (params) => {
        return (
          <Typography>{params.row.prenom + " " + params.row.nom}</Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderHeader: () => <strong>{"Email"}</strong>,
      cellClassName: "theme--cell",
      renderCell: (params) => {
        return <Typography>{params.row.mail}</Typography>;
      },
    },
    {
      field: "admin",
      headerName: "Admin",
      flex: 1,
      renderHeader: () => (
        <LightTooltip
          title={
            "Accès à l'ensemble des rubriques du back office / Validation des profils demandant à intervenir sur votre page organisme"
          }
        >
          <strong>{"Admin"}</strong>
        </LightTooltip>
      ),
      cellClassName: "theme--cell",
      renderCell: (params) => {
        const handleChange = (event) => {
          setAdmin(
            event,
            params.row.membershipId,
            `${params.row.prenom} ${params.row.nom}`
          );
        };
        return (
          <Box>
            <Checkbox
              disabled={params.row.id === data.id ? true : false}
              onChange={handleChange}
              checked={params.row.administratorPrivileges}
              color={params.row.administratorPrivileges ? "success" : "default"}
            />
          </Box>
        );
      },
    },
    {
      field: "accountant",
      headerName: "Accountant",
      flex: 1,
      renderHeader: () => (
        <LightTooltip title={"Accès à la rubrique finance"}>
          <strong>{"Finance"}</strong>
        </LightTooltip>
      ),
      cellClassName: "theme--cell",
      renderCell: (params) => {
        const handleChange = (event) => {
          setAccountant(
            event,
            params.row.membershipId,
            `${params.row.prenom} ${params.row.nom}`
          );
        };
        return (
          <Box>
            <Checkbox
              onChange={handleChange}
              checked={params.row.accountantPrivileges}
              color={params.row.accountantPrivileges ? "success" : "default"}
            />
          </Box>
        );
      },
    },
    {
      field: "editor",
      headerName: "Editor",
      flex: 1,
      renderHeader: () => (
        <LightTooltip
          title={
            "Accès aux rubriques 'Notre page', 'Conférences', 'Animation' et 'Analyse'"
          }
        >
          <strong>{"Planification"}</strong>
        </LightTooltip>
      ),
      cellClassName: "theme--cell",
      renderCell: (params) => {
        const handleChange = (event) => {
          setEditor(
            event,
            params.row.membershipId,
            `${params.row.prenom} ${params.row.nom}`
          );
        };
        return (
          <Box>
            <Checkbox
              onChange={handleChange}
              checked={params.row.editorPrivileges}
              color={params.row.editorPrivileges ? "success" : "default"}
            />
          </Box>
        );
      },
    },
    {
      field: "comm",
      headerName: "comm",
      flex: 1,
      renderHeader: () => (
        <LightTooltip
          title={
            "Accès aux rubriques 'Notre page', 'Promotion', 'Analyse' et 'Fidélisation'"
          }
        >
          <strong>{"Communication"}</strong>
        </LightTooltip>
      ),
      cellClassName: "theme--cell",
      renderCell: (params) => {
        const handleChange = (event) => {
          setCommunication(
            event,
            params.row.membershipId,
            `${params.row.prenom} ${params.row.nom}`
          );
        };
        return (
          <Box>
            <Checkbox
              onChange={handleChange}
              checked={params.row.communicationPrivileges}
              color={params.row.communicationPrivileges ? "success" : "default"}
            />
          </Box>
        );
      },
    },
  ];

  const confColumns = [
    {
      field: "nom",
      headerName: "Nom",
      width: 500,
      // flex: 2,
      renderHeader: () => <strong>{"Nom"}</strong>,
      cellClassName: "theme--cell",
      renderCell: (params) => {
        return (
          <Typography>{params.row.prenom + " " + params.row.nom}</Typography>
        );
      },
    },
    {
      field: "desc",
      headerName: "Desc",
      width: 500,
      // flex: 2,
      renderHeader: () => <strong>{"Introduction"}</strong>,
      cellClassName: "theme--cell",
      renderCell: (params) => {
        return <Typography>{params.row.intro}</Typography>;
      },
    },
    {
      field: "action",
      width: 100,
      renderHeader: () => <TableHeader header={"Modifier"} />,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setOpenFiche(true);
          setFicheId(params.row.id);
        };
        return (
          <Box
            width="40px"
            padding="5px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignContent="center"
          >
            <ColorButton onClick={onClick}>
              <EditIcon />
            </ColorButton>
          </Box>
        );
      },
    },
    {
      field: "delete",
      renderHeader: () => (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          justifyItems="center"
        >
          <TableHeader header={"Supprimer"} />
        </Box>
      ),
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          deleteSingleFormateur(params.row.id);
          // deleteSingleEvent(params.row.id)
          // setIsClone(false)
          // setConferenceId(params.row.id)
        };
        return (
          <Box
            width="40px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            justifyItems="center"
          >
            <ColorButton onClick={onClick}>
              <DeleteIcon />
            </ColorButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box width="100%">
      <Box>
        <Box width="100%" marginTop="20px" marginBottom="20px">
          {/* <Typography variant='h4' fontSize='1.5rem' fontWeight={900}>
            {"Liste des membres ainsi que des conférenciers de l'organisme".toUpperCase()}
          </Typography> */}
          <Typography variant="h4" fontSize="1.5rem" fontWeight={900}>
            {"Liste des conférenciers de l'organisme".toUpperCase()}
          </Typography>
        </Box>
      </Box>
      {/* <Box marginBottom="20px">
        <Box>
          <Box borderBottom='1px solid black' marginBottom="10px" display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant='h4' fontSize='1.5rem' margin='10px'>
              Membres
            </Typography>
            <StyledButton onClick={() => setOpenMember(!openMember)}>Ajouter un membre</StyledButton>
          </Box>
        </Box>
        <Box height="400px">
          <DataGridPro
            rowHeight={50}
            // localeText={localeText}
            columns={columns}
            rows={rows}
            disableSelectionOnClick
            // rows={[{ id: 1, url: "https://s3.amazonaws.com/www-inside-design/uploads/2020/10/aspect-ratios-blogpost-1x1-1.png", prenom: "test", nom: "oui"}]}
          />
        </Box>
      </Box> */}
      <Box>
        <Box>
          <Box
            borderBottom="1px solid black"
            marginBottom="10px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h4" fontSize="1.5rem" margin="10px">
              Conférenciers
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              width="300px"
              justifyContent="right"
            >
              <StyledButton
                onClick={() => {
                  setOpenFiche(!openFiche);
                  setFicheId("");
                }}
              >
                Créer une fiche
              </StyledButton>
              {/* <StyledButton onClick={() => setOpenConf(!openConf)}>Ajouter un conférencier</StyledButton> */}
            </Box>
          </Box>
        </Box>
        {openFiche ? (
          <FicheForm
            setOpen={setOpenFiche}
            companyAccountId={companies[0].id}
            company={companies[0]}
            fetchData={fetchAll}
            ficheId={ficheId}
          />
        ) : (
          <></>
        )}
        <Box height="400px">
          <DataGridPro
            rowHeight={50}
            // localeText={localeText}
            columns={confColumns}
            rows={confRows}
            // rows={[{ id: 1, url: "https://s3.amazonaws.com/www-inside-design/uploads/2020/10/aspect-ratios-blogpost-1x1-1.png", prenom: "test", nom: "oui"}]}
          />
        </Box>
      </Box>
      <MemberModal
        open={openMember}
        setOpen={setOpenMember}
        email={email}
        setEmail={setEmail}
        id={data.id}
      />
      <ConfModal open={openConf} setOpen={setOpenConf} />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
      />
    </Box>
  );
};

export default CompanyUsers;

const MemberModal = ({ open, setOpen, email, setEmail, id }) => {
  const [emailError, setEmailError] = useState(false);
  // const [email, setEmail] = useState('')

  const classes = useStylesMain();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (key) => {
    return (e) => {
      setEmail(e.target.value);
    };
  };

  const handleInvite = () => {
    if (emailRegex.test(email) && id) {
      const data = {
        from: id,
        email: email,
        role: "MEMBER",
      };
      API.graphql(graphqlOperation(createInvitation, { input: data }))
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: 24,
    // p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box style={style}>
          <Box>
            <Typography id="modal-modal-title" textAlign="center">
              Veuillez renseigner le mail du membre que vous souhaitez ajouter.
            </Typography>
          </Box>
          <Box marginTop="15px" marginBottom="15px">
            <TextField
              error={emailError}
              id="email"
              value={email}
              className={classes.textField}
              fullWidth
              required
              label="Email"
              variant="filled"
              onChange={handleChange("email")}
              InputProps={{
                shrink: "true",
                classes: {
                  root: classes.textFieldRoot,
                  focused: classes.textFieldRootAlt,
                  input: classes.textFieldRootAlt2,
                },
              }}
              InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
            />
          </Box>
          <Box width="100%" justifyContent="center" display="flex">
            <StyledButton onClick={handleInvite}>Inviter</StyledButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

const ConfModal = ({ open, setOpen }) => {
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");

  const classes = useStylesMain();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {};

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: 24,
    // p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box style={style}>
          <Box>
            <Typography id="modal-modal-title" textAlign="center">
              Veuillez renseigner le mail du conférencier que vous souhaitez
              inviter.
            </Typography>
          </Box>
          <Box marginTop="15px" marginBottom="15px">
            <TextField
              error={emailError}
              id="email"
              value={email}
              className={classes.textField}
              fullWidth
              required
              label="Email"
              variant="filled"
              onChange={handleChange("email")}
              InputProps={{
                shrink: "true",
                classes: {
                  root: classes.textFieldRoot,
                  focused: classes.textFieldRootAlt,
                  input: classes.textFieldRootAlt2,
                },
              }}
              InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
            />
          </Box>
          {/* <Box display='flex' width='100%' justifyContent='center'>
          {displayButton ? <EmailShare email="contact@pekoia.com" subject='Mise en ligne de replay' body={`Voici le lien contenant le replay de la conférence ${link}, je souhaite le publier pour la somme de ${price}€`} >
            {linkk => (
              <a href={linkk} data-rel='external'>Envoyer</a>
            )}
          </EmailShare> : <Typography>Veuillez renseigner le lien WeTransfer</Typography>}
        </Box> */}
        </Box>
      </Fade>
    </Modal>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: grey[800],
    padding: "10px",
    color: "#F0F0F0",
    lineHeight: "1.3rem",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const StyledButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "#1a5aa3",
  color: "#FFFFFF",
  alignSelf: "center",
  // height: '32px',
  borderRadius: "20px",
  fontSize: "0.85rem",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const useStylesMain = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      "& .theme--cell": {
        fontSize: "0.8rem",
      },
    },
    title: {
      color: "#273370",
      fontSize: "1.5rem",
      marginLeft: "3vw",
      marginBottom: "2vw",
    },
    contentContainer: {
      display: "flex",
    },
    tableContainer: {},
    actionsContainer: {},
    action: {},
    button: {
      border: 0,
      borderRadius: 2,
      color: "white",
      width: "1vw",
      height: "2vw",
      padding: "0vw",
    },
  }),
  { index: 1 }
);

const TableHeader = ({ header, restrictDate, setRestrictDate }) => {
  const isDate = header === "Date";
  const colors = [grey[300], orange[400], green[800]];
  const color = colors[restrictDate];

  return (
    <div
      style={{
        fontSize: "0.7rem",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      {isDate && (
        <Tooltip title="change le champs de sélection.">
          <IconButton
            onClick={() => setRestrictDate((restrictDate + 1) % 3)}
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <Adjust fontSize="small" sx={{ color: color }} />
          </IconButton>
        </Tooltip>
      )}
      {header}
    </div>
  );
};
