import { useSelector } from 'react-redux';
import React from 'react';
import { Box } from "@mui/system";
import Typography from '@mui/material/Typography';
import { cleanHtml } from '../SVGIcons/helpers'

const CompanyDescInfo = ({ companyData }) => {
    const data = useSelector(state => state.dataReducer)
    const company = data.companies && data.companies[0]
    var documentHtml = null
    documentHtml = cleanHtml(companyData && companyData.documentHtml)

    return (
    <Box margin="20px">
        <Box>
            <Box>
                <Box margin="0px 0px 20px 0px">
                    {documentHtml ? <div dangerouslySetInnerHTML={{__html: documentHtml}}/> : "Non renseigné"}
                </Box>
            </Box>
        </Box>
    </Box>
    );
}

export default CompanyDescInfo;