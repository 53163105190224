import React from 'react'
import { Link } from 'react-router-dom'

import Footer from './layout/footer'
import Header from './layout/header'
import AboutUsItem from '../components/home/aboutUsItem'
import TeachingItem from '../components/home/teachingItem'
import Button from '../components/home/button'

const aboutUs = [
  {
    title: 'Une université en ligne ouverte à tous',
    image: 'uploads/depositphotos476917552xl2015.jpg',
    description: 'www.pekoia.com est une université en ligne au service du plaisir de découverte et de transmission … '
  },
  {
    title: 'Des événements à distance en direct',
    image: 'uploads/depositphotos401858476xl2015.jpg',
    description: '… qui permet à des intervenants et participants de se rencontrer lors d’événements en direct…'
  },
  {
    title: 'Des formats de conférence à taille humaine',
    image: 'uploads/depositphotos376385652xl2015.jpg',
    description: '... dans des formats de conférences ou d’ateliers à taille humaine pour plus de convivialité.'
  }
]
const blogs = [
  {
    icon: 'fas fa-chalkboard-teacher',
    title: 'visibilité',
    description: "De la visibilité et de la valorisation apportées à votre offre de conférences et à votre parcours"
  },
  {
    icon: 'fas fa-bullhorn',
    title: 'fidéliser',
    description: "Des outils pour promouvoir votre offre de conférences, toucher et fidéliser votre audience"
  },
  {
    icon: 'fas fa-euro-sign',
    title: 'brique de paiement',
    description: "Une brique de paiement pour bénéficier du fruit de votre travail sur votre compte bancaire"
  },
  {
    icon: 'fas fa-address-book',
    title: 'développez vous',
    description: "L’opportunité de développer des contacts pour de futures conférences publiques ou privées"
  },
  {
    icon: 'fas fa-file-video',
    title: 'Du sur mesure',
    description: 'Des solutions de professionnalisation de vos interventions (tournage, design …)'
  },
  {
    icon: 'fas fa-mail-bulk',
    title: 'Faites vous connaître',
    description: 'Un service publicitaire pour vous aider dans le ciblage et la réalisation de campagnes'
  }
]



const Home = ({ setOpen, onStateChange, authState }) => {

  const discoverButtonHandler = (type) => {
    setOpen(true)
    if (type === 'signIn')
      onStateChange('signIn')
    else
      onStateChange('signUp')


  }
  if (authState === "signedIn")
    return (<>
      <Footer authState={authState}/>
    </>)




  const BOUrl = "https://www.back.pekoia.com"

  // data-bg-src={`${BOUrl}/uploads/photohomepagebackoffice.jpg`} data-bg-src-2x="false"


  return (
    <>
      <Header discoverButtonHandler={discoverButtonHandler} />
      {/* Call to action 32 */}
      <section className="bg-dark color-white color-filter-dark-4 call_to_action_32" height="300px">
        <div style={{ width: '100%', height: "600px"}}>
          <img style={{ width: '100%', height: "100%", objectFit: "cover" }} src={`${BOUrl}/uploads/photohomepagebackoffice.jpg`} />

          <div className="container px-xl-0" style={{postion: 'relative', bottom: '100%'}}>
            <div className="row flex-row-reverse align-items-center justify-content-between no-gutters">
              <div className="col-xl-6 col-lg-7 col-md-9 text-center text-md-left pt-100 pb-90 inner">
                <div className="text-adaptive f-30 color-white f-main">
                  Pekoia
                </div>
                <h2 data-aos="fade-down" data-aos-delay="500" className="f-main">
                  LE PLAISIR DE TRANSMETTRE
                </h2>
                <div className="mt-15 mb-50 f-22 text-adaptive f-main" data-aos="fade-down" data-aos-delay="750">
                  Organisez et mettez en avant vos conférences à distance, ateliers à distances ou webinars à l’aide du Back Office de Pekoia.
                </div>
                <div data-aos="fade-down" data-aos-delay="1000">
                  <Button title="Rejoignez-nous" size="lg" onClick={() => discoverButtonHandler('signIn')} />
                </div>
              </div>
              <div className="col-lg-5 col-md-3 d-md-flex justify-content-end">
              </div>
            </div>
          </div>
        </div>

      </section>
      {/* Showcase 2 */}
      <section className="pt-105 pb-90 bg-light text-center showcase_2">
        <div className="container px-xl-0">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <h2 className="small ml-0 color-bg-dark mt-0 pt-0 f-22 text-center mb-50" data-aos="fade-down" data-aos-delay="0">
                A propos de Pekoia
              </h2>
              <div className="mt-15 mb-25 f-22 color-heading text-adaptive" data-aos="fade-down" data-aos-delay="250">
              </div>
            </div>
          </div>
          <div className="row">
            {aboutUs.map((item, index) => (
              <AboutUsItem key={index} title={item.title} image={item.image} description={item.description} delayTime={`${250 * index}`} />
            ))
            }
            <div className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay="0">
            </div>
            <div className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay="250">
            </div>
            <div className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay="500">
            </div>
          </div>
        </div>
        <Button title="A savoir plus" />
      </section>
      {/* Feature 8 */}
      <section className="pt-105 pb-30 feature_8 bg-dark">
        <div className="container px-xl-0">
          <h2 className="mb-50 small text-lg-center color-bg-light" data-aos="fade-down" data-aos-delay="0">
            Pourquoi enseigner sur Pekoia?
          </h2>
          <div className="text-adaptive pt-0 pb-5 color-white f-20 f-main">
            Vous souhaitez partager votre savoir avec une audience curieuse, qui aime le dialogue entre les disciplines, qui souhaite comprendre et questionner le monde dans lequel nous vivons? Organisez et mettez en avant vos conférences à distance, ateliers à distances ou webinars à l’aide du Back Office de Pekoia. Celui-ci vous propose :
          </div>
          <div className="row">
            {blogs.map((item, index) => (
              <TeachingItem key={index} title={item.title} icon={item.icon} description={item.description} delayTime={`${250 * (index % 3 + 1)}`} />
            ))}
          </div>
        </div>
        <div className="text-center mb-5 mt-3">
          <Button title="Rejoignez-nous" size="sm" onClick={() => discoverButtonHandler('signIn')} />
        </div>
      </section>
      {/* Blog 15 */}
      <section className="pt-100 pb-100 bg-light blog_15">
        <div className="container px-xl-0">
          <Link to="#" className="mb-2 link color-heading f-14 bold text-uppercase sp-20" data-aos="fade-down" data-aos-delay="0">
            Intervenants
          </Link>
          <img src="uploads/intervenants.png" alt="Some text about this" />
          <div className="row flex-column-reverse flex-md-row justify-content-between">
            <div className="col-12 col-md-6 col-xl-6">
              <h3 className="mb-40" data-aos="fade-down" data-aos-delay="0">
                A qui s'adresse le back office de Pekoia?
              </h3>
              <div className="mb-lg-5 mw-380 f-18 text-adaptive color-bg-dark" data-aos="fade-down" data-aos-delay="250">
                Professeurs d’université, enseignants en classe préparatoire, chercheurs, écrivains, journalistes, enseignants à la retraite souhaitant continuer à transmettre  … ou tout simplement des passionnés qui souhaitent partager leur savoir dans des formats à taille humaine, en direct pour favoriser l’interactivité et la convivialité.
              </div>
              <div data-aos="fade-down" data-aos-delay="500">
                <Button title="Rejoignez-nous" onClick={() => discoverButtonHandler('signIn')} />
              </div>
            </div>
            <div className="mt-4 mt-md-0 mb-30 mb-md-0 col-12 col-md-6">
              <Link to="#" className="mb-55 link img_link w-full">
                <img src="uploads/intervenant.jpg" alt="" className="w-full radius10" data-aos="fade-down" data-aos-delay="750" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer authState={authState}/>
      {/* forms alerts */}
      <div className="alert alert-success alert-dismissible fixed-top alert-form-success" role="alert">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        Thanks for your message!
      </div>
      <div className="alert alert-warning alert-dismissible fixed-top alert-form-check-fields" role="alert">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        Please, fill in required fields.
      </div>
      <div className="alert alert-danger alert-dismissible fixed-top alert-form-error" role="alert">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <div className="message">An error occurred while sending data :( Please, check if your hosting supports PHP and check how to set form data sending
          <Link to="https://designmodo.com/startup/documentation/#form-handler" target="_blank" className="link color-transparent-white">here</Link>.
        </div>
      </div>
    </>
  )
}
export default Home
