import { createSlice } from '@reduxjs/toolkit'

export const publicationsSlice = createSlice({
    name: 'publications',
    initialState: {
        loading: "true",
        totalCount: 0,
        data: []
    },

    reducers: {
        addPublication: (state, action) => {
            return ({
                loading: "false",
                data: state.data.concat(action.payload),
                totalCount: state.totalCount + 1
            })
        },

        setPublications: (state, action) => {

         
            return ({
                loading: "false",
                data: action.payload,
                totalCount: action.payload.length,
                indexMax: Math.max(...action.payload.map(publication=>publication.index))
            })
        },
        switchPublications: (state, action) => {

            const originIndex = parseInt(action.payload.originIndex)
            const destinationIndex = parseInt(action.payload.destinationIndex)
            const originPublication = state.data.find(publication =>publication.index === originIndex)
            const destinationPublication = state.data.find(publication =>publication.index === destinationIndex)
            originPublication.index = destinationIndex
            destinationPublication.index = originIndex
            
      
        }

    }

})

export const { addPublication, setPublications,  switchPublications } = publicationsSlice.actions

export default publicationsSlice.reducer