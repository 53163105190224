import makeStyles from '@mui/styles/makeStyles'
import { grey, yellow } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({

    datePicker: {
        zIndex: 999
      
    },

    imageContainerError: {

         fontSize: "0.8rem",
         color: grey[600] ,
         backgroundColor: yellow[100]
    },

    imageContainer: {

        fontSize: "0.8rem",
        color: grey[600] 
       
        
   },

    info: {
                 fontSize: '0.8rem',
                 color: `${grey[700]}`,
                 fontWeight: 500
             },

    title1: {
        backgroundColor:yellow[100]
    },

    textFieldRoot: {
        backgroundColor: 'white',
        '&&:hover': {
            borderRadius: '4px',
            backgroundColor: '#f5f6fa',  // white +,
            //backgroundColor: 'red',
            underline: 'none',
           
        },
        '&&:focus': {
            backgroundColor: 'blue',
        },
        '&&:active': {
            //backgroundColor: '#dcdde1',
            backgroundColor: '#f5f6fa'
        },
        '&:active::after': {
            backgroundColor: '#f5f6fa',
        }

    },

    textFieldRootAlt: {
        //backgroundColor: '#8c7ae6',
        backgroundColor: '#f5f6fa'
     
    },
    textFieldRootAlt2: {
        //backgroundColor: '#8c7ae6',
        backgroundColor: 'white',
        '&&:focus': {
            backgroundColor: '#f5f6fa',
        }
     
    },



    textFieldRootInput:{
            color: '#f5f6fa'
    },

    textFieldLabel:{
       
        fontSize: '0.8rem'
    },
    textFieldLabelError:{
       backgroundColor: yellow[100],
        fontSize: '0.8rem'
    },

    selectProps:{

        backgroundColor: 'green',

    },
    dateField: {
        backgroundColor: 'yellow',
        font: "inherit",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        margin: "10px 0",
        padding: "10px",
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
    field: {
        marginTop: "2vw",
        backgroundColor: "#FDFDFD",
        width: "40vw"
    }
}), { index: 1 })

export default useStyles