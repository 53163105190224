import { API, graphqlOperation } from "aws-amplify"
import { updateStates } from "../../graphql/mutations"



export const frontBuild = () => {

    const input = { id: '0', status: 'true'}

    API.graphql(graphqlOperation(updateStates, { input: input }))
        .then(data => {
           

        })
        .catch(err => console.log(err))


}