import React, {useState, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import { indigo, red } from '@mui/material/colors';
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'



const useStyles = makeStyles((theme) => ({
    root: {
       
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    orange: {
        color: theme.palette.getContrastText(indigo[500]),
        backgroundColor: indigo[500],
    },
    purple: {
        color: theme.palette.getContrastText("#aa1d1d"  ),
        backgroundColor: "#aa1d1d",
        '&:hover' :{
            backgroundColor: red[600]
        }
    },
}),{ index: 1 });

export default function LetterAvatars({ data }) {
    const classes = useStyles();

    const [avatarName, setAvatarName] = useState(null)

    useEffect(() => {

        if (data){
            let firstLetter = ""
            let secondLetter = ""
            if (data.prenom)
            {
                firstLetter = data.prenom.substring(0, 1)
            }
            else{
                firstLetter= ""
            }
            if (data.nom)
            {
                secondLetter = data.nom.substring(0, 1)
            }
            else{
                secondLetter= ""
            }
            let avatar = null
            if ((firstLetter === "") && (secondLetter === "")){
                avatar = ""
            }
            else {
                avatar = firstLetter + secondLetter
            }
           
           

            setAvatarName(avatar)
        }
        else {
            setAvatarName("UI")
        }

       

    }, [data])











    return (
        <div className={classes.root}>
            <Avatar className={classes.purple}>{avatarName}</Avatar>
        </div>
    );
}

export const NewAvatar = () =>{

    const classes = useStyles()
    const imageStore = useSelector(state => state.dataReducer.newImageStore)

   


    return(
        <Box marginRight='20px'>
            <Avatar style={{width: '40px',height: "40px"}}>
            {imageStore?.portrait?.data && <img alt="" style={{ width: "60px", height: "60px", objectFit: "cover" }} src={imageStore?.portrait?.data}></img>}
            </Avatar>
        </Box>
    )
}

 