import React from 'react'
import { Link } from 'react-router-dom'

const AboutUsItem = (props) => {
  const {title, description, image, delayTime} = props
  return (
    <div className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay={delayTime}>
      <h2 className="f-24 mt-3">
        {title}
      </h2>
      <Link to="#" className="link img_link color-main mt-30">
        <img src={`${process.env.PUBLIC_URL}${image}`} alt="" className="img-fluid radius10" />
        <div className="mt-20 mb-15 title f-20">
          {description}
        </div>
        <div className="color-heading f-14 semibold text-uppercase sp-20">
        </div>
      </Link>
    </div>
  )
}
export default AboutUsItem
