import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box } from "@mui/system";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton'
import { importedcanvasSecondDraw } from '../helpers/canvasDrawing'
import { Storage } from "aws-amplify";
import { downloadImageBatch } from '../helpers/batches';

const CompanyInfo = ({ companyData }) => {
	const data = useSelector(state => state.dataReducer)
	const [image, setImage] = useState('')
	// const companyData = data.companies && data.companies[0]
	const canvasRef = useRef(null)
	const imageRef = useRef(null)


	useEffect(() => {
		const getImage = async () => {
			const url = await Storage.get(`${companyData.id}/company/${companyData.imageId}.${companyData.imageExt}`, { level: 'private', contentType: `image/${companyData.imageExt}` })
			const response = await fetch(url)
			const blob = await response.blob()
			const res = await new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onloadend = () => {
					resolve(reader.result)
				}
			})
			setImage(res)
		}
		getImage()
	}, [data, companyData])

	return (
		<Box margin="20px">
			<Box>
				<Box>
					<Box margin="30px 0px 0px 0px">
						{companyData.imageId &&
							<Box margin="10px">
								{image && <img ref={imageRef} style={{ width: "130px", height: "130px", objectFit: "cover", borderRadius: "50%" }} src={image} alt="organisme" />}
							</Box>}
					</Box>
					<Box marginBottom='10px'>
						<Typography variant='h2' style={{ fontWeight: 400, textAlign: "start", fontSize: '1.2rem' }}>
							{companyData.fullName}
						</Typography>
					</Box>
				</Box>
				{data.link &&
					<Box>
						<a href={data.link}>
							<LinkedInIcon />
						</a>
					</Box>}
				<Box>
					{companyData.description &&
						<Box margin="0px 0px 20px 0px">
							<Typography margin="10px">
								{companyData.description}
							</Typography>
						</Box>}
					{companyData.hashList &&
						<Box className={testStyle.specialityStyle} margin="0px 0px 10px 0px">
							<Typography variant="h5" style={{ fontWeight: 400, textAlign: "start", fontSize: '1rem' }}>
								Spécialités:
							</Typography>
							<Box margin="10px" display="flex" flexDirection="row" flexWrap="wrap" alignContent="center" alignItems="center">
								{companyData.hashList && companyData.hashList.split('|').map((hash, index) => <Typography marginRight="10px" key={index}>{hash}</Typography>)}
							</Box>
						</Box>}
				</Box>
			</Box>
		</Box>
	);
}

export default CompanyInfo;

const testStyle = makeStyles((theme) => {
	return ({
		specialityStyle: {
			marginTop: "30px",
			display: "flex",
			flexDirection: "column",
			textAlign: "center",
			alignContent: "center",
			[theme.breakpoints.up('sm')]: {
				flexDirection: "row",
				marginTop: "70px",
				alignItems: 'center',
				justifyContent: 'center'
			}
		}
	})
})

