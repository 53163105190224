import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import publicationsReducer from '../features/publications/publicationsSlice'
import eventsReducer from '../features/events/eventsSlice'
import cyclesReducer from '../features/cycles/cyclesSlice'
import navigationReducer from '../features/navigation/nevigationSlice'

import dataReducer from '../features/data/dataSlice'

const reducer = combineReducers({
  publicationsReducer,
  dataReducer,
  eventsReducer,
  cyclesReducer,
  navigationReducer
})

export default configureStore({
 reducer
})