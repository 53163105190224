import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { importedcanvasSecondDraw } from '../helpers/canvasDrawing'
import Typography from '@mui/material/Typography'
import { colorGray, colorBlue, colorTaupeClair } from '../../AppStyle'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { PublishSingleButton } from "../general"
import { updateFormateurBatch } from "../helpers/batches"
import { frontBuild } from '../helpers/lambda'
import Skeleton from '@mui/material/Skeleton'
import Modal00 from '../modal0-0'


import Button from '@mui/material/Button';
import { blueGrey, orange, purple, green, indigo, red, grey } from '@mui/material/colors'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Menu from '@mui/material/Menu';
import styled from 'styled-components'
import withStyles from '@mui/styles/withStyles';
import { getStatusCode } from '../../lemonway/helpers/lib'
import { lemonwayId } from '../../lemonway/helpers/migration'


const SectionDescription = ({ fetchData, user }) => {

    const data = useSelector(state => state.dataReducer)
    const [show, setShow] = useState(false)
    const specialites = data?.hashList?.split('|')
    const [isComplete, setIsComplete] = useState(false)
    const [hasInputStarted, setHasInputStarted] = useState(false)
    const [completedLevel, setCompletedLevel] = useState(0)
    const [statusCodes, setStatusCodes] = useState([-1, -1, -1])

    const [lemonwayData, setLemonwayData] = useState(null)

    const [isActive, setIsActive] = useState(false);

    const conferenceStore = useSelector(state => state.eventsReducer)

    const hasNotStarted = conferenceStore.data.length && conferenceStore.data.filter(event => event.attendingNb).length === 0


    useEffect(() => {
        let interval = null;
        if (!isActive) {
            interval = setInterval(() => {
                setIsActive(true);
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [isActive]);

    const mainMessage = () => {

        if (data && isComplete)
            return ({
                status: 1,
                message: ''
            })

        if (!data?.account && !isActive)
            return ({
                status: 0,
                message: ''
            })

        if ((data && !data.account) && !isComplete)
            return ({
                status: 0,
                message: 'Vous pouvez commencer à compléter votre profil intervenant ainsi que votre compte de paiement.'
            })

        if ((data?.account?.status === 5) && !isComplete)
            return ({
                status: 0,
                message: 'Pensez à compléter votre profil intervenant pour pouvoir commencer à publier vos événements.'
            })

        if (((data && !data.account) || data?.account?.status === 5) && isComplete)
            return ({
                status: 1,
                message: 'Vous devez compléter votre compte de paiement si vous désirez publier des événements payants.'
            })

        if (((data?.account?.status === 6) && isComplete))
            return ({
                status: 1,
                message: ''
            })

        return ({
            status: 0,
            message: ''
        })
    }


    useEffect(() => {

        setIsComplete(data.nom && data.prenom && data.intro && data.hashList && data.hasPhoto)
        setHasInputStarted((data.nom || data.prenom || data.intro || data.hashList || data.hasPhoto))
    }, [data])

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true)

        }, 1000);
        return () => clearInterval(timer);
    }, [])

    useEffect(() => {

        if (data && data.imageStore) {


            const cropData = {
                x: data.pixelCropX, y: data.pixelCropY, width: data.pixelCropWidth, height: data.pixelCropHeight
            }

            importedcanvasSecondDraw(imageRef, canvasRef, cropData)
        }

        if (data[lemonwayId])
            setCompletedLevel(1)

        if (data.documents) {

            if (data?.account?.accountType) {
                const statusTable = getStatusCode(data.documents, data.account.accountType)  // table giving the status of the 3 required documents; -1 if not uploaded 

                setStatusCodes(statusTable)

            }



        }


    }, [data])


    const canvasRef = useRef(null)
    const imageRef = useRef(null)

    const publishAction = () => {

        let batch = null

        if (isComplete) {

            batch = updateFormateurBatch({ id: data.id, state: "published" })
                .then(() => { fetchData(user) })
                .catch(err => console.log(err))

        }
    }

    const unpublishAction = () => {

        let batch = null




        if (data.state === 'published') {

            batch = updateFormateurBatch({ id: data.id, state: "unpublished" })
                .then(() => { fetchData(user) })
                .catch(err => console.log(err))

        }
    }



    return (
        <div>
            <Box display="flex" flexDirection="row" bgcolor={colorGray} padding="20px">
                <Box display="flex" flexDirection="column" flex={10}>
                    <Box display="flex" flexDirection="row" >
                        Etat de votre compte en vue d'une publication:
                        {data && JSON.stringify(data) !== '{}' && <Dot data={data} completedLevel={completedLevel} statusCodes={statusCodes} />}
                    </Box>
                    <Box marginLeft="30px" marginTop="20px">
                        <Typography style={{ fontSize: '0.9rem', color: grey[500] }}>
                            {mainMessage().message}
                        </Typography>



                    </Box>


                </Box>
                <Box width="100px" marginRight="50px">
                    {(mainMessage().status > 0) && (hasNotStarted === true) && <PublishButton publishAction={publishAction} unpublishAction={unpublishAction}>publier</PublishButton>}
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" marginTop="10px">
                <Box flex={5} display="flex" flexDirection="column">
                    <Typography style={{ marginTop: "10px" }} variant="h1" component="h1">{'Votre profil'.toUpperCase()}</Typography>


                </Box>
                {/* <IntervenantForm/> */}
                <Box display="flex" flexDirection="column">



                </Box>
            </Box>


            <Box bgcolor={colorGray} width="70vw" margin="1vw" padding="1vw" height="120px" >

                {!hasInputStarted && show && <Typography style={{ fontSize: "0.9rem", padding: "30px", color: grey[500] }}>
                    Renseignez tous les champs de votre profil intervenant.
                </Typography>}
                <Box display="flex" flexDirection="row">
                    {data && data.hasPhoto && !data.imageStore && <Box width="90px" height="90px"><Skeleton variant="rectangular" width={100} height={90} /></Box>}
                    {data.imageStore && <Box>
                        <canvas style={{ width: "90px", height: "90px" }} ref={canvasRef} />
                        {data.imageStore && <img ref={imageRef} style={{ width: "px", height: "0px", objectFit: "cover", objectPosition: "-40% 0%" }} src={data.imageStore.data} alt="photo de l'intervenant" />}
                    </Box>}
                    <Box marginLeft="15px" padding="5px" display="flex" flexDirection="row" width="100%" >
                        <Box flex={4} >
                            <Typography style={{ color: grey[800], fontWeight: 600 }}>
                                {data.prenom} {data.nom}
                            </Typography>
                            <Typography style={{ fontSize: "0.8rem", paddingTop: "3px", paddingLeft: "10px", lineHeight: "0.9rem" }}>
                                {textSlice(data.intro, 150)}
                            </Typography>

                        </Box>

                        <Box flex={3} marginLeft="30px" flexDirection="column" height="80px" display="flex" justifyContent="space-around" >
                            <Box display="flex" flexDirection="row" flexWrap="wrap">
                                {specialites?.map((item, index) =>
                                    <Box key={index}>
                                        <Typography style={{ borderRadius: "12px", margin: "4px", padding: "2px 10px 2px 10px", backgroundColor: colorTaupeClair, fontSize: "0.8rem" }}>
                                            {item}
                                        </Typography>

                                    </Box>)}

                            </Box >
                            {data.linkedin && <LinkedInIcon style={{ color: colorBlue }} />}
                        </Box>



                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default SectionDescription


const textSlice = (text, maxSize) => {
    if (!text)
        return null
    if (text.length > maxSize)
        return (text.slice(0, maxSize) + ' ...')
    else return text

}


const Dot = ({ data, completedLevel, statusCodes }) => {


    let color = red[500]
    let caption = "incomplet"


    if (data.nom && data.prenom && data.hasPhoto && data.intro && data.hashList) {
        color = orange[200]
        caption = "prêt à la publication"
    }

    if (data.nom && data.prenom && data.hasPhoto && data.intro && data.hashList && !data.account) {
        color = orange[200]
        caption = "Détails du compte de paiement non renseignés"
    }

    if (data.nom && data.prenom && data.hasPhoto && data.intro && data.hashList && data.account && (data.account.status === 3)) {
        color = orange[800]
        caption = "Compte de paiement incomplet - pièces rejetées"
    }

    if (data.nom && data.prenom && data.hasPhoto && data.intro && data.hashList && data.account && (data.account.status === 2)) {
        color = orange[800]
        caption = "Compte de paiement incomplet - pièces incomplètes"
    }

    if (data.nom && data.prenom && data.hasPhoto && data.intro && data.hashList && data.account && (data.account.status === 5)) {
        color = orange[200]
        caption = "Vérification de votre dossier en cours"

        if (completedLevel === 0)
            caption = "Informations manquantes pour votre compte de paiement"

        if (completedLevel === 1) {
            if (Math.min(...statusCodes) === -1)
                caption = "Compte de paiement incomplet. Document(s) non téléchargé(s)"
            else if ([0, 1].includes(Math.min(...statusCodes)))
                caption = "Validation du compte de paiement en cours"
            else if (Math.max(statusCodes) === 2)
                caption = "Dossier de paiement validé"
            else if (Math.max(statusCodes[0], statusCodes[1], statusCodes[2]) > 2)
                caption = "Compte de paiement incomplet. Document(s) refusé(s)"


        }

    }

    if (data.state && (data.state === "published")) {
        color = green[300]
        caption = "en ligne"
    }



    return (<Box marginLeft="25px" display="flex" flexDirection="row" position="relative" top="3px">
        <Box width="15px" height="15px">
            <svg x="0px" y="0px" viewBox="0 0 150 150" >
                <circle cx={50} cy={80} r={50} fill={color} />
            </svg>

        </Box>
        <Box>
            <Typography style={{ marginLeft: "10px", fontSize: "0.8rem" }}>
                {caption}
            </Typography>
        </Box>
    </Box>
    )
}


const PublishButton = ({ publishAction, unpublishAction }) => {

    const handleClose = () => { }
    const [anchorEl, setAnchorEl] = useState(null);
    const [show, setShow] = useState(false)



    const SecondButton = withStyles((theme) => ({
        root: {
            display: 'inline-block',
            padding: 0,
            minHeight: 0,
            minWidth: "30px",
            borderRadius: 0,
            color: theme.palette.getContrastText(blueGrey[200]),
            backgroundColor: blueGrey[200],
            '&:hover': {
                backgroundColor: blueGrey[300],
            },
        },
    }))(Button)






    const handleClick = (event) => {
        setShow(!show)
        setAnchorEl(event.currentTarget);
    };

    const menuClick = (key) => event => {


        if (key === "publier") {
            publishAction()
        }

        if (key === "dépublier") {
            unpublishAction()
        }

        frontBuild()
        setShow(false)

    }

    return (<Box height="35px">
        <Box display="flex" flexDirection="row" width="70px">
            <PublishSingleButton onClick={menuClick("publier")}>
                Publier
            </PublishSingleButton>
            <SecondButton onClick={handleClick}>
                {!show && <ArrowDropDownIcon />}
                {show && <ArrowDropUpIcon />}
            </SecondButton>


        </Box >
        <Box height={show ? "65px" : "65px"}>
            <LocalMenu menuClick={menuClick} show={show} setShow={setShow} />
        </Box>

    </Box>)
}

const LocalMenu = ({ menuClick, show, setShow }) => {


    const MyMenu = withStyles((theme) => ({
        root: {
            backgroundColor: "pink",
            width: "300px"
        }

    }))(Menu)









    return (<Box display={show ? "block" : "none"} onMouseLeave={() => setShow(false)}>
        <ul style={{ listStyle: "none", zIndex: "5", position: "relative", height: "85px", padding: "5px", width: "130px", left: "10px", bottom: "20px", backgroundColor: "white", border: "1px solid" }}>
        <StyledLi onClick={menuClick("publier")}>
            Publier2
        </StyledLi>
        <StyledLi onClick={menuClick("dépublier")}>
            Dépublier
        </StyledLi>

    </ul>
    </Box >)
}

const StyledLi = styled.button`
padding: 5px;
font-size: 0.9rem;
border: 0px solid;
margin-left: 30px;
background-color: white;
outline: none;
cursor: pointer;
&:hover {
  font-weight: bold;
}
`;