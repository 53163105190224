import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as Logo } from "../../assets/SVG_header_back_office.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { grey, yellow, indigo, orange } from "@mui/material/colors";
import { Typography } from "@mui/material";
import hash from "object-hash";

import { setMenuPage } from "../../features/navigation/nevigationSlice";

import TextField from "@mui/material/TextField";
import { countries } from "../../assets/country_codes";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";

import { randomId } from "../../lemonway/helpers/codeGen";
import moment from "moment";

import {
  createIndividualAccount,
  createCompanyAccount,
  getAccountDetails,
  uploadDocument,
} from "../../lemonway/sandbox/createIndividuialAccount";
import { DBupdateFormateur } from "../../components/handleClose";
import { lemonwayId } from "../../lemonway/helpers/migration";
import { FileUpload } from "../../helpers/fileUpload";

import { useSelector, useDispatch } from "react-redux";
import {
  testEmail,
  testDate,
  documentStatus,
  getStatusCode,
  sections,
  sectionsSociete,
} from "../../lemonway/helpers/lib";

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import Radio from "@mui/material/Radio";

const updateStore = (store, key, value) => {
  store[1]({ ...store[0], [key]: value });
};

function UploadPage({ fetchData, user, isInde }) {
  const data = useSelector((state) => state.dataReducer);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(isInde ? 0 : 1);

  const [completedLevel, setCompletedLevel] = useState(0);
  const [statusCodes, setStatusCodes] = useState([-1, -1, -1]);

  const [lemonwayData, setLemonwayData] = useState(null);
  const [completedSpinner, setCompletedSpinner] = useState(false);

  const localStore = useState({
    societeName: "",
    societeId: "",
    societeDescription: "",
    nom: "",
    prenom: "",
    nationalityInput: "",
    nationalityList: [],
    completed: false,
    email: "",
    birthDate: "",
    domicileCountryInput: "",
    domicileCountry: "",
  });

  useEffect(() => {
    //  console.log('@STORE')
    //  console.log(localStore[0])
  }, [hash(localStore)]);

  useEffect(() => {
    const newLocalStore = {
      societeName:
        data && data.account?.company?.name ? data.account.company.name : "",
      societeId:
        data && data.account?.company?.identificationNumber
          ? data.account.company.identificationNumber
          : "",
      societeDescription:
        data && data.account?.company?.description
          ? data.account.company.description
          : "",
      nom: data && data.account ? data.account.lastname : "",
      prenom: data && data.account ? data.account.firstname : "",
      nationalityInput: "",
      nationalityList:
        data && data.account ? data.account.nationality.split(",") : [],
      completed: false,
      email: data && data.account ? data.account.email : "",
      birthDate:
        data && data.account
          ? moment(data.account.birth.date, "DD/MM/YYYY").format("DD/MM/YYYY")
          : "",
      domicileCountryInput:
        data && data.account ? data.account.adresse.country : "",
      domicileCountry:
        data && data.account
          ? getCountryName(data.account.adresse.country)
          : "",
    };

    localStore[1](newLocalStore);
  }, [data]);

  // const [nom, setNom] = useState(data && data.account ? data.account.lastname : '')
  // const [prenom, setPrenom] = useState(data && data.account ? data.account.firstname : '')
  // const [nationalyInput, setNationalyInput] = useState('')
  // const [nationalityList, setNationalityList] = useState(data && data.account ? data.account.nationality.split(',') : [])
  // const [completed, setCompleted] = useState(false)
  // const [email, setEmail] = useState(data && data.account ? data.account.email : '')
  // const [birthDate, setBirthDate] = useState(data && data.account ? moment(data.account.birth.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : '')
  // const [domicileCountry, setDomicileCountry] = useState(data && data.account ? data.account.adresse.country : '')
  // const [domicileCountryInput, setDomicileCountryInput] = useState(data && data.account ? getCountryName(data.account.adresse.country) : '')

  const handleChange = (event) => {
    if (!(data.account?.accountType === 0 || data.account?.accountType === 1))
      setValue(event.target.value);
  };

  useEffect(() => {
    if (data)
      if (data[lemonwayId]) {
        setCompletedLevel(1);
        setCompletedSpinner(false);
        getAccountDetails(data[lemonwayId], data.id, setLemonwayData);
      }

    if (data.documents && data.account) {
      const statusTable = getStatusCode(
        data.documents,
        data.account.accountType
      ); // table giving the status of the 3 required documents; -1 if not uploaded

      setStatusCodes(statusTable);
    }

    if (data.account?.accountType) {
      setValue(data.account.accountType);
    }
  }, [data]);

  return (
    <Box
      width="80vw"
      height={parseInt(value) === 0 ? "1900px" : "2500px"}
      bgcolor={grey[100]}
      paddingTop="30px"
    >
      <TopSection />
      <Box
        display="flex"
        flexDirection="column"
        width="900px"
        height="80px"
        bgcolor="white"
        padding="20px"
        margin="0 auto"
        borderRadius="20px"
        marginBottom="30px"
        paddingTop="20px"
      >
        <Box>
          {isInde ? (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="type"
                value={value}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Box marginLeft="0px">
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="personne physique"
                    />
                  </Box>
                  <Box marginLeft="30px">
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="personne morale"
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
          ) : (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="type"
                value={value}
                // onChange={handleChange}
                name="radio-buttons-group"
              >
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Box marginLeft="30px">
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="personne morale"
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
          )}
        </Box>
      </Box>
      {parseInt(value) === 0 && (
        <DataSection
          localStore={localStore}
          completedSpinner={completedSpinner}
          setCompletedSpinner={setCompletedSpinner}
          fetchData={fetchData}
          user={user}
          data={data}
          completedLevel={completedLevel}
        />
      )}
      {parseInt(value) === 1 && (
        <DataSectionSociete
          localStore={localStore}
          completedSpinner={completedSpinner}
          setCompletedSpinner={setCompletedSpinner}
          fetchData={fetchData}
          user={user}
          data={data}
          completedLevel={completedLevel}
        />
      )}

      <Box
        width="900px"
        bgcolor="white"
        padding="20px"
        margin="0 auto"
        borderRadius="20px"
        border={completedLevel > 0 ? `3px solid ${indigo[400]}` : "none"}
      >
        <Box display="flex" flexDirection="row">
          <Box width="50px">
            <CustomAvatar
              number="2"
              state={completedLevel > 0 ? true : false}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
            marginLeft="10px"
          >
            <Typography style={{ fontWeight: 500, color: indigo[700] }}>
              {"téléchargement des documents".toUpperCase()}
            </Typography>
          </Box>
        </Box>

        {parseInt(value) === 0 && (
          <Box>
            {sections.map((section, index) => (
              <UploadSection
                key={index}
                status={statusCodes[index]}
                completedLevel={completedLevel}
                sectionData={section}
                data={data}
                lastLine={index === sections.length - 1}
              />
            ))}
          </Box>
        )}
        {parseInt(value) === 1 && (
          <Box>
            {sectionsSociete.map((section, index) => (
              <UploadSection
                key={index}
                status={statusCodes[index]}
                completedLevel={completedLevel}
                sectionData={section}
                data={data}
                lastLine={index === sections.length - 1}
              />
            ))}
          </Box>
        )}

        <Box padding="20px">
          <Typography>
            Vos pièces sont analysées sous 48 heures par Lemonway. Veuillez-vous
            reconnecter sur cette page “brique de paiement” au plus tard dans 48
            heures pour vérifier la bonne validation des pièces.
          </Typography>
        </Box>
        <Box paddingTop="30px" textAlign="center">
          <SubmitButtonOn onClick={() => dispatch(setMenuPage(0))}>
            Retour à la homepage
          </SubmitButtonOn>
        </Box>
      </Box>
    </Box>
  );
}

export default UploadPage;

const DataSection = ({
  localStore,
  completedSpinner,
  setCompletedSpinner,
  fetchData,
  user,
  data,
  completedLevel,
}) => {
  // const nom = localStore[0].nom
  // const prenom = localStore[0].prenom
  // const nationalityInput = localStore[0].nationalityInput
  // const nationalityList = localStore[0].nationalityList
  // const completed = localStore[0].completed
  // const email = localStore[0].email
  // const birthDate = localStore[0].birthDate
  // const domicileCountry = localStore[0].domicileCountry
  // const domicileCountryInput = localStore[0].domicileCountryInput

  // const [nom, setNom] = useState(data && data.account ? data.account.lastname : '')
  // const [prenom, setPrenom] = useState(data && data.account ? data.account.firstname : '')
  // const [nationalyInput, setNationalyInput] = useState('')
  // const [nationalityList, setNationalityList] = useState(data && data.account ? data.account.nationality.split(',') : [])
  // const [completed, setCompleted] = useState(false)
  // const [email, setEmail] = useState(data && data.account ? data.account.email : '')
  // const [birthDate, setBirthDate] = useState(data && data.account ? moment(data.account.birth.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : '')
  // const [domicileCountry, setDomicileCountry] = useState(data && data.account ? data.account.adresse.country : '')
  // const [domicileCountryInput, setDomicileCountryInput] = useState(data && data.account ? getCountryName(data.account.adresse.country) : '')

  const submitTopSection = () => {
    const newId = randomId();
    setCompletedSpinner(true);

    const lemonData = {
      accountId: newId,
      email: localStore[0].email,
      firstName: localStore[0].prenom,
      lastName: localStore[0].nom,
      nationality: localStore[0].nationalityList.join(","),
      adresse: {
        country: localStore[0].domicileCountry,
      },
      birth: {
        date: moment(localStore[0].birthDate, "DD/MM/YYYY").format(
          "YYYY/MM/DD"
        ),
      },
      payerOrBeneficiary: 2,
    };

    console.log("@NEW ACCOUNT");
    console.log(lemonData);

    createIndividualAccount(lemonData, DBupdateFormateur, fetchData, user);
  };

  useEffect(() => {
    if (
      localStore[0].nom?.length &&
      localStore[0].prenom?.length &&
      localStore[0].nationalityList?.length &&
      localStore[0].email &&
      testEmail(localStore[0].email) &&
      localStore[0].birthDate &&
      testDate(localStore[0].birthDate) &&
      localStore[0].domicileCountry &&
      localStore[0].domicileCountry
    )
      localStore[1]({ ...localStore[0], completed: true });
    else localStore[1]({ ...localStore[0], completed: false });
  }, [hash(localStore)]);

  const handleChange = (item) => (event) => {
    if (completedLevel) return null;
    if (item === "nationality") {
      updateStore(localStore, "nationalityInput", event.target.value);

      // setNationalyInput(event.target.value)
      const potentialCountries = countries.filter(
        (country) =>
          cleanText(country.name)
            .slice(0, event.target.value.length)
            .toLocaleLowerCase() ===
          cleanText(event.target.value).toLocaleLowerCase()
      );

      console.log("@POT2");
      console.log(potentialCountries);
      if (
        potentialCountries.length < 5 &&
        potentialCountries.length > 0 &&
        event.target.value.length
      )
        setOpenMenu(true);
      else setOpenMenu(false);
    }

    if (item === "nom") updateStore(localStore, "nom", event.target.value);
    // setNom(event.target.value)
    if (item === "prenom")
      updateStore(localStore, "prenom", event.target.value);
    // setPrenom(event.target.value)
    if (item === "email") updateStore(localStore, "email", event.target.value);
    //setEmail(event.target.value)
    if (item === "domicileCountryInput") {
      updateStore(localStore, "domicileCountryInput", event.target.value);

      //setDomicileCountryInput(event.target.value)
      const potentialCountries = countries.filter(
        (country) =>
          cleanText(country.name)
            .slice(0, event.target.value.length)
            .toLocaleLowerCase() ===
          cleanText(event.target.value).toLocaleLowerCase()
      );

      if (
        potentialCountries.length < 5 &&
        potentialCountries.length > 0 &&
        event.target.value.length
      )
        setOpenMenu2(true);
      else setOpenMenu2(false);
    }

    if (item === "birthDate")
      updateStore(localStore, "birthDate", event.target.value);
    //setBirthDate(event.target.value)
  };

  const classes = useStyles();
  const textFieldWidth = "450px";
  const textFieldPadding = "10px 0 10px 0";
  const anchorEl = useRef(null);
  const anchorEl2 = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="900px"
      bgcolor="white"
      padding="20px"
      margin="0 auto"
      borderRadius="20px"
      marginBottom="30px"
      paddingTop="20px"
      border={!completedLevel ? `3px solid ${indigo[400]}` : "none"}
    >
      <Box display="flex" flexDirection="row">
        <Box width="50px">
          <CustomAvatar number="1" state={true} />
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignContent="center"
          alignItems="center"
          marginLeft="10px"
        >
          <Typography style={{ fontWeight: 500, color: indigo[700] }}>
            {"informations du compte".toUpperCase()}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row">
        <Box width="550px">
          <Box display="flex" flexDirection="column" flex={2}>
            <form className={classes.root} noValidate autoComplete="off">
              <Box padding="20px 0 20px 0">
                <Typography style={{ fontSize: "0.9rem", color: indigo[500] }}>
                  information personnelle
                </Typography>
              </Box>

              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Email"
                  error={!testEmail(localStore[0].email)}
                  value={localStore[0].email}
                  onChange={handleChange("email")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      localStore[0].email != data?.account?.email &&
                      data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>

              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Prenom"
                  value={localStore[0].prenom}
                  onChange={handleChange("prenom")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      localStore[0].prenom != data?.account?.firstname &&
                      data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Nom"
                  value={localStore[0].nom}
                  onChange={handleChange("nom")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      localStore[0].nom.toUpperCase() !=
                        data?.account?.lastname && data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="column">
                  <Box width={textFieldWidth} padding={textFieldPadding}>
                    <TextField
                      label="Nationalité(s)"
                      value={localStore[0].nationalityInput}
                      onChange={handleChange("nationality")}
                      style={{
                        width: textFieldWidth,
                        borderBottom: `1px solid ${grey[100]}`,
                      }}
                    />
                  </Box>
                  <Box
                    marginLeft="50px"
                    position="relative"
                    bottom="70px"
                    width="10px"
                    ref={anchorEl}
                  ></Box>
                </Box>

                <Box>
                  <Popover
                    open={openMenu}
                    anchorEl={anchorEl.current}
                    // onClose={handleClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <SelectionContainer
                      setOpenMenu={setOpenMenu}
                      localStore={localStore}
                    />
                  </Popover>
                  <Box position="relative" right="300px" top="10px">
                    <DisplayCountries localStore={localStore} />
                  </Box>
                </Box>
              </Box>

              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Date de naissance (format JJ/MM/YYYY)"
                  error={!testDate(localStore[0].birthDate)}
                  value={localStore[0].birthDate}
                  onChange={handleChange("birthDate")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      areDatesDifferent(
                        localStore[0].birthDate,
                        data?.account?.birth?.date
                      ) && data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Pays de domicile"
                  value={localStore[0].domicileCountryInput}
                  onChange={handleChange("domicileCountryInput")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      localStore[0].domicileCountryInput !=
                        getCountryName(data?.account?.adresse.country) &&
                      data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box
                ref={anchorEl2}
                position="relative"
                left="50px"
                bottom="40px"
              ></Box>
              <Popover
                open={openMenu2}
                anchorEl={anchorEl2.current}
                // onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <SelectionContainer2
                  setOpenMenu2={setOpenMenu2}
                  localStore={localStore}
                />
              </Popover>
            </form>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box marginTop="10px">
              {!localStore[0].completed || completedLevel ? (
                <SubmitButtonOff>
                  Soumettre les données personnelles
                </SubmitButtonOff>
              ) : (
                <SubmitButtonOn onClick={() => submitTopSection()}>
                  Soumettre les données personnelles
                </SubmitButtonOn>
              )}
            </Box>
            {completedSpinner && (
              <Box position="relative" left="20px" top="10px">
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DataSectionSociete = ({
  localStore,
  completedSpinner,
  setCompletedSpinner,
  fetchData,
  user,
  data,
  completedLevel,
}) => {
  const societeName = localStore[0].societeName;
  const societeId = localStore[0].societeId;
  const societeDescription = localStore[0].societeDescription;
  const nom = localStore[0].nom;
  const prenom = localStore[0].prenom;
  const nationalityInput = localStore[0].nationalityInput;
  const nationalityList = localStore[0].nationalityList;
  const completed = localStore[0].completed;
  const email = localStore[0].email;
  const birthDate = localStore[0].birthDate;
  const domicileCountry = localStore[0].domicileCountry;
  const domicileCountryInput = localStore[0].domicileCountryInput;

  // const [societeName, setSocieteName] = useState(data && data.account?.company?.name ? data.account.company.name : '')
  // const [societeId, setSocieteId] = useState(data && data.account?.company?.identificationNumber ? data.account.company.identificationNumber : '')
  // const [societeDescription, setSocieteDescription] = useState(data && data.account?.company?.description ? data.account.company.description : '')
  // const [nom, setNom] = useState(data && data.account ? data.account.lastname : '')
  // const [prenom, setPrenom] = useState(data && data.account ? data.account.firstname : '')
  // const [nationalyInput, setNationalyInput] = useState('')
  // const [nationalityList, setNationalityList] = useState(data && data.account ? data.account.nationality.split(',') : [])
  // const [completed, setCompleted] = useState(false)
  // const [email, setEmail] = useState(data && data.account ? data.account.email : '')
  // const [birthDate, setBirthDate] = useState(data && data.account ? moment(data.account.birth.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : '')
  // const [domicileCountry, setDomicileCountry] = useState(data && data.account ? data.account.adresse.country : '')
  // const [domicileCountryInput, setDomicileCountryInput] = useState(data && data.account ? getCountryName(data.account.adresse.country) : '')

  const submitTopSection = () => {
    const newId = randomId();
    setCompletedSpinner(true);

    const lemonData = {
      accountId: newId,
      company: {
        name: societeName,
        description: societeDescription,
        identificationNumber: societeId,
      },
      email: email,
      firstName: prenom,
      lastName: nom,
      nationality: nationalityList.join(","),
      adresse: {
        country: domicileCountry,
      },
      birth: {
        date: moment(birthDate, "DD/MM/YYYY").format("YYYY/MM/DD"),
      },
      payerOrBeneficiary: 2,
    };

    console.log("@CREATE");
    console.log(lemonData);
    createCompanyAccount(lemonData, DBupdateFormateur, fetchData, user);
  };

  useEffect(() => {
    if (data) {
      if (data.account) {
        const { email, firstName, lastName, adresse, birth, nationality } =
          data.account;
        const newStoreData = {};

        if (lastName) newStoreData.nom = lastName;
        //updateStore(localStore, 'nom', lastName)
        // setNom(lastName)
        if (firstName) newStoreData.prenom = prenom;
        updateStore(localStore, "prenom", prenom);
        //   setPrenom(firstName)
        if (email) newStoreData.email = email;
        //updateStore(localStore, 'email', email)
        // setEmail(email)
        if (adresse?.country) newStoreData.domicileCountry = adresse.country;

        // updateStore(localStore, 'domicileCountry', adresse.country)
        // setDomicileCountry(adresse.country)
        if (birth.data)
          newStoreData.birthDate = moment(birth.date, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          );
        //  updateStore(localStore, 'birthDate', moment(birth.date, 'DD/MM/YYYY').format('DD/MM/YYYY'))
        // setBirthDate(moment(birth.date, 'DD/MM/YYYY').format('DD/MM/YYYY'))
        if (nationality) newStoreData.nationalityList = nationality.split(",");
        //  updateStore(localStore, 'nationalityList', nationality.split(','))
        // setNationalityList(nationality.split(','))

        localStore[1]({ ...localStore[0], ...newStoreData });
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      nom?.length &&
      prenom?.length &&
      nationalityList?.length &&
      email &&
      testEmail(email) &&
      birthDate &&
      testDate(birthDate) &&
      domicileCountry &&
      domicileCountry &&
      societeName?.length &&
      societeId?.length &&
      societeDescription?.length
    )
      updateStore(localStore, "completed", true);
    else updateStore(localStore, "completed", false);
  }, [
    nom,
    prenom,
    nationalityList,
    email,
    birthDate,
    domicileCountry,
    societeName,
    societeId,
    societeDescription,
  ]);

  const handleChange = (item) => (event) => {
    if (completedLevel) return null;
    if (item === "nationality") {
      updateStore(localStore, "nationalityInput", event.target.value);

      // setNationalyInput(event.target.value)
      const potentialCountries = countries.filter(
        (country) =>
          cleanText(country.name)
            .slice(0, event.target.value.length)
            .toLocaleLowerCase() ===
          cleanText(event.target.value).toLocaleLowerCase()
      );

      console.log("@POT");
      console.log(potentialCountries);

      if (
        potentialCountries.length > 0 &&
        potentialCountries.length < 5 &&
        event.target.value.length
      )
        setOpenMenu(true);
      else setOpenMenu(false);
    }
    if (item === "societeName")
      updateStore(localStore, "societeName", event.target.value);
    // setSocieteName(event.target.value)
    if (item === "societeDescription")
      updateStore(localStore, "societeDescription", event.target.value);
    // setSocieteDescription(event.target.value)
    if (item === "societeId")
      updateStore(localStore, "societeId", event.target.value);
    // setSocieteId(event.target.value)

    if (item === "nom") updateStore(localStore, "nom", event.target.value);
    // setNom(event.target.value)
    if (item === "prenom")
      updateStore(localStore, "prenom", event.target.value);
    // setPrenom(event.target.value)
    if (item === "email") updateStore(localStore, "email", event.target.value);
    // setEmail(event.target.value)
    if (item === "domicileCountryInput") {
      updateStore(localStore, "domicileCountryInput", event.target.value);

      // setDomicileCountryInput(event.target.value)
      const potentialCountries = countries.filter(
        (country) =>
          cleanText(country.name)
            .slice(0, event.target.value.length)
            .toLocaleLowerCase() ===
          cleanText(event.target.value).toLocaleLowerCase()
      );

      if (
        potentialCountries.length < 5 &&
        potentialCountries.length > 0 &&
        event.target.value.length
      )
        setOpenMenu2(true);
      else setOpenMenu2(false);
    }

    if (item === "birthDate")
      updateStore(localStore, "birthDate", event.target.value);
    // setBirthDate(event.target.value)
  };

  const classes = useStyles();
  const textFieldWidth = "450px";
  const textFieldPadding = "10px 0 10px 0";
  const anchorEl = useRef(null);
  const anchorEl2 = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="900px"
      bgcolor="white"
      padding="20px"
      margin="0 auto"
      borderRadius="20px"
      marginBottom="30px"
      paddingTop="20px"
      border={!completedLevel ? `3px solid ${indigo[400]}` : "none"}
    >
      <Box display="flex" flexDirection="row">
        <Box width="50px">
          <CustomAvatar number="1" state={true} />
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignContent="center"
          alignItems="center"
          marginLeft="10px"
        >
          <Typography style={{ fontWeight: 500, color: indigo[700] }}>
            {"informations du compte".toUpperCase()}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row">
        <Box width="550px">
          <Box display="flex" flexDirection="column" flex={2}>
            <form className={classes.root} noValidate autoComplete="off">
              <Box padding="20px 0 20px 0">
                <Typography style={{ fontSize: "0.9rem", color: indigo[500] }}>
                  information de la société
                </Typography>
              </Box>
              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Nom de la société"
                  value={societeName}
                  onChange={handleChange("societeName")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      email != data?.account?.email && data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="SIRET / SIREN"
                  value={societeId}
                  onChange={handleChange("societeId")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      email != data?.account?.email && data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Description"
                  error={!testEmail(email)}
                  value={societeDescription}
                  onChange={handleChange("societeDescription")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      email != data?.account?.email && data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box padding="20px 0 20px 0">
                <Typography style={{ fontSize: "0.9rem", color: indigo[500] }}>
                  information du representant légal
                </Typography>
              </Box>
              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Email"
                  error={!testEmail(email)}
                  value={email}
                  onChange={handleChange("email")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      email != data?.account?.email && data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>

              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Prenom"
                  value={prenom}
                  onChange={handleChange("prenom")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      prenom != data?.account?.firstname && data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Nom"
                  value={nom}
                  onChange={handleChange("nom")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      nom.toUpperCase() != data?.account?.lastname &&
                      data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="column">
                  <Box width={textFieldWidth} padding={textFieldPadding}>
                    <TextField
                      label="Nationalité(s)"
                      value={localStore[0].nationalityInput}
                      onChange={handleChange("nationality")}
                      style={{
                        width: textFieldWidth,
                        borderBottom: `1px solid ${grey[100]}`,
                      }}
                    />
                  </Box>
                  <Box
                    marginLeft="50px"
                    position="relative"
                    bottom="70px"
                    width="10px"
                    ref={anchorEl}
                  ></Box>
                </Box>

                <Box>
                  <Popover
                    open={openMenu}
                    anchorEl={anchorEl.current}
                    // onClose={handleClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <SelectionContainer
                      setOpenMenu={setOpenMenu}
                      localStore={localStore}
                    />
                  </Popover>
                  <Box position="relative" right="300px" top="10px">
                    <DisplayCountries localStore={localStore} />
                  </Box>
                </Box>
              </Box>

              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Date de naissance (format JJ/MM/YYYY)"
                  error={!testDate(localStore[0].birthDate)}
                  value={localStore[0].birthDate}
                  onChange={handleChange("birthDate")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      areDatesDifferent(
                        localStore[0].birthDate,
                        data?.account?.birth?.date
                      ) && data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box width={textFieldWidth} padding={textFieldPadding}>
                <TextField
                  label="Pays de domicile"
                  value={localStore[0].domicileCountryInput}
                  onChange={handleChange("domicileCountryInput")}
                  style={{
                    width: textFieldWidth,
                    borderBottom:
                      localStore[0].domicileCountryInput !=
                        getCountryName(data?.account?.adresse.country) &&
                      data.account
                        ? `2px solid ${yellow[800]}`
                        : `1px solid ${grey[100]}`,
                  }}
                />
              </Box>
              <Box
                ref={anchorEl2}
                position="relative"
                left="50px"
                bottom="40px"
              ></Box>
              <Popover
                open={openMenu2}
                anchorEl={anchorEl2.current}
                // onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <SelectionContainer2
                  setOpenMenu2={setOpenMenu2}
                  localStore={localStore}
                />
              </Popover>
            </form>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box marginTop="10px">
              {!completed || completedLevel ? (
                <SubmitButtonOff>
                  Soumettre les données personnelles
                </SubmitButtonOff>
              ) : (
                <SubmitButtonOn onClick={() => submitTopSection()}>
                  Soumettre les données personnelles
                </SubmitButtonOn>
              )}
            </Box>
            {completedSpinner && (
              <Box position="relative" left="20px" top="10px">
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const TopSection = () => {
  return (
    <Box
      width="900px"
      bgcolor="white"
      padding="10px"
      margin="0 auto"
      borderRadius="20px"
      marginBottom="30px"
      paddingTop="20px"
    >
      <Typography
        style={{ fontSize: "1rem", textAlign: "center", padding: "30px" }}
      >
        <Logo style={{ height: "80px" }} />
      </Typography>
      <Typography style={{ fontSize: "1.5rem", textAlign: "center" }}>
        Dossier de création du compte intervenant
      </Typography>
    </Box>
  );
};

const UploadSection = ({
  sectionData,
  lastLine,
  data,
  completedLevel,
  status,
}) => {
  const document =
    status >= -1
      ? documentStatus.find((document) => document.code === status)
      : {};
  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fileHook = useState(null);
  const { description, title, warning, additionlWarning } = sectionData;

  useEffect(() => {
    if (fileHook[0]?.base64 && data?.account.id) {
      uploadDocument(
        data.id,
        data.account.id,
        fileHook[0].name,
        fileHook[0].base64,
        parseInt(fileHook[0].code),
        fileHook[0].callback
      );
    }
  }, [fileHook[0]]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        bgcolor="white"
        padding="50px"
        borderBottom={!lastLine ? `1px dashed ${grey[300]}` : "0px solid"}
      >
        <Box
          width="35%"
          textAlign="center"
          color={grey[600]}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
            {description}
          </Typography>

          <Box padding="15px">
            <LightTooltip
              title={
                <div style={{ whiteSpace: "pre-line" }}>
                  <p>{title}</p>
                  <p style={{ color: orange[200], fontWeight: 600 }}>
                    {warning}
                  </p>
                </div>
              }
              style={{ color: grey[400] }}
            >
              <InfoIcon />
            </LightTooltip>
          </Box>
        </Box>
        <Box>
          <Box width="25%" position="relative" bottom="9px">
            <Box
              width="10px"
              height="10px"
              position="relative"
              top="9px"
              right="15px"
            >
              <svg viewBox="0 0 150 150">
                <circle cx={75} cy={75} r={60} fill={document.color} />
              </svg>
            </Box>

            <Box display="flex" flexDirection="column">
              <Box width="200px">
                <Typography style={{ fontSize: "0.8rem" }}>
                  {document.label}
                </Typography>
              </Box>
              <Box
                padding="10px"
                width="250px"
                position="relative"
                right="20px"
              >
                <Typography style={{ fontSize: "0.8rem", color: "red" }}>
                  {errorMessage}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          width="30%"
          height="40px"
          textAlign="center"
          display="flex"
          flexDirection="roxw"
        >
          <FileUpload
            setErrorMessage={setErrorMessage}
            setSpinner={setSpinner}
            modalStoreHook={fileHook}
            sectionData={sectionData}
            completedLevel={completedLevel}
          />
          {spinner && (
            <Box position="relative" right="40%">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
      {additionlWarning && (
        <Box
          textAlign="center"
          position="relative"
          bottom="30px"
          border={`2px solid ${orange[900]}`}
          borderRadius="15px"
          padding="20px"
          margin="0 40px 0 40px"
        >
          <Typography style={{ fontWeight: 400, color: orange[900] }}>
            Pour votre carte d'identité, veillez à télécharger le recto et le
            verso{" "}
            <span style={{ fontWeight: 600, textDecoration: "underline" }}>
              sur une seule page.
            </span>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const SelectionContainer = ({ setOpenMenu, localStore }) => {
  //   const potentialCountries = countries
  //           .filter(country => (cleanText(country.name).slice(0, nationalyInput.length).toLocaleLowerCase() === cleanText(nationalyInput).toLocaleLowerCase()))

  // setNb(potentialCountries.length)

  const handleClick = (code) => (event) => {
    const newStoreData = {};
    if (localStore[0].nationalityList.indexOf(code) === -1)
      newStoreData.nationalityList = localStore[0].nationalityList.concat(code);
    // updateStore(localStore, 'nationalityList', localStore[0].nationalityList.concat(code))
    // setNationalityList(nationalityList.concat(code))
    newStoreData.nationalityInput = "";
    //updateStore(localStore, 'nationalityInput', '')
    // setNationalyInput('')
    localStore[1]({ ...localStore[0], ...newStoreData });
    setOpenMenu(false);
  };

  return (
    localStore[0].nationalityInput?.length > 0 && (
      <Box bgcolor={grey[100]} padding="20px" width="200px" zIndex="modal">
        <Box marginBottom="10px">
          <Typography style={{ fontWeight: 600, lineHeight: "0.9rem" }}>
            Sélectionnez la nationalité:
          </Typography>
        </Box>
        {countries
          .filter(
            (country) =>
              cleanText(country.name)
                .slice(0, localStore[0].nationalityInput?.length)
                .toLocaleLowerCase() ===
              cleanText(localStore[0].nationalityInput).toLocaleLowerCase()
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .slice(0, 4)
          .map((country) => {
            return (
              <TextChoice
                key={country.code}
                onClick={handleClick(country.code)}
              >
                <Box padding="5px" borderRadius="10px" bgcolor={orange[100]}>
                  <Typography style={{ textAlign: "center" }}>
                    {country.name}
                  </Typography>
                </Box>
              </TextChoice>
            );
          })}
      </Box>
    )
  );
};

const SelectionContainer2 = ({ setOpenMenu2, localStore }) => {
  const handleClick = (code) => (event) => {
    console.log("HANDLE CLICK");
    const newStoreData = {};
    newStoreData.domicileCountry = code;
    //updateStore(localStore, 'domicileCountry', code)
    // setDomicileCountry(code)
    const countryName = countries
      .find((country) => country.code === code)
      .name.trim();
    newStoreData.domicileCountryInput = countryName;
    //updateStore(localStore, 'domicileCountryInput', countryName)
    localStore[1]({ ...localStore[0], ...newStoreData });
    // setDomicileCountryInput(countryName)
    setOpenMenu2(false);
  };

  const countryName = countries.find(
    (country) =>
      cleanText(country.name) === cleanText(localStore[0].domicileCountryInput)
  );
  if (countryName) return null;

  return (
    localStore[0].domicileCountryInput.length > 0 && (
      <Box bgcolor={grey[100]} padding="20px" width="200px" zIndex="modal">
        <Box marginBottom="10px">
          <Typography style={{ fontWeight: 600, lineHeight: "0.9rem" }}>
            Sélectionnez le pays de domicile:
          </Typography>
        </Box>

        {countries
          .filter(
            (country) =>
              cleanText(country.name)
                .slice(0, localStore[0].domicileCountryInput.length)
                .toLocaleLowerCase() ===
              cleanText(localStore[0].domicileCountryInput).toLocaleLowerCase()
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .slice(0, 4)
          .map((country) => {
            return (
              <TextChoice
                key={country.code}
                onClick={handleClick(country.code)}
              >
                <Box padding="5px" borderRadius="10px" bgcolor={orange[100]}>
                  <Typography style={{ textAlign: "center" }}>
                    {country.name}
                  </Typography>
                </Box>
              </TextChoice>
            );
          })}
      </Box>
    )
  );
};

const TextChoice = styled.div`
      color: #000;
      padding: 4px;
      :hover {
        font - weight: bold;
      cursor: pointer;
	}
      `;

const buttonColor = indigo[800];

const ColorButton = withStyles((theme) => ({
  root: {
    color: buttonColor,
    border: `1px solid ${buttonColor}`,
    textTransform: "none",

    "&:hover": {
      backgroundColor: "white",
      color: indigo[400],
      border: `1px solid ${indigo[400]}`,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),

      justifyContent: "space-between",
    },
  },
  avatar: {
    height: "30px",
    width: "30px",
    color: theme.palette.getContrastText(indigo[700]),
    backgroundColor: indigo[700],
    "&:hover": {
      backgroundColor: indigo[700],
    },
  },

  avatarOff: {
    height: "30px",
    width: "30px",
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
    "&:hover": {
      backgroundColor: grey[300],
    },
  },
}));

const DisplayCountries = ({ localStore }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flexWrap="wrap"
      width="250px"
    >
      {/* {nationalityList.length > 0 && <Typography style={{ color: indigo[800] }}>
      nationalité(s):
      </Typography>} */}
      {localStore[0].nationalityList?.map((code) => (
        <DisplaySingleCountry key={code} code={code} localStore={localStore} />
      ))}
    </Box>
  );
};

const DisplaySingleCountry = ({ code, localStore, modified }) => {
  const handleClick = (code) => () => {
    const newList = [...localStore[0].nationalityList];
    const index = localStore[0].nationalityList.indexOf(code);
    newList.splice(index, 1);
    updateStore(localStore, "nationalityList", newList);
    // setNationalityList(newList)
  };

  return (
    <Box
      bgcolor={modified ? orange[200] : grey[300]}
      padding="3px 10px 3px 10px"
      margin="1px"
      borderRadius="10px"
      alignItems="center"
      display="flex"
      flexDirection="row"
    >
      <Typography
        style={{
          textAlign: "center",
          color: modified ? orange[800] : grey[600],
          marginRight: "5px",
        }}
      >
        {countries.find((country) => country.code === code).name}
      </Typography>

      <CancelIcon
        style={{ color: modified ? orange[800] : grey[500], fontSize: "20px" }}
        onClick={handleClick(code)}
      />
    </Box>
  );
};

const cleanText = (text) => {
  const result = text.replace("é", "e").replace("è", "e").replace("É", "E");

  return result;
};

const SubmitButtonOn = withStyles((theme) => ({
  root: {
    color: buttonColor,
    border: `1px solid ${buttonColor}`,
    textTransform: "none",
    padding: "10px",

    "&:hover": {
      backgroundColor: indigo[100],
      color: indigo[800],
      border: `1px solid ${indigo[400]}`,
    },
  },
}))(Button);

const SubmitButtonOff = withStyles((theme) => ({
  root: {
    color: grey[300],
    border: `1px solid ${grey[300]}`,
    backgroundColor: "white",
    textTransform: "none",
    padding: "10px",

    "&:hover": {
      color: grey[300],
      border: `1px solid ${grey[300]}`,
      backgroundColor: "white",
      textTransform: "none",
      cursor: "not-allowed",
    },
  },
}))(Button);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: grey[800],
    padding: "10px",
    color: "#F0F0F0",
    lineHeight: "1.3rem",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const CustomAvatar = ({ number, state }) => {
  const classes = useStyles();
  if (state)
    return (
      <Box marginRight="20px" flex={10}>
        <Avatar className={classes.avatar}>{number}</Avatar>
      </Box>
    );
  else
    return (
      <Box marginRight="20px" flex={10}>
        <Avatar className={classes.avatarOff}>{number}</Avatar>
      </Box>
    );
};

const areDatesDifferent = (d1, d2) => {
  const t1 = moment(d1, "DD/MM/YYYY").format("DD/MM/YYYY");
  const t2 = moment(d2, "DD/MM/YYYY").format("DD/MM/YYYY");
  if (t1 === t2) return false;
  return true;
};

const getCountryName = (code) => {
  if (!code) return false;
  const countryName = countries.find((country) => country.code === code).name;
  return countryName;
};
