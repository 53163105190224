
// TO BE USED WITH REACT 18
// WAITING JODIT TO MIGRATE TO REACT-18
// import React from 'react'
// import {createRoot} from 'react-dom/client'
// import './index.css'
// import App from './App'
// import { Provider } from "react-redux"
// import store from './redux/store'

// const container = document.getElementById('root')
// const root = createRoot(container)
// root.render(<Provider store={store}><App /></Provider>)



import React from 'react'
import {render} from 'react-dom'
import './index.css'
import App from './App'
import { Provider } from "react-redux"
import store from './redux/store'
import 'querystring-es3';


const rootElement = document.getElementById('root')
//const root = createRoot(container)
//root.render(<Provider store={store}><App /></Provider>)

render(
	<Provider store={store}><App /></Provider>,
	rootElement
)




