import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { colorTaupeClair } from "../AppStyle";
import { colorGray } from "../AppStyle";
import Rating from "@mui/material/Rating";
import { useSelector } from "react-redux";
import { getFullDate } from "../components/general";

import { grey } from "@mui/material/colors";
import { Email, Facebook, Twitter, LinkedIn } from "@mui/icons-material";

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Checkbox } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import {
  cyclesbyFormateur,
  cyclesbyOrganisateur,
  eventsbyFormateur,
  eventsbyOrganisateur,
  getCompanyAccount,
  listMemberShips,
} from "../graphql/queries";
import { updateCompanyAccount, updateFormateur } from "../graphql/mutations";

const startURL = "https://www.pekoia.com";

function Promotion({ isInde, fetchData, user }) {
  const [checked, setChecked] = useState(false);
  const [checkedPromo, setCheckedPromo] = useState(false);
  const [companyAccount, setCompanyAccount] = useState({});
  const data = useSelector((state) => state.dataReducer);
  const [conferenceData, setConferenceData] = useState([]);
  // useSelector((state) => state.eventsReducer)
  //   ?.data.filter((event) => event.state === "published")
  //   .sort((a, b) => a.date - b.date)

  const [cycleData, setCycleData] = useState([]);
  // useSelector((state) => state.cyclesReducer)?.data
  const currentTime = Date.now() / 1000;

  const replaysData = conferenceData.filter((event) => {
    return event.date + event.duree * 60 < currentTime;
  });

  const upcomingConferenceData = conferenceData.filter((event) => {
    return event.date + event.duree * 60 > currentTime;
  });

  const fetchCompany = () => {
    API.graphql(
      graphqlOperation(listMemberShips, {
        filter: { formateurId: { eq: data.id } },
      })
    )
      .then((res) => {
        API.graphql(
          graphqlOperation(getCompanyAccount, {
            id: res.data.listMemberShips.items[0].companyAccountId,
          })
        )
          .then((companyAccountTmp) => {
            const account = companyAccountTmp.data.getCompanyAccount;
            setCompanyAccount(account);
            setChecked(account.abonnement ? account.abonnement : false);
            setCheckedPromo(
              account.promotionProgram ? account.promotionProgram : false
            );
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  useEffect(async () => {
    const modeCompany = !isInde && !!companyAccount.id;

    const conferences = modeCompany
      ? (
          await API.graphql(
            graphqlOperation(eventsbyOrganisateur, {
              organisateurId: companyAccount.id,
            })
          )
        ).data.eventsbyOrganisateur
      : (
          await API.graphql(
            graphqlOperation(eventsbyFormateur, {
              formateurId: data.id,
            })
          )
        ).data.eventsbyFormateur;

    const conferencesFiltered = conferences.items
      .filter((event) => event.state === "published")
      .sort((a, b) => a.date - b.date);

    const cycles = modeCompany
      ? (
          await API.graphql(
            graphqlOperation(cyclesbyOrganisateur, {
              organisateurId: companyAccount.id,
            })
          )
        ).data.cyclesbyOrganisateur
      : (
          await API.graphql(
            graphqlOperation(cyclesbyFormateur, {
              formateurId: data.id,
            })
          )
        ).data.cyclesbyFormateur;

    const cyclesFiltered = cycles.items
      .filter((event) => event.status === "PUBLISHED")
      .sort((a, b) => a.date - b.date);

    setConferenceData(conferencesFiltered);
    setCycleData(cyclesFiltered);
  }, [companyAccount, isInde, data]);
  useEffect(() => {
    if (isInde) {
      setChecked(data.abonnement);
      setCheckedPromo(data.promotionProgram);
    } else {
      fetchCompany();
    }
  }, [data, isInde]);

  const handleChange = () => {
    if (isInde) {
      API.graphql(
        graphqlOperation(updateFormateur, {
          input: { id: data.id, abonnement: !checked },
        })
      ).then((res) => {
        fetchData(user);
      });
    } else {
      API.graphql(
        graphqlOperation(updateCompanyAccount, {
          input: { id: companyAccount.id, abonnement: !checked },
        })
      )
        .then((res) => {
          fetchCompany();
        })
        .catch((err) => console.log(err));
    }
  };

  const handleChangePromo = () => {
    if (isInde) {
      API.graphql(
        graphqlOperation(updateFormateur, {
          input: { id: data.id, promotionProgram: !checkedPromo },
        })
      ).then((res) => {
        fetchData(user);
      });
    } else {
      API.graphql(
        graphqlOperation(updateCompanyAccount, {
          input: { id: companyAccount.id, promotionProgram: !checkedPromo },
        })
      )
        .then((res) => {
          fetchCompany();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <Typography
          style={{
            marginTop: "2vw",
            marginBottom: "1vw",
            fontWeight: "600",
          }}
          component="h1"
        >
          {"faites la promotion de vos conférences et de vos cycles de conferences".toUpperCase()}
        </Typography>
        <Typography>
          Une fois votre conférence ou votre cycle publié, celui-ci est mis en
          ligne sur www.pekoia.com et nous nous efforçons d'en faire la
          promotion sur d'autres supports Pekoia (pages réseaux sociaux de
          Pekoia et newsletter notamment).
        </Typography>
        <Typography style={{ paddingTop: "10px" }}>
          {
            "Pour maximiser les chances d'obtenir l'audience la plus large possible, nous recommandons à tous les conférenciers et organismes de promouvoir leurs conférences également sur leurs propres médias (votre site internet, votre newsletter, vos pages de réseaux sociaux)."
          }
        </Typography>
        <Typography marginTop="10px">
          {
            "Par ailleurs, nous organisons des programmes de promotion via code promo et abonnement afin de stimuler les ventes de conférences, vous pouvez choisir d'y participer ou non en cliquant dans les cases ci-dessous."
          }
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginTop="20px"
        >
          <Checkbox
            checked={checkedPromo != null ? checkedPromo : true}
            onChange={handleChangePromo}
            sx={{
              color: "#1a5aa3",
              "&.Mui-checked": {
                color: "#1a5aa3",
              },
            }}
          />
          <Typography>
            J'accepte que mes conférences fassent parti du programme de
            promotion auprès de partenaires de Pekoia et de céder à ces
            partenaires 10% de marge. Exemples de partenaires : amis de musées,
            plateforme des salariés, médias...
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            checked={checked ? checked : false}
            onChange={handleChange}
            sx={{
              color: "#1a5aa3",
              "&.Mui-checked": {
                color: "#1a5aa3",
              },
            }}
          />
          <Typography>
            J'accepte que mes conférences fassent parti du programme
            d'abonnement de Pekoia (voir rubrique tarifs) à partir de 2025.
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          style={{
            marginTop: "2vw",
            marginBottom: "2vw",
            color: "#1a5aa3",
          }}
          variant="h1"
          component="h1"
        >
          {"conférences".toUpperCase()}
        </Typography>

        {upcomingConferenceData.length === 0 && (
          <Box padding="10px">
            <Typography>Aucune conférence en promotion</Typography>
          </Box>
        )}
        {upcomingConferenceData.length > 0 && (
          <DisplayEvent events={upcomingConferenceData} />
        )}
        <Typography style={{ paddingTop: "20px" }}>
          Pour aller plus loin dans les solutions de promotion, n'hésitez pas à
          nous contacter : contact@pekoia.com
        </Typography>
      </Box>
      <Box>
        <Typography
          style={{
            marginTop: "2vw",
            marginBottom: "2vw",
            color: "#1a5aa3",
          }}
          variant="h1"
          component="h1"
        >
          {"cycles de conférences".toUpperCase()}
        </Typography>

        {cycleData.length === 0 && (
          <Box padding="10px">
            <Typography>Aucun cycle en promotion</Typography>
          </Box>
        )}
        {cycleData.length > 0 && <DisplayCycles events={cycleData} />}
        <Typography style={{ paddingTop: "20px" }}>
          Pour aller plus loin dans les solutions de promotion, n'hésitez pas à
          nous contacter : contact@pekoia.com
        </Typography>
      </Box>
      <Box>
        <Typography
          style={{
            marginTop: "2vw",
            marginBottom: "2vw",
            color: "#1a5aa3",
          }}
          variant="h1"
          component="h1"
        >
          {"conférence à la demande".toUpperCase()}
        </Typography>

        {replaysData.length === 0 && (
          <Box padding="10px">
            <Typography>Aucune conférence à la demande en promotion</Typography>
          </Box>
        )}
        {replaysData.length > 0 && <DisplayReplay events={replaysData} />}
        <Typography style={{ paddingTop: "20px" }}>
          Pour aller plus loin dans les solutions de promotion, n'hésitez pas à
          contacter : contact@pekoia.com
        </Typography>
      </Box>
    </Box>
  );
}

export default Promotion;

const DisplayEvent = ({ events }) => {
  return (
    <Box
      width="100%"
      border={`1px solid ${grey[300]}`}
      padding="0 20px 20px 20px"
    >
      <Box
        height="70px"
        display="flex"
        flexDirection="row"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box flex={2}>
          <Typography style={{ fontWeight: 700 }}>titre</Typography>
        </Box>

        <Box flex={1}>
          <Typography style={{ fontWeight: 700 }}>date</Typography>
        </Box>

        <Box flex={1}>
          <Typography style={{ fontWeight: 700 }}>Outil</Typography>
        </Box>

        <Box flex={1}>
          <Typography style={{ fontWeight: 700 }}>Horaire</Typography>
        </Box>

        <Box flex={1}>
          <Typography style={{ fontWeight: 700 }}>Réseaux Sociaux</Typography>
        </Box>

        <Box flex={1} style={{ marginLeft: 40 }}>
          <Typography style={{ fontWeight: 700 }}>Mail</Typography>
        </Box>
      </Box>

      {events.map((event, index) => {
        const normalizeTitle = String(event.title1)
          .replaceAll("-", " ")
          .toLowerCase()
          .replaceAll("é", "e")
          .replaceAll("ê", "e")
          .replaceAll("è", "e")
          .replaceAll("à", "a")
          .replaceAll("â", "a")
          .replaceAll("'", " ")
          .replaceAll('"', " ")
          .replaceAll(":", " ")
          .replaceAll("?", " ")
          .replaceAll(",", " ")
          .replaceAll(".", " ")
          .replaceAll(";", " ")
          .replaceAll("(", " ")
          .replaceAll("’", " ")
          .replaceAll(")", " ")
          .replaceAll("/", " ")
          .replace(/\s+/g, " ")
          .trim()
          .split(" ")
          .join("-");
        const idConf = String(event.id).split("-")[0];
        const confURL = `${startURL}/conference/${normalizeTitle}-${idConf}/`;
        const newDate = new Date(event.date * 1000).toLocaleDateString();
        const message = `Je viens de publier une nouvelle conférence sur le site pekoia.com:\n\n \"${event.title1}\"\n\n La conférence se tiendra le:\n ${newDate}\n\n Les inscriptions sont ouvertes via le lien suivant:\n${confURL}`;

        return (
          <Box
            height="50px"
            key={index}
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box flex={2}>
              <Typography>{event.title1}</Typography>
            </Box>

            <Box flex={1}>
              <Typography>{getFullDate(event.date)?.dateDDMM}</Typography>
            </Box>

            <Box flex={1}>
              <Typography>{event.outil}</Typography>
            </Box>

            <Box flex={1}>
              <Typography>{getFullDate(event.date)?.time}</Typography>
            </Box>

            <Box flex={1}>
              <FacebookShareButton style={{ paddingRight: 10 }} url={confURL}>
                <Facebook fontSize="large" />
              </FacebookShareButton>

              <TwitterShareButton style={{ paddingRight: 10 }} url={confURL}>
                <Twitter fontSize="large" />
              </TwitterShareButton>

              {/* <LinkedinShareButton url={confURL}>
                        <LinkedIn fontSize="large"/>
                    </LinkedinShareButton> */}
            </Box>

            <Box flex={1} style={{ marginLeft: 40 }}>
              <EmailShareButton url={message} subject={`Nouvelle conférence`}>
                <Email fontSize="large" />
              </EmailShareButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const DisplayCycles = ({ events }) => {
  return (
    <Box
      width="100%"
      border={`1px solid ${grey[300]}`}
      padding="0 20px 20px 20px"
    >
      <Box
        height="70px"
        display="flex"
        flexDirection="row"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box flex={2}>
          <Typography style={{ fontWeight: 700 }}>titre</Typography>
        </Box>

        <Box flex={1}></Box>

        <Box flex={1}></Box>

        <Box flex={1}></Box>

        <Box flex={1}>
          <Typography style={{ fontWeight: 700 }}>Réseaux Sociaux</Typography>
        </Box>

        <Box flex={1} style={{ marginLeft: 40 }}>
          <Typography style={{ fontWeight: 700 }}>Mail</Typography>
        </Box>
      </Box>

      {events.map((event, index) => {
        const normalizeTitle = String(event.title)
          .replaceAll("-", " ")
          .toLowerCase()
          .replaceAll("é", "e")
          .replaceAll("ê", "e")
          .replaceAll("è", "e")
          .replaceAll("à", "a")
          .replaceAll("â", "a")
          .replaceAll("'", " ")
          .replaceAll('"', " ")
          .replaceAll(":", " ")
          .replaceAll("?", " ")
          .replaceAll(",", " ")
          .replaceAll(".", " ")
          .replaceAll(";", " ")
          .replaceAll("(", " ")
          .replaceAll("’", " ")
          .replaceAll(")", " ")
          .replaceAll("/", " ")
          .replace(/\s+/g, " ")
          .trim()
          .split(" ")
          .join("-");
        const idCycle = String(event.id).split("-")[0];
        const cyclURL = `${startURL}/cycle/${normalizeTitle}-${idCycle}/`;
        const message =
          `Je viens de publier un nouveaux cycle sur le site pekoia.com:\n\n \"${event.title}\"\n\nLes inscriptions sont ouvertes via le lien suivant:\n${cyclURL}` +
          `\n\n\n https://www.pekoia.com/static/L${event.imageKey}-500-${event.imageETag500}.jpg`;

        return (
          <Box
            height="50px"
            key={index}
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box flex={2}>
              <Typography>{event.title}</Typography>
            </Box>

            <Box flex={1}></Box>

            <Box flex={1}></Box>

            <Box flex={1}></Box>
            <Box flex={1}>
              <FacebookShareButton style={{ paddingRight: 10 }} url={cyclURL}>
                <Facebook fontSize="large" />
              </FacebookShareButton>

              <TwitterShareButton style={{ paddingRight: 10 }} url={cyclURL}>
                <Twitter fontSize="large" />
              </TwitterShareButton>

              {/* <LinkedinShareButton url={cyclURL}>
                        <LinkedIn fontSize="large"/>
                    </LinkedinShareButton> */}
            </Box>

            <Box flex={1} style={{ marginLeft: 40 }}>
              <EmailShareButton
                url={message}
                subject={`Nouveau cycle disponible: ${event.title}`}
              >
                <Email fontSize="large" />
              </EmailShareButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const DisplayReplay = ({ events }) => {
  return (
    <Box
      width="100%"
      border={`1px solid ${grey[300]}`}
      padding="0 20px 20px 20px"
    >
      <Box
        height="70px"
        display="flex"
        flexDirection="row"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box flex={2}>
          <Typography style={{ fontWeight: 700 }}>titre</Typography>
        </Box>

        <Box flex={1}></Box>

        <Box flex={1}></Box>

        <Box flex={1}></Box>

        <Box flex={1}>
          <Typography style={{ fontWeight: 700 }}>Réseaux Sociaux</Typography>
        </Box>

        <Box flex={1} style={{ marginLeft: 40 }}>
          <Typography style={{ fontWeight: 700 }}>Mail</Typography>
        </Box>
      </Box>

      {events.map((event, index) => {
        const normalizeTitle = String(event.title1)
          .replaceAll("-", " ")
          .toLowerCase()
          .replaceAll("é", "e")
          .replaceAll("ê", "e")
          .replaceAll("è", "e")
          .replaceAll("à", "a")
          .replaceAll("â", "a")
          .replaceAll("'", " ")
          .replaceAll('"', " ")
          .replaceAll(":", " ")
          .replaceAll("?", " ")
          .replaceAll(",", " ")
          .replaceAll(".", " ")
          .replaceAll(";", " ")
          .replaceAll("(", " ")
          .replaceAll("’", " ")
          .replaceAll(")", " ")
          .replaceAll("/", " ")
          .replace(/\s+/g, " ")
          .trim()
          .split(" ")
          .join("-");
        const idRepl = String(event.id).split("-")[0];
        const replURL = `${startURL}/cycle/${normalizeTitle}-${idRepl}/`;
        const message =
          `Je viens de publier une nouvelle conférence à la demande sur le site pekoia.com:\n\n \"${event.title1}\"\n\nLes inscriptions sont ouvertes via le lien suivant:\n${replURL}` +
          `\n\n\nhttps://www.pekoia.com/static/L${event.imageKey}-500-${event.imageETag500}.jpg`;

        return (
          <Box
            height="50px"
            key={index}
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box flex={2}>
              <Typography>{event.title1}</Typography>
            </Box>

            <Box flex={1}></Box>

            <Box flex={1}></Box>

            <Box flex={1}></Box>
            <Box flex={1}>
              <FacebookShareButton style={{ paddingRight: 10 }} url={replURL}>
                <Facebook fontSize="large" />
              </FacebookShareButton>

              <TwitterShareButton
                style={{ paddingRight: 10 }}
                url={replURL}
                title="https://pekoia.com/static/4d6cf00f33466ed1d5b5d7d70eb2c668/c755a/Ld179fa45-3c9c-489e-9372-a14d23a92bf0-800.webp"
              >
                <Twitter fontSize="large" />
              </TwitterShareButton>

              {/* <ShareLink url={replURL}>
                    <LinkedIn fontSize='large'/>
                </ShareLink> */}
              {/* <LinkedinShareButton url={replURL}>
                    <LinkedIn fontSize="large"/>
                </LinkedinShareButton> */}
            </Box>

            <Box flex={1} style={{ marginLeft: 40 }}>
              <EmailShareButton
                url={message}
                subject={`Nouvelle conférence à la demande disponible: ${event.title1}`}
              >
                <Email fontSize="large" />
              </EmailShareButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
