import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify'
import withStyles from '@mui/styles/withStyles';
import { ReactComponent as Logo } from "../assets/back_office2.svg"
import InputBase from '@mui/material/InputBase'
import EmailIcon from '@mui/icons-material/Email'
import LockCloseIcon from '@mui/icons-material/LockOpen';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Checkbox from '@mui/material/Checkbox'
import ClearIcon from '@mui/icons-material/Clear';
import ReCAPTCHA from "react-google-recaptcha"
import "@fontsource/montserrat/800.css"
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ReactComponent as FacebookIcon } from "../assets/Facebook.svg"
import { ReactComponent as WalletIcon } from "../assets/Wallet.svg"
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { blueGrey, amber, green, indigo, grey, brown } from '@mui/material/colors';
import { Typography } from "@mui/material";
import Home from '../containers/home'
import Cgs from '../containers/cgs'
import { ReactComponent as Banner } from "../assets/main.svg"
import Confidentialite from "../containers/confidentialite";
import Tarifs from "../containers/tarifs";

const digitIndex = [0, 1, 2, 3, 4, 5]
const emptyDigit = ['', '', '', '', '', '']
const inputColor = brown[50]   // couleur du fonds des champs de saisie
const messageColor = amber[900]   // couleur des messages dans les champs de saisie
const CustomSignIn = (props) => {

    const [captcha, setCaptcha] = useState(process.env.NODE_ENV === "development" ? true : true)
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [checked, setChecked] = React.useState(false)
    const [digit, setDigit] = useState(['', '', '', '', '', ''])
    const [error, setError] = useState(false)
    const [errorDigit, setErrorDigit] = useState(false)
    const [options, setOption] = useState(null)
    const [validPassword, setValidPassword] = useState(false)
    const [validEmail, setValidEmail] = useState(false)

    const mediaMatch = window.matchMedia('(max-width: 800px)')

    const [matches, setMatches] = useState(mediaMatch.matches)

    const IS_PRODUCTION = true


    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    })



    useEffect(() => {
        setValidPassword(testPassword(password))
        setValidEmail(testEmail(email))


    }, [password, email])

    const nbDigits = () => {
        let i = 0
        digit.forEach(index => {
            if (index != '')
                i = i + 1
        })
        return (i)
    }





    useEffect(() => {

        const i = nbDigits()

        if ((i === 6) && (options != "forgotPassword")) {

            confirmSignUp(email, password, digit[0] + digit[1] + digit[2] + digit[3] + digit[4] + digit[5], setOpen, setDigit, setErrorDigit)
        }

    }, [digit])


    const { authState, onStateChange } = props



    const handleSwitch = () => {
        if (authState === "signIn")
            onStateChange("signUp")
        if (authState === "signUp")
            onStateChange("signIn")

    }



    const Modal1Body = () => {




        const signUpClick = async () => {

            if (validPassword && validEmail) {
                onStateChange('confirm')


                try {

                    const result = await Auth.signUp({
                        username: email,
                        password: password,
                        attributes: {
                            email: email         // optional
                        }
                    })


                }
                catch (error) {


                    console.log('@ERROR:')
                    console.log(error)

                }

            }
            else
                setError(true)
        }



        return (
            <Box  padding="1.5vw" width="100%" height="100%" minHeight="666px" minWidth="500px" bgcolor= 'white' >
                
                <div >
                    <div style={{ borderBottom: "1px solid", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }} >
                        <div style={{ position: "relative", right: "25px", flex: "10" }} >
                            <Logo height="5vw" />
                        </div>


                    
                        <CancelButton onClick={() => {
                            setOpen(false)
                            setEmail('')
                            setPassword('')
                            setError(false)
                        }}>
                            <ClearIcon />
                        </CancelButton>


                    </div>
                    <Box style={{ fontSize: "1rem", margin: "1vw 0vw 1vw 0vw" }}>
                        Inscrivez vous GRATUITEMENT.
                    </Box>
                    <form>
                        <Box bgcolor={inputColor} marginTop="4vw" display="flex" padding="0.25vw" flexDirection="row" alignItems="center">
                            <Box marginLeft="1vw" padding="0.25vw" color={validEmail ? green[900] : "gray"}>
                                <EmailIcon />
                            </Box>
                            <Box width="100%">

                           
                            <InputBase
                                autoComplete="on"
                                sx={classes.inputZone}
                                placeholder="Renseignez votre email"
                                label="ThemeProvider"
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }} />
                                 </Box>

                        </Box>
                        <Box marginTop="0.5vw" bgcolor={inputColor} display="flex" padding="0.25vw" flexDirection="row" alignItems="center">
                            <Box marginLeft="1vw" padding="0.25vw" color={validPassword ? "green" : "gray"} >
                                {validPassword && <LockCloseIcon />}
                                {!validPassword && <LockOpenIcon />}
                            </Box>
                            <Box width="100%">

                            <InputBase
                                type="password"

                                sx={classes.inputZone}
                                placeholder="Inventez un mot de passe"
                                label="ThemeProvider"
                                value={password}
                                onChange={(event) => { setPassword(event.target.value) }} />
                                </Box>

                        </Box>
                        <Box display="flex" flexDirection="row" marginTop="2vw">
                            <Checkbox style={{ color: "gray" }} checked={checked}
                                onChange={() => setChecked(!checked)} />
                            <div style={{ marginLeft: "2vw", color: grey[700] }}>
                                Je souhaite être informé des prochains événements en recevant la newsletter de Pekoia university
                            </div>

                        </Box>
                        <Box display="flex" justifyContent="center">


                            <SecondButton onClick={signUpClick}>
                                <Typography style={{ color: "white" }}>
                                    Inscription
                                </Typography>

                            </SecondButton>
                        </Box>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "3vw" }}>
                            <div>
                                Déjà un compte Pekoia University
                            </div>
                            <div style={{ marginLeft: "1vw", color: messageColor, cursor: "default" }} onClick={handleSwitch}>

                                Connectez-vous
                            </div>


                        </div>




                    </form>
                    <Box color="red" marginTop="1vw">


                        <div style={{ marginBottom: "1vw" }}>
                            {error && !validEmail && `Format d'email invalide.`}
                        </div>
                        <div>
                            {error && !validPassword && `Mot de passe invalide (au moins 8 caractères).`}
                        </div>
                    </Box>


                </div>


            </Box>
        )
    }

    const Modal2Body = () => {




        const signInClick = async () => {
            let flag = null

            Auth.signIn(email, password)
                .then(data => {

                    console.log('@SIGN-IN')
                    console.log(data)


                    setOpen(false)
                    setError(false)
                })
                .catch(error => {
                    console.log('&SIGN-IN ERROOR')
                    console.log(error)
                    if (error.message === 'User is not confirmed.') {

                        onStateChange('confirm')
                        setOpen(true)
                    }
                    else
                        setError(true)
                })




        }



        return (
            <Box  padding="1.5vw" width="100%" height="100%" minHeight="666px" minWidth="500px" bgcolor= 'white' >
                
                <Box>
                    <Box borderBottom="1px solid" display="flex" flexDirection="row" alignItems="center" justifyContent="start">
                        <Box position="relative" right="20px" flex={10}>
                            <Logo height="5vw" />
                        </Box>

                   
                        <CancelButton onClick={() => {
                            setOpen(false)
                            setEmail(null)
                            setError(false)
                            setPassword(null)
                            onStateChange('signIn')

                        }}>
                            <ClearIcon />
                        </CancelButton>


                    </Box>
                    <Box style={{ fontSize: "1rem", margin: "1vw 0vw 1vw 0vw" }}>
                        Bienvenue!
                    </Box>


                    <form>
                        <Box bgcolor={inputColor} marginTop="4vw" display="flex" padding="0.25vw" flexDirection="row" alignItems="center">
                            <Box marginLeft="1vw" padding="0.25vw" color="lighgray">
                                <EmailIcon />
                            </Box>
                            
                            <InputBase
                                autoComplete="on"
                                sx={classes.inputZone}
                                placeholder="Renseignez votre email"
                                label="ThemeProvider"
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }} />

                        </Box>
                        <Box marginTop="0.5vw" bgcolor={inputColor} display="flex" padding="0.25vw" flexDirection="row" alignItems="center">
                            <Box marginLeft="1vw" padding="0.25vw" color="lighgray" >
                                <LockCloseIcon />
                            </Box>

                            <InputBase
                                type="password"
                                sx={classes.inputZone}
                                placeholder="Mot de passe Pekoia University"
                                label="ThemeProvider"
                                value={password}
                                onChange={(event) => { setPassword(event.target.value) }} />

                        </Box>
                        <Box display="flex" flexDirection="row" marginTop="1vw" justifyContent="center" alignContent="center">

                            <div onClick={() => onStateChange('forgot')} style={{ cursor: "default",  color: messageColor }}>
                                Mot de passe oublié
                            </div>

                        </Box>
                        <Box display="flex" justifyContent="center">

                            <SecondButton onClick={captcha && signInClick}>
                                <Typography style={{ color: "white" }}>
                                    Connexion
                                </Typography>

                            </SecondButton>
                        </Box>
                        <Box display="flex" flexDirection="column" >


                            <Box display="flex" flexDirection="row" marginTop="5vw">
                                <div>
                                    Vous n'avez pas de compte
                                </div>
                                <div style={{ marginLeft: "1vw", color: messageColor, cursor: "default" }} onClick={handleSwitch}>

                                    Inscrivez-vous
                                </div>

                            </Box>
                            {IS_PRODUCTION && <Box marginTop="30px" display="flex" flexDirection="row" justifyContent="center">

                                {(process.env.NODE_ENV != 'development') && <ReCAPTCHA

                                    hl="fr"
                                    sitekey="6Lfq1KUaAAAAAEWWPpBiNLx0UD_ePAIt3BoxqmwN"
                                    onChange={() => setCaptcha(true)}
                                />}
                            </Box>}
                        </Box>





                    </form>
                    {error && <Box color="red" marginLeft="4vw" marginTop="4vw">
                        Email ou mot de passe incorrect
                    </Box>}
                </Box>


            </Box>
        )
    }

    const Modal3Body = () => {



        if (authState === "notconfirmed") {

            setOpen(false)
            return (<div></div>)
        }

        const handleClick = () => {
            // hadndle button click in case of lost password

            const i = nbDigits()
            if ((i === 6) && true) {
                setOpen(false)
                setEmail('')
                setPassword('')
                onStateChange('confirm')

                const digits = digit[0] + digit[1] + digit[2] + digit[3] + digit[4] + digit[5]

                Auth.forgotPasswordSubmit(email, digits, password)
                    .then(data => {

                    })
                    .catch(err => {


                    })

            }


        }



        const handleInput = (event, index) => {

            const regex = /\d\d\d\d\d\d/
            const input = event.target.value

            if ((input.length === 6) && (input.search(regex) >= 0) && (testDigitIsEmpty(digit))) {

                const newDigit = [input[0], input[1], input[2], input[3], input[4], input[5]]
                setDigit(newDigit)

            }
            else {




                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt((index + 1) % 6)}]`
                )





                if (event.target.value?.length < 2 && (parseInt(event.target.value) || parseInt(event.target.value) === 0)) {
                    const newDigit = [...digit]
                    newDigit[index] = event.target.value
                    setDigit(newDigit)
                    if (event.target.value != '' && nextSibling)
                        nextSibling.focus()
                }
            }
        }

        const handleKeyPress = (event, index) => {
            if (event.key === "Backspace") {
                if (digit[index] != '') {
                    const newDigit = [...digit]
                    newDigit[index] = ''
                    setDigit(newDigit)

                }
                else {
                    const previousSibling = document.querySelector(
                        `input[name=ssn-${parseInt((index - 1) % 6)}]`
                    )
                    previousSibling && previousSibling.focus()

                }

            }
        }


        return (
            <Box padding="1.5vw" width="100%" height="100%" minHeight="666px" minWidth="500px" bgcolor= 'white' >
                <Box>
                    <Box borderBottom="1px solid" display="flex" flexDirection="row" alignItems="center" justifyContent="start">
                        <Box position="relative" right="20px" flex={10}>
                            <Logo height="5vw" />
                        </Box>

                        {/* <Box display="flex" flexDirection="column">
                            <Box position="relative" right="5vw">
                                <div style={{ fontSize: "1.3rem", lineHeight: "0.8rem" }}>
                                    PEKOIA
                        </div>
                                <div style={{ letterSpacing: "0.16rem" }}>
                                    University
                        </div>

                            </Box>

                        </Box> */}

                        <CancelButton onClick={() => {
                            setOpen(false)
                            setEmail('')
                            setPassword('')
                            onStateChange('signUp')
                            setErrorDigit(false)
                            setError(false)
                        }}>
                            <ClearIcon />
                        </CancelButton>


                    </Box>
                    <Box >
                        <div style={{ fontSize: "1.4rem", marginTop: "3vw" }}>
                            {options != 'forgotPassword' ? "Vérification de votre email" : "Nouveau mot de passe"}
                        </div>
                        <div style={{ marginTop: "0.5vw" }}>
                            {`Renseignez le code à 6 chiffres envoyé à la boite mail de ${email}. Attendez quelques dizaines de secondes si vous ne l'avez pas encore reçu`}
                        </div>
                        <Box display="flex" flexDirection="row" marginTop="1vw">
                            {digitIndex.map(index => {
                                return (<InputBase
                                    autoFocus={(index === 0)}
                                    key={index}
                                    name={"ssn-" + index}
                                    sx={classes.inputDigit}
                                    label="ThemeProvider"
                                    value={digit[index]}
                                    onKeyDown={(event) => handleKeyPress(event, index)}
                                    onChange={(event) => handleInput(event, index)} />)

                            })}

                        </Box>
                        {(options === 'forgotPassword') && <Box marginTop="3vw" bgcolor={inputColor} display="flex" padding="0.25vw" flexDirection="row" alignItems="center">
                            <Box marginLeft="1vw" padding="0.25vw" color="lighgray" >
                                <LockCloseIcon />
                            </Box>

                            <InputBase
                                type="password"
                                sx={classes.inputZone}
                                placeholder="Nouveau mot de passe"
                                label="ThemeProvider"
                                value={password}
                                onChange={(event) => { setPassword(event.target.value) }} />

                        </Box>}

                    </Box>
                    <form>
                        <Box display="flex" justifyContent="center">

                            <SecondButton onClick={handleClick}>
                                Envoyer
                            </SecondButton>

                        </Box>




                    </form>
                    <NewCode email={email} />
                    {errorDigit && <Box color="red" marginLeft="4vw" marginTop="4vw">
                        Code incorrect
                    </Box>}
                </Box>


            </Box>
        )
    }

    const Modal4Body = () => {

        const signUpClick = async () => {

            console.log('@SIGNUP')


            try {
                const result = await Auth.forgotPassword(email)
                onStateChange('confirm')
                setPassword('')
                setOption('forgotPassword')

            }
            catch (error) {

            }
        }



        return (
            <Box padding="1.5vw" width="100%" height="100%" minHeight="666px" minWidth="500px" bgcolor= 'white' >
                <Box>
                    <Box borderBottom="1px solid" display="flex" flexDirection="row" alignItems="center" justifyContent="start">
                        <Box position="relative" right="20px" flex={10}>
                            <Logo height="5vw" />
                        </Box>

                        {/* <Box display="flex" flexDirection="column">
                            <Box position="relative" right="5vw">
                                <div style={{ fontSize: "1.3rem", lineHeight: "0.8rem" }}>
                                    PEKOIA
                        </div>
                                <div style={{ letterSpacing: "0.16rem" }}>
                                    University
                        </div>

                            </Box>

                        </Box> */}

                        <CancelButton onClick={() => {
                            setOpen(false)
                            setEmail('')
                            setPassword('')
                        }}>
                            <ClearIcon />
                        </CancelButton>


                    </Box>
                    <Box style={{ fontSize: "1rem", margin: "1vw 0vw 1vw 0vw" }}>
                        Renseignez votre email
                    </Box>
                    <form>
                        <Box bgcolor={inputColor} marginTop="4vw" display="flex" padding="0.25vw" flexDirection="row" alignItems="center">
                            <Box marginLeft="1vw" padding="0.25vw" color="lighgray">
                                <EmailIcon />
                            </Box>

                            <InputBase
                                sx={classes.inputZone}
                                placeholder="Renseignez votre email"
                                label="ThemeProvider"
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }} />

                        </Box>


                        <Box display="flex" justifyContent="center" marginTop="10vw">

                            <SecondButton onClick={signUpClick}>
                                Envoyer
                            </SecondButton>
                        </Box>





                    </form>
                </Box>


            </Box>
        )
    }


    if ((authState === "signIn") || authState === "signUp" || authState === "confirm" || (authState === "forgot") || (authState === "signedIn"))

        return (
            <div> 
                <Router>
                    <Routes>
                        <Route path="/cgs" element={<Cgs />}/>
                            
                        <Route path="/confidentialite" element={<Confidentialite />}/>
                        <Route path="/tarifs" element={<Tarifs/>}/>
                            
                        
                        <Route exact path="/" element={<Home setOpen={setOpen} onStateChange={onStateChange} authState={authState}/>}/>
                            
                    </Routes>
                </Router>
                <Box>

               
                {(authState === "signIn") && <Modal
                    open={open}
                    sx={classes.modal}
                   >

                    <Fade in={open}>
                        <Box sx={classes.paper}>
                            {Modal1Body(open)}
                        </Box>
                    </Fade>
                </Modal>}
                {(authState === "signUp") && <Modal
                    open={open}
                    sx={classes.modal}
                    
                  >

                    <Fade in={open}>
                        
                        <Box sx={classes.paper}>
                        
                            {Modal2Body(open)}
                        </Box>
                    </Fade>
                </Modal>}
                {((authState === "confirm") || (authState === "notconfirmed")) && <Modal
                    open={open}
                    sx={classes.modal}
                   >

                    <Fade in={open}>
                        <Box sx={classes.paper}>
                            {Modal3Body(open)}
                        </Box>
                    </Fade>
                </Modal>}
                {(authState === "forgot") && <Modal
                    open={open}
                    sx={classes.modal}
                   >

                    <Fade in={open}>
                        <Box sx={classes.paper}>
                            {Modal4Body(open)}
                        </Box>
                    </Fade>
                </Modal>}
                </Box>
               




            </div>
        )
    else return (<div></div>)
}
export default CustomSignIn

const classes = {
    root: {
        padding: "0px",
        width: "100vw",
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "white",
        width: "100vw",
        height: "5vw",
        flexGrow: 1,
        padding: "1vw"
    },
    menuButton: {
        marginRight: 2,
    },
    title: {
        flexGrow: 1,
    },
    modal: {
        backgroundColor: 'white',
        margin: 'auto',
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        width:"400px",
        height: '700px',
        minWidth:"500px"
    },
    paper: {
        
        outline: "none",
        width:"100%",
        minWidth:"500px",
       
    },
    inputZone: {
        width: "25vw",
        marginLeft: "1vw"

    },
    inputDigit: {

        fontSize: "4rem",
        width: "5vw",
        borderRadius: "0.5vw",
        height: "10vw",
        border: "3px solid",
        margin: "0.25vw",
        borderColor: "lightgray"


    }
}


const MainButton = withStyles((theme) => ({
    root: {
        minWidth: "250px",
        marginTop: "2vw",
        fontWeight: "400",
        width: "15vw",
        padding: "1vw",
        color: "white",
        backgroundColor: grey[600],

        '&:hover': {
            backgroundColor: grey[800],
        },
    },
}))(Button)

const SignInButton = withStyles((theme) => ({
    root: {
        fontWeight: "400",
        width: "15vw",
        color: "black",
        position: "relative",
        bottom: "-5px",
        border: "0px solid",
        borderColor: blueGrey[100],
        borderRadius: "0.5vw",
        '&:hover': {
            color: green[400],
            backgroundColor: blueGrey[100],
            borderColor: blueGrey[100],

            border: "0px solid",

        },
    },
}))(Button)

const SecondButton = withStyles((theme) => ({
    root: {
        marginTop: "2vw",
        fontWeight: "400",
        width: "15vw",
        padding: "1vw",
        color: "white",
        backgroundColor: grey[600],
        '&:hover': {
            backgroundColor: grey[800],
        },
    },
}))(Button)

const CancelButton = styled(Button)(({theme}) => ({
        position: "relative",
        bottom: "45px",
        left: "40px",

        backgroundColor: "inherit",
        '&:hover': {
            backgroundColor: "inherit",
            color: indigo[200],
        },
    
}))




const hidePassword = (password) => {

    if (!password)
        return ('')

    const hiddendPassword = "*".repeat(password.length)
    return (hiddendPassword)

}

async function confirmSignUp(username, password, code, setOpen, setDigit, setErrorDigit) {
    try {
        const result = await Auth.confirmSignUp(username, code)

        if (result === "SUCCESS") {
            const login = await Auth.signIn(username, password)
            setOpen(false)
            setDigit(emptyDigit)
            setErrorDigit(false)


        }
    } catch (error) {
        setErrorDigit(true)
        setDigit(emptyDigit)

    }
}

const testEmail = (email) => {
    if (!email)
        return (null)
    const split1 = email.split('@')
    const split2 = (split1.length > 1) ? split1[1].split('.') : null

    if (split2 && split2[1])
        return (true)
    else
        return (false)

}


const testPassword = (password) => {

    // add this line and line 931-933 to protect password
    //const regexList = [/[a-z]+/, /[A-Z]+/, /[0-9]/, /[!?=%&#]/]



    if (!password)
        return (false)

    if (password.length < 8)
        return (false)

    let result = true

    // add back with line 918
    // regexList.forEach(regex => {
    //     if (password.search(regex) === -1)
    //         result = false
    // })

    return result



}

const testDigitIsEmpty = (digit) => {
    if (!digit)
        return false
    let result = true
    digit.forEach(
        (digit) => {
            if (digit != '')
                result = false
        }
    )
    return (result)



}


const NewCode = ({ email }) => {

    const [delayed, setDelayed] = useState(true);
    const [askedNewCode, setAskedNewCode] = useState(false)
    const [nbNew, setNbNew] = useState(0)


    useEffect(() => {
        const timeout = setTimeout(() => {
            setDelayed(false)
            setAskedNewCode(false)
        }, 10000);
        return () => clearTimeout(timeout);
    }, [nbNew])


    return (<Box marginTop="3vw" display="flex" flexDirection="row" >
        {!delayed && <div style={{ color: messageColor, cursor: "default" }} onClick={() => asknewCode(email, setAskedNewCode, nbNew, setNbNew, setDelayed)}>
            Obtenir un nouveau code
        </div>}
        {askedNewCode && <div style={{ color: "red" }}>Nouveau code envoyé dans votre boite mail</div>}
    </Box>)

}

const asknewCode = (username, setAskedNewCode, nbNew, setNbNew, setDelayed) => {


    Auth.resendSignUp(username)
        .then(() => {
            setAskedNewCode(true)
            setDelayed(true)
            setNbNew(nbNew + 1)
        })
        .catch(
            (err) => console.log(err)
        )
}



const MyBar = ({ onStateChange, setOpen }) => {
    return (<div style={{ display: "flex", flexDirection: "column", minHeight: "65px", backgroundColor: "white", width: "100vw", height: "5vw", padding: "1vw" }}>


        <div style={{ display: "flex", lexDirection: "row", alignContent: "center" }} >
            < div style={{ flex: 5, display: "flex", flexDirection: "row", alignContent: "center" }} >


                <div style={{ position: "relative", bottom: "7px" }} >
                    <Logo height="65px" />
                </div>

            </div>
            <div style={{ flex: 1 }}>
                <SignInButton onClick={() => {
                    onStateChange('signUp')
                    setOpen(true)
                }
                }>
                    <Typography style={{ color: 'black' }}>
                        Connexion
                    </Typography>

                </SignInButton>
            </div>


        </div>




    </div>)
}

const IconColor = "#aaa69d"


const IconsSpace = () => {
    return (<div style={{ backgroundColor: "#EAEAEA", paddingTop: "5vw" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <SingleIcon title="Faites la promotion" text="de vos événements ou que soit votre public">
                <WalletIcon fill={IconColor} />
            </SingleIcon>
            <SingleIcon title="Offrez de la visibilité" text="à vos cours, ateliers, conférences">
                <WalletIcon fill={IconColor} />
            </SingleIcon>
            <SingleIcon title="Restez en contact" text="au travers de quizz, sondages, chat, streaming video">
                <WalletIcon fill={IconColor} />
            </SingleIcon>

        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <SingleIcon title="Offrez de la visibilité" text="à vos cours, ateliers, évènements">
                <WalletIcon fill={IconColor} />
            </SingleIcon>
            <SingleIcon title="Recevez des leads" text="pour des événements privatisés et utiliser la plateforme en mode privé">
                <WalletIcon fill={IconColor} />
            </SingleIcon>
            <SingleIcon title="Un service publicitaire" text="pour vous aider dans le ciblage et la réalisation de vos campagnes">
                <FacebookIcon fill={IconColor} />
            </SingleIcon>
        </div>
    </div >)
}

const SingleIcon = ({ title, text, children }) => {
    return (<div style={{ display: "flex", flexDirection: "column", width: "20vw", padding: "3vw", alignItems: "center" }}>
        <div style={{ width: "6vw" }}>
            {children}
        </div>

        <div style={{ fontWeight: "bold", fontSize: "1.5rem", textAlign: "center", marginTop: "1vw", height: "5vw" }}>
            {title}
        </div>
        <div style={{ fontSize: "1.2rem", textAlign: "center", marginTop: "1vw" }}>
            {text}
        </div>
    </div>)
}

const TexteInercalaire = () => {
    return (<div style={{ width: "100vw", display: "flex", alignItems: "center", flexDirection: "column", padding: "4vw" }}>
        <h1> Ce que PEKOIA University vous propose: </h1>
        <div style={{ width: "70vw", fontSize: "1.2rem" }}>
            Pekoia University permet d’organiser une rencontre (conférences, classes virtuelles, visites guidées …) avec une philosophie d’apprentissage autour du plaisir et de l’interactivité.
        </div>
    </div>

    )
}

const Intro = ({ setOpen, matches }) => {

    const bannerColor = "#ffd32a"



    return (<React.Fragment>


        <div style={{ display: "flex", flexDirection: matches ? "column-reverse" : "row-reverse", flexWrap: "flex-wrap", justifyContent: "end", backgroundColor: bannerColor }}>
            <Banner height={matches ? "500px" : "500px"} />





            <div style={{ width: matches ? "85vw" : "35vw", display: "flex", margin: "7vw 5vw 0vw 10vw", flexDirection: "column" }}>

                <div style={{ fontSize: matches ? "1.75rem" : "2.5rem", fontWeight: "bold", color: "white" }}>
                    <div style={{ fontWeight: "900", lineHeight: matches ? "2rem" : "3rem", color: "black" }}>
                        {"La première plateforme web française au service du plaisir de transmission et de découverte"}
                    </div>
                    <div style={{ marginTop: "2vw", marginBottom: "1vw", color: "#101010", fontWeight: "200", fontSize: "1.5rem", lineHeight: "1.5rem" }}>
                        Proposez vos événements, ateliers, conférences, cours ... à distance ou en présentiel
                    </div>
                    <MainButton className="frontPage" onClick={() => setOpen(true)}>
                        <Typography style={{ color: "white" }}>
                            Rejoignez nous
                        </Typography>

                    </MainButton>
                </div>

            </div>



        </div>





    </React.Fragment>
    )
}