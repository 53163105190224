import React, { useRef, useState } from "react";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "./SunEditorOverride.css";
import extractHtml from "./htmlExtract";

import { parse, serialize } from "parse5";
import Box from "@mui/material/Box";

import fetchOpenGraph from "./fetchers";
import linkedinIconString from "../SVGIcons/linkedin";
import youtubeIconString from "../SVGIcons/youtube";
import twitchIconString from "../SVGIcons/twitch";
import instagramIconString from "../SVGIcons/instagram";
import {
  externalPageImageStyle,
  pageUrlStyle,
  componentStyle,
  titleStyle,
} from "../SVGIcons/helpers";
import { ContentCutRounded } from "@mui/icons-material";

const sortedFontOptions = [
  "Roboto",
  "Salesforce Sans",
  "Garamond",
  "Sans-Serif",
  "Serif",
  "Times New Roman",
  "Helvetica",
].sort();

const Editor = (props) => {
  const {
    config,
    content: myContent,
    setContent: setMyContent,
    debug,
    setNewDocumentHtml,
  } = props;

  //const content = modalStoreHook && modalStoreHook[0].documentHtml || content

  //console.log('*EDITOR')

  // const [myContent, setMyContent] = useState(content);
  // console.log('TE', content);

  const editor = useRef(null);

  const getSunEditorInstance = (sunEditor) => {
    // sunEditor.onPaste = handlePaste;

    editor.current = sunEditor;
  };

  const handleChange = (locaContent) => {
    setMyContent(locaContent);
    setNewDocumentHtml(locaContent);
  };

  const handlePaste = async (event, cleanData, maxCharCount, core) => {
    const xml = parse(cleanData);

    const extractedText = extractHtml(xml);
    console.log("@TEXT:");
    console.log(extractedText);

    const linkedinRegex = /https:\/\/www.linkedin.com\/.+\//;
    const instagramRegex = /https:\/\/www.instagram.com\/.+\//;
    const youTubeRegex = /https:\/\/www.youtube.com\/embed\/(.+)/;
    const youTubeChannel = /https:\/\/www.youtube.com\/c\/(.+)/;
    const twitchRegex = /https:\/\/www.twitch.tv\/.+/;
    const youtubeRegularChannelRegex = /https:\/\/www.youtube.com\/watch\?v=.+/;

    const instagramMatch = extractedText.match(instagramRegex);

    //https://www.youtube.com/watch?v=CAKB28LPsbI

    if (instagramMatch) return instagramIconString(instagramMatch[0]);

    const linkedinMatch = extractedText.match(linkedinRegex);
    if (linkedinMatch) return linkedinIconString(linkedinMatch[0]);

    const youtubeRegularMatch = extractedText.match(youtubeRegularChannelRegex);

    let isYoutubeRegular = false;
    if (youtubeRegularMatch) isYoutubeRegular = true;

    const youTubeChannelMatch = extractedText.match(youTubeChannel);

    if (youTubeChannelMatch)
      return youtubeIconString(
        `https://www.youtube.com/c/${youTubeChannelMatch[1]}`
      );

    const youTubeMatch = extractedText.match(youTubeRegex);
    if (youTubeMatch) {
      const youTubeId = youTubeMatch[1];
      return youtubeIconString(`https://www.youtube.com/watch\?v=${youTubeId}`);
    }
    const twitchMatch = extractedText.match(twitchRegex);

    if (twitchMatch) return twitchIconString(twitchMatch[0]);

    const urlRegex = /https:\/\/(www.)?([^\/]+(\/[^\/]+)?)/;
    const urlMatch = extractedText.toString().match(urlRegex);

    if (urlMatch || isYoutubeRegular) {
      await fetchOpenGraph(extractedText).then((data) => {
        if (!data) return -1;

        console.log("** DATA");
        console.log(data);

        const imageSrc = data.ogImage;
        const urlTitle = data.ogTitle || data.ogSiteName;

        const pageUrl = urlMatch[1] != "www." ? urlMatch[2] : urlMatch[2];

        if (imageSrc && urlTitle) {
          updateMessageContent(
            extractedText,
            `
                  <p>
                  <div ${componentStyle}>
                  <a el="nofollow" target="_blank" href="${
                    urlMatch[0]
                  }" class="_externalPage"><img ${externalPageImageStyle}
                    src="${imageSrc}"
                    alt=""></a>
                  <div>
                  <div ${pageUrlStyle}>${pageUrl.toUpperCase()}</div>
                    <div ${titleStyle}>${urlTitle}</div>

                  </div>
                    </div>
                    </p>
                  `
          );
        } else if (urlTitle) {
          editor.current.insertHTML(extractHtml(xml));
          updateMessageContent(extractedText, `${urlTitle}`);
        }
      });
      return `<a style='text-decoration:underline; color: #74b9ff; rel='nofollow' target='_blank' href='${urlMatch[0]}'>${extractedText}</a>`;
    }

    console.log("@XML");
    console.log(editor.current.core.getContents());
    //console.log(urlMatch)
    // editor.current.insertHTML(extractHtml(xml));
  };

  const regex = /<a href="(.+)"(.+)<\/a>/;

  const updateMessageContent = (from, to) => {
    const oldContent = editor.current.core.getContents();
    const newContent = oldContent.replace(from, to);
    editor.current.setContents(newContent);
  };

  const onBlur = (newContent) => {
    //setContent(newContent);
  };

  return (
    <Box width="100%" height="100%" minHeight={300}>
      <SunEditor
        lang="fr"
        setContents={myContent}
        onPaste={handlePaste}
        getSunEditorInstance={getSunEditorInstance}
        onChange={handleChange}
        setOptions={{
          minHeight: "300px",
          width: "100%",
          size: "5px",
          buttonList: [
            ["undo"],

            ["bold", "underline", "italic"],
            ["fontColor"],
            ["align", "list"],
            ["outdent", "indent"],

            ["link"],
            ["removeFormat"],
          ],
          defaultTag: "div",
          showPathLabel: false,
          font: sortedFontOptions,
        }}
      />
    </Box>
  );
};

export default Editor;
