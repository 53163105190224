import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box } from "@mui/system";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton'
import { importedcanvasSecondDraw } from './helpers/canvasDrawing'

const FormateurInfo = ({ props }) => {
    const data = useSelector(state => state.dataReducer)
    const canvasRef = useRef(null)
    const imageRef = useRef(null)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (data && data.imageStore) {
            const cropData = {
                x: data.pixelCropX, y: data.pixelCropY, width: data.pixelCropWidth, height: data.pixelCropHeight
            }
            importedcanvasSecondDraw(imageRef, canvasRef, cropData)
        }
    }, [data, refresh])

    return (
    <Box margin="20px">
        <Box>
            <Box>
                <Box margin="30px 0px 0px 0px">
                    {data && data.hasPhoto && !data.imageStore && <Box width="130px" height="130px"><Skeleton variant="rectangular" width={100} height={90} /></Box>}
                    {data.imageStore &&
                    <Box margin="10px">
                        <canvas style={{ width: "130px", height: "130px", borderRadius: "50%" }} ref={canvasRef} />
                        <img ref={imageRef} style={{ width: "px", height: "0px", objectFit: "cover", objectPosition: "-40% 0%" }} src={data.imageStore.data} alt="intervenant" onLoad={() => setRefresh(true)}/>
                    </Box>}
                </Box>
                <Box marginBottom='10px'>
                    <Typography variant='h2' style={{ fontWeight: 400, textAlign: "start", fontSize: '1.2rem' }}>
                        {data.prenom}{' '}{data.nom}
                    </Typography>
                </Box>
            </Box>
            {data.linkedin &&
            <Box>
                <a href={data.linkedin}>
                    <LinkedInIcon/>
                </a>
            </Box>}
            <Box>
                {data.intro && 
                <Box margin="0px 0px 20px 0px">
                    <Typography margin="10px">
                        {data.intro}
                    </Typography>
                </Box>}
                {data.hashList && 
                <Box className={testStyle.specialityStyle} margin="0px 0px 10px 0px">
                    <Typography variant="h5" style={{ fontWeight: 400, textAlign: "start", fontSize: '1rem' }}>
                        Spécialités:
                    </Typography>
                    <Box margin="10px" display="flex" flexDirection="row" flexWrap="wrap" alignContent="center" alignItems="center">
                        {data.hashList && data.hashList.split('|').map((hash, index) => <Typography marginRight="10px" key={index}>{hash}</Typography>)}
                        {/* {formateur.hashList.split('|').map((hash, index) => <Hash key={index} hash={hash} />)} */}
                    </Box>
                </Box>}
            </Box>
        </Box>
    </Box>
    );
}

export default FormateurInfo;

const testStyle = makeStyles((theme) => {
    return ({
    specialityStyle: {
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignContent: "center",
        [theme.breakpoints.up('sm')]: {
          flexDirection: "row",
          marginTop: "70px",
          alignItems: 'center',
          justifyContent: 'center'
        }
        }
    })
})

