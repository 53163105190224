import React from 'react'
import { Link } from 'react-router-dom'
const Footer = ({ authState }) => {




  const handleClick = (type) => {

  }




  return (
    <>
      {/* Footer 13 */}
      <footer className="pt-45 pb-60 bg-dark color-white text-center text-lg-left footer_13">
        {/* <img src="uploads/logoverticalblanc.svg" alt="Some text about this" className="w-150 my-0 mx-120" /> */}
        <div className="container px-xl-0 " >
          <div className="lh-40 footer_links" data-aos="fade-down" data-aos-delay="0">
            <a href="https://www.pekoia.com" className="link f-14 semibold text-uppercase sp-20 color-white mr-10">
              www.pekoia.com
            </a>
            <a href="https://www.back.pekoia.com/tarifs" className="link f-14 semibold text-uppercase sp-20 color-white mr-10">
              Tarifs
            </a>
              {/* <Link to="#" className="link f-14 semibold text-uppercase sp-20 color-white mx-10">
              A propos
            </Link> */}
            <Link to="#" onClick={(e) => { 
              window.location.href = 'mailto:contact@pekoia.com';
              e.preventDefault();
            }} className="link f-14 semibold text-uppercase sp-20 color-white mx-10">
              Contact
            </Link>
          </div>
          <div data-aos="fade-down" data-aos-delay="0">
            <div className="mt-35 mb-40 hr h-2 bg-white op-3">
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-xl-4 col-lg-5">
                  <Link to="#" className="link img_link">
                    <img src="uploads/logoverticalblanc.svg" alt="Logo" className="img-fluid" data-aos="fade-down" data-aos-delay="0" />
                  </Link>
                </div>
                <div className="mt-15 mb-15 my-lg-0 col-xl-8 col-lg-7" data-aos="fade-down" data-aos-delay="250">
                  <div className="text-adaptive">
                    Copyright Pekoia 2023
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 text-lg-right" data-aos="fade-down" data-aos-delay="500">
           
              <a href="https://www.back.pekoia.com/confidentialite" target="_blank" className="link color-white mx-15 ">
              Conditions de confidentialité
              </a>

              <a href="https://www.back.pekoia.com/cgs" target="_blank" className="link color-white mx-15 ">
                CGS
              </a>
              <span className="d-inline-block socials">
                <a href="https://www.twitter.com/Pekoia" className="link color-white mx-15">
                  <i className="fab fa-twitter">
                  </i>
                </a>
                <a className="link color-white mx-15" href="https://www.facebook.com/Pekoia-101133162116892">
                  <i className="fab fa-facebook-f">
                  </i>
                </a>
                <Link to="#" className="link color-white ml-15">
                </Link>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer
