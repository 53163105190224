import { AppBar, Button, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { styled, withStyles } from "@mui/styles";
import { Box } from "@mui/system"
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useSelector } from "react-redux";
import LemonwayStatus from "./lemonwayStatus";
import UploadPage from "./profile/upload";

const EspaceBrique = ({ isInde }) => {
  const userData = useSelector(state => state.dataReducer)

  console.log(userData.lemonwayId)

  return (
    <Box>
      <Box borderRadius='20px' width='100%' padding="20px">
        <Box display="flex" flexDirection="row">
          <LemonwayStatus lemonwayId={userData.lemonwayId}/>
        </Box>
      </Box>
      <UploadPage isInde={isInde}/>
    </Box>
  );
}

const EspaceRemuneration = () => {
  const data = useSelector(state => state.eventsReducer)

  const rows = []
  // const rows = data.data.map((elem) => {
  //   return {
  //     id: elem.id,
  //     title: 'fakeData',
  //     format: 'conference',
  //     nbSells: 20,
  //     beforeLiberate: 4,
  //     price: 30,
  //     total: 600,
  //     liberable: 0,
  //   };
  // });

  const columns = [
    {
      field: 'conference',
      headerName: 'Conference',
      flex: 2,
      renderHeader: () => (
        <strong>
          {'Conférence'}
        </strong>
      ),
      cellClassName: 'theme--cell',
      renderCell: (params) => {
        return <Typography>{params.row.title}</Typography>
      }
    },
    {
      field: 'format',
      headerName: 'Format',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Format'}
        </strong>
      ),
      cellClassName: 'theme--cell',
      renderCell: (params) => {
        const format = params.row.format === 'conference' ? 'Conférence' : params.row.format === 'cycle' ? 'Cycle' : 'Replay';
        return <Typography>{format}</Typography>
      }
    },
    {
      field: 'ventes',
      headerName: 'Ventes',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Ventes'}
        </strong>
      ),
      cellClassName: 'theme--cell',
      renderCell: (params) => {
        return <Typography>{params.row.nbSells}</Typography>
      }
    },
    {
      field: 'liberer',
      headerName: 'Liberer',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Nb jours avant de pouvoir libérer'}
        </strong>
      ),
      cellClassName: 'theme--cell',
      renderCell: (params) => {
        return <Typography>{params.row.beforeLiberate}</Typography>
      }
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Total'}
        </strong>
      ),
      cellClassName: 'theme--cell',
      renderCell: (params) => {
        return <Typography>{(params.row.nbSells * params.row.price).toString() + "€"}</Typography>
      }
    },
    {
      field: 'liberable',
      headerName: 'Liberable',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Dont libérable'}
        </strong>
      ),
      cellClassName: 'theme--cell',
      renderCell: (params) => {
        return <Typography>{params.row.liberable}</Typography>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      renderHeader: () => (
        <strong>
          {'Actions'}
        </strong>
      ),
      cellClassName: 'theme--cell',
      renderCell: (params) => {
        return (<Box display="flex" flexDirection="row" justifyContent="space-evenly">
          <LightTooltip title="Demander le versement depuis mon compte de paiement Lemonway vers mon compte bancaire (RIB fourni lors de l'inscription).">
            <StyledButton>
              <Typography>Versement</Typography>
            </StyledButton>
          </LightTooltip>
          <LightTooltip title="Récupérer un justificatif de cette transaction.">
            <StyledButton>
              <Typography>Justificatif</Typography>
            </StyledButton>
          </LightTooltip>
        </Box>);
      }
    },
  ]

  return (
    <Box height="400px" margin="30px">
      <Typography style={{ color: "red", fontSize: "1.2rem", margin: "5px" }}>
        {"Travaux en cours ; pour effectuer un versement depuis votre compte de paiement Lemonway vers votre compte bancaire, veuillez nous adresser la demande à contact@pekoia.com"}
      </Typography>
      <DataGridPro
        rowHeight={50}
        columns={columns}
        rows={rows}
      />
    </Box>
  );
}

const EspaceFinances = ({ fetchData, user, isInde }) => {
  const [tab, setTab] = useState(0)

  const handleTab = (event, newValue) => {
    setTab(newValue)
  }

  const tabs = [
    <EspaceBrique isInde={isInde}/>,
    <EspaceRemuneration/>
  ]
  
  return (
    <Box>
      <Box>
        <Box width='100%' margin='20px'>
          <Typography variant='h4' fontSize='1.5rem' fontWeight={900}>
            {"Enregistrez vous auprès de notre brique de paiement et gerez vos rémunérations".toUpperCase()}
          </Typography>
        </Box>
        <Box width='100%' margin='30px 0px 30px 20px'>
          <Typography marginBottom="10px">
            {"À noter que l'inscription à la brique de paiement lemonway (délai de validation sous 48 heures) est obligatoire pour créer une première conférence payante ou un premier cycle de conférences payant sur Pekoia."}
          </Typography>
          <Typography>
            {"Partenaire de Lemonway établissement de paiement agréé par l’ACPR en France le 24 décembre 2012 sous le numéro 16568"}
          </Typography>
        </Box>
      </Box>
      <Box width="100%">
        <Tabs value={tab} onChange={handleTab} variant="fullWidth" TabIndicatorProps={{ style: { backgroundColor: '#1a5aa3', accentColor: '#1a5aa3' }}} textColor="inherit">
          <Tab label="Brique de paiement" sx={{ fontSize: "1.1rem" }}/>
          <Tab label="Gestion des rémunérations" sx={{ fontSize: "1.1rem" }}/>
        </Tabs>
      </Box>
      {tabs[tab]}
    </Box>
  );
}

export default EspaceFinances

const StyledButton = styled(Button)({
	textTransform: 'none',
	backgroundColor: "#1a5aa3",
	color: '#FFFFFF',
  alignSelf: 'center',
	// height: '32px',
	borderRadius: '20px',
	fontSize: '0.85rem',
  marginTop: '5px',
  marginRight: '5px',
  marginBottom: '5px',
  '&:hover': {
    backgroundColor: '#404040',
  }
})

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#3b3b3b",
		padding: '10px',
		color: '#F0F0F0',
		lineHeight: '1.3rem',
		boxShadow: theme.shadows[1],
		fontSize: 14,
	},
}))(Tooltip)
