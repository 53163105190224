// dynamoDB data associated to a userID

import { createSlice } from '@reduxjs/toolkit'

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {menu : 0},

    reducers: {
        setMenuPage: (state, action) => {state.menu = action.payload},
    }
})

export const { setMenuPage} = navigationSlice.actions


export default navigationSlice.reducer

