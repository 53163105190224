 const regexTable = [
    /(https?:\/\/)(\S+)/,
    /meet\.google\.com\S+/,
    /meet\.google\.com\S+/,
    /tel\.meet\S+/
]

export const isValidMeetUrl = (url) => {
    let response = null
    if (!url)
        return false
    const found = regexTable.map(reg => url.match(reg)).find(elem => elem)
    if (found?.length === 1)
        response = found[0]
    else if ((found?.length))
        response = found[2]
    return response
}