import React, { useState, useRef } from 'react';
import { Box } from "@mui/system";
import { Button, Typography } from '@mui/material';
import FormateurForm from '../formateurForm';
import FormateurInfo from '../formateurInfo';
import FormateurDescForm from '../formateurDescForm';
import FormateurDescInfo from '../formateurDescInfo';
import FormateurPublicationForm from '../formateurPublicationForm';
import ProfileAdvancement from '../profileAdvancement';
import { useSelector } from 'react-redux';
import store from "../../redux/store";
import { insertPublication, deletePublication } from "../../features/data/dataSlice";
import { handleClose00, handleClose00b, handleClose01 } from '../handleClose';
import { localeText, regexList, cleanText, cutText } from "../general";
import DeleteIcon from '@mui/icons-material/Delete';
import { ColorButton } from "../BO-style";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useConfirm } from 'material-ui-confirm';
import { colorGray } from '../../AppStyle';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const getSource = (url) => {
	const regex = regexList.find(regex => url.match(regex))
	return (url.match(regex)[2])
}

const SectionFormateur = ({ fetchData, user }) => {
	var [isFormateurForm, setIsFormateurForm] = useState(false)
	var [isFormateurDescForm, setIsFormateurDescForm] = useState(false)
	var [isFormateurPublicationForm, setIsFormateurPublicationForm] = useState(false)
	var [formData, setFormData] = useState(null)
	var [descFormData, setDescFormData] = useState(null)
	var [publiFormData, setPubliFormData] = useState(null)
	const profileRef = useRef(null)
	const descRef = useRef(null)
	const publiRef = useRef(null)
	const [dragOrigin, setDragOrigin] = useState(null)
	const data = useSelector(state => state.dataReducer)
	const publicationsHook = useSelector(state => state.publicationsReducer)
	const publications = publicationsHook.data
	const confirm = useConfirm()

	const areEqual = (actData, formData) => {
		if (actData.prenom === formData.prenom &&
			actData.nom === formData.nom &&
			actData.linkedin === formData.linkedin &&
			actData.hashList === formData.hashList &&
			actData.intro === formData.intro)
			return true
		return false
	}

	const dragStart = (event) => {
		document.body.style.cursor = 'grab'
		setDragOrigin(event.target.id)
	}

	const allowDrop = (event) => {
		event.preventDefault()
	}

	const onMouseLeave = (event) => {
		event.preventDefault()
		document.body.style.cursor = 'default'
	}

	const onDrop = (event) => {
		event.preventDefault()
		store.dispatch(insertPublication({
			originIndex: dragOrigin,
			destinationIndex: event.target.id
		}))
		document.body.style.cursor = 'default'
	}

	const sortedPublications = data.publicationList ? JSON.parse(data.publicationList)
		.map(index => publications.find(publication => publication.index === index)) : []

	const rows = sortedPublications
		.filter(elem => typeof (elem) !== "undefined" && !elem.organisateurId && !elem.salonId)
		.map((elem) => {
			return ({
				id: elem.index,
				url: elem.type === 'IMAGE' ? elem.base64Image : elem.ogImage,
				title: elem.type === 'IMAGE' ? elem.imageTitle : cutText(cleanText(elem.ogTitle), 80),
				source: elem.type === 'IMAGE' ? elem.url ? elem.url : "formateur" : getSource(elem.url)
			})
		})

	const columns = [{
		field: "image",
		renderHeader: () => (
			<strong>
				{'Image'}
			</strong>
		),
		flex: 2,
		disableClickEventBubbling: true,
		renderCell: (params) => {

			return <div style={{ cursor: "grab" }}><img alt='' onMouseLeave={onMouseLeave} onDrop={onDrop} onDragOver={allowDrop} id={params.row.id} draggable={true} onDragStart={dragStart} style={{ width: "90px", margin: "1vw", padding: "0.25vw", height: "90px", objectFit: "cover" }} src={params.row.url}></img></div>;
		}
	},
	{
		field: 'title',
		headerName: 'Description',
		flex: 5,
		renderHeader: () => (
			<strong>
				{'Description'}
			</strong>
		),
		cellClassName: 'theme--cell',
	},
	{
		field: 'source',
		headerName: 'Source',
		flex: 3,
		renderHeader: () => (
			<strong>
				{'Source'}
			</strong>
		),
		cellClassName: 'theme--cell',
	}, {
		field: "delete",
		renderHeader: () => (
			<strong>
				{'Supprimer'}
			</strong>
		),
		sortable: false,
		flex: 1.7,
		disableClickEventBubbling: true,
		renderCell: (params) => {
			const onClick = () => {
				confirm({
					title: 'ATTENTION',
					cancellationText: 'Annuler',
					confirmationText: 'Supprimer',
					description: 'Vous allez supprimer définitivement cette publication'
				})
					.then(() => {
						store.dispatch(deletePublication({
							id: params.row.id
						}))
					})
			};
			return <ColorButton
				onClick={onClick}
			><DeleteIcon /></ColorButton>;
		}
	}]

	const clickHandlerForm = () => {
		if (isFormateurForm) {
			// if (!areEqual(data, formData))
			handleClose00(formData, fetchData, false)
		}
		isFormateurForm = setIsFormateurForm(!isFormateurForm)
	};
	const clickHandlerFormCancel = () => {
		if (!areEqual(data, formData)) {
			setFormData({
				prenom: data.prenom,
				nom: data.nom,
				intro: data.intro,
				hashList: data.hashList,
				linkedin: data.linkedin,
			})
		}
		isFormateurForm = setIsFormateurForm(!isFormateurForm)
	};
	const clickHandlerDescForm = () => {
		if (isFormateurDescForm) {
			handleClose00b(descFormData, setDescFormData, fetchData)
		}
		isFormateurDescForm = setIsFormateurDescForm(!isFormateurDescForm)
	};
	const clickHandlerDescFormCancel = () => {
		setDescFormData({ documentHtml: data.documentHtml })
		isFormateurDescForm = setIsFormateurDescForm(!isFormateurDescForm)
	};

	const clickHandlerPublicationForm = () => {
		if (isFormateurPublicationForm) {
			handleClose01(publiFormData, setPubliFormData, fetchData)
		}
		isFormateurPublicationForm = setIsFormateurPublicationForm(!isFormateurPublicationForm)
	}

	const clickHandlerPublicationFormCancel = () => {
		setPubliFormData(null)
		isFormateurPublicationForm = setIsFormateurPublicationForm(!isFormateurPublicationForm)
	}

	return (
			<Box width='100%'>
				<Box>
					<Box width='100%' margin='20px'>
						<Typography variant='h4' fontSize='1.5rem' fontWeight={900}>
							{"Completez les formulaires «ma page» pour vous présenter sur www.pekoia.com".toUpperCase()}
						</Typography>
					</Box>
					<Box width='100%' margin='30px 0px 30px 20px'>
						<Typography>
							À noter que le bon remplissage du formulaire «Profil» est obligatoire pour créer une première conférence ou un premier cycle de conférences sur Pekoia.
						</Typography>
					</Box>
				</Box>
				<ProfileAdvancement profileRef={profileRef} descRef={descRef} publiRef={publiRef} fetchData={fetchData} user={user} isCompany={false} />
				<Box ref={profileRef} border={(isFormateurForm) ? `2px solid black` : "none"} borderRadius='20px' width='100%' padding='20px'>
					<Box>
						<Box borderBottom='1px solid black'>
							<Typography variant='h4' fontSize='1.5rem' margin='10px'>
								Profil
							</Typography>
						</Box>
						{isFormateurForm ? <FormateurForm formData={formData} setFormData={setFormData} /> :
							<FormateurInfo />}
					</Box>
					{isFormateurForm ? <div><StyledButton onClick={clickHandlerForm} style={{ marginBottom: '10px' }}>Sauvegarder</StyledButton><StyledButton onClick={clickHandlerFormCancel} style={{ marginBottom: '10px' }}>Annuler</StyledButton></div> : <StyledButton onClick={clickHandlerForm} style={{ marginBottom: '10px' }}>Modifier</StyledButton>}
				</Box>
				<Box ref={descRef} border={(isFormateurDescForm) ? `2px solid black` : "none"} borderRadius='20px' width='100%' padding='20px'>
					<Box>
						<Box borderBottom='1px solid black'>
							<Typography variant='h4' fontSize='1.5rem' margin='10px'>
								Parcours
							</Typography>
						</Box>
						{isFormateurDescForm ? <FormateurDescForm descFormData={descFormData} setDescFormData={setDescFormData} /> : <FormateurDescInfo />}
					</Box>
					{isFormateurDescForm ? <div><StyledButton onClick={clickHandlerDescForm} style={{ marginBottom: '10px' }}>Sauvegarder</StyledButton><StyledButton onClick={clickHandlerDescFormCancel} style={{ marginBottom: '10px' }}>Annuler</StyledButton></div> : <StyledButton onClick={clickHandlerDescForm} style={{ marginBottom: '10px' }}>Modifier</StyledButton>}
				</Box>
				<Box ref={publiRef} border={(isFormateurPublicationForm) ? `2px solid black` : "none"} borderRadius='20px' width='100%' padding='20px'>
					<Box borderBottom='1px solid black'>
						<Typography variant='h4' fontSize='1.5rem' margin='10px'>
							Publications
						</Typography>
					</Box>
					{isFormateurPublicationForm ? <FormateurPublicationForm style={{ width: "64vw" }} formData={publiFormData} setFormData={setPubliFormData} /> : null}
					{isFormateurPublicationForm ? <div><StyledButton onClick={clickHandlerPublicationForm} style={{ marginBottom: '10px' }}>Sauvegarder</StyledButton><StyledButton onClick={clickHandlerPublicationFormCancel} style={{ marginBottom: '10px' }}>Annuler</StyledButton></div> : <StyledButton onClick={clickHandlerPublicationForm} style={{ marginBottom: '10px' }}>Ajouter</StyledButton>}
					<Box>
						<Box bgcolor={colorGray} height='50vw' marginLeft="0vw" border={`1px solid ${grey[100]}`}>
							<DataGridPro
								rowHeight={80}
								localeText={localeText}
								onClick={() => { }}
								rows={rows}
								columns={columns}
								pageSize={5}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
	);
}

export default SectionFormateur

const StyledButton = styled(Button)({
	textTransform: 'none',
	backgroundColor: "#1a5aa3",
	color: '#FFFFFF',
	height: '32px',
	borderRadius: '20px',
	fontSize: '0.85rem',
	margin: '5px',
	marginTop: '20px',

	'&:hover': {
		backgroundColor: grey[700]
	}


})
