import React, { useEffect, useState, useRef } from "react"
import Box from '@mui/material/Box'
import {Grid} from 'react-loader-spinner'
import CropperScreen from "./cropper"
import { importedcanvasDraw, importedcanvasSecondDraw } from "./canvasDrawing"
import { v4 as uuid_v4 } from "uuid";

const ImageUpload = ({ data, tempImageStore: imageStore, setTempImageStore: setImageStore, selectFile }) => {

    const [stage, setStage] = useState(0)
    const [spinner, setSpinner] = useState(false)
    const imageRef = useRef(null)
    const canvasRef = useRef(null)
    const [currentUrl, setCurrentUrl] = useState(null)
    const [cropData, setCropData] = useState(null)
    const [hasNewImage, setHasNewImage] = useState(false)

   
    useEffect(() => {
        if (selectFile) {
            onDrop(selectFile)
        }

    }, [selectFile])


    useEffect(() => {
        if (data?.hasPhoto && !imageStore) {
            setSpinner(true)
        }
        setCropData(null)
        if (imageStore) {
            setSpinner(false)
            if (data?.pixelCropX) {
                setCropData({
                    x: data.pixelCropX,
                    y: data.pixelCropY,
                    width: data.pixelCropWidth,
                    height: data.pixelCropHeight
                })
            }

            setCurrentUrl(imageStore.data)
        }

        if (stage === 4) {
            canvasSecondDraw(cropData)
            setImageStore({
                ...imageStore,
                cropData: {
                    x: cropData.x,
                    y: cropData.y,
                    width: cropData.width,
                    height: cropData.height
                }
            })
            setStage(2)
        }

    }, [canvasRef, imageStore, currentUrl, stage])



    const canvasDraw = () => {
        if (!hasNewImage && cropData) {
            canvasSecondDraw(cropData)
        }
        else {

            importedcanvasDraw(imageRef, canvasRef)
        }
        if (hasNewImage) {
            const minSize = Math.min(imageRef.current.naturalWidth, imageRef.current.naturalHeight)
            const x = Math.max(0, (imageRef.current.naturalWidth - minSize) / 2)
            const y = Math.max(0, (imageRef.current.naturalHeight - minSize) / 2)

            setImageStore({
                ...imageStore,
                cropData: {
                    x: x,
                    y: y,
                    width: minSize,
                    height: minSize
                }
            })

        }

        setSpinner(false)
        setStage(2)
    }

    const canvasSecondDraw = (cropData) => importedcanvasSecondDraw(imageRef, canvasRef, cropData)



    const onDragOver = (e) => { e.preventDefault() }

    const onDrop = (selectedFile) => {

        let photo = null
        //if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        if (selectedFile.selectedFile) {
            photo = selectedFile.selectedFile
        }
        else {
            photo = selectedFile
        }


        setSpinner(true)

        // setStage(1)
        // setTimeout(() => {

        setTimeout(() => {
            const reader = new FileReader();

            reader.readAsDataURL(photo);


            reader.onloadstart = () => {
                //
            }

            reader.onloadend = () => {

                const data = reader.result
                setHasNewImage(true)
                setImageStore({
                    data: data,
                    imageId: uuid_v4()
                })
            }
        }
            , 250)
        //}
    }

    const onDragLeave = (e) => { e.preventDefault() }


    return (<Box width={CanvasSize} height={CanvasSize} onDragOver={onDragOver} onDrop={(e) => { e.preventDefault(); onDrop(e.dataTransfer.files[0]) }} onDragLeave={onDragLeave}>
        <img onLoad={canvasDraw} ref={imageRef} style={{ width: "0vw", height: "0vw", display: "none" }} alt="" src={currentUrl} />
        <canvas style={{ position: "relative", top: "0vw", width: CanvasSize, height: CanvasSize, display: (stage === 2) ? "block" : "none" }} ref={canvasRef} />

        {(stage === 2) && <RecadrageButton setStage={setStage} />}
        <Spinner stage={stage} spinner={spinner} />
        {(stage === 3) && <CropperScreen cropData={cropData} CanvasSize={CanvasSize} setCropData={setCropData} imageStore={imageStore} setImageStore={setImageStore} setStage={setStage} />}

        <Box display="flex" flexDirection="row">


        </Box>

    </Box>)
}

export default ImageUpload

const CanvasSize = "18vh"

const RecadrageButton = ({ setStage }) => {
    return (<Box position="relative" zIndex="3" top="-2.25vw" left="0.5vw" opacity="0.1">
        <button onClick={() => setStage(3)} >Recadrer</button>
    </Box>)
}




const Spinner = ({ stage, spinner }) => {
    return (<Box bgcolor="#ff9ff300" position="relative" top="-21vw" display="flex" flexDirection="row" alignItems="center" justifyContent="center" width={CanvasSize} height={CanvasSize} >
        {(stage === 2) && spinner && <Grid  color="white" height="7vw" width="7vw" />}
    </Box>)
}





