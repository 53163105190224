import { useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box } from "@mui/system";
import Typography from '@mui/material/Typography';
import { cleanHtml } from '../components/SVGIcons/helpers'

const FormateurInfo = ({ props }) => {
    const data = useSelector(state => state.dataReducer)
    var documentHtml = null
    documentHtml = cleanHtml(data.documentHtml)

    return (
    <Box margin="20px">
        <Box>
            <Box>
                <Box margin="0px 0px 20px 0px">
                    {documentHtml ? <div dangerouslySetInnerHTML={{__html: documentHtml}}/> : "Non renseigné"}
                </Box>
            </Box>
        </Box>
    </Box>
    );
}

export default FormateurInfo;