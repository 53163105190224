import { ColorButtonUpload, ColorButtonUploadOff } from "../components/BO-style"
import { Input, Typography } from '@mui/material';
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { addLemonwayDocument } from "../features/data/dataSlice"
import imageCompression from 'browser-image-compression';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
    }
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


export const FileUpload = ({ modalStoreHook, setSpinner, sectionData, completedLevel, setErrorMessage }) => {



    const [modalStore, setModalStore] = modalStoreHook
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const dispatch = useDispatch()

    const handleClick = (event) => {


        setAnchorEl(event.currentTarget);
    }


    useEffect(()=>{
    
        setAnchorEl(null)

    },[modalStoreHook[0]])

    const handleClose = (event) => {

    
        setAnchorEl(null)
        if (event.id)
            dispatch(addLemonwayDocument(event))
        setSpinner(false)

    };



    return (<React.Fragment>
        <CustomButton handleClick={handleClick} completedLevel={completedLevel} />
        
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <SelectMenu setErrorMessage={setErrorMessage} setSpinner={setSpinner} setAnchorEl={setAnchorEl} sectionData={sectionData} modalStoreHook={modalStoreHook} callback={handleClose} />
        </Popover>
    </React.Fragment>

    )


}

const compressionOptions = {
    maxSizeMB: 4,
    maxWidthOrHeight: 1024,
    useWebWorker: true
}

const SelectMenu = ({ setSpinner, setAnchorEl, sectionData, modalStoreHook, callback, setErrorMessage }) => {

    const defaultCode = sectionData.documentTypes[0].code


    const { description, documentTypes } = sectionData
    const classes = useStyles();
    const [code, setCode] = React.useState(defaultCode)
    const handleChange = code => (event) => {
        setCode(event.target.value);
    };

    const handleUploadClick = event => {

        setSpinner(true)

        const file = event.target.files[0]

  
        const ext = file.name && file.name.split('.')[1]
        setErrorMessage("")

      
       
        if (!['jpg', 'jpeg', 'png', 'pdf'].includes(ext))
            {
                setErrorMessage("format d'image non reconnu.")
                setAnchorEl(null)
                setSpinner(false)
                return null
            }

        // @TOTO
        
        let orginalImage = null
        let compressedImage = null

        const regex = /data:image\/.+;base64,(.+)/
        const regexPDF = /data:application\/.+;base64,(.+)/

        let isPDF =  ['pdf'].includes(ext) ? true : false

       

        if (isPDF)
        {
          
            const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onloadend = () => {


                    const data = reader.result

                    const match = data.match(regexPDF)


                        //data:application/pdf;
             
                        modalStoreHook[1]({
                            name: file.name,
                            code: code,
                            base64: match[1],
                            callback: callback
            
                        })

                    
                 

                    setAnchorEl(null)


                }

         
               
        

        }
        else {

            imageCompression(file, compressionOptions)
            .then((compressedFile) => {
                const reader = new FileReader()
                reader.readAsDataURL(compressedFile)
                reader.onloadend = () => {


                    const data = reader.result

             
                

                        const match = data.match(regex)

                        if (match)
                            modalStoreHook[1]({
                                name: compressedFile.name,
                                base64: match[1],
                                code: code,
                                callback: callback
    
                            })

                    
                 

                    setAnchorEl(null)


                }
            })
            .catch((error) => {
                console.log('error:')
                console.log(error)
                setAnchorEl(null)
            });

        }

       
    }

    const FBButtons = documentTypes.map((document, index) => ({
        key: index,
        value: document.label,
        code: document.code
    }))



    return (<div>
        <Box padding="5px" display='flex' flexDirection="column" alignItems="center">
            <Box padding="5px">
                <FormControl className={classes.margin}>
                    <InputLabel htmlFor="demo-customized-select-native">Type de document</InputLabel>
                    <NativeSelect
                        id="demo-customized-select-native"
                        value={code}
                        onChange={handleChange(code, callback)}
                        input={<BootstrapInput />}
                    >
                        {FBButtons.map(fbb =>
                            <option key={fbb.key} value={fbb.code}>{fbb.value}</option>
                        )};
                    
                    </NativeSelect>
                </FormControl>

            </Box>
            <Box padding="5px">
                <ColorButtonUpload
                    variant="contained"
                    component="label">
                    <Input accept="image/*, image/x-png"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        name="upload-photo"
                        type="file"
                        onChange={handleUploadClick} />


                    <Box display="flex" flexDirection="row" justifyContent="center" width="160px">
                        <Box >
                            <Typography >
                                Selectionner
                            </Typography>
                        </Box>

                    </Box>

                </ColorButtonUpload>

            </Box>
        </Box>


    </div>)
}


const CustomButton = ({ completedLevel, handleClick }) => {

    if (completedLevel)
        return (<ColorButtonUpload onClick={handleClick}>
            <Box display="flex" flexDirection="row" justifyContent="center" width="120px">
                <Box flex={2}>
                    <Typography >
                        Ajouter
                    </Typography>
                </Box>


                <CloudUploadIcon style={{ fontSize: "20px" }} />

            </Box>
        </ColorButtonUpload>)

    else return (<ColorButtonUploadOff>
        <Box display="flex" flexDirection="row" justifyContent="center" width="120px">
            <Box flex={2}>
                <Typography >
                    Ajouter
                </Typography>
            </Box>


            <CloudUploadIcon style={{ fontSize: "20px" }} />

        </Box>
    </ColorButtonUploadOff>)



}



