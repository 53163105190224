const extractHtml = (parse5Object) => {



 
    

    var text = ''
  
    const tags = ['p', 'strong']
  
    if (parse5Object.nodeName == '#text')
      return parse5Object.value

    if (tags.includes(parse5Object.nodeName) && parse5Object.childNodes) {
      const prefix = `<${parse5Object.nodeName}>`
      const suffix = `</${parse5Object.nodeName}>`
      parse5Object.childNodes.forEach(node => text += extractHtml(node))
      return prefix + text + suffix
    }
  
    if (parse5Object.nodeName == 'a') {
  
      const href = parse5Object.attrs.find(attr => attr.name == 'href')
      if (href) {
        const prefix = `<a href='${href.value}'>`
        const suffix = `</a>`
        parse5Object.childNodes.forEach(node => text += extractHtml(node))
        return prefix + text + suffix
      }
  
  
    }

    
  
  
    if (parse5Object.childNodes)
      parse5Object.childNodes.forEach(node => text += extractHtml(node))


   
  
    return text
  
  
  
  
  }

  export default extractHtml