import React, { useState, useEffect } from "react"

import Toolbar from '@mui/material/Toolbar';
import { ReactComponent as Logo } from "../assets/back_office.svg"
import { DBupdateFormateur } from "./helpers/batches"

import Button from '@mui/material/Button';

import { Hub, Auth } from "aws-amplify"
import { Storage } from "aws-amplify"
import hash from 'object-hash'
import Box from '@mui/material/Box'
import Avatar, { NewAvatar } from "./avatar"
import { publicationsbyFormateur, eventsbyFormateur, cyclesbyFormateur } from '../graphql/queries'
import { MakeFetchConferenceData, fetchDataAbstraction } from "./helpers/fetchers"

// Redux - change for bundle hash analysis
import { useSelector, useDispatch } from 'react-redux'
import { setFormateurData, setImage, addImageToStore } from '../features/data/dataSlice'
import { setPublications } from "../features/publications/publicationsSlice"
import { setCycles } from "../features/cycles/cyclesSlice"
import { setEvents } from "../features/events/eventsSlice"



import Menu from './menu/popUp'
import UploadPage from './profile/upload'

//fetchers
import { MakefetchData } from "./helpers/fetchers"


import Tableau from './tableau'
import { setMenuPage } from "../features/navigation/nevigationSlice";



const Display = (props) => {


    const { value, appImageCacher, fetchConferenceData, data, user, setUser, fetchData, content, setContent } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const navigationState = useSelector(state => state.navigationReducer)
    const dispatch = useDispatch()
    



    const clickHandler = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    const logoClickHandler = () => {
        dispatch(setMenuPage(0))
    }


    return (<Box padding="0px" width="100vw" flexGrow={1} bgcolor="white">


        {/* <AppBar position="static" display="flex" flexdirection="row"> */}
        <Box bgcolor="black" height="70px" >





            <Toolbar >
                <Box display="flex" height="100%" flex={10} onClick={logoClickHandler} style={{ cursor: "pointer" }} >
                    <Box display="flex" alignItems="center" justifyContent="center" marginRight="200px" color="black">
                        <Logo style={{ height: "50px" }} fill="white" />
                    </Box>

                </Box>



                <Button onClick={clickHandler}><Avatar data={data} /></Button>
                <Box marginRight="10px">    <NewAvatar /></Box>





            </Toolbar>
            <Menu open={open} anchorEl={anchorEl} data={data} setAnchorEl={setAnchorEl} />
        </Box>
        {/* </AppBar> */}
        <div style={{ display: "flex" }} >

            {((value === 0) && (navigationState && navigationState.menu === 0)) ? <Tableau content={content} setContent={setContent} appImageCacher={appImageCacher} fetchConferenceData={fetchConferenceData} fetchData={fetchData} user={user} setUser={setUser} /> : <Box />}
            {navigationState && navigationState.menu === 1 && <UploadPage fetchData={fetchData} user={user} />}

        </div>
        {/* <Footer /> */}

    </Box>


    )
}



const App = () => {



    const dataSelector = useSelector(state => state.dataReducer.publicationList)
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0)
    let timestamp = new Date().getTime()
    const [lastTimestamp, setLastTimestamp] = useState(timestamp)
   
    // setData: server information about the client
    // setUser: basic auth information

    const [data, setData] = useState(null)
    const [conferenceData, setConferenceData] = useState(null)
    const fetchConferenceData = MakeFetchConferenceData([conferenceData, setConferenceData])
    const [user, setUser] = useState(null)


    const [content, setContent] = useState('')
    const appImageCacher = (data) => { imageCacher(data, dispatch) }
    const fetchData = MakefetchData(dispatch, setData, setFormateurData)
    const [zoomKey, setZoomKey] = useState(null)
    void zoomKey

    useEffect(() => {
        const ZOOM_COOKIE = 'pekoia-zoom'
        const href = window.location.href
        const codeKey = extractCode(href)
        if (codeKey)
            localStorage.setItem(ZOOM_COOKIE, codeKey)
        const existingKey = localStorage.getItem(ZOOM_COOKIE)
        setZoomKey(existingKey)


    }, [])

    useEffect(() => {
        if (data && data.id) {

           

            fetchDataAbstraction(data, cyclesbyFormateur, setCycles, 'cycles', row => ({ ...row, imageKey: row.imageKey }))
            fetchDataAbstraction(data, eventsbyFormateur, setEvents, 'conferences', row => ({ ...row, imageKey: row.imageKey }))
            fetchDataAbstraction(data, publicationsbyFormateur, setPublications, 'publications', row => ({ ...row, imageKey: row.imageId }))
        }

    }, [data])

    useEffect(() => {

        if (data) {

            appImageCacher(data)
        }

    }, [data])

    useEffect(() => {
        DBupdateFormateur()
    }

        , [dataSelector]);



    useEffect(() => {

        let mounted = true


        const listener = ({ payload: { event, data } }) => {
            if (mounted) {
                switch (event) {
                    case "signIn":
                        console.log("test")
                        getUser().then((userData) => {

                            console.log('@SIGNED')
                            console.log(userData)

                            setUser(userData
                            )
                        })
                        .catch(err => console.log(err));
                        break;
                    case "signOut":
                        setUser(null);
                        setData(null);
                        break;
                    case "signIn_failure":
                        break;
                    default:
                        console.log('MISSING CASE')
                }
            }
        }

        Hub.listen("auth", listener);

        getUser()
            .then(fetchData);

        return () => {
            Hub.remove("auth", () => { });
            mounted = false
        };

    }, []);

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => {

                setUser(userData)
                fetchData(userData)
                

            })
            .catch(() => {

            }
            );
    }



    return (

        <Box>
         
      
        
        <Display content={content} setContent={setContent} value={value} data={data} setValue={setValue} appImageCacher={appImageCacher} fetchConferenceData={fetchConferenceData} conferenceData={conferenceData} setConferenceData={setConferenceData} user={user} setUser={setUser} setData={setData} fetchData={fetchData} />
        </Box>
    )
}

export default App




const imageCacher = (data, dispatch) => {

    if (data.hasPhoto) {

        const userId = data.id

        Storage.list(`${userId}/formateur/`, { level: 'private' }).then((filesList) => {
            const file = filesList.find((file) =>
                file.key.split('/')[2].split('.')[0] === data.imageId
            )


            const files = filesList.filter(image => image.key.includes(data.imageId))

            const fetchOrder = ['croppedThumbnail', 'portrait', 'originalCropped']
            fetchOrder.forEach(name => {
                const file = files.find(file => file.key.includes(name))
                if (file) {


                    Storage.get(file.key, { level: 'private' })
                        .then(
                            (url) => {

                                caches.open('v1').then((cache) => {
                                    fetch(url)
                                        .then(response => response.blob())
                                        .then((blob) => {
                                            var reader = new FileReader();
                                            reader.readAsDataURL(blob);
                                            reader.onloadend = function () {
                                                const base64data = reader.result;

                                                const newImageStore = {
                                                    userPhoto: {
                                                        data: base64data,
                                                        imageHash: hash(base64data),
                                                    }
                                                }

                                                dispatch(addImageToStore({ name: name, content: newImageStore.userPhoto }))
                                            }
                                        })
                                }
                                )
                                return (url)
                            })
                        .then((url) => {
                        })
                }

            })

            if (file) {

                Storage.get(file.key, { level: 'private' }).then(
                    (url) => {

                        caches.open('v1').then((cache) => {
                            fetch(url)
                                .then(response => response.blob())
                                .then((blob) => {
                                    var reader = new FileReader();
                                    reader.readAsDataURL(blob);
                                    reader.onloadend = function () {
                                        const base64data = reader.result;

                                        const newImageStore = {
                                            userPhoto: {
                                                data: base64data,
                                                imageHash: hash(base64data),
                                            }
                                        }
                                        dispatch(setImage(newImageStore.userPhoto))
                                    }
                                })
                        }
                        )
                        return (url)
                    })
                    .then((url) => {
                    })
            }


        })
    }
}



// done in a very rustic way at this stage. Need some improvement
const extractCode = (url) => {

   

    if (url.includes('http://localhost:3000')) {
        const regex = /http(s)?:\/\/localhost:3000\/\?code=(.+)/
        const code = url.match(regex)
        if (code)
            return code[2]

    }
    else if (url.includes('d17buahgpgudne')) {
        const regex = /https:\/\/d17buahgpgudne.cloudfront.net\/\?code=(.+)/
        const code = url.match(regex)
        if (code)
            return code[1]

    }
    return null

}

