import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
//import { XGrid } from '@material-ui/x-grid';
import { localeText, regexList, cleanText, cutText } from "./general"   
import Typography from '@mui/material/Typography'

import store from "../redux/store"
import { insertPublication, deletePublication } from "../features/data/dataSlice"
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'material-ui-confirm'
import {ColorButton} from "../components/BO-style"
import {colorGray} from '../AppStyle'
import SectionDescription from './conferenceEnvironnement/presentation'
import SectionFormateur from './conferenceEnvironnement/formateur'
import { grey } from '@mui/material/colors'
import { DataGridPro } from '@mui/x-data-grid-pro'
//import { LicenseInfo } from '@mui/x-data-grid-pro'



const getSource = (url) => {
    const regex = regexList.find(regex => url.match(regex))
    return (url.match(regex)[2])
}





const EspaceEvenement = ({fetchData, user}) => {

    const publicationsHook = useSelector(state => state.publicationsReducer)

    const formateurHook = useSelector(state => state.dataReducer)

    return (
        <Box >
            <DisplayPublications width='100%' formateur={formateurHook} publications={publicationsHook.data} fetchData={fetchData} user={user}/>
            
            {/* <div style={{fontSize: "1rem", marginTop: "5vw"}}>{"STABLE VERSION 0.2.38"}</div> */}
        </Box>
    )
}



const DisplayPublications = (props) => {
    const { publications, formateur, fetchData, user } = props

    return (
    <Box justifyContent="space-evenly" marginTop="25px">
        <SectionFormateur fetchData={fetchData} user={user}/>
    </Box>);
}


export default EspaceEvenement





