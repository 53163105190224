// imports the React Javascript Library
import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Button, Input } from "@mui/material";
import hash from "object-hash";
import imageCompression from "browser-image-compression";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { ColorButton2 } from "../components/BO-style";
import { blue } from "@mui/material/colors";

const colorBlue = "#273370";

const StyledButton = withStyles({
  root: {
    background: colorBlue,
    borderRadius: 3,
    border: 0,
    marginTop: "0.5vw",
    color: "white",
    height: "3vw",
    padding: "0.5vw",
    "&:hover": {
      backgroundColor: blue[800],
      color: "#FFF",
    },
  },
  label: {},
})(Button);

const ImageUpload = ({ modalStore, setModalStore, setSpinner }) => {
  // const [modalStore, setModalStore] = modalStoreHook

  const handleUploadClick = (event) => {
    const file = event.target.files[0];

    var imagesBlob = {
      original: null,
      compressedS: null,
      compressedM: null,
      compressedL: null,
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadstart = () => {
      setSpinner(true);
    };

    reader.onloadend = () => {
      const data = reader.result;
      imagesBlob.original = data;
      console.log("ici", data);
    };

    compressionSet.forEach((compression) => {
      imageCompression(file, compression.options)
        .then((compressedFile) => {
          imagesBlob = { ...imagesBlob, [compression.name]: compressedFile };

          if (
            (imagesBlob.compressedS != null) &
            (imagesBlob.compressedM != null) &
            (imagesBlob.compressedL != null)
          ) {
            setModalStore({
              ...modalStore,
              imageStore: {
                original: imagesBlob.original,
                hash: hash(imagesBlob.original),
                compressedS: imagesBlob.compressedS,
                compressedM: imagesBlob.compressedM,
                compressedL: imagesBlob.compressedL,
              },
            });
            setSpinner(false);
          }
        })
        .catch((error) => {
          console.log("error:");
          console.log(error);
        });
    });
  };

  return (
    <ColorButton2 variant="contained" component="label">
      <Input
        accept="image/*"
        style={{ display: "none" }}
        id="contained-button-file"
        name="upload-photo"
        type="file"
        onChange={handleUploadClick}
      />

      <AddAPhotoIcon />
    </ColorButton2>
  );
};

const compressionSet = [
  {
    name: "compressedS",
    options: {
      maxSizeMB: 0.02,
      maxWidthOrHeight: 128,
      useWebWorker: true,
    },
  },

  {
    name: "compressedM",
    options: {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 512,
      useWebWorker: true,
    },
  },
  {
    name: "compressedL",
    options: {
      maxSizeMB: 300,
      maxWidthOrHeight: 512,
      useWebWorker: true,
    },
  },
];

export default ImageUpload;
