import { createSlice } from '@reduxjs/toolkit'

export const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        loading: "true",
        totalCount: 0,
        data: [],
        selectedRows: []
    },

    reducers: {
        addEvent: (state, action) => {
            return ({
                loading: "false",
                data: state.data.concat(action.payload),
                totalCount: state.totalCount + 1,
                selectedRows: state.selectedRows
            })
        },

        setEvents: (state, action) => {

            const newData = []
            action.payload.forEach(event => {
                if (event.title1 && (event.prix || event.prix === 0) && event.imageKey && event.nbMax && event.date)
                    newData.push({...event, publishable: true})
                else
                    newData.push({...event, publishable: false})
            })

           
            return ({
                loading: "false",
                data: newData,
                totalCount: action.payload.length,
                selectedRows: state.selectedRows
            })
        },
        setEventsRows: (state, action) => {

        

            return ({
                loading: "false",
                data: state.data,
                totalCount: state.totalCount,
                selectedRows: action.payload
            })
        }


    }

})

export const { addEvent, setEvents, setEventsRows } = eventsSlice.actions

export default eventsSlice.reducer