import React from 'react'
import ReactMarkdown from 'react-markdown'
import ReactDom from 'react-dom'
import styled from 'styled-components'

const MarkdownStyles = styled.div`
	& > h1 {
		font-size: 22px;
	}
	
	& > h2 {
		font-size: 20px;
	}

    & > h3 {
		font-size: 20px;
	}
	
	
	& > p {
		font-size: 15px;
	}
	
`

const textContent = `# CGS Juillet 2021

# PREAMBULE

La société PEKOIA, société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 508 763 950 dont le siège social est sis 13 bis avenue de la Motte Picquet 75007 (_ci-après « PEKOIA »_ ou _« l&#39;Opérateur »)_ édite et exploite le site internet accessible à l&#39;adresse suivante : www.pekoia.com (_ci-après « la Plateforme»)._

La Plateforme propose un Service de Place de Marché entre des personnes morales ou physiques susceptibles d&#39;intervenir sur un sujet donné (conférenciers, coachs, formateurs, guides, passionnés, professeurs, journalistes, associations, musées, entreprises…) (ci-après « **les Organisateurs** ») et des personnes susceptibles d&#39;être intéressées par ces interventions (ci-après « **les Apprenants** ») proposées par les Organisateurs sur des sujets donnés. Les interventions peuvent avoir lieu sous diverses formes (conférences, ateliers, visites guidées, masterclasses …) (ci-après « **les Evénements** »)

C&#39;est dans ce contexte que les Parties se sont rapprochées afin que l&#39;Organisateur puisse proposer ses Evénements via la Plateforme. L&#39;Organisateur reconnaît avoir reçu de PEKOIA toutes les informations et conseils lui permettant de bien connaître la teneur des Services, d&#39;apprécier leur adéquation à ses besoins et ainsi de signer le Contrat en connaissance de cause.

**IMPORTANT**

**TOUTE INSCRIPTION SUR LE SITE PAR L&#39;ORGANISATEUR IMPLIQUE L&#39;ACCEPTATION SANS RESERVE PAR CELUI-CI DES PRESENTES CONDITIONS GENERALES DE SERVICES.**

# ARTICLE 1. DEFINITIONS

Les termes employés ci-après ont, dans le présent Contrat, la signification suivante :

- **« Apprenant »**  : désigne toute personne physique ou morale utilisant la Plateforme afin d&#39;être mise en relation avec un Organisateur pour assister à un Evénement.

- **« Back-Office »** : désigne l&#39;interface permettant à l&#39;Organisateur d&#39;accéder à son espace personnel et de remplir sa Page Organisateur. L&#39;accès au Back-Office se fait à partir des Identifiants à l&#39;adresse www.back.pekoia.com.

- **« CGV de l&#39;Organisateur »**  : désigne le contrat de vente publié par un Organisateur, encadrant la vente de son Evénement. Les CGV de l&#39;Organisateur peuvent être complétées, voire remplacées, par les Conditions Commerciales de la Plateforme.

- **« Conditions Générales d&#39;Utilisation »** ou «  **CGU**  » : désigne les conditions contractuelles mises à disposition sur la page d&#39;accueil de la Plateforme, afin d&#39;encadrer l&#39;utilisation de celle-ci par tout Utilisateur.

- **« Conditions Commerciales » :** désigne les Conditions Commerciales harmonisant les pratiques commerciales des Organisateurs de la Plateforme, complétant le Contrat de Vente dans l&#39;hypothèse où les CGV de l&#39;Organisateur serait incomplètes ou non conformes. Les Conditions Commerciales s&#39;appliqueront prioritairement si l&#39;Organisateur n&#39;a pas de CGV de l&#39;Organisateur ou si ces dernières sont lacunaires ou non conformes aux Conditions Commerciales de la Plateforme.

- «  **Contenus**  » : désigne l&#39;ensemble des informations, Supports de présentation, textes, logos, marques, animations, dessins et modèles, photographies, données, liens hypertextes, et de façon générale tous les éléments et contenus de l&#39;Organisateur publiés sur la Plateforme ou durant un Evénement selon les modalités, la forme et les conditions qui lui sont proposées dans le cadre des Services.

- «  **Contrat**  » : désigne les présentes Conditions Générales de Services et toutes leurs annexes, qui encadrent la fourniture des Service de la Plateforme par PEKOIA aux Organisateurs.

- **« Contrat de Vente »**  : désigne l&#39;ensemble contractuel encadrant finalement la vente d&#39;un Evénement par l&#39;intermédiaire de la Plateforme. Le Contrat de vente est composé de la Page Evénement, et des CGV de l&#39;Organisateur éventuellement complétées, voire remplacées, par les Conditions Commerciales de la Plateforme. En cas de contradiction entre les deux documents, les Conditions Commerciales de la Plateforme s&#39;appliqueront prioritairement.

- **« Evénement »** : désigne toute intervention, privée ou publique, gratuite ou payante, préenregistrée ou instantanée, physique ou à distance, proposée par un Organisateur sur la Plateforme. L&#39;Evénement peut être virtuel et avoir lieu par un outil de conférence à distance ou avoir lieu physiquement, dans le cadre d&#39;un évènement organisé.

- **« Expertises »** : désigne l&#39;ensemble des domaines dans lesquels l&#39;Organisateur dispose d&#39;une expertise particulière dans le cadre de laquelle il pourrait proposer un Evénement.

- **« Identifiants**  » : désigne l&#39;adresse email de l&#39;Organisateur et le mot de passe, nécessaires à l&#39;accès à son Back-Office sur la Plateforme.

- **« Informations Confidentielles »** : désigne toutes les informations financières, juridiques, techniques, commerciales, stratégiques, ainsi que les données, documents de toute nature, dessins, concepts, secrets de fabrication, savoir-faire, systèmes d&#39;information, logiciels, transmis ou portés à la connaissance d&#39;une Partie au titre du Contrat, quels que soient la forme et/ou les supports utilisés.

- **« Intervenant »**  : désigne la personne physique intervenant pour animer oralement l&#39;Evénement. L&#39;Intervenant peut être l&#39;Organisateur lui-même ou intervenir pour le compte d&#39;un Organisateur, intervenant alors comme conférencier Intervenant.

- **« Organisateur »**  : désigne la personne physique ou morale organisant un Evénement et pouvant pour cela faire appel à un Intervenant. L&#39;Organisateur peut, ou non, agir dans le cadre de son activité professionnelle habituelle.

- **« Outil CRM »**  : désigne l&#39;ensemble de fonctionnalités de la Plateforme permettant à l&#39;Organisateur de promouvoir ses Evénements en exploitant notamment sa propre base de données, enrichie au moyen de la communauté d&#39;Utilisateurs de la Plateforme.

- **« Page Evénement »**  : désigne la description attachée à un Evénement, devant comporter l&#39;ensemble des informations obligatoires pour sa promotion et sa vente.

- **« Parties »** : désigne ensemble PEKOIA et l&#39;Organisateur. Au singulier, désigne une des deux Parties.

- **« Prestataire de Services de Paiement »** ou **« PSP »** : désigne la société, détentrice d&#39;un agrément bancaire, fournissant, par l&#39;intermédiaire de PEKOIA, des services de paiement aux Organisateurs afin de leur permettre d&#39;encaisser les paiements des Apprenants. Le Prestataire de Services de Paiement de l&#39;Opérateur est Lemonway; société par actions simplifiée, enregistrée au RCS de PARIS sous le numéro 500 486 916, sise 8 rue du Sentier 75002 PARIS, habilitée à exercer son activité en qualité d&#39;Etablissement de paiement avec le Code banque 16568.

- **«  Page Organisateur »** : désigne la fiche de présentation de l&#39;Organisateur détaillant l&#39;ensemble de ses Expertises **,** ses Evénements, ses ouvrages et les notes et avis obtenus par ce dernier.

- **« Services » :** désigne l&#39;ensemble des services proposés par PEKOIA aux Organisateurs par l&#39;intermédiaire de la Plateforme.

- **« Service de Place de Marché »** : désigne la possibilité principale pour l&#39;Organisateur de proposer ses Evénements sur la Plateforme et d&#39;être automatiquement mis en relation avec un Apprenant.
- **« Services Marketing » :** désigne les services publicitaires de promotion des Evénements proposés de manière optionnelle par l&#39;Opérateur. Les Services Marketing sont régis par une convention distincte du présent Contrat. Font notamment partie des Services Marketing :
  - **Le Service Média** proposant la mise en avant d&#39;Evénements sponsorisés ;
  - **Le Service Régie** proposant aux Organisateurs de gérer, pour leur compte, leurs campagnes publicitaires sur les réseaux sociaux et d&#39;autres canaux digitaux.
- **Services Optionnels :** désigne tous les Services Optionnels (incluant les Services Marketing) proposés par l&#39;Opérateur, intégrant notamment :
  - **Les Services Marketing** prédéfinis ;
  - **Le Service Designer** consistant en la mise en relation avec un infographiste ou un designer partenaire pour l&#39;optimisation ou la création d&#39;un Support d&#39;Evénement ;
  - **Le Service Vidéaste,** consistant en la mise en relation avec un vidéaste pour une prise de vue dans le cadre d&#39;un Evénement ;
  - **Le Service Prise de Parole** consistant en la mise en relation avec un coach en prise de parole (physique ou virtuel) pour la préparation d&#39;un Evénement ;
  - **Le Service sur mesure** répondant aux demandes spécifiques d&#39;un Organisateur et faisant l&#39;objet d&#39;un devis spécifique.

Les Services Optionnels sont régis par un contrat distinct des présentes Conditions Générales.

- **« Support de présentation »**  : désigne le document soutenant la présentation orale de l&#39;Organisateur, présenté aux Apprenants.

- **« Plateforme »** : désigne la plateforme en ligne accessible à l&#39;adresse www.pekoia.com. Le Plateforme regroupe l&#39;ensemble des pages web, services et fonctionnalités proposés aux Utilisateurs.

- «  **Utilisateur**  » : désigne toute personne qui accède et navigue sur la Plateforme, qu&#39;il soit Organisateur, Intervenant, Apprenant, ou simple internaute.

# ARTICLE 2. DOCUMENTS CONTRACTUELS

Le Contrat est composé des documents contractuels suivants, listés par ordre de préséance :

- Les Conditions Générales de Services en vigueur,
- Les Annexes.

# ARTICLE 3. MODIFICATION DU CONTRAT

PEKOIA se réserve la possibilité de modifier à tout moment le présent Contrat. Ces modifications seront notifiées à l&#39;Organisateur sur un support durable au moins trente (30) jours avant l&#39;entrée en vigueur des changements. En cas de modifications substantielles des présentes, il convient de distinguer les hypothèses suivantes :

- Soit l&#39;Organisateur consent auxdites modifications substantielles, auquel cas celles-ci entreront automatiquement en vigueur à la date prévue dans la notification ;

- Soit l&#39;Organisateur refuse les modifications substantielles, auquel cas il pourra résilier le Contrat avant l&#39;expiration du délai de préavis. Cette résiliation prend effet dans les quinze (15) jours suivant la réception de la notification par l&#39;Organisateur de sa décision de résilier le contrat.

L&#39;Organisateur accepte expressément que son silence suite à l&#39;information donnée au sujet de la modification du Contrat soit considéré comme une acceptation des modifications apportées.

# ARTICLE 4. OBJET

Le présent Contrat a pour objet de fixer les conditions et modalités d&#39;accès de l&#39;Organisateur aux Services ainsi que les droits et obligations respectifs des Parties induits par l&#39;utilisation de ces Services.

Le présent Contrat, qui exclue par défaut tout lien de subordination, ne confère en aucun cas à l&#39;Organisateur la qualité de salarié, mandataire, agent ou représentant de PEKOIA. Les Parties déclarent en outre qu&#39;en l&#39;absence de conditions dérogatoires, le présent Contrat ne peut en aucun cas être considéré comme un acte constitutif de personne morale ou d&#39;une entité juridique quelconque, et que toute forme « _d&#39;affectio societatis_ » est formellement exclue de leurs relations.

# ARTICLE 5. MODALITES D&#39;ACCES AUX SERVICES

## 5.1 – Modalités d&#39;inscription des Organisateurs

Pour pouvoir être référencé sur la Plateforme, l&#39;Organisateur devra notamment :

- Accepter le présent Contrat ;
- Communiquer l&#39;ensemble des informations et autres documents obligatoires ;
- Communiquer l&#39;ensemble des Contenus devant figurer sur sa Page Organisateur (Photo, description, Expertises, etc.) ;
- Voir sa candidature être validée.

L&#39;Organisateur pourra bénéficier des Services via son Back-Office accessible sur la Plateforme.

En tout état de cause, pour pouvoir bénéficier des Services, l&#39;Organisateur s&#39;engage à fournir à l&#39;Opérateur une adresse de courrier électronique et un numéro de téléphone valides.

**5.2 – Modalités de sélection des Organisateurs**

Le Plateforme étant dédiée à la mise en relation entre des Apprenants et des Organisateurs ayant des Expertises originales, en privilégiant une pédagogie ouverte et positive, PEKOIA met un point d&#39;honneur à sélectionner des Organisateurs s&#39;inscrivant dans ce positionnement.

Ainsi, l&#39;Organisateur qui souhaite s&#39;inscrire sur la Plateforme afin de bénéficier du service en ligne d&#39;intermédiation professionnelle proposé par PEKOIA se déclare informé que seules certaines Expertises correspondant à cette exigence de qualité et cohérence pourront être sélectionnées.

A cet égard l&#39;Organisateur est clairement informé du fait que le présent Contrat sera conclu entre les Parties sous la condition résolutoire que l&#39;Organisateur respecte la procédure d&#39;inscription mise en place dans les Conditions Générales d&#39;Utilisation et les KPI mises en place en annexe 2.

# ARTICLE 6. SERVICES

## 6.1 – Service de Place de Marché

### 6.1.1. Description

Le Service de Place de Marché permet à l&#39;Organisateur de pouvoir être référencé sur la Plateforme, de présenter ses Expertises et les Evénements qu&#39;il pourrait réaliser ainsi que les tarifs qu&#39;il pratique afin d&#39;être mis en relation avec des Apprenants.

L&#39;Opérateur s&#39;engage à mettre à disposition de l&#39;Organisateur une architecture logicielle lui permettant de proposer ses Evénements aux Apprenants, que ce soit en mode privé ou public et de bénéficier de fonctionnalités de l&#39;Outil CRM.

- D&#39;un Back-Office ;

- D&#39;interfaces informatiques lui permettant de rédiger sa Page Organisateur afin de le référencer sur la Plateforme ;

- D&#39;un Outil CRM ;

- De l&#39;ensemble des fonctionnalités de la Plateforme ;

- De la possibilité de proposer ses Evénements aux Apprenants.

A partir de son Back-Office, l&#39;Organisateur aura la possibilité :

- De compléter et / ou modifier sa Page Organisateur avec les informations descriptives le concernant ;
- De compléter et / ou modifier ses Expertises ;
- De compléter et / ou modifier le type d&#39;Evénements proposés ;
- D&#39;organiser librement ses Evénements :
- De gérer le tarif proposé ;
- De mettre à jour ses informations administratives.

Des services de discussion (chats, forums …) seront aussi disponibles pour favoriser l&#39;échange entre les différents Utilisateurs.

Par ailleurs, le Service de Place de Marché inclut le Service support, qui est un Service d&#39;assistance dédié aux Organisateurs.

### 6.1.2. Connexion au Back-Office

L&#39;Organisateur s&#39;oblige à tenir les Identifiants rigoureusement secrets, à prendre toute mesure pour en préserver la confidentialité et à avertir PEKOIA en cas d&#39;utilisation non autorisée ou frauduleuse de son Back-Office ou de ses Identifiants dès qu&#39;il en aura connaissance, par tous moyens. De convention expresse entre les Parties, toute opération effectuée au moyen des Identifiants de l&#39;Organisateur est réputée émaner de l&#39;Organisateur, qui en assume seul la responsabilité.

Ainsi l&#39;Organisateur sera responsable à l&#39;égard de son Intervenant et se porte fort du respect par ce dernier, des présentes.

Il est convenu entre les Parties que tout manquement au présent article pourra entrainer la suspension de plein droit du Back-Office.

### 6.1.3 – Rédaction de la Page Organisateur

L&#39;Organisateur pourra directement charger les Contenus figurant sur sa Page Organisateur (présentation, Expertises etc.) à partir de son Back-Office.

L&#39;Organisateur restera seul responsable de la fourniture de l&#39;ensemble des informations rendues obligatoires par la loi ou la réglementation en vigueur.

Conformément aux présentes, PEKOIA sera seulement hébergeur de ces Contenus.

L&#39;Organisateur s&#39;engage à ne publier sur sa Page Organisateur que des informations véridiques et à ne renseigner que les Expertises dont il dispose réellement.

### 6.1.4. – Classement

Les Page Organisateurs seront présentées sur la Plateforme selon l&#39;ordre défini au sein des Conditions Générales d&#39;Utilisation de la Plateforme.

### 6.1.5. – Prix des Evénements

L&#39;Organisateur pourra proposer tout type d&#39;Evénement, gratuit comme payant.

Le prix des Evénements payants est librement défini par l&#39;Organisateur, dans le respect des lois et règlements en vigueur. L&#39;Organisateur est responsable du prix indiqué.

Il est entendu que les frais facturés par PEKOIA ne seront pas ajoutés au prix des Evénements proposés par l&#39;Organisateur sur la Plateforme. Ainsi, au moment de déterminer son tarif, l&#39;Organisateur devra anticiper (et, le cas échéant, y intégrer) le paiement de frais de service à PEKOIA, détaillés en Annexe. En outre, PEKOIA se réserve la possibilité d&#39;ajouter toute taxe qui serait imposée par la législation en vigueur.

### 6..1.6 – Conditions de vente des Evénements

La réalisation des Evénements est régie par le Contrat de Vente, composé des CGV de l&#39;Organisateur, qui seront complétées, voire remplacées, par les Conditions Commerciales de la Plateforme dans l&#39;hypothèse où elles seront absentes, lacunaires ou non conformes à ces dernières.

En acceptant les présentes Conditions Générales de Service (CGS), l&#39;Organisateur garantit que ses CGV de l&#39;Organisateur sont en tous points conformes aux Conditions Commerciales de la Plateforme présentes en Annexe 4 et qu&#39;à cet égard, il s&#39;engage à se conformer à ces CGS dans le cadre de la réalisation des Evénements commandées via la Plateforme.

En cas de conflit entre les CGV de l&#39;Organisateur et les Conditions Commerciales de la Plateforme, ce sont ces dernières qui s&#39;appliqueront prioritairement. Dès lors, l&#39;ordre de priorité suivant s&#39;appliquera : Page Evénement présentant l&#39;Evénement, Conditions Commerciales de la Plateforme, puis CGV de l&#39;Organisateur.

### 6.1.7. Sur le processus de commande d&#39;Evénement

L&#39;Organisateur dispose d&#39;un Back-Office lui permettant de gérer ses offres d&#39;Evénement. Il est informé de toute nouvelle commande d&#39;Evénement via le Back-Office.

L&#39;Organisateur peut répondre à un appel d&#39;offre formulé par un autre Utilisateur. L&#39;Organisateur peut alors proposer un devis à celui-ci.

### 6.1.8. Sur le paiement des Evénements par l&#39;Apprenant

L&#39;Apprenant aura la possibilité de payer les Evénements selon les modes de paiement suivants :

- Carte bancaire ;

Il est entendu entre les Parties que la Plateforme n&#39;intègre pas de coordination avec des Opérateurs de Compétences (OPCO) de telle manière que l&#39;éventuelle prise en charge du coût d&#39;un Evénement par ces derniers devra avoir lieu en dehors de la Plateforme.

### 6.1.9. Sur l&#39;Evénement délivré par l&#39;Organisateur

L&#39;Organisateur est seul responsable de l&#39;organisation pratique de son Evénement, conformément à l&#39;article 12 des présentes.

Il est entendu que le contrat de réalisation des Evénements, (les CGV de l&#39;Organisateur) est conclu uniquement entre l&#39;Organisateur et l&#39;Apprenant. PEKOIA, agissant en tant qu&#39;intermédiaire, est tiers à ce contrat et ne pourra donc pas être tenu responsable du suivi et de l&#39;exécution de l&#39;Evénement par l&#39;Organisateur.

L&#39;Organisateur s&#39;engage à exécuter les Evénements en respectant les critères de qualité attendus de lui et correspondant à l&#39;état de l&#39;art dans son domaine de Compétence.

## 6.2 – Outil CRM

L&#39;Opérateur met à disposition de l&#39;Organisateur un Outil CRM intégré à la Plateforme. L&#39;Organisateur pourra utiliser librement et de manière autonome cet Outil sans intervention de l&#39;Opérateur, dans le respect de la réglementation propre aux traitement de données et à la clause correspondante au sein des présentes.

L&#39;Organisateur pourra souscrire aux Services Marketing pour se faire assister dans la promotion digitale de ses Evénements.

Les traitements de données permis par l&#39;Outil CRM, utilisé librement par l&#39;Organisateur, sont encadrés par l&#39;article 14 – Données Personnelles.

## 6.3 – Services de paiement

Les Service de paiement permettant à l&#39;Organisateur d&#39;être payé par l&#39;Apprenant sont fournis par le Prestataire de Services de paiement (PSP).

Pour pouvoir bénéficier des Services de paiement, l&#39;Organisateur devra accepter les Conditions Générales du PSP. Il est entendu que toute résiliation ou annulation pour quelque raison que ce soit, de l&#39;Annexe 3 conclue entre le PSP et l&#39;Organisateur, pourra donner lieu de plein droit à la résiliation du présent Contrat par l&#39;Opérateur, sans qu&#39;aucune indemnité ne puisse être exigée.

# ARTICLE 7. SERVICES COMPLEMENTAIRES

L&#39;Opérateur pourra proposer à l&#39;Organisateur des Services Optionnels.

Ces Services Optionnels, tout comme le Service Régie, donneront lieu à la conclusion d&#39;une convention spécifique distincte du présent Contrat, régissant ces Services et la responsabilité associée de l&#39;Opérateur.

# ARTICLE 8. AVIS APPRENANTS

L&#39;Organisateur est informé que l&#39;Apprenant pourra noter l&#39;Evénement auquel il aura assisté.

L&#39;Apprenant se verra attribuer une note équivalente à une moyenne de l&#39;ensemble des notes des Evénements qu&#39;il aura organisés par la Plateforme.

Les avis Apprenants et la gestion de ces derniers par l&#39;Opérateur sont encadrés par les Conditions Générales d&#39;Utilisation.

# ARTICLE 9. CONTESTATIONS / LITIGES ENTRE L&#39;ORGANISATEUR ET L&#39;APPRENANT

**Il est rappelé que PEKOIA est un simple intermédiaire entre l&#39;Organisateur et l&#39;Apprenant. PEKOIA ne pourra pas voir sa responsabilité engagée pour l&#39;exécution même de l&#39;Evénement.**

L&#39;Organisateur, en utilisant la Plateforme pour vendre ses Evénements, reconnaît qu&#39;il engage également l&#39;image de marque de PEKOIA. L&#39;Organisateur reconnaît donc que ses agissements, qui ne respecteraient pas l&#39;ensemble des obligations stipulées au présent Contrat, peuvent avoir un effet préjudiciable pour PEKOIA.

Il est rappelé que tout Apprenant aura la possibilité de saisir PEKOIA dans les soixante-douze (72) heures suivant l&#39;Evénement, au sujet de cette dernière, notamment dans les cas suivants :

- Qualité de l&#39;Evénement non satisfaisante ;
- Impossibilité d&#39;assister à l&#39;Evénement ;
- Annulation tardive ;
- Evénement au contenu illégal.

L&#39;Organisateur a également la possibilité de saisir PEKOIA en cas de litige avec l&#39;Apprenant notamment dans les cas suivants :

- Absence ;
- Retard ;
- Annulation d&#39;un Evénement par l&#39;Apprenant ne respectant pas les modalités d&#39;annulation prévues au sein des Conditions Commerciales.

Lorsque l&#39;Apprenant ou l&#39;Organisateur saisit le Service client de PEKOIA, ces derniers informeront, dans un délai de quarante-huit (48) heures ouvrées l&#39;autre partie de cette réclamation. La réclamation sera signalée à la personne concernée par courriel_._

En tout état de cause, l&#39;Organisateur s&#39;engage à répondre à cette réclamation dans le délai prévu au sein de l&#39;Annexe 2 KPI, suivant la notification de la réclamation par le Service Client de PEKOIA.

L&#39;Organisateur fera alors son affaire personnelle de la résolution du différend qui l&#39;oppose à l&#39;Apprenant. Soucieuse de l&#39;image de la Plateforme, PEKOIA invite toutefois l&#39;Organisateur à faire ses meilleurs efforts pour résoudre amiablement tout litige l&#39;opposant à l&#39;Apprenant.

Dans l&#39;éventualité où l&#39;Apprenant et l&#39;Organisateur ne parviendraient pas à un accord dans le délai prévu à l&#39;Annexe 2 KPI, l&#39;Organisateur ou l&#39;Apprenant pourra contacter PEKOIA par le biais d&#39;un formulaire dédié afin de mandater cette dernière en tant que médiateur. PEKOIA interviendra alors entre l&#39;Apprenant et l&#39;Organisateur afin de tenter de proposer une solution aux parties.

En tout état de cause, l&#39;Organisateur mandate expressément PEKOIA pour intervenir en tant que médiateur dans l&#39;hypothèse où le litige n&#39;aurait pas été réglé à l&#39;issue du délai prévu dans l&#39;Annexe 2 des KPI.

# ARTICLE 10. MODALITES FINANCIERES

## 10.1 Facturation des Services

L&#39;Organisateur paye à PEKOIA, en contrepartie de la fourniture des Services, des frais de service, équivalents à un certain pourcentage du montant TTC des sommes (incluant les participations libres, dons et autres pourboires) reçues en contrepartie d&#39;un Evénement organisé par l&#39;intermédiaire de la Plateforme.

Les frais de service sont prélevés par l&#39;Opérateur, sur le compte de paiement de l&#39;Organisateur ouvert auprès du PSP, ce que ce dernier accepte expressément.

En cas de défaut ou de retard de paiement, les pénalités de retard seront calculées de la façon suivante :

_Pénalités de retard = (montant ttc de la facture x Taux Légal Applicable pour le semestre) X (nombre de jours de retard dans le semestre / 365)._

Le Taux Légal Applicable s&#39;entend du taux d&#39;intérêt appliqué par la Banque Centrale Européenne à son opération de refinancement la plus récente majoré de 3 points de pourcentage.

Les pénalités de retard sont exigibles le jour suivant la date de règlement figurant sur la facture, sans qu&#39;aucune mise en demeure doive être envoyée au préalable.

Tout Organisateur en situation de retard de paiement est de plein droit débiteur, à l&#39;égard de PEKOIA, d&#39;une indemnité forfaitaire pour frais de recouvrement d&#39;un montant de 40 euros. Lorsque des frais de recouvrement exposés sont supérieurs au montant de cette indemnité forfaitaire, PEKOIA pourra demander une indemnisation complémentaire, sur justification.

## 10.2 Paiement de l&#39;Organisateur

L&#39;Organisateur sera payé par PEKOIA sept (7) jours après la réalisation de l&#39;Evénement, dès lors que ce dernier ne fera l&#39;objet d&#39;aucune réclamation ou litige non encore soldé.

# ARTICLE 11. OBLIGATIONS DE PEKOIA

PEKOIA s&#39;engage à réaliser les Services dans le respect des règles applicables eu égard à l&#39;objet du Contrat, telles que ces règles résultent des règles de l&#39;art, normes européennes, lois, décrets, arrêtés et textes législatifs, règlementaires ou administratifs nationaux, locaux ou professionnels.

Il est expressément convenu entre les Parties que PEKOIA est soumise à une obligation générale de moyens et qu&#39;elle n&#39;est tenue d&#39;aucune obligation de résultat ou de moyen renforcé d&#39;aucune sorte.

## 11.1 Hébergement - disponibilité

PEKOIA s&#39;engage à mettre tous les moyens en œuvre pour rendre accessibles la Plateforme 24 heures sur 24, 7 jours sur 7 sauf en cas de force majeure ou d&#39;un événement hors du contrôle de PEKOIA et sous réserve des éventuelles pannes ou interventions de maintenance nécessaires au bon fonctionnement de la Plateforme.

Toutefois, PEKOIA ne saurait être tenue responsable des perturbations, coupures et anomalies qui ne sont pas de son fait et qui affecteraient, par exemple, les transmissions par le réseau Internet et plus généralement par le réseau de communication, quelles qu&#39;en soient l&#39;importance et la durée.

Il est par ailleurs précisé que PEKOIA se réserve le droit d&#39;interrompre temporairement l&#39;accessibilité à la Plateforme ou de suspendre tout ou partie des Services pour des raisons de maintenance, pour l&#39;amélioration et l&#39;installation de nouvelles fonctionnalités, pour l&#39;audit du bon fonctionnement ou encore en cas de dysfonctionnement ou de menace de dysfonctionnement.

De la même manière, PEKOIA pourra limiter le nombre de commandes relatives à un Evénement pour s&#39;assurer de la conformité du nombre d&#39;Apprenants avec les capacités techniques de l&#39;outil utilisé pour fournir l&#39;Evénement à distance.

## 11.2. – Maintenance

### 11.2.1 Maintenance corrective

PEKOIA assure l&#39;hébergement de la Plateforme et, pour ce faire, se réserve la faculté de faire appel à un ou plusieurs sous-traitant(s).

PEKOIA s&#39;engage à mettre tout en œuvre pour faire effectuer, dans un délai raisonnable, les corrections techniques à apporter à la Plateforme concernant les éventuelles anomalies de fonctionnement et/ou de conformité par rapport aux normes applicables en matière de sécurité.

Toute intervention résultant d&#39;une mauvaise utilisation par l&#39;Organisateur de la Plateforme ou des Services pourra donner lieu à une facturation spécifique.

### 11.2.2 Evolutions de la Plateforme

Il est expressément convenu entre les Parties que la Plateforme et les Services objets des présentes pourront faire l&#39;objet d&#39;évolutions décidées par l&#39;Opérateur en vue de s&#39;adapter aux évolutions des technologies ou pour optimiser ses Services.

Dans ce cas, l&#39;Organisateur accepte sans réserve que lui soient appliquées lesdites évolutions après en avoir été préalablement informé par l&#39;Opérateur. Dans l&#39;hypothèse où l&#39;Organisateur n&#39;accepterait pas lesdites évolutions, il disposera alors de la possibilité de résilier le présent Contrat selon les modalités prévues aux présentes.

En cas de résiliation du Contrat, les modalités de suppression des Contenus sont détaillées aux présentes.

## 11.3 – Transparence financière

Conformément à l&#39;article 242 bis du Code Général des Impôts, PEKOIA informe l&#39;Organisateur qu&#39;il est tenu de se mettre à jour de ses obligations fiscales et sociales concernant son activité et les transactions qu&#39;il réalise sur la Plateforme.

A ce titre, l&#39;Opérateur pourra adresser :

- A l&#39;occasion de chaque transaction, une information loyale, claire et transparente sur les obligations fiscales et sociales qui incombent aux Organisateurs qui réalisent des transactions commerciales par son intermédiaire. L&#39;Opérateur est également tenu de mettre à disposition un lien électronique vers les sites des administrations permettant de se conformer, le cas échéant, à ces obligations.

- En janvier de chaque année à chaque Organisateur un récapitulatif des transactions réalisées pendant l&#39;année précédente, ainsi que le chiffre d&#39;affaires global réalisé par l&#39;Organisateur par l&#39;intermédiaire de la Plateforme.

L&#39;Organisateur est informé du fait que l&#39;Opérateur est tenu de communiquer à l&#39;Administration fiscale l&#39;ensemble des informations en sa possession concernant l&#39;identité de l&#39;Organisateur, ses coordonnées bancaires et les transactions commerciales réalisées par son intermédiaire au cours de l&#39;année civile précédente.

Le chiffre d&#39;affaires généré par l&#39;intermédiaire de la Plateforme devra être déclaré à l&#39;administration fiscale compétente au même titre que les revenus générés par l&#39;Organisateur au titre de son activité professionnelle habituelle. Ainsi l&#39;Organisateur reste seul responsable de ses obligations déclaratives en matière de TVA, y compris dans l&#39;hypothèses de transactions transfrontières.

Pour connaître ses obligations sociales et fiscales, l&#39;Organisateur est invité à consulter les liens suivants :

- concernant les obligations fiscales, lien suivant : [https://www.impots.gouv.fr/portail/node/10841](https://www.impots.gouv.fr/portail/node/10841)
- concernant les obligations sociales, lien suivant : [https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html](https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html).

# ARTICLE 12. OBLIGATIONS DU CONFERENCIER

## 12.1 – Obligations générales

L&#39;Organisateur est seul responsable de l&#39;organisation et de la fourniture de l&#39;Evénement promu par l&#39;intermédiaire de la Plateforme. A ce titre, l&#39;Organisateur garantit la conformité juridique de ses propres CGV, et, le cas échéant, le respect des articles L.221-5 et -6 du Code de la Consommation.

En sus des obligations détaillées dans les présentes Conditions Générales, l&#39;Organisateur s&#39;engage en outre à soigner sa relation avec l&#39;Apprenant. A ce titre, l&#39;Organisateur s&#39;engage à adopter une communication courtoise et respectueuse avec les Apprenants et à mettre tous les moyens pour lui répondre dans le délai déterminé au sein de l&#39;Annexe 2 – KPI.

**En tant qu&#39;éditeur au sens de la réglementation française et de la jurisprudence, l&#39;Organisateur est seul responsable des Contenus publiés sur la Plateforme ou durant un Evénement**. A ce titre, il fait son affaire de tout litige et de toute poursuite judiciaire relative à ses Contenus. En aucun cas la responsabilité de l&#39;Opérateur ne pourra être engagée en cas de violation de droit des tiers ou encore au titre d&#39;un Contenu illicite, dès lors que celui-ci n&#39;aurait pas fait l&#39;objet d&#39;une notification de contenu illicite permettant à l&#39;Opérateur d&#39;en avoir connaissance.

Le cas échéant, l&#39;Organisateur reconnaît également être titulaire de toutes les autorisations et autres agréments nécessaires pour pouvoir exercer son activité et publier ses Contenus.

Afin de permettre à PEKOIA d&#39;accomplir les Services dans les meilleures conditions, l&#39;Organisateur s&#39;engage à :

- Respecter l&#39;Annexe 2 « KPI » définissant les engagements de qualité de l&#39;Organisateur à l&#39;égard de la Plateforme ;
- S&#39;assurer du maintien de toutes les autorisationsnécessaires à la tenue des Evénements ;
- Fournir tous les documents et informations en sa possession et utiles pour la tenue de l&#39;Evénement,
- Effectuer une sauvegarde régulière de ses Contenus.

Chaque Organisateur :

- S&#39;engage à collaborer avec PEKOIA dans la mesure requise, sans que son intervention ne puisse être qualifiée d&#39;immixtion ;
- Payer le prix correspondant aux Services ;
- Effectuer une sauvegarde régulière de ses données et Contenus.

En tout état de cause, chaque Organisateur a pour obligation de :

- Ne pas prendre attache directement avec les Apprenants pour procéder à la vente d&#39;Evénements ou de prestations de services en dehors de la Plateforme ;
- Ne pas utiliser la Plateforme pour pouvoir utiliser l&#39;Outil CRM de manière autonome ou détournée ;
- Ne pas diffuser tout Contenu, données, informations, contraires aux bonnes mœurs ou à l&#39;ordre public ;
- Ne pas détourner la finalité de la Plateforme pour commettre des crimes, délits ou contraventions réprimées par le Code pénal ou toute autre loi internationale ;
- Respecter la vie privée des tiers et la confidentialité des échanges avec PEKOIA ;
- Respecter les Conditions Générales d&#39;Utilisation de la Plateforme ;
- Se conformer à toutes les lois, règlements en vigueur encadrant notamment la communication sur internet (en ce compris, sans que cette liste soit limitative la loi pour une République Numérique du 7 octobre 2016, la loi Macron du 25 août 2015, la loi Hamon du 17 mars 2014, la Loi pour la Confiance dans l&#39;Economie Numérique du 21 juin 2004) et/ou règles qui pourraient empêcher, limiter ou règlementer la diffusion d&#39;informations ou de données, et sans que cela soit exhaustif, à se conformer à la Loi «Informatique, fichiers et libertés» du 6 janvier 1978 modifiée par la loi du 20 juin 2018, au RGPD, aux codes de bonnes conduites et à la Netiquette, aux règles de l&#39;art telles que par exemple établies par la CNIL (Commission Nationale Informatique et Libertés / www.cnil.fr), le SNCD (Syndicat National de la Communication Directe / www.sncd.org) et des Fournisseurs d&#39;Accès à l&#39;Internet, à la protection de la vie privée, au respect des droits de propriétés. Il est précisé que cette obligation est étendue au respect des normes en vigueur dans chaque pays ciblé par l&#39;Organisateur dans ses campagnes ;
- Ne pas chercher à porter atteinte au sens des articles 323-1 et suivants du Code pénal aux systèmes de traitements automatisés de données mis en œuvre pour l&#39;exploitation de la Plateforme ;
- Ne pas violer ou tenter de violer la sécurité ou l&#39;intégrité de la Plateforme, de communiquer toute information trompeuse, d&#39;utiliser les informations à des fins illicites ;
- Ne pas publier de contenu diffamatoire.

## 12.2 – Obligations liées à l&#39;organisation des Evénements

L&#39;Organisateur est responsable de l&#39;organisation pratique de son Evénement.

Concernant les Evénements à distance, l&#39;Organisateur s&#39;engage à accepter et à respecter les conditions contractuelles de tout prestataire tiers fournissant un outil de conférence vidéo. L&#39;Organisateur se porte fort du respect de celles-ci par l&#39;Intervenant.

Concernant les Evénements physiques, l&#39;Organisateur sera responsable de l&#39;organisation et de la tenue de l&#39;Evénement et notamment de la sécurité physique des Apprenants.

# ARTICLE 13. PROPRIETE INTELLECTUELLE

## 13.1 – Propriétés de PEKOIA

L&#39;Organisateur reconnaît les droits de propriété intellectuelle de PEKOIA sur la Plateforme, ses composantes et les contenus y afférents et renonce à contester ces droits sous quelque forme que ce soit.

Les contenus sur la Plateforme– _à l&#39;exception des Contenus de l&#39;Organisateur non compris dans le périmètre de la licence_ -, sont la propriété intellectuelle exclusive de PEKOIA et/ou de ses partenaires et ne peuvent être reproduits, utilisés ou représentés sans l&#39;autorisation expresse de PEKOIA sous peine de poursuites judiciaires.

Toute représentation totale ou partielle de la Plateforme et de son contenu, par quelque procédé que ce soit, sans l&#39;autorisation préalable expresse de PEKOIA est interdite et donnera lieu à des poursuites judiciaires.

## 13.2 – Licence d&#39;utilisation de la Plateforme

Par le présent Contrat, PEKOIA accorde à l&#39;Organisateur une licence non exclusive d&#39;utilisation de la Plateforme et de la solution logicielle associée.

Il est précisé que la présente licence est incessible et ne saurait être considérée comme un transfert de propriété d&#39;aucune sorte en faveur de l&#39;Organisateur. Ce dernier s&#39;interdit par conséquent de céder, échanger, prêter, louer ou concéder à un tiers, même à titre gratuit, un quelconque droit d&#39;utilisation conféré par le présent Contrat.

La présente licence est accordée pour le monde entier, pour toute la durée du présent Contrat, en contrepartie des sommes convenues à l&#39;article 10 des présentes.

Par ailleurs, il est rappelé que toute utilisation non-conforme à la licence est susceptible de poursuites judiciaires.

## 13.3 – Propriétés de l&#39;Organisateur

L&#39;Organisateur garantit détenir l&#39;intégralité des droits de propriété intellectuelle sur les Contenus publiés sur la Plateforme.

L&#39;Organisateur déclare que lesdits Contenus ne portent en aucune façon atteinte aux droits des tiers, notamment qu&#39;ils ne constituent pas une contrefaçon ou un acte de concurrence déloyale ou parasitaire d&#39;une œuvre préexistante et qu&#39;ils n&#39;enfreignent en aucune façon les droits de propriété intellectuelle des tiers.

# ARTICLE 14. DONNEES A CARACTERE PERSONNEL

Chacune des Parties garantit l&#39;autre Partie du respect des obligations légales et réglementaires lui incombant en fonction de son rôle au titre de la protection des données à caractère personnel.

## 14.1 – Concernant les traitements réalisés par PEKOIA

Dans le cadre des Services, l&#39;Opérateur collecte et traite des données à caractère personnel, notamment lors de l&#39;inscription de l&#39;Organisateur.

Ces données sont traitées uniquement pour :

- Administrer la Plateforme ;
- Pouvoir fournir aux Utilisateurs les Services proposés par la Plateforme ;
- Mettre l&#39;Organisateur en relation avec l&#39;Apprenant ;
- Établir des statistiques de fréquentation (anonymes et agrégées) de la Plateforme ;

L&#39;ensemble des données de l&#39;Organisateur seront traitées conformément à ces finalités.

Les données personnelles de l&#39;Organisateur sont conservées pendant toute la durée du Contrat puis durant une durée supplémentaire de trois (3) ans à compter de la fin de la relation commerciale, et sont destinéesà l&#39;Opérateur ainsi qu&#39;à l&#39;ensemble des prestataires associés au fonctionnement de la Plateforme.

L&#39;Organisateur bénéficie notamment à ce titre des droits suivants :

- Des droits d&#39;accès, de rectification, de mise à jour, de portabilité et d&#39;effacement des informations qui le concernent, ainsi qu&#39;un droit à la limitation du traitement conformément aux articles 49,50,51,53 et 55 de la Loi Informatique et Libertés et aux dispositions des articles 15, 16, 17 et 18 du RGPD ;
- Droit d&#39;opposition pour des motifs légitimes conformément aux dispositions de l&#39;article 56 de la Loi Informatique et Libertés et à l&#39;article 21 du RGPD ;
- Droit de définir le sort de ses données après sa mort, et choisir de communiquer ou non à l&#39;Opérateur, ses données à un tiers qui aura été préalablement désigné. En cas de décès et à défaut d&#39;instruction de la part de l&#39;Organisateur, PEKOIA s&#39;engage à détruire ses données, sauf si la conservation s&#39;avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.

L&#39;Organisateur peut exercer ses droits en adressant un courrier électronique à l&#39;adresse contact@pekoia.com ou en envoyant un courrier à : Pekoia SAS – 13 bis avenue de la Motte-Picquet – 75007 Paris.

L&#39;Organisateur a également la possibilité d&#39;introduire une réclamation auprès des autorités de contrôle et notamment de la CNIL ([https://www.cnil.fr/fr/plaintes](https://www.cnil.fr/fr/plaintes)).

L&#39;ensemble des informations relatives au traitement de données personnelles réalisé par PEKOIA sont détaillées au sein de la Politique de Confidentialité accessible ici :[_https://www.pekoia.com/confidentialite/_](https://www.pekoia.com/confidentialite/)

## 14.2 – Concernant les traitements réalisés par l&#39;Organisateur

L&#39;Organisateur peut être amené à collecter et traiter les données à caractère personnel des Apprenants dans le cadre de la réalisation des Evénements. A ce titre, l&#39;Organisateur sera responsable du traitement des données à caractère personnel des Apprenants qui commandent des Evénements via la Plateforme.

Il garantit qu&#39;il traite ces données dans le respect des droits et obligations issus de la loi « Informatique et Libertés » et du RGPD.

La finalité de ce traitement est l&#39;offre, la fourniture et le suivi des Evénements, à partir de la Plateforme et, le cas échéant, au moyen de l&#39;Outil CRM.

Pour ces traitements, l&#39;Opérateur, s&#39;il est amené à traiter les données des Apprenants, agira sur les instructions de l&#39;Organisateur, en tant que sous-traitant (notamment dans le cadre de l&#39;utilisation de l&#39;Outil CRM).

Il est donc expressément convenu entre les Parties que l&#39;Organisateur, en tant que responsable des traitements, mettra tous les moyens en sa possession pour assurer la sécurité et la confidentialité des données à caractère personnel qui lui sont confiées.

### 14.2.1 – Concernant l&#39;utilisation de l&#39;Outil CRM

L&#39;Organisateur restera responsable des traitements de données mis en œuvre dans le cadre de son utilisation de l&#39;Outil CRM, l&#39;Opérateur intervenant en principe en tant que simple sous-traitant, sous réserve de cas de figure particuliers, notamment dans l&#39;hypothèse où l&#39;Organisateur aurait souscrit à des Services Marketing.

Pour promouvoir ses Evénements, l&#39;Organisateur pourra importer sur l&#39;Outil CRM sa propre base de données, distincte de la base des Utilisateurs de la Plateforme.

En utilisant cette fonctionnalité, l&#39;Organisateur garantit que la base de données fournie est bien conforme à la Réglementation applicable et que ladite base de données a été constituée licitement, en collectant, le cas échéant, le consentement des personnes concernées.

L&#39;Organisateur est informé que dans un tel cas de figure, l&#39;Opérateur ne fusionnera pas la base de données de l&#39;Organisateur avec celle constituée par les Utilisateurs de la Plateforme. La base de données importée par le Conférencier reste donc sa propriété et ne pourra en aucun cas être utilisée par l&#39;Opérateur à des fins marketing. Ce n&#39;est que si la personne prospectée par l&#39;Organisateur, se créé un compte sur la Plateforme pour assister à un Evénement que ses données, fournies pour la création d&#39;un compte et profiter des Services de la Plateforme, seront intégrées à la base de données des Utilisateurs de la Plateforme.

# ARTICLE 15. RESPONSABILITE

## 15.1 – Principes Généraux

L&#39;Organisateur pourra engager la responsabilité de PEKOIA dès lors qu&#39;il aura préalablement notifié par lettre recommandée avec accusé de réception le manquement allégué et que PEKOIA n&#39;aura pas répondu dans un délai de trente (30) joursà compter de la réception de cette mise en demeure. En tout état de cause, il est rappelé que la responsabilité de PEKOIA ne pourra être recherchée qu&#39;en cas de faute prouvée.

PEKOIA sera dégagée de plein droit de tout engagement et de toute responsabilité :

- Liée à l&#39;exécution même de l&#39;Evénement ;
- En cas de force majeure tel que défini par la jurisprudence applicable ;
- En cas d&#39;indisponibilité des Services ou de la Plateforme imputable à un tiers ;
- En cas de défaut de paiement de l&#39;Apprenant ;
- En cas de violation des présentes.

L&#39;Organisateur reconnaît par la présente que la responsabilité de PEKOIA ne saurait être engagée en cas de dysfonctionnements ou d&#39;interruptions des réseaux de transmission ou du matériel informatique de l&#39;Organisateur ou de l&#39;Utilisateur de la Plateforme. PEKOIA décline ainsi toute responsabilité en cas de perte de données, intrusions, virus, rupture de service ou autres problèmes étrangers à l&#39;Opérateur.

PEKOIA n&#39;est aucunement responsable à l&#39;égard de l&#39;Organisateur d&#39;un mauvais positionnement de sa Page Organisateur sur la Plateforme. Les conditions de référencement des Page Organisateurs sont détaillées dans les Conditions Générales d&#39;Utilisation de la Plateforme.

En aucun cas la responsabilité de PEKOIA ne pourra être recherchée, quel que soit le type d&#39;action intentée, pour un dommage indirect d&#39;aucune sorte par exemple, et sans que la liste soit exhaustive, tout préjudice financier ou commercial, perte de bénéfice, trouble commercial, manque à gagner, préjudice d&#39;un tiers, ou action intentée par un tiers contre l&#39;Organisateur ainsi que leurs conséquences, lié aux présentes ou à leur exécution. L&#39;Organisateur est seul responsable de tout préjudice, direct ou indirect, matériel ou immatériel causé par lui-même ou un de ses préposés à PEKOIA ou à des tiers du fait de son utilisation des Services.

En tout état de cause, il est expressément convenu entre les Parties que si la responsabilité de l&#39;Opérateur était retenue dans le cadre de l&#39;exécution du présent Contrat, celle-ci serait limitée à tous préjudices directs et toutes demandes confondues, aux sommes versées par l&#39;Organisateur à l&#39;Opérateur (à titre de paiement des Services) au cours des quatre (4) derniers mois maximum.

Les Parties conviennent expressément que l&#39;Opérateur peut faire appel à des prestataires extérieurs pour exécuter tout ou partie des Services. Dans ce cas, l&#39;Organisateur sera informé sur simple demande des garanties et niveau de service proposés par le prestataire extérieur qui lui seront opposables. En tout état de cause, PEKOIA demeurera l&#39;interlocutrice unique de l&#39;Organisateur en cas d&#39;appel à des sous-traitants et n&#39;engagera sa responsabilité que s&#39;il est démontré qu&#39;elle a commis une faute grave ou que le prestataire extérieur n&#39;a pas respecté les garanties et niveau de service proposés. Le plafond de responsabilité prévu à l&#39;alinéa précédent trouvera également à s&#39;appliquer dans ce cas.

Il est expressément convenu entre les Parties que les stipulations de la présente clause continueront à s&#39;appliquer même en cas de résolution des présentes constatée par une décision de justice devenue définitive.

## 15.2 – Sur la responsabilité de PEKOIA en sa qualité de simple hébergeur

L&#39;Organisateur reconnaît par le présent Contrat que l&#39;Opérateur a la qualité d&#39;hébergeur de ses Contenus.

A ce titre, PEKOIA se réserve la possibilité de retirer tout contenu publié sur les Page Organisateurs qui lui aura été signalé et qu&#39;il considèrera comme manifestement illicite au sens de l&#39;article 6 I 2° de la loi du 21 juin 2004 pour la confiance en l&#39;économie numérique dite LCEN, et suspendre l&#39;exécution des Services dans les conditions prévues aux présentes.

## 15.3 – Sur la responsabilité de l&#39;Organisateur

L&#39;Organisateur est l&#39;unique responsable des propos qu&#39;il tient auprès des Utilisateurs de la Plateforme, notamment dans le cadre de ses Evénements. En cas de litige, les Utilisateurs seront invités à s&#39;adresser directement à l&#39;Organisateur.

Ainsi, en aucune façon PEKOIA ne pourra être tenu pour responsable en cas de copie, contrefaçon, imitation et généralement de toute reproduction et représentation de toute ou partie des données et Contenus publiés par l&#39;Organisateur.

A ce titre, l&#39;Organisateur garantit PEKOIA contre tous recours ou actions que pourrait former à un titre quelconque toute personne tierce.

De la même manière, l&#39;Organisateur garantit PEKOIA contre toute action ou recours d&#39;un tiers lié à l&#39;import d&#39;une base de données tierce via l&#39;Outil CRM. L&#39;Organisateur garantit la conformité juridique de toute base de données importées ou exploitée via l&#39;Outil CRM.

# ARTICLE 16. DUREE DU CONTRAT - RESILIATION

## 16.1 – Durée

Le contrat est conclu pour une durée indéterminée à compter de la signature du présent Contrat.

## 16.2 – Hypothèses de suspension et de résiliation

## i. Suspension

En cas de violation continue des présentes dispositions, de l&#39;annexe 2 « KPI » ou des lois et règlements en vigueur, ainsi que d&#39;absence de coopération et/ou de déloyauté, s&#39;il y a urgence à faire cesser les agissements constatés, PEKOIA pourra suspendre de plein droit le présent Contrat, sans indemnité au profit de l&#39;Organisateur, sous réserve de lui avoir transmis sur un support durable, l&#39;exposé des motifs au moment où la suspension prend effet.

Cette décision est portée à la connaissance de l&#39;Organisateur par courriel, indiquant, dans un support durable, les griefs reprochés ainsi que les obligations dont le non-respect est allégué.

L&#39;Opérateur pourra mettre la Page Organisateur de l&#39;Organisateur hors ligne afin qu&#39;il régularise les manquements identifiés sous trente (30) jours, à compter de la lettre recommandée avec accusé de réception et du courriel. A l&#39;issue de ce délai, si l&#39;Organisateur n&#39;a pas corrigé les différents manquements, PEKOIA pourra résilier sans délai ce Contrat et supprimer ses offres d&#39;Evénements de la Plateforme.

## ii. Résiliation pour faute

En cas de manquement grave ou répété, par une Partie, d&#39;au moins une de ses obligations au titre des présentes, et plus particulièrement en cas de manquement répétés ou prolongés aux engagements de l&#39;Annexe 2, le présent Contrat pourra être résilié par l&#39;autre Partie. Il est expressément convenu que cette résiliation aura lieu de plein droit, 30 jours après (i) l&#39;envoi d&#39;une mise en demeure de s&#39;exécuter, restée sans effet ou après (ii) le délai accordé dans le cadre d&#39;une procédure de suspension.

La mise en demeure, qui devra impérativement indiquer les griefs reprochés et les obligations dont le non-respect est allégué, sera notifiée par lettre recommandée avec accusé de réception.

## iii. Résiliation pour convenance

L&#39;Organisateur aura la possibilité de résilier le présent Contrat à tout moment, sans avoir à fournir de justificatif ni à respecter de préavis, sous réserve d&#39;honorer toutes ses commandes d&#39;Evénement en cours.

Pour résilier le présent Contrat, l&#39;Organisateur devra procéder à sa désinscription conformément aux modalités détaillées au sein des CGU.

Au moment de la résiliation, l&#39;Organisateur s&#39;assure d&#39;avoir bien reçu l&#39;intégralité des paiements qui lui sont dus.

L&#39;Opérateur pourra également résilier le présent Contrat pour convenance, en adressant un courriel à l&#39;Organisateur sous réserve du respect d&#39;un préavis d&#39;un (1) mois.

## 16.3 - Conséquences de la résiliation – réversibilité

Toute résiliation, résolution ou annulation du Contrat entraîne automatiquement le déréférencement de la Page Organisateur de l&#39;Organisateur, ainsi que l&#39;annulation de l&#39;accès aux différents Services proposés à l&#39;Organisateur.

PEKOIA se réserve le droit de renvoyer vers l&#39;Organisateur toute réclamation initiée par un Apprenant concernant l&#39;une des Evénements commandés via la Plateforme et conservera la possibilité de communiquer en visant sa collaboration avec l&#39;Organisateur pendant une durée complémentaire de douze (12) moisà compter de la réception du courrier de résiliation.

En cas de cessation de la relation contractuelle, quelle qu&#39;en soit la cause, PEKOIA s&#39;engage à restituer à l&#39;Organisateur l&#39;ensemble des fichiers et documents lui appartenant dans un format standard (sous forme de fichier CSV/Excel par exemple) dans un délai de 30 jours suivant la date de résiliation effective du Contrat.

Il est toutefois précisé que faute pour l&#39;Organisateur d&#39;avoir manifesté sa volonté d&#39;obtenir cette restitution dans les soixante (60) jours suivant la fin du Contrat, PEKOIA aura la possibilité de procéder à la destruction de ces fichiers et documents.

Sauf résiliation imputable à une faute de PEKOIA, en cas de résiliation du Contrat, toutes les sommes dues par l&#39;Organisateur à PEKOIA sont exigibles immédiatement.

Les articles Propriété intellectuelle, Confidentialité et Protection des Données resteront en vigueur en cas de résiliation des présentes et ce pour une durée complémentaire de cinq (5) ans sauf stipulation expresse ou disposition législative ou réglementaire contraire.

# ARTICLE 17. CONFIDENTIALITE

Chaque Partie s&#39;engage à n&#39;utiliser les Informations Confidentielles, directement ou indirectement, en tout ou partie, que pour la stricte exécution du présent Contrat.

Toute divulgation fondée pourra engager la responsabilité de son auteur, et ce quelle que soit la cause de la divulgation.

Les obligations de confidentialité stipulées par la présente clause ne s&#39;appliquent pas à l&#39;intégralité ou à toute partie des Informations Confidentielles dans la mesure où :

1. Elles étaient légalement détenues par la partie destinataire avant leur divulgation ;
2. Elles ont été légalement divulguées à la partie destinataire par une tierce partie sans restriction de divulgation ;
3. Elles sont assujetties à une obligation légale de divulgation par tout tribunal compétent, autorité ou administration.

La présente clause de confidentialité sera maintenue à l&#39;expiration du Contrat jusqu&#39;à ce que les Informations Confidentielles passent dans le domaine public autrement que par une infraction de la partie destinataire.

# ARTICLE 18. DISPOSITIONS GENERALES - INDEPENDANCE

Si l&#39;une quelconque des stipulations du Contrat venait à être nulle au regard d&#39;une disposition législative ou réglementaire en vigueur et/ou d&#39;une décision de justice ayant autorité de la chose jugée, elle sera réputée non écrite mais n&#39;affectera en rien la validité des autres clauses qui demeureront pleinement applicables.

Chacune des Parties est une personne morale indépendante de l&#39;autre, que ce soit d&#39;un point de vue juridique ou financier. Ainsi chaque Partie, agit en son nom propre et sous sa seule responsabilité.

Aucune des Parties ne pourra être considérée comme le représentant de l&#39;autre et ne pourra agir ni s&#39;engager au nom de l&#39;autre.

Le présent Contrat exclut tout lien de subordination ou volonté de créer une société créée de fait entre les Parties.

Néanmoins, chacune des Parties s&#39;engage à toujours se comporter vis-à-vis de l&#39;autre, comme un partenaire loyal et de bonne foi, et notamment à porter sans délai à la connaissance de l&#39;autre Partie, tout différend ou toute difficulté qu&#39;elle pourrait rencontrer dans le cadre de l&#39;exécution du présent Contrat.

# ARTICLE 19. DROIT APPLICABLE – JURIDICTION COMPETENTE

Le présent Contrat, son exécution et son interprétation sont soumis exclusivement au droit français.

Les Parties s&#39;efforceront de régler à l&#39;amiable tout différend survenant entre elles au sujet de l&#39;interprétation, de l&#39;exécution ou de la résiliation du présent Contrat.

A DEFAUT D&#39;ACCORD AMIABLE DANS UN DELAI D&#39;UN (1) MOIS A COMPTER DE LA SAISINE DE L&#39;UNE DES PARTIES, LE LITIGE POURRA ETRE SOUMIS AUX TRIBUNAUX DU RESSORT DE LA COUR D&#39;APPEL DE PARIS AUXQUELS IL EST FAIT EXPRESSEMENT ATTRIBUTION DE COMPETENCE, NONOBSTANT PLURALITE DE DEFENDEURS OU APPEL EN GARANTIE, Y COMPRIS POUR LES PROCEDURES D&#39;URGENCE OU LES PROCEDURES CONSERVATOIRES, EN REFERE OU PAR REQUETE

# ANNEXE 1 – ANNEXE TARIFAIRE

Les conditions tarifaires applicables à la date de signature du présent Contrat sont les suivantes :

![](RackMultipart20210907-4-19tdlcz_html_d5ffad2f6f52811c.gif)

![](RackMultipart20210907-4-19tdlcz_html_983e84dd9abb0d13.gif)

# ANNEXE 2 - KPI

L&#39;Opérateur et l&#39;Organisateur s&#39;entendent sur le fait que le respect des seuils de qualité suivants est une condition essentielle du présent Contrat :

- L&#39;Organisateur devra organiser au moins un (1) Evénement tous les six (6) mois.
- Note moyenne d&#39;au moins 3/5, ou note supérieure à 2/5 sur trois Evénements d&#39;affilé.
- Nombre de litiges par Evénement : une moyenne maximale d&#39;un litige pour 20 Apprenants.
- Nombre de demandes de remboursement : une moyenne maximale d&#39;une demande de remboursement pour 20 Apprenants.
- Délai pour accuser réception d&#39;une plainte : quarante-huit (48) heures ouvrées.
- Délai pour solder un litige à réception : soixante-douze (72) heures ouvrées à la suite de la réception de la notification du litige.
- Délai à l&#39;issue duquel PEKOIA est expressément mandatée pour agir au nom et pour le compte de l&#39;Organisateur afin de trouver un accord amiable.
- Nombre d&#39;événements annulés nécessitant un remboursement des billets : limité à 1 par mois.

# **ANNEXE 3 - Conditions Générales d&#39;Utilisation de Services de paiement – Contrat-cadre de Services de paiement**

**Version 2021**

  **Le Titulaire**  d&#39;une part

et,

**Lemonway** , société par actions simplifiée dont le siège social est situé au 8 rue du Sentier, 75002 Paris, France, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 500 486 915 («  **Lemonway** « ), agréée le 24 décembre 2012 par l&#39;Autorité de Contrôle Prudentiel et de Résolution (« ACPR », France, site internet http://acpr.banque-france.fr/), 4 place de Budapest CS 92459, 75436 Paris, en qualité d&#39;Etablissement de Paiement hybride, sous le numéro 16 568 J, d&#39;autre part.

Désignés individuellement une «  **Partie**  » et ensemble les «  **Parties**  ».

#### AVERTISSEMENT

Le Titulaire peut à tout moment consulter ce Contrat-cadre, le reproduire, le stocker sur son ordinateur ou sur un autre support, le transférer par courrier électronique ou l&#39;imprimer sur papier de manière à le conserver.

A tout instant, conformément à la loi, il est possible de vérifier l&#39;agrément de Lemonway sur le site regafi.fr, en qualité d&#39;établissement de paiement. Le Site Internet de l&#39;établissement de paiement Lemonway est le suivant : www.lemonway.com

#### 1. OBJET

Le présent « Contrat-cadre de Services de paiement » est consultable à tout moment sur le Site Internet (https://www.lemonway.com). Il régit les termes et conditions de l&#39;ouverture d&#39;un Compte de paiement par Lemonway au nom du Titulaire et la fourniture de Services de paiement. Le Titulaire est invité à les lire avec attention avant de les accepter.

#### 2. DÉFINITIONS

Les termes utilisés dans le présent Contrat-cadre auront, lorsqu&#39;ils sont utilisés avec la première lettre en majuscule et indépendamment du fait qu&#39;ils soient utilisés au singulier ou au pluriel, le sens défini ci-après :

- Bénéficiaire : personne physique ou morale désignée par le Titulaire comme destinataire d&#39;un virement provenant de son Compte de paiement. Le Bénéficiaire peut être le Titulaire.
- Contrat-cadre : Contrat-cadre de Services de paiement tel que défini à l&#39;article L. 314-12 du Code monétaire et financier conclu entre Lemonway et le Titulaire composé des présentes CGU.
- Compte de paiement : Compte de paiement au sens de l&#39;article L. 314-1 du Code monétaire et financier ouvert dans les livres de Lemonway à l&#39;effet d&#39;inscrire au débit et au crédit les Opérations de paiement, les frais dus par le Titulaire et toute contrepassation en lien avec ses Opérations et de compenser ces montants à la date de leur inscription aux fins de faire apparaître un solde net.
- Jour Ouvré : un jour autre qu&#39;un samedi, un dimanche ou un jour férié en France.
- Opérations de paiement : action consistant à verser, transférer ou retirer des fonds à partir ou à destination du Compte de paiement, indépendamment de toute obligation sous-jacente entre le Payeur et le Bénéficiaire.
- Ordre de paiement : consentement du Titulaire donné suivant le dispositif personnalisé et les procédures convenus entre le Titulaire et Lemonway, afin d&#39;autoriser une Opération de paiement.
- Partenaire : société commerciale exploitant le Site Partenaire et utilisant les services de Lemonway.
- Payeur : Personne physique ou morale pouvant être le Titulaire en cas d&#39;alimentation de son propre Compte de paiement ou utilisatrice du Site Partenaire.
- Provision : montant disponible inscrit au crédit du Compte de paiement pouvant être affecté à l&#39;exécution d&#39;Opérations de paiement futures, déterminé par Lemonway après prise en compte des Opérations de paiement en cours et du montant de la Provision bloquée définie à l&#39;article 5.
- Prestataire de Services de paiement (PSP) tiers : prestataire de Services de paiement (établissement financier) agréé par une autorité d&#39;un Etat partie à l&#39;Espace Économique Européen ayant ouvert un compte bancaire ou de paiement au nom du Titulaire.
- Services de paiement : services fournis par Lemonway en application du Contrat-cadre et comprenant l&#39;exécution de virements et l&#39;acquisition d&#39;ordres de paiement par cartes et par virements, ainsi que l&#39;encaissement de chèques.
- Site Internet : Désigne le site internet http://www.lemonway.com à partir duquel Lemonway propose les Services de paiement.
- Site Partenaire ou Site : Désigne le site et/ou l&#39;application exploité par le Partenaire en vue de permettre à des personnes de réaliser des Opérations de paiement.
- Titulaire : Personne physique ou morale disposant d&#39;un Compte de paiement permettant d&#39;effectuer et/ou recevoir une Opération de paiement.

#### 3. OUVERTURE DE COMPTE DE PAIEMENT

Le Titulaire doit satisfaire la procédure d&#39;ouverture de Compte de paiement ci-après décrite.

##### 3.1 Déclarations préalables du Titulaire

Le Titulaire, personne morale ou physique majeure capable, déclare expressément avoir la capacité et/ou avoir reçu les autorisations requises pour utiliser les Services de paiement fournis par Lemonway et garantit Lemonway contre toute responsabilité pouvant résulter pour elle d&#39;une fausse déclaration.

Le Titulaire déclare agir pour son compte. Le Titulaire a l&#39;obligation d&#39;utiliser les services fournis par Lemonway de bonne foi, à des fins licites et dans le respect des dispositions du Contrat-cadre.

Le Titulaire personne physique déclare être résident en France ou dans l&#39;Espace Économique Européen.

Pour tout autre pays de résidence ou d&#39;immatriculation, Lemonway se garde la possibilité d&#39;étudier la demande d&#39;ouverture d&#39;un Compte de paiement afin de se conformer au périmètre géographique de son agrément.

##### 3.2 Transmission des documents d&#39;identification

Lemonway informe le Titulaire que conformément aux obligations d&#39;identification relatives à la lutte contre le blanchiment de capitaux et de financement du terrorisme, l&#39;ouverture du Compte de paiement est conditionnée à la transmission et validation des documents d&#39;identification requis en fonction de la qualité du Titulaire.

Lemonway se réserve le droit de demander tout autre document ou information complémentaire, pour lui permettre d&#39;effectuer les vérifications utiles au respect de ses obligations légales y compris en matière de lutte contre le blanchiment de capitaux.

Le Titulaire accepte que le Site Partenaire fasse parvenir ces documents à Lemonway par transmission informatique et téléversement sur les systèmes informatiques de Lemonway.

##### 3.3 Modalités d&#39;approbation du Contrat-cadre

Le Contrat-cadre doit être approuvé par le Titulaire par tout moyen. Ce dernier reconnaît avoir attentivement lu, compris et accepté le Contrat-cadre dans son intégralité.

##### 3.4 Acceptation d&#39;ouverture de Compte de paiement

Lemonway peut refuser d&#39;ouvrir un Compte de paiement pour tout motif sans avoir à justifier sa décision. Celle-ci ne donnera lieu à aucun dommage et intérêt.

Sous réserve de l&#39;autorisation expresse et écrite de son représentant légal, le mineur de moins de dix-huit (18) ans peut ouvrir un Compte de paiement.

A cet effet, les documents d&#39;identification requis seront ceux du représentant légal ayant donné son autorisation expresse et écrite, du mineur ainsi qu&#39;un document attestant du pouvoir (livret de famille, décision de justice conférant le pouvoir de représentation).

Le Site Partenaire pourra faire parvenir l&#39;acceptation ou le refus de l&#39;ouverture de son Compte de paiement au Titulaire par envoi d&#39;un Email. Le Titulaire peut à compter de l&#39;acceptation s&#39;identifier sur le Site Partenaire pour constater que son Compte de paiement est ouvert.

##### 3.5 Régime spécifique de la clientèle occasionnelle

Le client occasionnel est le client « de passage » qui sollicite l&#39;intervention de Lemonway pour la réalisation d&#39;une Opération de paiement isolée ou de plusieurs Opérations de paiement présentant un lien entre elles dans la limite des plafonds applicables par Lemonway. Les Opérations effectuées par un client occasionnel ne feront pas l&#39;objet de l&#39;ouverture d&#39;un Compte de paiement.

Lemonway rappelle que le régime spécifique de la clientèle occasionnelle est uniquement applicable à une certaine typologie de clientèle et d&#39;activité.

L&#39;acceptation du présent Contrat-cadre ne constitue pas une entrée en relation d&#39;affaires et l&#39;ouverture d&#39;un Compte de paiement dans le cas où le régime spécifique de la clientèle occasionnelle est applicable.

Lemonway rappelle qu&#39;en cas de dépassement des plafonds d&#39;Opérations de paiement applicables, l&#39;ouverture d&#39;un Compte de paiement sera obligatoire pour effectuer de nouvelles Opérations de paiement.

#### 4. CRÉDITER LE COMPTE DE PAIEMENT

##### 4.1 Alimentation du Compte de paiement

Le Titulaire peut procéder à l&#39;alimentation de son Compte de paiement par les moyens de paiement mis à disposition par le Partenaire de Lemonway.

Lors de l&#39;alimentation du Compte de paiement par virement, par chèque (France uniquement) ou par ordre de prélèvement, ces Opérations de paiement doivent être réalisées à l&#39;ordre d&#39;un compte bancaire ouvert au nom de Lemonway. Lemonway crédite ensuite les fonds correspondants, après réception, sur le Compte de paiement.

Lemonway peut refuser ou annuler l&#39;enregistrement du moyen de paiement utilisé par le Titulaire par mesure de sécurité.

Lemonway paramètre des plafonds de paiement dans l&#39;intérêt de la protection du Titulaire et le respect de la réglementation en vigueur en matière de Services de paiement. Des plafonds uniques, par jour, par mois et par an, ainsi que toutes formes de restrictions (notamment portant sur l&#39;authentification forte du Titulaire) sont appliquées par Lemonway pour lutter contre la fraude.

Le Titulaire est informé que toute Opération de paiement risquant d&#39;entraîner un dépassement des plafonds applicables sera automatiquement rejetée par Lemonway.

Toute Opération par carte bancaire ou de paiement, qui ferait l&#39;objet d&#39;un impayé, d&#39;un rejet ou d&#39;une opposition verra son montant automatiquement déduit par Lemonway du solde net du Compte de paiement. Si le solde net est insuffisant, Lemonway est autorisée à utiliser toutes les voies de recours contre le Titulaire en vue de recouvrer le montant dû. En outre, Lemonway sera en droit de refuser l&#39;exécution de toutes les futures remises de fonds effectuées au moyen de la carte ayant donné lieu à l&#39;incident.

Lemonway répercute, en outre, au débit du Compte de paiement du Titulaire, les Opérations de paiement rejetées et autres amendes qui peuvent être prononcées par les réseaux d&#39;acceptation.

##### 4.2 Délais d&#39;inscription des fonds sur le Compte

Lemonway inscrira les fonds résultant de l&#39;acquisition d&#39;une Opération de paiement par carte ou par virement dans les plus brefs délais et au plus tard à la fin du Jour Ouvré au cours duquel ils ont été reçus par Lemonway.

Les délais d&#39;inscription des fonds sur le Compte peuvent être plus longs en cas d&#39;utilisation d&#39;un autre moyen de paiement mis à disposition par Lemonway.

Pour des raisons de sécurité, les délais d&#39;inscription peuvent être plus longs en cas de suspicion de fraude dans l&#39;attente de fourniture d&#39;informations complémentaires par le Titulaire ou de tout autre tiers concerné.

#### 5. DÉBITER UN COMPTE DE PAIEMENT PAR VIREMENT

##### 5.1 Initier un Ordre de paiement

Lemonway fournit un service de paiement permettant aux Titulaires disposant d&#39;un Compte de paiement de donner instruction à Lemonway, d&#39;exécuter un virement sous réserve que la Provision du Compte soit supérieure au montant total du virement (frais inclus). En cas de Provision insuffisante, l&#39;Ordre de paiement sera automatiquement refusé.

La Provision disponible correspond au solde net du Compte de paiement exclusion faite de la Provision bloquée et des Opérations en cours. Le montant de la Provision bloquée est déterminé par Lemonway en vue de couvrir les éventuelles contrepassations provenant de la contestation d&#39;un Ordre de paiement. Une telle contestation peut intervenir dans un délai de treize (13) mois à compter du débit en Compte de l&#39;Opération.

L&#39;Ordre de paiement pourra être fait à l&#39;attention soit d&#39;un autre Compte de paiement ouvert dans les livres de Lemonway, soit d&#39;un compte bancaire au nom du Titulaire ouvert dans les livres d&#39;un PSP tiers autorisé.

L&#39;Ordre de paiement devra comporter les informations suivantes :

- le montant en euros ou en devises ;
- le nom et prénom du Bénéficiaire ;
- le numéro de son compte bancaire ouvert dans les livres du PSP tiers.

Le Titulaire reconnaît que si la devise du Compte de paiement diffère de celle du compte Bénéficiaire vers lequel va s&#39;effectuer le virement des fonds, des frais de change seront imputés par le PSP du Bénéficiaire. Il appartient au Partenaire de Lemonway et au PSP du Bénéficiaire d&#39;informer le Bénéficiaire avant toute acquisition d&#39;un ordre de virement, du taux de change pratiqué, des frais et des délais d&#39;exécution. Cette information devra être transmise au Titulaire Payeur par le Partenaire.

Lemonway ne peut être tenu pour responsable si les coordonnées bancaires transmises pour les demandes de virement sont erronées ou non mises à jour.

##### 5.2 Irrévocabilité d&#39;un Ordre de paiement

L&#39;Ordre de paiement valablement donné par un Titulaire est irrévocable pour le Titulaire. Le Titulaire ne pourra donc pas en demander l&#39;annulation.

Lemonway pourra cependant bloquer tout Ordre de paiement en cas de suspicion de fraude ou de violation des règles applicables en matière de lutte contre le blanchiment et le financement du terrorisme (LCB-FT).

Les Ordres de paiement devront préalablement recueillir le consentement du Payeur ou du Titulaire. En l&#39;absence d&#39;un tel consentement, l&#39;Opération ou la série d&#39;Opérations de paiement est réputée non autorisée.

Le consentement peut être retiré par le Payeur tant que l&#39;Ordre de paiement n&#39;a pas acquis un caractère d&#39;irrévocabilité conformément aux dispositions de l&#39;article L. 133-8 du Code monétaire et financier.

Lorsque l&#39;Opération de paiement est initiée par le Bénéficiaire ou par le Payeur qui donne un Ordre de paiement par l&#39;intermédiaire du Bénéficiaire, le Payeur ne peut révoquer l&#39;Ordre de paiement après avoir transmis l&#39;Ordre de paiement au Bénéficiaire ou donné son consentement à l&#39;exécution de l&#39;Opération de paiement au Bénéficiaire.

Le consentement à l&#39;exécution d&#39;une série d&#39;Opérations de paiement peut aussi être retiré, avec pour effet que toute Opération postérieure est réputée non autorisée.

##### 5.3 Montants des plafonds et limites applicables

Toute Opération de paiement risquant d&#39;entraîner un dépassement des plafonds applicables au montant des paiements mensuels cumulés sera automatiquement rejetée par Lemonway.

D&#39;autres plafonds ou blocages d&#39;Ordre pourront être activés à tout moment par Lemonway en cas de risque de fraude.

Lemonway se réserve le droit de contrepasser une Opération de paiement, si l&#39;opération de transfert de fonds par carte bancaire ou de paiement utilisée pour créditer le Compte de paiement en vue de réaliser cette opération est rejetée ou annulée par le PSP émetteur de la carte.

##### 5.4 Délais d&#39;exécution

Les délais maximaux d&#39;exécution des Services de paiement, conformément à l&#39;arrêté du 29 juillet 2009, d&#39;application de l&#39;article L. 133-13 du Code monétaire et financier, sont les suivants :

- une Opération de paiement initiée un Jour Ouvré sera exécutée au plus tard par Lemonway le Jour Ouvré suivant si elle est réalisée en euros au profit d&#39;un établissement de crédit localisé dans un Etat membre de l&#39;Union Européenne ;
- une Opération de paiement initiée un Jour Ouvré sera exécutée au plus tard par Lemonway à la fin dudit Jour Ouvré si elle est réalisée en euros au profit d&#39;un autre Compte de paiement

#### 6. REPORTING – RELEVÉS

Toutes les Opérations de paiement sont reprises dans un relevé établi en temps réel pour chaque Compte de paiement.

Le Titulaire aura accès aux relevés de Comptes de paiement reprenant l&#39;ensemble des Opérations de paiement inscrites au débit et au crédit de ce compte par l&#39;intermédiaire du Site Partenaire.

La période de consultation est maintenue pendant deux (2) ans, plus l&#39;année en cours. Lemonway conservera sur support électronique d&#39;archivage, pendant les délais réglementaires applicables, les enregistrements et documents des Opérations de paiement effectuées.

#### 7. DURÉE DU CONTRAT-CADRE ET DATE D&#39;EFFET

Le Contrat-cadre entre en vigueur au moment de l&#39;acceptation des présentes par le Titulaire et ce pour une durée indéterminée.

Le Titulaire dispose d&#39;un délai de quatorze (14) jours calendaires pour renoncer sans frais au Contrat-cadre, s&#39;il répond aux conditions de l&#39;article D. 341-1 du Code monétaire et financier pour les personnes morales ou s&#39;il est une personne physique. Ce délai court à compter du jour où le Contrat-cadre est conclu c&#39;est-à-dire le jour où le Titulaire a accepté les présentes conditions générales. Pendant ce délai de renonciation, l&#39;exécution du Contrat-cadre ne pourra commencer qu&#39;à la demande expresse du Titulaire. Le Titulaire reconnaît expressément et accepte que toute instruction de paiement adressée à Lemonway avant l&#39;écoulement du délai de renonciation, constitue une demande expresse du Titulaire d&#39;exécuter le Contrat-cadre. Le Titulaire ne sera donc pas en droit d&#39;annuler une instruction de paiement qu&#39;il aurait donnée et confirmée pendant le délai de renonciation.

Ce droit de renonciation peut être exercé par le Titulaire sans pénalités et sans indication de motif.

Le Titulaire est tenu de notifier sa décision de renonciation à Lemonway par tout moyen. Si le Titulaire n&#39;exerce pas son droit de renonciation, le Contrat-cadre sera maintenu conformément aux dispositions des présentes conditions générales. Il devra pour résilier le Contrat-cadre se conformer aux conditions de résiliation de l&#39;article 19.

#### 8. RÉCLAMATIONS

Les réclamations qui portent sur les relations entre deux Titulaires ou entre un Titulaire et un tiers ne sont pas recevables auprès de Lemonway. Seules celles qui portent sur l&#39;absence ou la mauvaise exécution d&#39;une Opération de paiement exécutée par Lemonway sont visées par le présent article et par le Contrat-cadre.

Les réclamations (contestations, droits d&#39;opposition, d&#39;accès et de rectification) peuvent être exercées gratuitement sur demande adressée à Lemonway via la page de réclamation disponible à l&#39;adresse suivante : [www.lemonway.com/reclamation](http://www.lemonway.com/reclamation) ou par courrier à l&#39;adresse suivante :

Société LEMONWAY – Service Réclamation – 8 rue du Sentier, CS 60820, 75083 Paris CEDEX 2.

Toute contestation ou requête relative :

- à une information communiquée par Lemonway en application du Contrat-cadre ;
- à une erreur dans l&#39;exécution des Services de paiement ou dans son inexécution ;
- à une erreur dans le prélèvement de commission, taxe ou frais par Lemonway ;

doit être notifiée à Lemonway par le Titulaire dans les plus brefs délais à compter du jour où le Titulaire en a eu connaissance ou est présumé en avoir eu connaissance ou dans tout autre délai plus long prévu par des dispositions particulières ou par la loi.

Conformément à la recommandation de l&#39;ACPR 2016-R-02 du 14 novembre 2016, un accusé de réception sera envoyé sous dix jours maximum. Les réclamations seront traitées sous deux mois maximum à compter de leur réception.

A défaut d&#39;accord amiable, le Titulaire agissant pour des besoins non professionnels peut s&#39;adresser, par lettre, à un médiateur indépendant, pouvant être saisi gratuitement en cas de litige né de l&#39;application des présentes, le Médiateur de l&#39;AFEPAME, 36 rue Taitbout 75009 Paris, et ceci sans préjudice des autres voies d&#39;actions légales.

#### 9. FRAIS

En contrepartie de la fourniture des Services de paiement au Titulaire, Lemonway percevra une rémunération dont le montant et les conditions sont fixées entre le Partenaire et Lemonway.

Lemonway informe le Titulaire que le Partenaire prend en charge l&#39;ensemble des frais relatifs à la fourniture des Services de paiement.

#### 10. SÉCURITÉ

##### 10.1 Obligation de notification

Le Titulaire a l&#39;obligation d&#39;informer immédiatement Lemonway en cas de soupçon d&#39;accès ou d&#39;utilisation frauduleuse de son Compte de paiement ou de tout événement susceptible de mener à une telle utilisation, tels que et de manière non limitative : la perte, la divulgation accidentelle ou le détournement de ses identifiants de Compte de paiement ou une Opération non autorisée.

Cette notification doit s&#39;effectuer par l&#39;envoi d&#39;un courrier électronique à l&#39;adresse email suivante : alerte.lcbft@lemonway.com et être confirmée par courrier à l&#39;adresse suivante :

Société LEMONWAY – Service Sécurité Financière – 8 rue du Sentier, CS 60820, 75083 Paris CEDEX 2.

##### 10.2 Prévention

Lemonway fera ses meilleurs efforts pour empêcher toute autre utilisation du Compte de paiement. Le Partenaire dispose également de ses propres moyens de communication sécurisée avec le Titulaire sous sa propre responsabilité.

##### 10.3 Utilisation de cookies

Lemonway informe le Titulaire que dans le cadre des Services de paiement, des cookies pourront être utilisés. Ces cookies servent avant tout à améliorer le fonctionnement des Services de paiement notamment en termes de rapidité.

Le Titulaire est informé qu&#39;il peut refuser les cookies auprès de Lemonway dans les paramètres de son navigateur, mais que cela risque d&#39;altérer son utilisation des Services de paiement.

##### 10.4 Interruption des Services de paiement

Lemonway s&#39;engage à mettre en œuvre tous les moyens raisonnables à sa disposition pour assurer un service permanent. Lemonway ne garantit toutefois pas l&#39;accès continu, ininterrompu aux Service de paiement. En conséquence Lemonway ne pourra pas être tenue pour responsable du retard et/ou de la non-accessibilité totale ou partielle aux Services de paiement, dès lors qu&#39;ils résultent de facteurs échappant au contrôle raisonnable de Lemonway.

Le Titulaire est informé que Lemonway peut interrompre, occasionnellement, l&#39;accès à tout ou partie des Services de paiement :

- pour permettre les réparations, maintenances, ajouts de fonctionnalité ;
- en cas de suspicion de tentative de piratage, de détournement de fonds ou de tout autre risque d&#39;atteinte ;
- sur demandes ou instructions émanant de personnes ou autorités compétentes habilitées.

Lemonway ne peut en aucun cas être tenue responsable des dommages résultants éventuellement de ces suspensions.

Dès la reprise normale du service, Lemonway mettra en œuvre les efforts raisonnables pour traiter les Opérations de paiement en attente dans les meilleurs délais.

##### 10.5 Opposition au dispositif de sécurité

Le Titulaire peut faire opposition en contactant Lemonway via la page de réclamation disponible à l&#39;adresse suivante : [www.lemonway.com/reclamation](http://www.lemonway.com/reclamation) ou par téléphone au numéro : : +33 1 76 44 04 60.

Le dispositif de sécurité s&#39;entend comme toute mesure de sécurisation des Opérations de paiement et/ou d&#39;accès au Titulaire à son Compte via son espace client du Site Partenaire, conformément à la réglementation en vigueur.

Un numéro d&#39;enregistrement de cette opposition est créé et est conservé pendant 18 mois. Sur demande écrite du Titulaire et avant l&#39;expiration de ce délai, Lemonway lui communiquera une copie de cette opposition.

Lemonway ne saurait être tenue pour responsable des conséquences d&#39;une opposition qui n&#39;émanerait pas du Titulaire. La demande d&#39;opposition est réputée faite à la date de réception effective de la demande par Lemonway ou toute personne mandatée par lui, à cet effet. En cas de vol ou d&#39;utilisation frauduleuse, Lemonway est habilitée à demander un récépissé ou une copie du dépôt de plainte au Titulaire qui s&#39;engage à y répondre dans les plus brefs délais.

Lemonway bloquera l&#39;accès au Compte de paiement.

#### 11. RESPONSABILITÉS

Conformément à l&#39;article L. 133-22 du Code monétaire et financier, Lemonway est responsable, sous réserve des articles L. 133-5 et L. 133-21 du Code monétaire et financier, de la bonne exécution de l&#39;Opération de paiement à l&#39;égard du Titulaire Payeur jusqu&#39;à réception des fonds par le PSP tiers du Bénéficiaire. Lorsque Lemonway est responsable d&#39;une Opération de paiement mal exécutée par sa faute, Lemonway restitue sans tarder son montant au Payeur et rétablit le compte débité dans la situation qui aurait prévalu si l&#39;Opération de paiement mal exécutée n&#39;avait pas eu lieu.

Le Titulaire agissant pour des besoins non professionnels qui souhaite contester une Opération de paiement non autorisée par lui doit contacter le service clients conformément à l&#39;article 8 dans les plus brefs délais suivant sa prise de connaissance de l&#39;anomalie et au plus tard 13 mois suivant l&#39;inscription de l&#39;Opération de paiement. En cas d&#39;utilisation du dispositif de sécurité, les Opérations de paiement non autorisées effectuées avant la notification de l&#39;opposition sont à la charge du Titulaire agissant pour des besoins non professionnels, dans la limite d&#39;un plafond de 50 euros conformément à l&#39;article L. 133-19 du Code monétaire et financier. Toutefois, la responsabilité de Lemonway n&#39;est pas engagée en cas de faute du Titulaire tel qu&#39;un manquement volontaire ou constitutif d&#39;une négligence grave à ses obligations, une transmission tardive de l&#39;opposition ou de mauvaise foi. En cas de détournement de ses données ou contrefaçon, les pertes résultant des Opérations de paiement passées avant l&#39;opposition par le Titulaire agissant pour des besoins non professionnels sont supportées par Lemonway, sauf en cas de faute telle que définie ci-dessus. Les Opérations de paiement réalisées après l&#39;opposition du Titulaire agissant pour des besoins non professionnels sont supportées par Lemonway sauf en cas de fraude.

Lemonway n&#39;est pas en droit d&#39;annuler un Ordre de paiement irrévocable sur demande du Titulaire.

En aucun cas, Lemonway n&#39;est responsable des dommages indirects, tels que préjudice commercial, perte de clientèle, trouble commercial quelconque, perte de bénéfice, perte d&#39;image de marque subis par un Titulaire, ou par un tiers, et qui pourraient résulter des Services de paiement fournis par Lemonway. Toute action dirigée contre un Titulaire par un tiers est assimilée à un préjudice indirect, et en conséquence n&#39;ouvre pas droit à réparation.

Sauf stipulation contraire des présentes conditions générales ou des lois impératives et sans préjudice des autres causes d&#39;exclusion ou de limitation de responsabilité prévues par les présentes, Lemonway ne pourra en aucun cas être tenue pour responsable de tout dommage causé par un cas de force majeure ou un événement hors de son contrôle ou de toute mesure ou dispositions législatives prises par les autorités françaises ou étrangères. Sont réputés constituer un cas de force majeure ou un événement hors de son contrôle, notamment, mais sans que cela soit limitatif : une panne d&#39;électricité, un incendie ou une inondation, la grève de son personnel ou d&#39;un de ses sous-traitants ou fournisseurs, un dysfonctionnement des systèmes interbancaires ou de paiement par carte bancaire, troubles à l&#39;ordre public, négligence d&#39;un tiers au sens retenu par la jurisprudence et de la doctrine telles que les personnes responsables de la livraison d&#39;électricité, des services de télécommunication ou d&#39;hébergement.

#### 12. PROTECTION DES FONDS CLIENTS

Lemonway conservera les fonds disponibles inscrits au crédit du Compte de paiement du Titulaire à la fin du Jour Ouvré suivant le jour où ils ont été reçus sur un compte de cantonnement ouvert auprès des banques partenaires de Lemonway conformément l&#39;article L. 522-17 du Code monétaire et financier.

#### 13. DÉCÈS – COMPTE DE PAIEMENT INACTIF – PROCURATION

##### 13.1 Décès

En cas de décès du Titulaire du Compte de paiement, Lemonway doit en être avisée le plus rapidement possible par les ayant droits ou leur mandataire. Si cet avis est donné verbalement, il doit être confirmé par écrit. Dès réception de cet écrit, Lemonway veillera à ce qu&#39;aucune nouvelle Opération de paiement ne soit exécutée et procèdera à la clôture du Compte.

Si la Provision que Lemonway détient au nom du défunt est supérieure aux frais permettant de supporter les coûts de retrait, elle pourra faire l&#39;objet d&#39;un remboursement en faveur des ayants-droits uniquement en cas de production par ces ayants-droits ou leur mandataire des pièces probantes, selon la législation applicable, établissant la dévolution de la succession ainsi que de toute autre pièce que Lemonway jugerait nécessaire.

A défaut de virement pour quelque raison que ce soit, y compris l&#39;absence de production de document probant à Lemonway, les dispositions de l&#39;article 13.2 des présentes conditions générales s&#39;appliqueront à la Provision.

##### 13.2 Compte inactif

Un Compte de paiement est réputé inactif si :

1.

a) le Compte de paiement n&#39;a fait l&#39;objet d&#39;aucune Opération de paiement pendant une période de douze mois au cours de laquelle, hors inscription de débit par Lemonway tenant le compte de frais et commissions de toutes natures, et

b) le Titulaire du Compte, son représentant légal ou la personne habilitée par lui ne s&#39;est pas manifesté, sous quelque forme que ce soit, auprès de Lemonway ; ou

2. à l&#39;issue d&#39;une période de douze (12) mois suivant le décès du Titulaire. Le Titulaire et ses ayants droit sont informés par les présentes des conséquences qui y sont attachées.

Les avoirs inscrits sur le Compte de paiement inactif sont déposés à la Caisse des dépôts et consignations à l&#39;issue d&#39;un délai de dix (10) ans à compter de la date de la dernière Opération de paiement hors inscription des débits par Lemonway tenant le compte de frais et commissions de toutes natures ; sauf en cas de décès du Titulaire du compte où les avoirs inscrits sur le compte de paiement inactif sont déposés à la Caisse des dépôts et consignations à l&#39;issue d&#39;un délai de trois (3) ans après la date du décès du Titulaire.

Pour toute relance et notification de Lemonway au Titulaire ou au Partenaire, des frais seront applicables. En cas de compte inactifs et après relance et notification de Lemonway, des frais de gestion seront applicables.

##### 13.3 Procuration

Le Titulaire peut donner à une personne pouvoir d&#39;effectuer sur son Compte de paiement et sous son entière responsabilité les Opérations de paiement telles que définies dans la procuration. La procuration ne prendra effet qu&#39;à compter de la réception et de l&#39;acceptation du formulaire dûment complété par Lemonway. La procuration sera notifiée par tout moyen. Elle cesse automatiquement au décès du Titulaire. Elle peut être révoquée à l&#39;initiative du Titulaire qui en informe le mandataire et Lemonway par lettre recommandée avec avis de réception. La révocation prend effet à la date de réception de la notification par Lemonway. Le Titulaire reste tenu des Opérations de paiement initiées pour son compte jusqu&#39;à cette date par le mandataire désigné.

Le Titulaire lève expressément Lemonway du secret professionnel relatif aux données du Compte de paiement à l&#39;égard du mandataire désigné par la procuration.

#### 14. PROPRIÉTÉ INTELLECTUELLE

Aucun droit de propriété intellectuelle relatif à l&#39;utilisation du Service de paiement ou aux prestations rendues par Lemonway n&#39;est transféré au Titulaire au titre des présentes conditions générales.

Le Titulaire s&#39;engage à ne pas porter atteinte aux droits détenus par Lemonway, en s&#39;interdisant notamment, toute reproduction, ou adaptation de tout ou partie des éléments intellectuels et matériels de Lemonway et ses accessoires, et ce quel que soit le support, actuel et futur.

L&#39;ensemble des droits relatifs aux logiciels servant à la réalisation des Services de paiement sont la propriété pleine et entière de la société Lemonway. Ils font partie de ses informations confidentielles sans égard au fait que certaines composantes puissent ou non être protégées en l&#39;état actuel de la législation par un droit de propriété intellectuelle.

Les logiciels de Lemonway et, le cas échéant, leur documentation, sont reconnus par le Titulaire comme œuvre de l&#39;esprit que lui-même et les membres de son personnel s&#39;obligent à considérer comme telle en s&#39;interdisant de les copier, de les reproduire, de les traduire en toute autre langue ou langage, de les adapter, de les distribuer à titre gratuit ou onéreux, ou de leur adjoindre tout objet non conforme à leurs spécifications.

La marque « Lemonway » est la propriété de la société Lemonway. Le Titulaire s&#39;engage à ne pas supprimer la mention de la marque « Lemonway » sur tout élément fourni ou mis à sa disposition par Lemonway, tels que logiciel, document ou bannière publicitaire.

#### 15. CONFIDENTIALITÉ

Le Titulaire s&#39;engage à respecter la plus stricte confidentialité concernant l&#39;ensemble des techniques, commerciales ou de toute autre nature dont il viendrait à avoir connaissance dans le cadre de l&#39;exécution du Service de paiement.

Cette obligation de confidentialité demeurera en vigueur pendant la durée de la souscription au Service de paiement et pendant trois (3) ans suivant la date de résiliation du Contrat-cadre. Cette obligation de confidentialité ne s&#39;applique pas aux informations qui sont ou deviennent publiquement disponibles sans faute du Titulaire.

Les Parties reconnaissent que les Opérations de paiement sont couvertes par le secret professionnel en application de l&#39;article L. 522-19 du Code monétaire et financier.

#### 16. COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL

Conformément au Règlement Général sur la Protection des Données (_General Data Protection Régulation_) adopté par le Parlement européen le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée, Lemonway informe le Titulaire :

##### 16.1 Identification du Responsable de traitement

Société Lemonway SAS, siège social sis au 8 rue du Sentier, 75002 Paris, France, Tél. : +33 1 76 44 04 60.

##### 16.2 Délégué à la protection des données

Le Titulaire pourra joindre le délégué à la protection des données à l&#39;adresse courriel suivante : [dpo@lemonway.com](mailto:dpo@lemonway.com)

##### 16.3 Finalités du traitement

Dans le cadre de l&#39;exploitation du Site et des services fournis par Lemonway, le traitement de données personnelles a pour finalité la gestion des clients, la création et la gestion des comptes, la gestion des contrats, la gestion des résiliations, la gestion des litiges, la gestion du Site Internet, le mailing, les communications, les vérifications en matière de lutte contre le blanchiment de capitaux et de lutte contre le financement du terrorisme, la gestion de la connaissance client, l&#39;élaboration de statistiques dans le but d&#39;améliorer les outils de Lemonway, la gestion des demandes concernant les droits des personnes, l&#39;implémentation des Partenaires, la gestion du support.

##### 16.4 Nature des données

Lemonway collecte de manière directe et indirecte les catégories de données suivantes concernant ses utilisateurs :

- Données d&#39;état-civil, d&#39;identité, d&#39;identification… ;
- Données relatives à la vie professionnelle (CV, scolarité, formation professionnelle, …) ;
- Informations d&#39;ordre économique et financier (revenus, situation financière, situation fiscale…) ;
- Données de connexion (adresses IP, journaux d&#39;événements…).

##### 16.5 Source des données

Lemonway collecte les données à caractère personnel de manière directe par le biais d&#39;un contrat, d&#39;une obligation légale, du consentement de la personne ou de l&#39;intérêt légitime de la société.

Lemonway collecte également les données à caractère personnel de manière indirecte afin de respecter la règlementation en matière de lutte contre le blanchiment de capitaux et de lutte contre le financement du terrorisme.

##### 16.6 Consentement de la personne

Lorsque la personne a donné son consentement pour la collecte de ses données personnelles, celle-ci peut retirer son consentement, si le fondement juridique pour la collecte des données n&#39;existe plus. Le Titulaire peut retirer son consentement via l&#39;adresse dpo@lemonway.com.

Lemonway informe qu&#39;un tel retrait entraînera la clôture du Compte.

##### 16.7 Intérêt légitime du traitement

Lorsque Lemonway collecte et utilise des données personnelles de représentants de ses Partenaires en se fondant sur l&#39;intérêt légitime, celui-ci a pour finalité la prospection de nouveaux Partenaires.

##### 16.8 Scoring

Un _scoring_ est mis en place uniquement en matière de lutte contre le blanchiment de capitaux, de lutte contre le financement du terrorisme et de lutte contre la fraude.

##### 16.9 Destinataires des données

Les destinataires des données à caractère personnel sont les collaborateurs habilités au sein de Lemonway, les autorités de contrôle, les partenaires de Lemonway et ses sous-traitants. Les données personnelles peuvent également être divulguées en application d&#39;une loi, d&#39;un règlement ou en vertu d&#39;une décision d&#39;une autorité réglementaire ou judiciaire compétente.

##### 16.10 Durée de conservation

Les données à caractère personnel collectées par Lemonway sont conservées le temps nécessaire à la finalité du traitement. Au-delà de cette durée de conservation, elles deviennent des archives intermédiaires ou sont anonymisées et conservées dans un but statistique et historique.

Des purges concernant les données à caractère personnel sont mises en place afin de vérifier la suppression effective dès lors que la durée de conservation ou d&#39;archivage nécessaire à l&#39;accomplissement des finalités déterminées ou imposées est atteinte.

##### 16.11 Droits des personnes

Conformément aux dispositions en vigueur, le Titulaire dispose de droits concernant ses données personnelles qu&#39;il pourra exercer en écrivant à l&#39;adresse postale mentionnée au point 16.1 en l&#39;adressant au DPO ou en écrivant à dpo@lemonway.com.

_Ø Droit d&#39;accès_

Le Titulaire dispose de la faculté d&#39;accéder aux données personnelles le concernant. Cependant, pour des motifs de sécurité et de confidentialité, la demande ne pourra être traitée que si le Titulaire apporte la preuve de son identité.

Lemonway peut s&#39;opposer ou mettre en place une facturation pour les demandes manifestement abusives (nombre important de demandes, caractère répétitif ou systématique).

_Ø Droit de rectification_

Le Titulaire a la faculté de demander la rectification de ses données personnelles lorsque celles-ci sont inexactes, erronées, incomplètes ou obsolètes.

_Ø Droit à limitation_

Le Titulaire a la faculté de demander la limitation de ses données personnelles. Lorsque le droit à limitation est demandé, Lemonway pourra seulement stocker les données. Aucune autre opération ne pourra avoir lieu.

_Ø Droit à portabilité_

Le Titulaire conserve la faculté de demander de récupérer les données personnelles qu&#39;il a fourni à Lemonway, dans un format structuré, couramment utilisé et lisible par machine afin de les transmettre à un autre responsable de traitement. Ce droit ne peut être utilisé que si le traitement des données est basé sur le consentement de la personne concernée ou sur un contrat.

_Ø Droit d&#39;opposition_

Le Titulaire a la faculté de s&#39;opposer à l&#39;utilisation de ses données dans deux situations :

- Motifs légitimes ;
- En cas d&#39;utilisation des données recueillies à des fins commerciales.

_Ø Droit à l&#39;effacement_

Le Titulaire a la faculté de demander l&#39;effacement de ses données dans les meilleurs délais si l&#39;un des motifs du paragraphe 1 de l&#39;article 17 du Règlement Général sur la Protection des Données s&#39;applique.

Si les données du Titulaire ont été transmises à d&#39;autres entités, le mécanisme du « droit à l&#39;oubli » s&#39;enclenche : Lemonway devra prendre toutes les mesures raisonnables pour informer les autres entités que la personne concernée a demandé l&#39;effacement de tout lien vers ses données personnelles, ou de toute copie ou reproduction de celles-ci.

_Ø Droit post mortem_

Le Titulaire a la faculté de définir des directives concernant les données personnelles du Titulaire après son décès. Le cas échéant, les héritiers du Titulaire peuvent exiger de prendre en considération le décès ou procéder aux mises à jour.

##### 16.12 Délai de réponse

Lemonway s&#39;engage à répondre aux demandes relatives aux données à caractère personnel d&#39;accès ou à l&#39;exercice d&#39;un droit dans un délai d&#39;un (1) mois à compter de la réception de la demande.

##### 16.13 Transfert de données

Lemonway a recours à des prestataires habilités se situant à l&#39;intérieur et à l&#39;extérieur de l&#39;Union Européenne.

En cas de transfert vers un pays tiers, Lemonway respecte le Règlement Général sur la Protection des Données en ayant recours à des partenaires ou sous-traitant présentant des garanties adéquates par le biais d&#39;une procédure d&#39;adéquation, de clauses contractuelles types ou de règles internes d&#39;entreprise.

##### 16.14 Commission Nationale Informatique et Libertés (CNIL)

Si le Titulaire considère que Lemonway ne respecte pas ses obligations au regard de Loi Informatique et Libertés ou du Règlement Général sur la Protection des Données, il pourra adresser une plainte ou une demande auprès de l&#39;autorité compétente. Le siège social de Lemonway se situant en France, l&#39;autorité compétente est la Commission Nationale Informatique et Libertés. Le Titulaire a la possibilité de s&#39;adresser à la Commission Nationale Informatique et Libertés par voie électronique via le lien suivant : https://www.cnil.fr/fr/plaintes/internet.

#### 17. CONVENTION DE PREUVE

Les communications effectuées par le biais de courrier électronique sont des modes de communication valablement admis à titre de preuve par le Titulaire et Lemonway.

Toutes les informations enregistrées dans les bases de données informatiques de Lemonway relatives notamment aux Ordres et Opérations de paiement, ont, jusqu&#39;à preuve du contraire, la même force probante qu&#39;un écrit signé sur un support papier, tant en ce qui concerne leur contenu qu&#39;en ce qui concerne la date et l&#39;heure à laquelle ils ont été effectués et/ou reçus. Ces traces inaltérables, sûres et fiables sont gravées et conservées dans les systèmes informatiques de Lemonway.

Les documents de Lemonway reproduisant ces informations, ainsi que les copies ou reproductions de documents produits par Lemonway ont la même force probante que l&#39;original, jusqu&#39;à preuve du contraire.

#### 18. BLOCAGE ET GEL DU COMPTE

##### 18.1 Blocage du Compte de paiement

La suspension temporaire et immédiate d&#39;un Compte de paiement peut être prononcée par Lemonway pour toute raison à l&#39;appréciation de Lemonway et notamment :

- Si le Titulaire n&#39;a pas respecté les dispositions du Contrat-cadre ;
- Si le Titulaire a fourni à Lemonway des données d&#39;identification inexactes, périmées ou incomplètes ;
- En cas de risque sensiblement accru d&#39;incapacité par le Titulaire de s&#39;acquitter de son obligation de paiement ;
- En cas de réception par Lemonway d&#39;un nombre important de remboursements, d&#39;impayés, d&#39;annulation d&#39;Ordres ou de contestation pour Opérations de paiement non autorisées.

Cette décision est notifiée au Titulaire par tout moyen. La suspension du Compte de paiement ayant pour objet de protéger le Titulaire, elle ne pourra en aucun cas donner lieu au versement de dommages intérêts au profit de ce dernier.

La réactivation du Compte de paiement se fera à la discrétion de Lemonway, sur la base d&#39;informations ou de documents additionnels qui pourront être réclamés.

En fonction de la gravité du manquement au Contrat-cadre et notamment en cas d&#39;utilisation du Compte de paiement à des fins illicites ou contraire aux bonnes mœurs, Lemonway se réserve le droit de résilier le Contrat-cadre conformément aux dispositions de l&#39;article 19.

Lemonway se réserve le droit d&#39;appliquer des pénalités, des frais de gestion et de réclamer des dommages et intérêts au Titulaire.

##### 18.2 Gel du Compte de paiement

Dans le cadre du dispositif de filtrage mis en place par Lemonway (notamment basé sur les listes de sanctions internationales et de personnes politiquement exposées), le gel immédiat d&#39;un Compte de paiement pourra être prononcé en cas de risque de fraude, de blanchiment de capitaux ou financement du terrorisme ou de risque pouvant affecter la sécurité du Compte de paiement.

Le gel du Compte de paiement sera levé ou non après analyse approfondie du risque par les équipes LCB-FT de Lemonway, et les fonds gelés seront traités conformément aux mesures définies par la Direction générale du Trésor.

#### 19. RÉSILIATION DU CONTRAT-CADRE

Le Titulaire peut résilier de plein droit le Contrat-cadre qui emportera clôture de son Compte de paiement par tout moyen suivant le respect d&#39;un préavis d&#39;un (1) mois. Il doit maintenir une Provision suffisante pour assurer la bonne fin des Opérations de paiement en cours pendant le délai nécessaire à leur dénouement et le paiement des frais dus par lui.

Lemonway peut résilier de plein droit le Contrat-cadre qui emportera clôture du Compte de paiement par lettre recommandée avec avis de réception suivant un préavis de deux (2) mois.

En cas de manquement grave d&#39;une Partie, le Contrat-cadre peut être résilié avec effet immédiat. Il est entendu par manquements graves réalisés par le Titulaire : communication de fausses informations ; exercice d&#39;activité illégale, contraire aux bonnes mœurs, de blanchiment de capitaux ou de financement du terrorisme ; menaces à l&#39;encontre de préposés de Lemonway ou du Partenaire ; défaut de paiement ; non-respect d&#39;une obligation du Titulaire au titre des présentes ; résiliation des relations entre le Titulaire et le Partenaire ; résiliation des relations entre le Partenaire et Lemonway ; surendettement ou pour les personnes morales nomination d&#39;un mandataire ad hoc, d&#39;un administrateur judiciaire, ouverture d&#39;une procédure de redressement ou de liquidation. Il est entendu par manquements graves réalisés par Lemonway : communication de fausses informations ; irrespect d&#39;une obligation au titre des présentes ; nomination d&#39;un mandataire ad hoc, d&#39;un administrateur judiciaire, ouverture d&#39;une procédure de redressement ou de liquidation.

En cas de modification de la réglementation applicable et de l&#39;interprétation qui en est faite par l&#39;autorité de régulation concernée affectant la capacité de Lemonway ou de ses mandataires à exécuter les Opérations de paiement, le Contrat-cadre sera automatiquement résilié.

Le Titulaire ne pourra plus transmettre d&#39;Ordre de paiement à compter de la date d&#39;effet de la résiliation. Le Compte pourra être maintenu pendant une durée de 13 mois à l&#39;effet de couvrir les éventuelles contestations et réclamations ultérieures. Les Opérations de paiement initiées avant la date d&#39;effet de la résiliation ne seront pas remises en cause par la demande de résiliation et devront être exécutées dans les termes du Contrat-cadre.

La résiliation du Contrat-cadre entraîne la clôture définitive du Compte de paiement. La clôture d&#39;un Compte de paiement ne pourra donner lieu à aucune indemnité quels que soient les éventuels dommages occasionnés par la fermeture de ce Compte de paiement. Le Titulaire dont le Compte a été clôturé par Lemonway n&#39;est pas autorisé, sauf accord exprès de Lemonway, à ouvrir un autre Compte de paiement. Tout Compte de paiement ouvert en violation de cette disposition pourra être immédiatement clôturé par Lemonway, sans préavis.

La Provision sur le Compte de paiement objet de la clôture donnera droit à un virement au profit du Titulaire de ce compte suivant ses instructions sous réserve (i) des Opérations de paiement en cours et des éventuels impayés, rejets bancaires ou oppositions à venir et (ii) des mesures applicables au gel des avoirs telles que définies par la Direction Générale du Trésor. Si un successeur est désigné par Lemonway, il pourra être proposé au Titulaire de clôturer son Compte de paiement et de transférer la Provision sur un nouveau compte de paiement ouvert dans les livres de l&#39;établissement désigné comme successeur.

Lemonway se réserve le droit de demander en justice réparation du préjudice qu&#39;elle aurait subi du fait de la violation du Contrat-cadre. La clôture du Compte de paiement pourra donner lieu à des frais dans la limite de l&#39;article L. 314-13 du Code monétaire et financier.

#### 20. MODIFICATION DU CONTRAT-CADRE

Les dispositions du Contrat-cadre sont susceptibles d&#39;être modifiées ou complétées à tout moment, notamment en vue de se conformer à toute évolution législative, règlementaire, jurisprudentielle ou technologique.

Tout projet de modification du Contrat-cadre est communiqué sur support papier ou sur un autre support durable au Titulaire au plus tard deux (2) mois avant la date d&#39;application proposée pour son entrée en vigueur.

En l&#39;absence de contestation écrite par lettre recommandée avec avis de réception adressée à Lemonway par le Titulaire avant l&#39;expiration de ce délai de deux mois, ce dernier est réputé avoir accepté ces modifications. En cas de refus de la modification proposée, le Titulaire peut résilier sur demande écrite le Contrat-cadre sans frais, avant la date d&#39;entrée en vigueur proposée de celle-ci. Cette demande n&#39;affecte pas l&#39;ensemble des débits (frais, cotisations, paiement) dont le Titulaire reste redevable.

#### 21. GENERALITES

Au cas où des formalités administratives seraient nécessaires au titre de l&#39;exécution du présent Contrat-cadre, Lemonway et le Titulaire se prêteront mutuelle assistance pour la régularisation de ces formalités.

Si l&#39;une quelconque des stipulations non substantielles du Contrat-cadre est nulle au regard d&#39;une règle de droit en vigueur, elle sera réputée non écrite, mais n&#39;entraînera pas la nullité du présent Contrat-cadre.

Le fait pour l&#39;une des Parties de ne pas se prévaloir d&#39;un manquement par l&#39;autre Partie à l&#39;une quelconque des obligations visées dans les présentes ne saurait être interprété pour l&#39;avenir comme une renonciation à l&#39;obligation en cause.

En cas de difficulté d&#39;interprétation entre l&#39;un quelconque des titres et l&#39;une quelconque des clauses des conditions générales il ne sera pas tenu compte des titres.

Le présent Contrat-cadre a été rédigé en français et en anglais puis traduit dans d&#39;autres langues à titre d&#39;information uniquement. En cas de difficulté d&#39;interprétation, les versions françaises et anglaises du Contrat-cadre prévaudront sur toute autre traduction existante.

#### 22. DROIT APPLICABLE ET JURIDICTIONS

Le présent Contrat-cadre est régi par le droit français.

Sauf disposition impérative contraire, tout litige relatif à son exécution, son interprétation ou sa validité, sera porté devant les cours et tribunaux compétents et à défaut de Paris.

#


# ANNEXE 4 : CONDITIONS COMMERCIALES PLATEFORMES

#
**# CONDITIONS COMMERCIALES**

# PREAMBULE

La société PEKOIA, société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 508 763 950 dont le siège social est sis 13 bis avenue de la Motte Picquet 75007 (ci-après « _ **PEKOIA ** _» ou « _ **l&#39;Opérateur** _ ») édite et exploite la plateforme disponible à l&#39;adresse suivante _:_ [_www.pekoia.com_](http://www.pekoia.com/) (ci-après la &quot; _ **Plateforme** _ &quot;).

La Plateforme propose un service d&#39;intermédiation par lequel des personnes morales ou physiques susceptibles d&#39;intervenir sur un sujet donné (conférenciers, coachs, Organisateurs, guides, passionnés, professeurs, journalistes, associations, musées, entreprises…) (ci-après « _ **les Organisateurs** _ ») proposent à des professionnels et à des consommateurs (ci-après « _ **les Apprenants** _ ») des interventions (conférences, ateliers, visites guidées, masterclasses …) sur un sujet donné (ci-après « _ **les Evénements** _ »).

Dans ce cadre, il est rappelé que l&#39;Opérateur intervient en tant qu&#39;opérateur de plateforme en ligne. Le rôle de l&#39;Opérateur est limité à l&#39;hébergement des offres d&#39;Evénements des Organisateurs sur la Plateforme et à la mise en relation des Organisateurs et des Apprenants.

L&#39;Evénement est régi prioritairement par la Convention d&#39;Evénement proposée par l&#39;Organisateur.

Toutefois les présentes Conditions Commerciales de la Plateforme, qui ont vocation à harmoniser les politiques commerciales des Organisateurs au regard des contraintes techniques de la Plateforme, s&#39;appliqueront prioritairement en cas de lacune ou de contradiction entre la Convention d&#39;Evénement et des Conditions Commerciales de la Plateforme.

# SECTION 1. DÉFINITIONS

Les termes mentionnés ci-dessous ont les significations suivantes dans les présentes conditions générales de la plate-forme :

- «  **Apprenant**  » : désigne toute personne ayant le statut de professionnel ou de consommateur, qui s&#39;inscrit à un Evénement sur la Plateforme.

- **« Contenus »** : désigne l&#39;ensemble des informations, Supports de présentation, textes, logos, marques, animations, dessins et modèles, photographies, données, liens hypertextes, et de façon générale tous les éléments et contenus de l&#39;Organisateur publiés durant un Evénement (via la Plateforme, dans l&#39;endroit où se déroule l&#39;Evénement, ou via l&#39;Interface vidéo) selon les modalités, la forme et les conditions qui lui sont proposées dans le cadre des Services.

- &quot; **Compte**&quot; : désigne l&#39;interface hébergée sur la Plateforme dans laquelle sont regroupées toutes les données fournies par l&#39;Apprenant, lui permettant de gérer ses Inscriptions. L&#39;accès au Compte se fait par l&#39;intermédiaire des Identifiants.

- **« Conditions Commerciales »**  : désigne les présentes Conditions commerciales applicables aux transactions réalisées via la Plateforme et pouvant, le cas échéant, compléter, voire remplacer (en cas de lacune ou de contradiction), les Conventions d&#39;Evénement.

- **« Conditions de Vente »**  : désigne l&#39;ensemble contractuel encadrant l&#39;Inscription de l&#39;Apprenant à un Evénement. Les Conditions de Vente sont composées de :
  - La Fiche Descriptive de l&#39;Evénement ;
  - La Convention d&#39;Evénement de l&#39;Organisateur
  - Les présentes Conditions Commerciales de la Plateforme, pouvant s&#39;appliquer en cas de lacune ou de contradiction de la Convention d&#39;Evénement de l&#39;Organisateur avec les Conditions Commerciales.

- **« Conditions Générales d&#39;Utilisation »** ou «  **CGU**  » : désigne les conditions contractuelles mises à disposition sur la Plateforme afin d&#39;encadrer l&#39;utilisation de celle-ci et des Services par tout Utilisateur.

- «  **Conditions Générales de Services** ou **CGS » :** désigne les conditions contractuelles encadrant la fourniture de Services de la Plateforme par l&#39;Opérateur aux Organisateurs.

- &quot; **Consommateur**&quot; : désigne toute personne physique qui agit à des fins qui n&#39;entrent pas dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, conformément à l&#39;article liminaire du Code de la consommation.

- **« Convention d&#39;Evénement » :** désigne la convention régissant tant l&#39;Inscription d&#39;un Apprenant à un Evénement que la tenue de l&#39;Evénement en lui-même.

- **« Evénement »**  : désigne toute intervention, privée ou publique, gratuite ou payante, préenregistrée ou instantanée, physique ou à distance, proposée par un Organisateur sur la Plateforme. L&#39;Evénement peut être virtuel et avoir lieu via une Interface vidéo ou avoir lieu physiquement, dans le cadre d&#39;un événement organisé.

- **« Fiche Descriptive »**  : désigne la présentation d&#39;un Evénement comportant notamment l&#39;ensemble des détails de l&#39;Evénement, son programme, les modalités d&#39;Inscription, voire d&#39;annulation, la présentation de l&#39;Organisateur ou de l&#39;Evénement et de manière plus générale toutes les informations légalement obligatoires.

- «  **Identifiants**  » : désigne l&#39;adresse e-mail de l&#39;Organisateur ou de l&#39;Apprenant et le mot de passe défini par ce dernier, nécessaires à l&#39;accès à son Compte sur la Plateforme.

- &quot; **Inscription**&quot; : désigne l&#39;acte d&#39;achat d&#39;une place à un Evénement, par un Apprenant, auprès de l&#39;Organisateur, via la Plateforme.

- **« Intervenant »**  : désigne la personne physique intervenant pour animer oralement l&#39;Evénement. L&#39;Intervenant peut être l&#39;Organisateur lui-même ou intervenir pour le compte d&#39;un Organisateur, intervenant alors comme conférencier Intervenant.

- **« Interface vidéo »**  : désigne l&#39;outil logiciel utilisé par l&#39;Organisateur pour organiser et tenir des Evénements à distance.

- **« Organisateur »**  : désigne la personne physique ou morale organisant un Evénement et pouvant pour cela faire appel à un Intervenant. L&#39;Organisateur peut, ou non, agir dans le cadre de son activité professionnelle habituelle.

- **« Parties »** : au pluriel, désigne ensemble l&#39;Apprenant et l&#39;Organisateur. Au singulier, désigne une seule des deux Parties.

- **« Plateforme »** : désigne la plateforme en ligne accessible à l&#39;adresse [_www.pekoia.com_](http://www.pekoia.com/)_._ La Plateforme regroupe l&#39;ensembledes pages web, Services et fonctionnalités proposés aux Utilisateurs.

- «  **Prestataire de Services de Paiement**  » ou «  **PSP** »: désigne la société, détentrice d&#39;un agrément bancaire, fournissant, par l&#39;intermédiaire de l&#39;Opérateur, des services de paiement aux Organisateurs afin de leur permettre d&#39;encaisser les paiements des Apprenants. Le PSP est LEMONWAY, société par actions simplifiée dont le siège social est situé au 8 rue du Sentier, 75002 Paris, France, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 500 486 915 («  **Lemonway**  » ), agréée le 24 décembre 2012 par l&#39;Autorité de Contrôle Prudentiel et de Résolution (« ACPR », France, site internet http://acpr.banque-france.fr/), 4 place de Budapest CS 92459, 75436 Paris, en qualité d&#39;Etablissement de Paiement hybride.

- **&quot; Professionnel &quot;** : désigne toute personne physique ou morale, publique ou privée, agissant à des fins entrant dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, y compris lorsqu&#39;elle agit au nom ou pour le compte d&#39;un autre professionnel, conformément à l&#39;article liminaire du Code de la consommation.

- **« Services » :** désigne l&#39;ensemble des services proposés par l&#39;Opérateur aux Utilisateurs par l&#39;intermédiaire de la Plateforme et notamment la mise en relation des Organisateurs et des Apprenants.

- **« Support de présentation »**  : désigne le document soutenant la présentation orale de l&#39;Organisateur, présenté aux Apprenants dans le cadre d&#39;un Evénement.

- «  **Utilisateur**  » : désigne toute personne qui accède et navigue sur la Plateforme, qu&#39;il soit Organisateur, Apprenant ou simple internaute.

# SECTION 2. OBJECTIF

L&#39;Inscription à un Evénement est régie par les Conditions de vente, composées de :

  - La Fiche Descriptive de l&#39;Evénement ;
  - La Convention d&#39;Evénement de l&#39;Organisateur
  - Les présentes Conditions Commerciales de la Plateforme, pouvant s&#39;appliquer en cas de lacune ou de contradiction (avec elles) de la Convention d&#39;Evénement de l&#39;Organisateur.

Lors de son inscription sur la Plateforme, l&#39;Organisateur est censé fournir sa propre Convention d&#39;Evénement. La Convention d&#39;Evénement de l&#39;Organisateur doit respecter l&#39;ensemble des mentions obligatoires prévues par les articles L. 221-5 et suivants du code de la consommation ainsi que les présentes Conditions Commerciales de la Plateforme.

Les présentes Conditions Commerciales de la Plateforme sont proposées afin d&#39;harmoniser la politique commerciale applicable à la vente d&#39;Evénements sur la Plateforme.

Ainsi, les Conditions Commerciales de la Plateforme ne s&#39;appliquent que dans le cas où les Conditions de Vente de l&#39;Organisateur sont incomplètes, non conformes aux présentes Conditions Commerciales de la Plateforme, ou absentes.

# SECTION 3. L&#39;ACCEPTATION DES CONDITIONS COMMERCIALES DE LA PLATEFORME

L&#39;Apprenant doit lire attentivement les présentes Conditions Commerciales de la Plateforme et les accepter avant de procéder au paiement de son Inscription à un Evénement. Il est à ce titre invité à les télécharger et les imprimer ainsi qu&#39;à en conserver une copie.

L&#39;Apprenant doit lire les présentes Conditions Commerciales de la Plateforme pour chaque nouvelle Inscription, la dernière version des Conditions Commerciales de la Plateforme s&#39;appliquant à chaque nouvelle Inscription à un Evénement.

En cliquant pour confirmer l&#39;Inscription, l&#39;Apprenant reconnaît avoir lu, compris et accepté les présentes Conditions Commerciales de la Plateforme sans limitation ni condition.

# SECTION 4. L&#39;INFORMATION RELATIVE A l&#39;APPRENANT

L&#39;Apprenant sera invité à fournir des informations permettant de l&#39;identifier en remplissant le formulaire disponible sur la Plateforme. Le signe (\*) indique les champs obligatoires qui doivent être remplis pour que l&#39;Inscription de l&#39;Apprenant soit traitée par l&#39;Organisateur.

Les informations que l&#39;Apprenant fournit à l&#39;Organisateur lors de l&#39;Inscription à un Evénement doivent être complètes, exactes et à jour. L&#39;Organisateur se réserve le droit de demander à l&#39;Apprenant de confirmer, par tout moyen approprié, son identité et, le cas échéant, les informations fournies.

L&#39;Organisateur ne saurait être tenu responsable des conséquences liées à la fourniture d&#39;informations erronées par l&#39;Apprenant.

# SECTION 5. INSCRIPTIONS

## 5.1 **Caractéristiques des Evénements**

Les offres d&#39;Evénement sont publiées par les Organisateurs, sous leur seule responsabilité, l&#39;Opérateur se contentant d&#39;héberger les Fiches Descriptives des Evénements.

Chaque Organisateur s&#39;efforce de présenter le plus clairement possible dans les Fiches Descriptives les caractéristiques principales des Evénements et les informations obligatoires que l&#39;Apprenant doit recevoir en vertu du droit applicable. L&#39;Apprenant doit les lire attentivement avant de passer commande sur la Plateforme.

L&#39;Organisateur s&#39;engage à afficher son statut de professionnel ou de non professionnel au sens de la réglementation française et européenne. Il est à ce titre rappelé que tout Organisateur professionnel se faisant passer pour un Organisateur consommateur encourra les peines prévues à l&#39;article [L.132-2 du Code de la Consommation](https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032227120/), liées aux pratiques commerciales trompeuses.

 ## 5.2 **Selection des Evénements**

Après en avoir pris connaissance, l&#39;Apprenant, sous sa seule responsabilité et en fonction de ses propres besoins, fait son choix des Evénements auxquels il souhaite s&#39;inscrire. La responsabilité de l&#39;Organisateur ne peut en aucun cas être engagée à cet égard.

## 5.3 **Identification**

Lorsque l&#39;Apprenant clique sur un bouton d&#39;inscription dans une fiche Evénement, celui-ci est invité à s&#39;identifier selon le processus décrit dans les Conditions d&#39;Utilisation de la Plateforme.

## 5.4 **Validation de l&#39;Inscription**

Une fois les Evénements sélectionnés et les données d&#39;identification renseignées, l&#39;Apprenant se verra présenter un récapitulatif de l&#39;Inscription, comprenant les informations relatives aux Evénements, les prix et les quantités.

L&#39;Apprenant alors :

- Remplit les détails de la facturation ;
- Spécifie les informations relatives au paiement ;
- Le cas échéant, renonce expressément à son droit de rétractation ;
- Déclare avoir pris connaissance et accepter les Conditions de Vente ;
- Cliquez sur &quot;Effectuer le paiement&quot; pour finaliser l&#39;achat.

L&#39;Apprenant reçoit par email une confirmation de l&#39;enregistrement de son Inscription.

L&#39;Inscription est automatiquement acceptée dès le paiement par l&#39;Apprenant et ne nécessite pas de validation par l&#39;Organisateur.

## 5.5 **Disponibilité des Evénements**

L&#39;Organisateur s&#39;engage à honorer les Inscriptions reçues sous réserve de la disponibilité d&#39;un Evénement.

L&#39;indisponibilité d&#39;un Evénement est normalement indiquée au moment de l&#39;Inscription par l&#39;Apprenant sur la Plateforme.

En tout état de cause, si l&#39;indisponibilité n&#39;a pas été indiquée au moment de l&#39;Inscription, l&#39;Organisateur s&#39;engage à informer sans délai l&#39;Apprenant en cas d&#39;indisponibilité de l&#39;Evénement.

Dans ce cas, l&#39;Apprenant sera remboursé de la totalité des sommes versées pour l&#39;Evénement indisponible au plus tard dans les trente (30) jours du paiement.

## 5.6 **Date d&#39;Inscription**

La date d&#39;Inscription ainsi que la date, l&#39;heure, les indications pour se connecter (événement à distance) et le lieu de l&#39;Evénement sont communiqués à l&#39;Apprenant sur la Plateforme et/ou par e-mail, sous réserve que l&#39;adresse e-mail communiquée par le biais du formulaire d&#39;inscription soit correcte.

## 5.7 **Organisation de l&#39;Evénement**

Quel que soit le type d&#39;Evénement, l&#39;Organisateur conserve une totale indépendance dans l&#39;organisation et la réalisation de l&#39;Evénement. Il peut accomplir tous les actes requis par ses obligations professionnelles et, le cas échéant, nécessaires au respect de sa déontologie.

L&#39;organisation et les obligations des Parties d&#39;un Evénement sont régies par la Convention d&#39;Evénement, qui peut être complétée par les présentes Conditions commerciales en cas de lacune ou de contradiction avec les présentes.

## 5.8 **Accès à l&#39;Evénement**

- **Evénement à distance**

L&#39;Apprenant se voit remettre par courriel la procédure d&#39;accès ou un lien lui permettant d&#39;accéder à l&#39;Interface vidéo hébergeant l&#39;Evénement.

L&#39;heure à laquelle l&#39;Apprenant pourra accéder à l&#39;Evénement sera indiquée dans le courriel de confirmation d&#39;inscription.

L&#39;Apprenant s&#39;engage à respecter les conditions générales d&#39;utilisation de l&#39;Interface vidéo utilisée par l&#39;Organisateur.

- **Evénement physique**

A l&#39;issue de son inscription, l&#39;Apprenant recevra un courriel récapitulant l&#39;ensemble des informations nécessaires pour accéder et assister à l&#39;Evénement.

## 5.9 **Obligations de l&#39;Apprenant**

Outre les Conditions de vente, l&#39;Apprenant s&#39;engage à se conformer à l&#39;ensemble des instructions et autres obligations qui lui auront été transmises par l&#39;Organisateur.

L&#39;Apprenant s&#39;engage à cet égard à respecter l&#39;ensemble des dispositions des Conditions Générales d&#39;Utilisation de la Plateforme, y compris dans l&#39;hypothèse où il serait amené à suivre l&#39;Evénement en dehors de la Plateforme, via l&#39;Interface vidéo.

# SECTION 6. PRIX ET PAIEMENT

L&#39;Organisateur, dans le respect des lois et règlements applicables, définit librement les prix de vente des Evénements.

Les prix des Evénements payants proposés à la vente par les Organisateurs sur la Plateforme sont exprimés en euros toutes taxes comprises.

Ces prix comprennent tous les coûts associés et l&#39;ensemble des taxes applicables.

Le prix applicable est le prix indiqué sur la Plateforme à la date à laquelle l&#39;Inscription est effectuée par l&#39;Apprenant.

# SECTION 7. PAIEMENT

Les paiements sont gérés par le Prestataire De Services De Paiement.

Le paiement des Evénements par l&#39;Apprenant sur la Plateforme peut s&#39;effectuer :

- Par carte bancaire ;

Le compte bancaire de l&#39;Apprenant sera débité directement après la validation de l&#39;Inscription.

Dans le cas où, pour quelque raison que ce soit, opposition, refus ou autre, la transmission du flux monétaire dû par l&#39;Apprenant s&#39;avère impossible, la commande d&#39;Evénement sera annulée et la vente résiliée ou annulée de plein droit. Le remboursement aura alors lieu dans les trente (30) jours suivants.

# SECTION 8. DROIT DE RETRACTATION

**Lorsqu&#39;une réservation est effectuée entre un Organisateur professionnel et un Apprenant consommateur** , ce dernier peut bénéficier du droit de rétractation prévu aux articles L.221-18 et suivants du Code de la consommation.

Le délai de rétractation court à compter de la validation de l&#39;Inscription de l&#39;Apprenant.

Toutefois, et uniquement en ce qui concerne les Evénements débutant avant l&#39;expiration du délai de rétractation de quatorze (14) jours, le droit de rétractationne s&#39;applique pas :

- En cas d&#39;Inscription d&#39;un Apprenant n&#39;ayant pas la qualité de consommateur au sens de la réglementation française et européenne ;
- Dans l&#39;hypothèse où l&#39;Organisateur n&#39;organiserait pas l&#39;Evénement en tant que professionnel, dans le cadre de son activité professionnelle habituelle ;
- Lorsque l&#39;Apprenant a renoncé expressément à son droit de rétractation conformément à l&#39;article 221-28 du Code de la consommation qui prévoit que le droit de rétractation ne s&#39;applique pas en cas de «fourniture de services pleinement exécutés avant la fin du délai de rétractation et dont l&#39;exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation. » ;
- Lorsque l&#39;Apprenant commande un contenu numérique non fourni sur un support matériel, dont l&#39;exécution a commencé après accord préalable de l&#39;Apprenant et renoncement à son droit de rétractation (L.221-28, 13° du Code de la consommation) ;
- Lorsque l&#39;Evénement auquel s&#39;est inscrit l&#39;Apprenant peut être assimilé à une activité de loisir (L.221-28, 12° du Code de la consommation).

# SECTION 9. HYPOTHESES DE MODIFICATION OU D&#39;ANNULATION

 ## 9.1 **Modification**

_L&#39;Organisateur pourra exceptionnellement modifier son Evénement, que ce soit dans le contenu ou dans les modalités d&#39;accès (lieu, date, horaire)._

_Dans un tel cas de figure, l&#39;Apprenant s&#39;étant inscrit à l&#39;Evénement sera notifié des modifications. Il pourra alors librement annuler, sans frais, son Inscription._

## 9.2 **Annulation**

L&#39;Apprenant pourra uniquement annuler son Inscription conformément à la Fiche Descriptive de l&#39;Evénement et/ou à la Convention d&#39;Evénement régissant l&#39;Evénement choisi.

Ainsi, dans l&#39;hypothèse où l&#39;Organisateur offrait la possibilité d&#39;annuler son Inscription avant la tenue de l&#39;Evénement, l&#39;Apprenant pourra être remboursé de tout ou partie du coût de l&#39;Inscription, en fonction des modalités détaillées par l&#39;Organisateur. Le remboursement total ou partiel de l&#39;Apprenant sera alors réalisé par l&#39;intermédiaire du Prestataire de Services de paiement, conformément aux modalités précisées par la Convention d&#39;Evénement et/ou la Fiche Descriptive de l&#39;Evénement.

Sous réserve de ce que l&#39;Organisateur propose dans la Convention d&#39;Evénement et/ou dans la Fiche Descriptive, aucune hypothèse d&#39;annulation ne pourra donner lieu à remboursement, sauf en cas :

- d&#39;annulation de l&#39;Evénement par l&#39;Organisateur,
- de cas de force majeure empêchant la tenue de l&#39;Evénement
- ou d&#39;impossibilité de tenir l&#39;Evénement.

En tout état de cause, l&#39;Apprenant pourra toujours annuler sans frais son Inscription dans l&#39;hypothèse où celle-ci serait gratuite.

#


#


# SECTION 10. PROPRIETE INTELLECTUELLE

Sauf mention contraire dans la Fiche Descriptive de l&#39;Evénement ou dans la Convention d&#39;Evénement, l&#39;Organisateur reste seul propriétaire des droits de propriété intellectuelle portant sur les Contenus et les Supports de présentation qu&#39;il diffuse lors de l&#39;Evénement, que ce dernier ait lieu directement sur la Plateforme, dans un lieu physique, ou via une Interface vidéo.

Ces droits de propriété intellectuelle restent donc la propriété exclusive de l&#39;Organisateur et ne font l&#39;objet d&#39;aucune cession d&#39;aucune sorte au profit de l&#39;Apprenant qui s&#39;interdit de les utiliser, sous peine de poursuites judiciaires.

Par conséquent, sous réserve du contenu de la Fiche Descriptive et/ou de la Convention d&#39;Evénement,il est strictement interdit à l&#39;Apprenant de : Enregistrer, distribuer, transmettre, diffuser, modifier, vendre, louer, concéder des licences ou exploiter, de quelque manière et par quelque moyen que ce soit, sans l&#39;accord exprès préalable et écrit de l&#39;Organisateur, tout ou partie des Contenus utilisés par l&#39;Organisateur lors d&#39;un Evénement.

# SECTION 11. RESPONSABILITÉ

## 11.1 **Litiges**

**IMPORTANT**

**L&#39;Opérateur agit en tant que simple intermédiaire entre l&#39;Organisateur et l&#39;Apprenant dans le cadre de l&#39;Inscription à un Evénement sur la Plateforme. A ce titre, l&#39;Opérateur n&#39;est pas responsable de tout manquement imputable à l&#39;Organisateur.**

**Conformément aux Conditions Générales d&#39;Utilisation** , en cas de réclamation, l&#39;Apprenant s&#39;adresse en priorité à l&#39;Organisateur. Tout litige survenant entre un Apprenant et un Organisateur doit être traité entre eux, l&#39;Organisateur restant seul responsable de l&#39;exécution de la vente d&#39;Evénements réalisée via la Plateforme.

Concernant toute réclamation faite par un Apprenant, l&#39;Organisateur concerné sera notifié via le Compte de sa Plateforme. Il recevra également un courriel à l&#39;adresse électronique fournie dans le Compte.

Si la résolution d&#39;un litige ne peut être trouvée entre l&#39;Organisateur et l&#39;Apprenant dans un délai de soixante douze (72) heures à compter de la réception de la plainte, l&#39;Opérateur peut être désigné par les Parties pour agir en tant que médiateur entre elles.

En outre, dans tous les cas, l&#39;Organisateur et l&#39;Apprenant s&#39;engagent à rechercher un règlement amiable du litige.

Si les Parties conviennent de rembourser l&#39;Apprenant, ce remboursement interviendra dans les 30 jours de sa décision par les Parties.

 ## 11.2 **Responsabilité de l&#39;Organisateur**

L&#39;Organisateur est responsable de l&#39;organisation pratique de son Evénement mais aussi du contenu des informations et autres Contenus qui y sont divulgués, conformément aux Conditions Générales d&#39;Utilisation de la Plateforme.

Concernant les Evénements physiques, l&#39;Organisateur sera responsable de l&#39;organisation et de la tenue de l&#39;Evénement et notamment de la sécurité physique des Apprenants.

A cet égard, l&#39;Organisateur non professionnel, engagera sa responsabilité en cas de mauvaise exécution de l&#39;Evénement, conformément aux dispositions des [articles 1217 à 1231-7 du Code civil](https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000006070721/LEGISCTA000006150254/#LEGISCTA000032041441). A défaut de contrat écrit, la fourniture de l&#39;Evénement sera régie, par le droit commun, principalement prévu dans le [Code Civil aux articles 1101 à 1237-1](https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000006070721/LEGISCTA000032006712/#LEGISCTA000032006712).

Il est précisé qu&#39;en aucun cas la responsabilité de l&#39;Organisateur ne pourra être engagée en cas d&#39;inexécution ou de mauvaise exécution des obligations contractuelles imputables à l&#39;Apprenant, notamment lors de son Inscription.

L&#39;Organisateur ne sera pas tenu pour responsable, ou considéré comme ayant failli aux présentes, pour tout retard ou inexécution, lorsque la cause du retard ou de l&#39;inexécution est liée à un cas de force majeure tel que défini par la jurisprudence des tribunaux français.

# SECTION 11. DONNÉES PERSONNELLES

Dans le cadre de l&#39;exécution des présentes CGV, l&#39;Organisateur traite les données personnelles de l&#39;Apprenant. A cet égard, l&#39;Organisateur garantit le respect des dispositions du Règlement général sur la protection des données (ci-après &quot; RGPD &quot;).

L&#39;Organisateur a la qualité de responsable du traitement des données. Par conséquent, l&#39;Organisateur s&#39;engage à :

- Communiquer les données personnelles de l&#39;Apprenant au seul personnel autorisé ;
- Ne pas transférer les données personnelles de l&#39;Apprenant en dehors de l&#39;Union européenne ;
- Conserver les données personnelles de l&#39;Apprenant pendant, au moins, la durée d&#39;exécution du présent Contrat et dans des archives sécurisées, pour les périodes prévues légalement.

Conformément aux dispositions du RGPD, l&#39;Apprenant dispose d&#39;un droit d&#39;accès, de rectification et de suppression de ses données. Il dispose également du droit de limiter le traitement de ses données, du droit de s&#39;opposer au traitement et du droit à la portabilité de ses données.

L&#39;Apprenant a également le droit de donner des instructions quant au sort de ses données post-mortem. Ces droits peuvent être exercés à l&#39;adresse suivante : _contact@pekoia.com_

L&#39;Opérateur traite les données personnelles de l&#39;Apprenant conformément à sa Politique de confidentialité, accessible en bas de chaque page de la Plateforme.

# SECTION 12. LOI APPLICABLE ET JURIDICTION

Les présentes conditions générales de la plate-forme sont soumises au droit français.

A défaut d&#39;accord, y compris après que l&#39;Opérateur est intervenu en tant que médiateur entre les Parties, il sera proposé une procédure de médiation facultative, conduite dans un esprit de loyauté et de bonne foi en vue de parvenir à un accord amiable lors de la survenance de tout litige relatif au présent contrat, y compris celui concernant sa validité.

Pour engager cette médiation, l&#39;Apprenant peut contacter le médiateur de l&#39;Organisateur, identifié dans la Convention d&#39;Evénement.

A défaut, l&#39;Apprenant pourra contacter le médiateur choisi par l&#39;Opérateur : XXXX dont les coordonnées sont : XXXX et qui peut être contacté via ce lien : XXXX

La partie qui souhaite mettre en œuvre le processus de médiation doit au préalable informer l&#39;autre partie par lettre recommandée avec accusé de réception, en indiquant les éléments du conflit.

La médiation n&#39;étant pas obligatoire, l&#39;Apprenant ou l&#39;Organisateur peut se retirer du processus à tout moment.

EN CAS D&#39;ÉCHEC DE LA MÉDIATION OU SI ELLE N&#39;EST PAS ENVISAGÉE, LE LITIGE QUI AURAIT PU DONNER LIEU À UNE MÉDIATION EST PORTÉ DEVANT LA JURIDICTION COMPÉTENTE.`



const Cgs = () => {

 
    return (<div className="px-100">
        <MarkdownStyles>
            <ReactMarkdown>
                {textContent}
            </ReactMarkdown>
                
        </MarkdownStyles>

    </div>)
}

export default Cgs