import { LightenDarkenColor } from 'lighten-darken-color'
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors'
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/300.css"



export const colorBlue = "#273370"
const colorYellow = "#F7B643"
export const colorTaupe = LightenDarkenColor("#908D88", -60)
export const colorTaupeClair = LightenDarkenColor("#BCB9B4", 40)
export const colorTauoeMoyenClair = LightenDarkenColor(colorTaupeClair, 30)
export const colorTaupeEncorePlusClair = LightenDarkenColor(colorTaupeClair, 70)

export const colorGray = "#FAFAFA"

export const colorSecondaryLight = LightenDarkenColor("#F9F6F0", 0)
export const mainPageHorizontalSeparator = "5vw"




const theme = createTheme({
    typography: {

        fontFamily: [
            'Montserrat',
            'Roboto',
            "Helvetica",
            'serif',
        ].join(',')

    },
    spacing: factor => `${0.25 * factor}rem`,


    palette: {
        primary: grey,

        secondary: {
            light: colorSecondaryLight,
            main: "#E9EEFD",
            dark: "#2A3580",
            contrastText: "white"
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
    },

    components: {

        MuiButton: {
            styleOverrides: {
                label: {
                    color: "inherit"
                },
                "&:frontPage": {
                    color: "white"
                }

            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    width: "100vw",
                    display: "flex",
                    padding: "0px",
                    boxShadow: "5px 10px",

                },
                colorPrimary: {
                    backgroundColor: colorTaupe
                }

            }
        },
        MuiDataGrid: {
            styleOverrides: {
                cellCheckbox: {
                    color: 'r'
                }
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        padding: '0.75vw 0vw 0.25vw 0vw',
                        display: "flex",
                        lineHeight: "1.2rem",
                        flexDirection: "column",
                        backgroundColor: colorTaupeClair,
                        color: "black"
                    },

                    button: {
                        '&&': {
                            padding: '0.75vw 0vw 0.25vw 0vw',
                            display: "flex",
                            lineHeight: "1.2rem",
                            flexDirection: "column",
                            backgroundColor: colorTaupeClair,
                            color: "black"
                        },

                        '&&:hover': {
                            backgroundColor: colorTaupeEncorePlusClair


                        },
                        '&&:focus': {
                            backgroundColor: colorTauoeMoyenClair,
                            borderLeft: `5px solid ${colorTaupeClair}`
                        }
                    },
                    gutters: {
                        backgroundColor: colorSecondaryLight,
                        padding: "1.5rem 0 1.5rem 0",
                        color: '#808080',
                        cursor: "pointer",
                        bgcolor: "inherit",
                        "&:hover": {
                            color: "black"
                        }
                    }

                },
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        '@media (min-width: 600px)': {
                            paddingLeft: 0,
                            paddingRight: 0,
                        },
                        padding: "0px",
                        margin: "0px",
                        paddingLeft: "0px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        paddingRight: "0px",
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        "& h1": {
                            fontSize: "3rem",
                            color: colorBlue,

                        },
                        "& h2": {
                            color: colorYellow
                        },

                    },
                    h1: {
                        color: '#001d1d',
                        fontSize: "1.0rem",
                        fontWeight: 600,
                        marginBottom: "1vw"
                    },
                    h3: {
                        backgroundColor: "inherit",
                        "& toto": {
                            margin: "0.2vw",
                            padding: "1vw",
                        
                        }
                    }
                }
            }



        }
    }})

    theme.typography.root = {
        fontSize: '2.0rem',
        fontWeight: 300,
       
        '@media (min-width:600px)': {
          fontSize: '1.0rem',
          fontWeight: 300,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.0rem',
          fontWeight: 300,
        },
      }

 


    theme.typography.h1 = {
        fontSize: '1.0rem',
        color: '#aa1d1d',
        fontWeight: 500,
        marginBottom: "10px",
        '@media (min-width:600px)': {
          fontSize: '1.0rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.0rem',
        },
      }

      theme.typography.body1 = {
        fontSize: '1.0rem',
        fontWeight: 300,
       
        '@media (min-width:600px)': {
          fontSize: '1.0rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.0rem',
        },
      }


      theme.typography.h3 = {
        fontSize: '1.0rem',
        backgroundColor: "inherit",
        margin: "0.2vw",
        padding: "1vw",
       
        '@media (min-width:600px)': {
          fontSize: '1.0rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.0rem',
        },
      }

export default theme

