import { Button, Tooltip, Typography } from "@mui/material"
import { green, grey, orange, red } from "@mui/material/colors"
import { Box } from "@mui/system"
import { API, graphqlOperation, Storage } from "aws-amplify"
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { listPublications, listSalons } from "../../graphql/queries"
import styled from 'styled-components';
import { DataGridPro } from "@mui/x-data-grid-pro"
import { Adjust, CoPresent } from "@mui/icons-material";
import withStyles from '@mui/styles/withStyles';
import SalonForm from "./salonForm";
import { createSalon, deletePublication, deleteSalon, updateSalon } from "../../graphql/mutations";
import { handleClose01 } from "../handleClose";
import { uploadImageBatch, uploadImageBatchV2 } from "../helpers/batches";
import { v4 as uuid_v4 } from "uuid"
import DeleteIcon from '@mui/icons-material/Delete';
import { ColorButton } from "../../components/BO-style";
import EditIcon from '@mui/icons-material/Edit';

const emptyForm = {
	id: '',
	image: '',
	title: '',
	htmlDocument: '',
	state: 'OFFLINE',
}

const EspaceSalon = ({ creator }) => {
  const data = useSelector(state => state.dataReducer)
  const [salons, setSalons] = useState([])
	const [status, setStatus] = useState('create')
  const [rows, setRows] = useState([])
  const [isSalonForm, setIsSalonForm] = useState(false)
  const [formData, setFormData] = useState(emptyForm)
	const [htmlDocument, setHtmlDocument] = useState('')
	const [publicationsData, setPublicationsData] = useState([])
  const [showButton, setShowButton] = useState(false)
	const [company, setCompany] = useState(null)

  useEffect(() => {
		fetchSalons()
  }, [data, creator])

	const fetchSalons = () => {
		const filter = { filter: { [creator]: { eq: creator === "organisateurId" ? data.companies[0].id : data.id } } }
		API.graphql(graphqlOperation(listSalons, filter))
			.then((res) => {
				setSalons(res.data.listSalons.items)
			})
			.catch((err) => console.log(err))
			setFormData({...formData, [creator]: creator === "organisateurId" ? data.companies[0].id : data.id})
	}

	const publishSingleAction = (id) => {
		API.graphql(graphqlOperation(updateSalon, { input: { id: id, state: "ONLINE"}}))
			.then((res) => {
				fetchSalons()
			})
			.catch(err => console.log(err))
	}

	const unpublishSingleAction = (id) => {
		API.graphql(graphqlOperation(updateSalon, { input: { id: id, state: "OFFLINE"}}))
			.then((res) => {
				fetchSalons()
			})
			.catch(err => console.log(err))
	}

	const editSalon = (id) => {
		setIsSalonForm(true)
		setStatus('edit')
		setFormData({...formData, id: id})
		setHtmlDocument('')
	}

	const deleteSalonFunc = (id) => {
		API.graphql(graphqlOperation(deleteSalon, { input: { id: id }}))
			.then((res) => {
				API.graphql(graphqlOperation(listPublications, { filter: { salonId: { eq: id }}}))
				.then((res) => {
					res.data.listPublications.items.forEach((e) => {
						API.graphql(graphqlOperation(deletePublication, { input: { id: e.id }}))
					})
				})
				.catch(err => console.log(err))
				fetchSalons()
			})
			.catch(err => console.log(err))
	}

	const getBase64Image = async (image) => {
		if (!image) {
			return
		}
		const split = image.split('.')
		const key = split[0]
		const ext = split[1]
		const url = await Storage.get(`${creator === "organisateurId" ? data.companies[0].id : data.id}/salons/S${key}.${ext}`, { level: 'private', contentType: `image/${ext}` })
		const response = await fetch(url)
		const blob = await response.blob()
		const res = await new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(blob)
			reader.onloadend = () => {
				resolve(reader.result)
			}
		})
		return res
	}

  useEffect(() => {
    const getRows = async () => {
      let tmp = []
      for (const e of salons) {
        const res = await API.graphql(graphqlOperation(listPublications, { filter: { salonId: { eq: e.id } } }))
        tmp.push({
          id: e.id,
          image: e.image,
					base64image: await getBase64Image(e.image),
          title: e.title,
          state: e.state,
          nbPubli: res.data.listPublications.items.length
        })
      }
      setRows(tmp)  
    }
    getRows()
  }, [salons])

	const saveSalonImage = (key, imageStore) => {
		uploadImageBatch(imageStore, (creator === "organisateurId") ? data.companies[0].id : data.id, key, "salons")
	}

	const handleSave = () => {
		const operation = status === 'create' ? createSalon : updateSalon
		let key = uuid_v4()
		const imageStore = formData.imageStore
		let tmpData = {}
		if (imageStore) {
			tmpData = {...formData, image: `${key}.${imageStore.compressedL.name.split('.')[1]}`, htmlDocument: htmlDocument}
		} else {
			tmpData = {...formData, htmlDocument: htmlDocument}
		}
		imageStore && delete tmpData.imageStore
		if (status === 'create') {
			delete tmpData.id
			delete tmpData.categories
		}

		API.graphql(graphqlOperation(operation, { input: tmpData }))
			.then((res) => {
				const salonId = status === 'create' ? res.data.createSalon.id : res.data.updateSalon.id
				saveSalonImage(key, imageStore)
				if (status === 'create') {
					let tmpPubli = []
					publicationsData.forEach((e) => tmpPubli.push({...e, salonId: salonId}))
					tmpPubli.forEach((e) => handleClose01(e, () => {}, fetchSalons))
				} else {
					fetchSalons()
				}
			})
			.catch(err => console.log(err))
	}

  const clickHandlerForm = () => {
		if (isSalonForm) {
			handleSave()
		}
    setIsSalonForm(!isSalonForm)
  }

  const clickHandlerFormCreate = () => {
		setStatus('create')
    setIsSalonForm(true)
		setHtmlDocument('')
  }

  const clickHandlerFormCancel = () => {
		setFormData({...emptyForm, [creator]: creator === "organisateurId" ? data.companies[0].id : data.id})
    setIsSalonForm(false)
  }

  return (
    <Box>
			<Box>
				<Box>
					<Box width='100%' marginTop='20px'>
						<Typography variant='h4' fontSize='1.3rem' fontWeight={900}>
							{"Mettez vos salons virtuels en ligne sur pekoia".toUpperCase()}
						</Typography>
					</Box>
					<Box width='100%' marginTop='30px' marginLeft="10px">
						<Typography style={{ fontSize: "0.9rem" }}>
							Dans cette rubrique, vous pouvez créer un « Salon Virtuel ». De quoi s’agit-il?
						</Typography>
						<Typography marginTop="20px" style={{ fontSize: "0.9rem" }}>
							Le Salon Virtuel est un espace créé par le conférencier ou un organisateur de conférences sur un thème de son choix et  dans lequel il va pouvoir :
						</Typography>
						<Box display="flex" flexDirection="row" marginTop="20px">
						<Typography style={{ fontSize: "0.9rem" }}>
							Écrire un
						</Typography>
						<Typography style={{ fontSize: "0.9rem", fontWeight: "bold" }} marginLeft="4px">
							article
						</Typography>
						<Typography style={{ fontSize: "0.9rem" }} marginLeft="4px">
							/
						</Typography>
						<Typography style={{ fontSize: "0.9rem", fontWeight: "bold" }} marginLeft="4px">
							mot d'accueil
						</Typography>
						</Box>
						<Box display="flex" flexDirection="row">
						<Typography style={{ fontSize: "0.9rem" }}>
							Soumettre au public des
						</Typography>
						<Typography style={{ fontSize: "0.9rem", fontWeight: "bold" }} marginLeft="4px">
							conseils pour aller plus loin
						</Typography>
						<Typography style={{ fontSize: "0.9rem" }} marginLeft="4px">
							sur le sujet considéré :
						</Typography>
						</Box>
						<Typography marginTop="20px" marginLeft="40px" style={{ fontSize: "0.9rem" }}>
							● Conseils de conférences à suivre (à venir ou en replay ; sur Pekoia ou sur d’autres plateformes)
						</Typography>
						<Typography marginLeft="40px" style={{ fontSize: "0.9rem" }}>
							● Conseils « A lire » (livres, articles …)
						</Typography>
						<Typography marginLeft="40px" style={{ fontSize: "0.9rem" }}>
							● Conseils « A voir » (vidéos Youtube, films, reportages …)
						</Typography>
						<Typography marginLeft="40px" style={{ fontSize: "0.9rem" }}>
							● Conseils « A faire » (visites, expériences, musées, expositions …)
						</Typography>
						<Typography marginLeft="40px" style={{ fontSize: "0.9rem" }}>
							● Conseils « A écouter » (musiques, podcasts …)
						</Typography>
						<Typography marginTop="20px" style={{ fontSize: "0.9rem" }}>
							La première version mise en ligne par Pekoia de ces espaces n’est pas encore interactive mais les prochaines versions intégrerons des modules pour que l'audience puisse aussi partager des conseils sans que cela vienne modifier les vôtres. 
						</Typography>
						<Typography marginTop="20px" style={{ fontSize: "0.9rem" }}>
							N’hésitez pas à utiliser cet espace. C’est une opportunité aussi de promouvoir vos conférences, replays, publications ...
						</Typography>
						<Typography marginTop="20px" style={{ fontSize: "0.9rem" }}>
							La prise en main de ces salons est simple et se base surtout sur un principe de copier / coller de liens menant vers les ressources que vous recommandez. 
						</Typography>
						<Typography marginTop="20px" style={{ fontSize: "0.9rem" }}>
							Pour toute difficulté dans la prise en main, n’hésitez pas à nous contacter : contact@pekoia.com
						</Typography>
					</Box>
				</Box>
				<Box width="100%" marginTop='30px'>
					<Box borderBottom='1px solid black'>
						<Typography variant='h4' fontSize='1.5rem' margin='10px'>
						Salons virtuels
						</Typography>
					</Box>
					{isSalonForm ? <SalonForm formData={formData} setFormData={setFormData} setShowButton={setShowButton} setPublicationsData={setPublicationsData} htmlDocument={htmlDocument} setHtmlDocument={setHtmlDocument} creator={creator}/> : null}
					{isSalonForm ? <div><StyledButton onClick={clickHandlerForm} style={{ marginBottom: '10px' }}>Sauvegarder</StyledButton><StyledButton onClick={clickHandlerFormCancel} style={{ marginBottom: '10px' }}>Annuler</StyledButton></div> : <StyledButton onClick={clickHandlerFormCreate} style={{ marginBottom: '10px' }}>Créer un nouveau salon virtuel</StyledButton>}
          <SalonTable rows={rows} publishSingleAction={publishSingleAction} unpublishSingleAction={unpublishSingleAction} deleteSalon={deleteSalonFunc} editSalon={editSalon}/>
				</Box>
			</Box>
    </Box>
  )
}

export default EspaceSalon

const SalonTable = ({rows, publishSingleAction, unpublishSingleAction, editSalon, deleteSalon}) => {
  const columns = [
		{
			field: 'etat',
			width: 50,
			headerName: 'Etat',
			renderHeader: () => (
				<TableHeader header={"Etat"} />
			),
			renderCell: (params) => {
				const state = params.row.state
				let color = red[500]
				let caption = "Incomplet"
				let title = "Il manque des informations nécessaires à la publication de votre conférence"
        switch (state) {
          case "ONLINE":
            color = green[300]
            caption = 'En ligne'
            title = "Vous n'avez plus rien à faire, le salon sera en ligne sous peu"
            break
          case "OFFLINE":
            caption = 'Hors ligne'
            color = orange[300]
            title = "Le salon est prêt à être publiée"
            break
          default:
            break
        }
				return (<div style={{ width: "100%", height: '100%' }}>
						<Box marginTop="10px"  display="flex" flexDirection="column" alignContent="center" alignItems="center" >
							<LightTooltip title={title}>
								<Box  display="flex" flexDirection="column" alignContent="center" alignItems="center">
									<Dot color={color} />
									<Box>
										<Typography style={{ fontSize: "0.5rem", marginTop: '2px' }}>
											{caption}
										</Typography>
									</Box>
								</Box>
							</LightTooltip>
						</Box>
					</div>
				)
			}
		},
		{
			field: "image",
			width: 70,
			renderHeader: () => (
				<TableHeader header={"Image"} />
			),
			disableClickEventBubbling: true,
			renderCell: (params) => {
				const image = params.row.base64image
				return <img alt="" style={{ width: "60px", height: "60px", objectFit: "cover" }} src={image}></img>;
			}
		},
		{
			field: 'title',
			width: 400,
			headerName: 'Titre du salon',
			renderHeader: () => (
				<TableHeader header={"Titre"} />
			),
			renderCell: (params) => {
				const state = params.row.title
				return (
          <div>
					  {state}
          </div>
        )
			}
		},
    {
      field: 'publications',
      width: 150,
      headerName: 'publications',
      renderHeader: () => (
        <TableHeader header={"Publications"} />
      ),
      renderCell: (params) => {
        return (
          <div>
            {params.row.nbPubli}
          </div>
        )
      }
    },
		{
			field: "action",
			width: 80,
			renderHeader: () => (
				<TableHeader header={"Modifier"} />
			),
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				const onClick = () => {
					editSalon(params.row.id)
					// setIsSalonForm(true)
					// setStatus('edit')
					// eventRef.current?.scrollIntoView({behavior: 'smooth'})
					// setSecondScreen({
					// 	modalId: "1-0",
					// 	state: "modify",
					// 	eventId: params.row.id
					// })
					// setConferenceId(params.row.id)
				};
				return <Box width="40px" padding="5px" display="flex" flexDirection="row" justifyContent="center" alignContent="center">
					<ColorButton
						onClick={onClick}
					><EditIcon /></ColorButton>
				</Box>
			}
		},
		{
      field: "delete",
      renderHeader: () => (
        <Box width="100%" display="flex" flexDirection="row" justifyContent="center" justifyItems="center">
          <TableHeader header={"Supprimer"} />
        </Box>
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          deleteSalon(params.row.id)
          // setIsClone(false)
          // setConferenceId(params.row.id)
        };
        return <Box width='40px' display="flex" flexDirection="row" justifyContent="center" justifyItems="center" ><ColorButton
          onClick={onClick}
        ><DeleteIcon/></ColorButton></Box>;
      }
    },
		{
			field: "publier",
			renderHeader: () => (
				<Box width="100%" display="flex" flexDirection="row" justifyContent="center" justifyItems="center">
					<TableHeader header={"Action"} />
				</Box>
			),
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				const publishRow = () => {
					publishSingleAction(params.row.id)
				}
				const unpublishRow = () => {
					unpublishSingleAction(params.row.id)
				}
				return (
					<Box>
						{params.row.state === 'OFFLINE' ? 
							<StyledButtonTab onClick={publishRow}>Publier</StyledButtonTab> 
							: <StyledButtonTab onClick={unpublishRow}>Dépublier</StyledButtonTab> 
						}
					</Box>
				)
			}
		},
  ]
  return (
    <Box height="400px">
      <DataGridPro
        rows={rows}
        columns={columns}
        rowHeight={50}
      />
    </Box>
  )
}

const TableHeader = ({ header, restrictDate, setRestrictDate }) => {
	const isDate = header === 'Date'
	const colors = [grey[300], orange[400], green[800]]
	const color = colors[restrictDate]

	return (
		<div style={{
			fontSize: "0.7rem", fontWeight: "bold", textAlign: "center"
		}}>
			{isDate && <Tooltip title="change le champs de sélection."><IconButton onClick={() => setRestrictDate((restrictDate + 1) % 3)} color="primary" aria-label="upload picture" component="label">
				<Adjust fontSize="small" sx={{ color: color }} />
			</IconButton></Tooltip>}
			{header}
		</div>
  )
}

const Dot = ({ color }) => {
	return (<Box width="15px" height="15px" position="relative" bottom="0px" zIndex={-10}>
		<svg viewBox="0 0 150 150" >
			<circle cx={75} cy={75} r={60} fill={color} />
		</svg>
	</Box>)
}

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: grey[800],
		padding: '10px',
		color: '#F0F0F0',
		lineHeight: '1.3rem',
		boxShadow: theme.shadows[1],
		fontSize: 14,
	},
}))(Tooltip)

const StyledButton = styled(Button)({
	textTransform: 'none',
	backgroundColor: "#1a5aa3",
	color: '#FFFFFF',
	height: '32px',
	borderRadius: '20px',
	fontSize: '0.85rem',
	margin: '5px',
	marginTop: '10px',

	'&:hover': {
		backgroundColor: grey[700]
	},
	'&:disabled': {
		backgroundColor: grey[200]
	}
})

const StyledButtonTab = styled(Button)({
	textTransform: 'none',
	backgroundColor: "#1a5aa3",
	color: '#FFFFFF',
	height: '32px',
	borderRadius: '20px',
	fontSize: '0.85rem',

	'&:hover': {
		backgroundColor: grey[700]
	},
	'&:disabled': {
		backgroundColor: grey[200]
	}
})
