import React, { useState, useCallback, useEffect } from 'react'
import Slider from '@mui/material/Slider';

import Box from '@mui/material/Box'
import Cropper from 'react-easy-crop';

const CropperScreen = ({ imageStore, CanvasSize, setImageStore, setStage, cropData, setCropData }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onSelection = () => {
        setCropData(croppedAreaPixels)
        setStage(4)
    }



    return (<Box width={CanvasSize} height={CanvasSize} position="relative" top="-10vw">
        <Box>
            <Box bgcolor="lightyellow" textAlign="center" height={CanvasSize} width={CanvasSize}>
                RECADRAGE
 			</Box>
            <Cropper
                image={imageStore.data}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
            />
            <Box width="15vw" padding="0.5vw" display="flex">
                <Box style={{ width: "3vw", padding: "0 0vw 0 0vw" }}>
                    <Slider
                        value={zoom}
                        min={1}
                        max={2}
                        step={0.01}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom)}

                    />
                </Box>
                <Box padding="0 0vw 0 2vw" display="flex" alignContent="center">
                    <button style={{ height: "2vw", alignContent: "center", border: "0", outline: "none" }} onClick={onSelection} >Valider</button>
                </Box>


            </Box>
        </Box>

    </Box>)

}

export default CropperScreen



