import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SpeedIcon from "@mui/icons-material/Speed";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import Typography from "@mui/material/Typography";
import GroupIcon from "@mui/icons-material/Group";
import EspacePreparation from "./conferencePrepa";
import { grey } from "@mui/material/colors";

import EspaceEvenement from "./conferenceEnvironnement";
import EspaceMenu from "./conferenceMenu";
import EspaceAnalyse from "./conferenceAnalyse";
import Promotion from "./promotion";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";

import { LightenDarkenColor } from "lighten-darken-color";
import { colorGray } from "../AppStyle";
import { mainPageHorizontalSeparator } from "../AppStyle";

import { API, graphqlOperation } from "aws-amplify";
import { listMemberShips } from "../graphql/queries";
import SectionCompany from "./company/company";
import CompanyUsers from "./company/companyUsers";
import EspaceFinances from "./finances";
import CompanyPreparation from "./company/companyPrepa";
import EspaceSalon from "./salon/conferenceSalon";
import CompanyAnalyse from "./company/companyAnalyse";

/**
 * [imageStore, setImageStore]: user image information in memory buffer
 * [user, setUser]: user auth/login information
 * [data, setData]: user cloud information
 * [fetchData, fetchData]: read user information from the cloud
 * nbConferences, ..., noteMoyenne: cloud processed stats reagarding conferences
 */

const Tableau = (props) => {
  const {
    content,
    setContent,
    user,
    conferenceData,
    setConferenceData,
    data,
    fetchData,
    fetchConferenceData,
  } = props;

  const indeItemsList = [
    "Accueil",
    "Ma page",
    "Finances",
    "Conférences",
    "Salons",
    "Promotion",
    "Analyse",
  ].map((item) => item.toUpperCase());
  indeItemsList.splice(4, 1);
  var adminItemsList = [
    "Accueil",
    "Notre page",
    "Membres",
    "Finances",
    "Conférences",
    "Salons",
    "Promotion",
    "Analyse",
  ].map((item) => item.toUpperCase());
  adminItemsList.splice(5, 1);
  var baseItemsList = ["Accueil", "Membres"];
  // const itemsList = preItemsList.map((item) => item.toUpperCase())
  // var finalList = itemsList
  const formateurData = useSelector((state) => state.dataReducer);

  const [additionalItemsNumber, setddAditionalItemsNumber] = useState(0);
  const [conferenceImageData, setConferenceImageData] = useState(null);
  const [isInde, setIsInde] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAccountant, setIsAccountant] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const [isCommunication, setIsCommunication] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (formateurData?.id) {
      API.graphql(
        graphqlOperation(listMemberShips, {
          filter: { formateurId: { eq: formateurData.id } },
        })
      )
        .then((res) => {
          const membership = res.data.listMemberShips.items[0];
          setIsAdmin(membership.administratorPrivileges ? true : false);
          setIsEditor(membership.editorPrivileges ? true : false);
          setIsAccountant(membership.accountantPrivileges ? true : false);
          setIsCommunication(membership.communicationPrivileges ? true : false);
        })
        .catch((err) => console.log(err));
    }
  }, [formateurData]);

  const sideBarclickHandler = (e, index) => {
    setddAditionalItemsNumber(index);
  };

  const [finalPages, setFinalPages] = useState([]);
  const [finalIcons, setFinalIcons] = useState([]);
  const [finalList, setFinalList] = useState([]);

  useEffect(() => {
    const independentPages = [
      <EspaceMenu
        fetchData={fetchData}
        user={user}
        setIsInde={setIsInde}
        isInde={isInde}
      />,
      <EspaceEvenement fetchData={fetchData} user={user} />,
      <EspaceFinances fetchData={fetchData} user={user} isInde={true} />,
      <EspacePreparation
        data={data}
        conferenceImageData={conferenceImageData}
        setConferenceImageData={setConferenceImageData}
        userData={data}
        conferenceData={conferenceData}
        setConferenceData={setConferenceData}
        fetchConferenceData={fetchConferenceData}
        content={content}
        setContent={setContent}
      />,
      // <EspaceSalon creator="formateurId"/>,
      <Promotion isInde={isInde} fetchData={fetchData} user={user} />,
      <EspaceAnalyse />,
    ];

    const icons = [
      <HomeIcon fontSize="small" />,
      <BorderColorIcon fontSize="small" />,
      <AccountBalanceIcon fontSize="small" />,
      <VideoLabelIcon fontSize="small" />,
      // <MenuBookIcon fontSize="small" />,
      <SpeedIcon fontSize="small" />,
      <BusinessIcon fontSize="small" />,
      <AssessmentIcon fontSize="small" />,
    ];

    const basePages = [
      <EspaceMenu
        fetchData={fetchData}
        user={user}
        setIsInde={setIsInde}
        isInde={isInde}
      />,
      // <CompanyUsers fetchData={fetchData}/>,
    ];

    const baseIcons = [
      <HomeIcon fontSize="small" />,
      <GroupIcon fontSize="small" />,
    ];

    const adminPages = [
      <EspaceMenu
        fetchData={fetchData}
        user={user}
        setIsInde={setIsInde}
        isInde={isInde}
      />,
      <SectionCompany />,
      <CompanyUsers fetchData={fetchData} />,
      <EspaceFinances fetchData={fetchData} user={user} isInde={false} />,
      <CompanyPreparation />,
      // <EspaceSalon creator="organisateurId"/>,
      <Promotion isInde={false} fetchData={fetchData} user={user} />,
      <CompanyAnalyse />,
    ];

    const adminIcons = [
      <HomeIcon fontSize="small" />,
      <BorderColorIcon fontSize="small" />,
      <GroupIcon fontSize="small" />,
      <AccountBalanceIcon fontSize="small" />,
      <VideoLabelIcon fontSize="small" />,
      // <MenuBookIcon fontSize="small" />,
      <SpeedIcon fontSize="small" />,
      <BusinessIcon fontSize="small" />,
    ];

    const accountantPages = [
      <EspaceFinances fetchData={fetchData} user={user} isInde={false} />,
    ];

    const accountantIcons = [<AccountBalanceIcon fontSize="small" />];

    const editorPages = [
      <SectionCompany />,
      <CompanyPreparation />,
      // <EspaceSalon creator="organisateurId"/>,
    ];

    const editorIcons = [
      <BorderColorIcon fontSize="small" />,
      <VideoLabelIcon fontSize="small" />,
      <MenuBookIcon fontSize="small" />,
    ];

    const comPages = [
      <Promotion isInde={isInde} fetchData={fetchData} user={user} />,
      <EspaceAnalyse />,
    ];

    const comIcons = [
      <SpeedIcon fontSize="small" />,
      <BusinessIcon fontSize="small" />,
    ];

    var tmpList = [];
    var tmpPages = [];
    var tmpIcons = [];
    if (isInde) {
      tmpList = indeItemsList;
      tmpPages = independentPages;
      tmpIcons = icons;
    } else {
      tmpList = baseItemsList;
      tmpPages = basePages;
      tmpIcons = baseIcons;
      if (isAdmin) {
        tmpList = adminItemsList;
        tmpPages = adminPages;
        tmpIcons = adminIcons;
      } else {
        if (isAccountant) {
          tmpList.push("Finances");
          tmpPages.push(accountantPages);
          tmpIcons.push(accountantIcons);
        }
        if (isEditor) {
          tmpList = tmpList.concat(["Notre page", "Conférences"]);
          tmpPages = tmpPages.concat(editorPages);
          tmpIcons = tmpIcons.concat(editorIcons);
        }
        if (isCommunication) {
          tmpList = tmpList.concat(["Promotion", "Analyse"]);
          tmpPages = tmpPages.concat(comPages);
          tmpIcons = tmpIcons.concat(comIcons);
        }
      }
    }
    if (tmpList) tmpList = tmpList.map((item) => item.toUpperCase());
    setFinalList(tmpList);
    setFinalPages(tmpPages);
    setFinalIcons(tmpIcons);
  }, [isInde, isAdmin, isAccountant, isCommunication, isEditor]);

  return (
    <Box>
      <Box
        height="100%"
        width="20vw"
        padding="0.25vw"
        display="flex"
        justifyContent="start"
        alignItems="flex-start"
      >
        <Box height="100%" width="100%">
          <Box
            border="Opx solid"
            outline="none"
            width="100px"
            paddingTop="0vw"
            height="100%"
            bgcolor={grey[300]}
          >
            <List
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              {finalList.map((text, index) => (
                <ListItem
                  disableRipple
                  autoFocus={index === 0}
                  style={{ display: "flex", flexDirection: "column" }}
                  button
                  key={index}
                  onClick={(event) => sideBarclickHandler(event, index)}
                >
                  <Box>{finalIcons[index]}</Box>
                  <Box marginBottom="1.5vw">
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            color: "black",
                            fontWeigh: "bold",
                            fontSize: "0.6rem",
                          }}
                        >
                          {text}{" "}
                        </Typography>
                      }
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Box marginLeft="2vw" bgcolor="white">
          <Box
            width="80vw"
            marginLeft={mainPageHorizontalSeparator}
            fontSize="1rem"
            marginRight="2vw"
            display="flex"
            flexDirection="column"
            bgcolor={LightenDarkenColor(colorGray, 10)}
          >
            {finalPages[additionalItemsNumber]}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Tableau;
