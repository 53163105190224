import moment from 'moment'
import { red, orange, indigo, green } from '@mui/material/colors';
import { ContactsOutlined } from '@mui/icons-material';
import { createProcessController } from '../../graphql/mutations';

export const testEmail = (email) => {
  if (!email || email === '')
    return (true)
  const split1 = email.split('@')
  const split2 = (split1.length > 1) ? split1[1].split('.') : null

  if (split2 && split2[1])
    return (true)
  else
    return (false)

}

export const testDate = (dateString) => {
  if (!dateString || dateString === '')
    return (true)
  const d = moment(dateString, 'DD/MM/YYYY')
  return d.isValid()
}

export const documentStatus = [
  {
    code: -1,
    label: 'Non renseigné',
    color: indigo[800]
  },
  {
    code: 0,
    label: 'En attente de vérification',
    color: orange[300]
  },
  {
    code: 1,
    label: 'En attente de vérification',
    color: orange[300]
  }, {
    code: 2,
    label: 'Accepté',
    color: green[400]
  },
  {
    code: 3,
    label: 'Refusé',
    color: red[500]
  },
  {
    code: 4,
    label: 'Refusé - Document illisible',
    color: red[500]
  },
  {
    code: 5,
    label: 'Refusé - Document expiré',
    color: red[500]
  },
  {
    code: 6,
    label: 'Refusé - Mauvais type de document',
    color: red[500]
  },
  {
    code: 7,
    label: 'Refusé - Mauvais nom du titulaire',
    color: red[500]
  },
  {
    code: 8,
    label: 'Refusé - Document en doublon',
    color: red[500]
  }]

export const getStatusCode = (varDocuments, accountType) => {


  let status = [-1, -1, -1]
  if (parseInt(accountType) === 1)
    status = [-1, -1, -1, -1]

  if (parseInt(accountType) === 0){

 
  let index = -1
  let newPrimaryDocument = null

  const documentsRaw = varDocuments
    .filter(document => document.status != 33)
    .sort((b, a) => (a.id - b.id))

    let documents = []
  
    documentsRaw.forEach(doc=>{
      if (!documents.find(loopDoc => loopDoc.type === doc.type))
        documents.push(doc)
    
    })


  const primaryCodes = [0, 5, 3, 4]
  const secondaryCodes = primaryCodes.concat([11, 14, 15, 16, 17])
  const tertiaryCodes = [2]

  const rejectedStatus = [3, 4, 5, 6, 7, 8]

  const validPrimaryDocuments = documents.filter(document => ((primaryCodes.includes(document.type))))
  const validSecondaryDocuments = documents.filter(document => ((secondaryCodes.includes(document.type))))
  const primaryDocument = validPrimaryDocuments.find(document => document.status === 2) || validPrimaryDocuments.find(document => document.status === 1) || validPrimaryDocuments.find(document => rejectedStatus.includes(document.status))


  if (primaryDocument) {
    status[0] = primaryDocument.status
    const removeFromSecondary = validSecondaryDocuments.find(elem => elem.id === primaryDocument.id)

    index = validSecondaryDocuments.indexOf(removeFromSecondary)

  }


  if (index >= 0)
    validSecondaryDocuments.splice(index, 1)


  index = -1
  const secondaryDocument = validSecondaryDocuments.find(document => document.status === 2) || validSecondaryDocuments.find(document => document.status === 1) || validSecondaryDocuments.find(document => rejectedStatus.includes(document.status))
  if (secondaryDocument) {
    status[1] = secondaryDocument.status
  }

  const validTerciaryDocuments = documents.filter(document => ((tertiaryCodes.includes(document.type))))
  const terciaryDocument = validTerciaryDocuments.find(document => document.status === 2) || validTerciaryDocuments.find(document => document.status === 1) || validTerciaryDocuments.find(document => rejectedStatus.includes(document.status))
  if (terciaryDocument) {
    status[2] = terciaryDocument.status
  }
}

if (parseInt(accountType) === 1){

 
  let index = -1
  let newPrimaryDocument = null

  const documentsRaw = varDocuments
    .filter(document => document.status != 33)
    .sort((b, a) => (a.id - b.id))

    
  let documents = []
  
  documentsRaw.forEach(doc=>{
    if (!documents.find(loopDoc => loopDoc.type === doc.type))
      documents.push(doc)
  
  })





  const primaryCodes = [7]
  const secondaryCodes = [12]
  const tertiaryCodes = [0, 3, 4]
  const quaternaryCodes = [2]

  const rejectedStatus = [3, 4, 5, 6, 7, 8]

  const validPrimaryDocuments = documents.filter(document => ((primaryCodes.includes(document.type))))
  const validSecondaryDocuments = documents.filter(document => ((secondaryCodes.includes(document.type))))
  const validTertiaryDocuments = documents.filter(document => ((tertiaryCodes.includes(document.type))))
  const validQuaternaryDocuments = documents.filter(document => ((quaternaryCodes.includes(document.type))))


  if (validPrimaryDocuments.length) {
    status[0] = validPrimaryDocuments[0].status
  }

  if (validSecondaryDocuments.length) {
    status[1] = validSecondaryDocuments[0].status
  }

  if (validTertiaryDocuments.length) {
    status[2] = validTertiaryDocuments[0].status
  }

  if (validQuaternaryDocuments.length) {
    status[3] = validQuaternaryDocuments[0].status
  }


}



  return (status)
}

export const sections = [{
  label: 'identity1',
  description: "Pièce d'identité principale",
  title: "Une pièce d’identité (carte d’identité recto verso, titre de séjour recto verso, passeport) en cours de validité du titulaire du compte de paiement",
  warning: 'Attention à bien veiller à envoyer les recto/verso sur une seule page et de choisir des images aux formats JPG ou PNG',
  additionlWarning: true,
  documentTypes: [
    {
      label: "Carte d'identité",
      code: 0
    },
    {
      label: "Carte de séjour",
      code: 5
    },
    {
      label: "Passeport UE",
      code: 3
    },
    {
      label: "Passeport non-UE",
      code: 4
    }
  ]
},
{
  label: 'identity2',
  description: "Pièce d'identité secondaire",
  title: "Une seconde pièce d’identité (passeport, carte nationale d’Identité, permis de conduire recto-verso, dernier avis d’imposition, titre de séjour, livret de famille ou le récépissé de l'enregistrement de la déclaration conjointe des partenaires du pacte civil de solidarité) en cours de validité du titulaire du compte de paiement ou l'avis d'imposition de moins d’un an",

  warning: 'Attention à bien veiller à envoyer les recto/verso sur une seule page et de choisir des images aux formats JPG ou PNG',
  documentTypes: [
    {
      label: "Carte d'identité",
      code: 0
    },
    {
      label: "Carte de séjour",
      code: 5
    },
    {
      label: "Passeport UE",
      code: 3
    },
    {
      label: "Passeport non-UE",
      code: 4
    },
    {
      label: "Permis de conduire",
      code: 11
    },
    {
      label: "Avis d'imposition",
      code: 14
    },
    {
      label: "Livret de famille",
      code: 15
    },
    {
      label: "Récépissé PACS",
      code: 16
    },

    {
      label: "Carte vitale",
      code: 17
    }
  ]
},
{
  label: 'ibanProof',
  description: "Copie du RIB",
  title: "Banques classiques: Scan de votre RIB\n Néo-banques: copie d'écran avec votre numéro de compte et votre nom",
  documentTypes: [
    {
      label: "Scan du RIB",
      code: 2
    }
  ]
}]

export const sectionsSociete = [{
  label: 'kbis',
  description: "Extrait K-bis (< 3 mois)",
  title: "Extrait K-bis datant de moins de 3 mois",
  documentTypes: [
    {
      label: "kbis",
      code: 7
    }
  ]
},
{
  label: 'status',
  description: "Status",
  title: "Status de la société signés",

  documentTypes: [
    {
      label: "Status",
      code: 12
    }
  ]
},
{
  label: 'cibeneficiary',
  description: "Identité des bénéficiaires",
  title: "Carte d'identité ou passeport des bénéficiaires effectifs detenant plus de 25% du capital",
  additionlWarning: true,
  documentTypes: [
    {
      label: "Carte d'identité",
      code: 0
    },
    {
      label: "Passeport UE",
      code: 3
    },
    {
      label: "Passeport non-UE",
      code: 4
    }
  ]
},
{
  label: 'ribsosociete',
  description: "RIB",
  title: "RIB au nom de la personne morale",
  documentTypes: [
    {
      label: "Scan du RIB",
      code: 2
    }
  ]
}]



