import { API, graphqlOperation } from "aws-amplify";
import {
  createFormateur,
  createEvent,
  createCycle,
  updateFormateur,
  updateEvent,
  updateCycle,
  deleteEvent,
  deleteCycle,
  createDocumentHtml,
  updateDocumentHtml,
} from "../../graphql/mutations";
import { Storage } from "aws-amplify";
import store from "../../redux/store";
import { fetchConferenceData } from "./fetchers";

export const DBupdateFormateur = () => {
  const data = store.getState().dataReducer;
  if (data != {}) {
    const { id, publicationList } = data;
    const input = { id: id, publicationList: publicationList };

    if (data.id) {
      API.graphql(graphqlOperation(updateFormateur, { input: input }))
        .then((data) => {})
        .catch((err) => console.log(err));
    }
  }
};

export const saveNewDocumentHtmlBatch = ({
  modelId,
  documentHtml,
  HtmlCategory,
}) => {
  const input = {
    content: documentHtml,
    modelId: modelId,
    HtmlCategory: HtmlCategory,
  };

  return API.graphql(graphqlOperation(createDocumentHtml, { input: input }));
};

export const updateDocumentHtmlBatch = ({
  documentHtml,
  documentId,
  modelId,
}) => {
  const input = {
    id: documentId,
    content: documentHtml,
    modelId,
    modelId,
  };
  return API.graphql(graphqlOperation(updateDocumentHtml, { input: input }));
};

export const saveNewEventBatch = (conferenceData) => {
  const input = { ...conferenceData };
  return API.graphql(graphqlOperation(createEvent, { input: input }));
};

export const saveNewCycleBatch = (cycleData) => {
  const input = { ...cycleData };
  return API.graphql(graphqlOperation(createCycle, { input: input }));
};

export const updateEventBatch = (conferenceData) => {
  const input = { ...conferenceData };
  return API.graphql(graphqlOperation(updateEvent, { input: input }));
};

export const updateCycleBatch = (cycleData) => {
  const input = { ...cycleData };
  return API.graphql(graphqlOperation(updateCycle, { input: input }));
};

export const updateFormateurBatch = (data) => {
  const input = { ...data };

  return API.graphql(graphqlOperation(updateFormateur, { input: input }));
};

export const handleDeleteEvent = (eventData) => {
  const input = { ...eventData };

  return API.graphql(graphqlOperation(deleteEvent, { input: input }));
  // .then(e => {

  //     return fetchConferenceData();
  // }).catch(err => { console.log(err)
  // })
};

export const handleDeleteCycle = (input) => {
  return API.graphql(graphqlOperation(deleteCycle, { input: input }));
  // .then(e => {

  //     return fetchConferenceData();
  // }).catch(err => { console.log(err)
  // })
};

export const uploadImageBatch = (
  imageData,
  userId,
  imageRef,
  path = "conferences"
) => {
  if (imageData != null) {
    const imagesSet = [
      {
        ext: "S",
        data: imageData.compressedS,
      },
      {
        ext: "L",
        data: imageData.compressedL,
      },
    ];

    const batches = [];
    // upload data only in case there is a new image: ie 'L' image exists
    if (imagesSet.find((image) => image.ext === "L").data) {
      imagesSet.forEach((image) => {
        const fileName = image.ext + imageRef;
        const fileExt =
          image.data.name.split(".").pop() === "jpg"
            ? "jpeg"
            : image.data.name.split(".").pop();

        const miniBatch = Storage.put(
          `${userId}/${path}/${fileName}.${fileExt}`,
          image.data,
          { level: "private", contentType: `image/${fileExt}` }
        );
        batches.push(miniBatch);
      });
    }
    return Promise.all(batches);
  }
  return new Promise((r) => {});
};

export const uploadImageBatchV2 = (
  blob,
  prefix,
  userId,
  key,
  path = "conferences"
) => {
  const fileExt = blob.name.split(".").pop();
  console.log(`${userId}/${path}/${prefix}${key}.${fileExt}`);
  const batch = Storage.put(
    `${userId}/${path}/${prefix}${key}.${fileExt}`,
    blob,
    { level: "private", contentType: `image/${fileExt}` }
  ).then((res) => {
    console.log(res);
  });
  return batch;
};

export const downloadImageBatch = (
  userId,
  fileName,
  fileExt,
  path = "conferences"
) => {
  const batch = Storage.get(`${userId}/${path}/${fileName}.${fileExt}`, {
    level: "private",
    contentType: `image/${fileExt}`,
  })
    .then((url) => {
      return fetch(url);
    })
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    })
    .catch((err) => console.log(err));

  return batch;
};
