import axios from "axios"




const app = (setData, youtubeID)=>{

    // const urlBuild = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=${youtubeID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`

    // const fetchAPI = () =>  axios.get(urlBuild, {
    //     headers: {
    //         'Accept': 'application/json'
    //     }
    
    // })
    //     .then(function (response) {
    
    //         let result = null
    
    //         const responseJSON = response
    //         if (responseJSON.data.items.length > 0) {
    //             result = responseJSON.data.items[0]
    //         }
    //         return (result)
    //     })
    //     .catch(
    
    //         (err) => {
    //             console.log('&ERROR-YT')
    //             return null
    //         }
    
            
            
    //     )
        
   
    //   const promise = fetchAPI();
    //   promise.then( data => {
        
    //     setData(data)
    // }

    //   )

}

export default app






