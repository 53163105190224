import { Button, MenuItem, styled, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
// import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// import { ColorButton2 } from "../BO-style";
import TextEditor from "../helpers/sunTextEditor";
import { cleanHtml } from "../SVGIcons/helpers";
import useStylesOld from "../style/textFields";
import ImageUpload from "../uploadImage-3";
import { eventCategories } from "../helpers/events";
import { grey } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import store from "../../redux/store";
import { insertPublication } from "../../features/data/dataSlice";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { deletePublication } from "../../graphql/mutations";
import { useConfirm } from "material-ui-confirm";
import { ColorButton } from "../BO-style";
import { getSalon, listPublications } from "../../graphql/queries";
import { localeText, regexList, cleanText, cutText } from "../general";
import { useSelector } from "react-redux";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import { colorGray } from "../../AppStyle";
import SalonPublicationForm from "./salonPublicationForm";
import withStyles from "@mui/styles/withStyles";
import { array } from "prop-types";
import { handleClose01 } from "../handleClose";

const emptyForm = {
  id: "",
  image: "",
  title: "",
  htmlDocument: "",
  state: "OFFLINE",
  categories: "",
};

const types = {
  CONFERENCE: "Conférence",
  READ: "À lire",
  SEE: "À voir",
  DO: "À faire",
  LISTEN: "À écouter",
};

const nbMaxCategories = 3;

const getSource = (url) => {
  const regex = regexList.find((regex) => url.match(regex));
  return url.match(regex)[2];
};

const SalonForm = (props) => {
  const data = useSelector((state) => state.dataReducer);
  const {
    formData,
    setFormData,
    salonId,
    setPublicationsData,
    htmlDocument,
    setHtmlDocument,
    creator,
  } = props;
  const [titleError, setTitleError] = useState(false);
  const [subtitleError, setSubtitleError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [tmpImage, setTmpImage] = useState("");
  const [dragOrigin, setDragOrigin] = useState(null);
  const [publications, setPublications] = useState([]);
  const [isPublicationForm, setIsPublicationForm] = useState(false);
  const [publicationData, setPublicationData] = useState({});
  const confirm = useConfirm();
  const [rows, setRows] = useState([]);

  const marginText = "0px";
  const classes = useStylesOld(props);
  const textFieldStyle = { margin: marginText, backgroundColor: "white" };

  const getBase64Image = async (image) => {
    const split = image.split(".");
    const key = split[0];
    const ext = split[1];
    const url = await Storage.get(
      `${
        creator === "organisateurId" ? data.companies[0].id : data.id
      }/salons/L${key}.${ext}`,
      { level: "private", contentType: `image/${ext}` }
    );
    const response = await fetch(url);
    const blob = await response.blob();
    const res = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
    return res;
  };

  const getBase64ImageFromKey = async (key, ext) => {
    const url = await Storage.get(
      `${
        creator === "organisateurId" ? data.companies[0].id : data.id
      }/publications/S${key}.${ext}`,
      { level: "private", contentType: `image/${ext}` }
    );
    const response = await fetch(url);
    const blob = await response.blob();
    const res = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
    return res;
  };

  const getPublications = async () => {
    const res = await API.graphql(
      graphqlOperation(listPublications, {
        filter: { salonId: { eq: formData.id } },
      })
    );
    let publications = res.data.listPublications.items;
    let tmpRows = [];
    for (const e of publications) {
      let obj = {
        ...e,
      };
      if (e.imageId && e.imageExt) {
        obj = {
          ...obj,
          base64Image: await getBase64ImageFromKey(e.imageId, e.imageExt),
        };
      }
      tmpRows.push(obj);
    }
    setRows(tmpRows);
  };

  useEffect(() => {
    if (formData.id) {
      API.graphql(graphqlOperation(getSalon, { id: formData.id })).then(
        (res) => {
          let salon = res.data.getSalon;
          delete salon.createdAt;
          delete salon.formateurId;
          delete salon.updatedAt;
          delete salon.organisateurId;
          setFormData(salon);
          if (salon.image) {
            getBase64Image(salon.image).then((res) => setTmpImage(res));
          }
          setHtmlDocument(salon.htmlDocument);
        }
      );
      getPublications();
    }
  }, []);

  const handleForm = () => {
    if (isPublicationForm) {
      if (formData.id) {
        const obj = {
          ...publicationData,
          title: publicationData.imageTitle ? publicationData.imageTitle : "",
          formateurId: creator === "organisateurId" ? "" : data.id,
          organisateurId:
            creator === "organisateurId" ? data.companies[0].id : "",
          salonId: formData.id,
        };
        handleClose01(obj, setPublicationData, getPublications);
      } else {
        let tmpRows = [];
        rows.forEach((e) => tmpRows.push(e));
        tmpRows.push({
          ...publicationData,
          id: tmpRows.length,
          formateurId: creator === "organisateurId" ? "" : data.id,
          organisateurId:
            creator === "organisateurId" ? data.companies[0].id : "",
        });
        setRows(tmpRows);
        setPublicationsData(tmpRows);
        setPublicationData({});
      }
    }
    setIsPublicationForm(!isPublicationForm);
  };

  const handleFormCancel = () => {
    setPublicationData({});
    setHtmlDocument("");
    setIsPublicationForm(false);
  };

  const handleChange = (key) => {
    return (e) => {
      const value = e.target.value;
      if (key === "category") {
        const categories = formData.categories
          ? formData.categories.split("|")
          : [];
        if (
          value !== emptyForm.categories &&
          categories.length < nbMaxCategories
        ) {
          const newCategories = categories.concat(value);
          setFormData({ ...formData, categories: newCategories.join("|") });
        }
        return;
      }
      setFormData({ ...formData, [key]: value });
    };
  };

  const handleChangeHtml = (value) => {
    setHtmlDocument(value);
  };

  const labelClass = (key) => {
    if (key === "title" && formData.title?.length === 0)
      return classes.textFieldLabelError;
    if (key === "image" && formData) return classes.imageContainerError;
    return classes.textFieldLabel;
  };

  const handleSelectionClick = (index) => () => {
    const previousCategories = formData.categories.split("|");
    previousCategories.splice(index, 1);
    setFormData({ ...formData, categories: previousCategories.join("|") });
  };

  const dragStart = (event) => {
    document.body.style.cursor = "grab";
    setDragOrigin(event.target.id);
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const onMouseLeave = (event) => {
    event.preventDefault();
    document.body.style.cursor = "default";
  };

  const onDrop = (event) => {
    event.preventDefault();
    store.dispatch(
      insertPublication({
        originIndex: dragOrigin,
        destinationIndex: event.target.id,
      })
    );
    document.body.style.cursor = "default";
  };

  const columns = [
    {
      field: "image",
      renderHeader: () => <strong>{"Image"}</strong>,
      flex: 2,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "grab" }}>
            <img
              alt=""
              onMouseLeave={onMouseLeave}
              onDrop={onDrop}
              onDragOver={allowDrop}
              id={params.row.id}
              draggable={true}
              onDragStart={dragStart}
              style={{
                width: "90px",
                margin: "1vw",
                padding: "0.25vw",
                height: "90px",
                objectFit: "cover",
              }}
              src={
                params.row.base64Image
                  ? params.row.base64Image
                  : params.row.ogImage
              }
            ></img>
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "Description",
      flex: 5,
      renderHeader: () => <strong>{"Description"}</strong>,
      cellClassName: "theme--cell",
      renderCell: (params) => {
        return (
          <div>
            {params.row.imageTitle ? params.row.imageTitle : params.row.ogTitle}
          </div>
        );
      },
    },
    {
      field: "activityType",
      headerName: "Type",
      flex: 3,
      renderHeader: () => <strong>{"Type"}</strong>,
      cellClassName: "theme--cell",
      renderCell: (params) => {
        return <div>{types[params.row.activityType]}</div>;
      },
    },
    {
      field: "url",
      headerName: "Source",
      flex: 3,
      renderHeader: () => <strong>{"Source"}</strong>,
      cellClassName: "theme--cell",
    },
    {
      field: "delete",
      renderHeader: () => <strong>{"Supprimer"}</strong>,
      sortable: false,
      flex: 1.7,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          confirm({
            title: "ATTENTION",
            cancellationText: "Annuler",
            confirmationText: "Supprimer",
            description:
              "Vous allez supprimer définitivement cette publication",
          }).then(() => {
            if (formData.id) {
              API.graphql(
                graphqlOperation(deletePublication, {
                  input: { id: params.row.id },
                })
              ).then((res) => {
                getPublications();
              });
            } else {
              let tmpRows = [];
              rows.forEach((e) => tmpRows.push(e));
              tmpRows.splice(params.row.id, 1);
              tmpRows.forEach((e, idx) => (e.id = idx));
              setRows(tmpRows);
              setPublicationsData(tmpRows);
            }
          });
        };
        return (
          <ColorButton onClick={onClick}>
            <DeleteIcon />
          </ColorButton>
        );
      },
    },
  ];

  return (
    <Box margin="20px">
      {/* <Typography>Créer un salon virtuel</Typography> */}
      <Box width="100%">
        <Typography
          style={{ fontSize: "1.2rem", color: "#1a5aa3", marginBottom: "10px" }}
        >
          Titre :
        </Typography>
        <TextField
          error={titleError}
          id="title"
          value={formData.title ? formData.title : ""}
          style={textFieldStyle}
          InputProps={{
            shrink: "true",
            classes: {
              root: classes.textFieldRoot,
              focused: classes.textFieldRootAlt,
              input: classes.textFieldRootAlt2,
            },
          }}
          InputLabelProps={{ classes: { root: labelClass("title") } }}
          // variant="filled"
          fullWidth
          required
          label="Titre principal"
          onChange={handleChange("title")}
        />
      </Box>
      <Box width="100%" marginTop="20px">
        <Typography
          style={{ fontSize: "1.2rem", color: "#1a5aa3", marginBottom: "10px" }}
        >
          Sous-titre :
        </Typography>
        <TextField
          error={subtitleError}
          id="subtitle"
          value={formData.subtitle ? formData.subtitle : ""}
          style={textFieldStyle}
          InputProps={{
            shrink: "true",
            classes: {
              root: classes.textFieldRoot,
              focused: classes.textFieldRootAlt,
              input: classes.textFieldRootAlt2,
            },
          }}
          InputLabelProps={{ classes: { root: labelClass("subtitle") } }}
          // variant="filled"
          fullWidth
          label="Sous-titre"
          onChange={handleChange("subtitle")}
        />
      </Box>
      <Box marginTop="20px">
        <Typography style={{ fontSize: "1.2rem", color: "#1a5aa3" }}>
          Image :
        </Typography>
        <Box
          height="200px"
          marginTop="10px"
          border="1px dashed black"
          borderRadius="5px"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          {formData.imageStore?.original || formData.image ? (
            <Box display="flex" flexDirection="column" margin="auto">
              <img
                alt=""
                style={{ width: "300px", height: "150px", objectFit: "cover" }}
                src={
                  formData.imageStore && formData.imageStore.original
                    ? formData.imageStore.original
                    : tmpImage
                }
              />
              <ImageUpload
                modalStore={formData}
                setModalStore={setFormData}
                setSpinner={setSpinner}
              />
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignContent="center">
              <ImageUpload
                modalStore={formData}
                setModalStore={setFormData}
                setSpinner={setSpinner}
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* <Box marginTop="20px">
        <Typography>Image :</Typography>
        <Box height="120px" marginTop="10px" backgroundColor="#dedede">
          <ImageUpload modalStore={formData} setModalStore={setFormData} setSpinner={setSpinner}/>
          {formData.imageStore?.original ? <img style={{ width: "120px", height: "120px", objectFit: "cover" }} src={formData.imageStore.original}/> : <></>}
        </Box>
      </Box> */}
      <Box marginTop="20px">
        <Typography
          style={{ fontSize: "1.2rem", color: "#1a5aa3", marginBottom: "10px" }}
        >
          Le mot d'accueil :
        </Typography>
        <TextEditor
          setNewDocumentHtml={handleChangeHtml}
          content={cleanHtml(htmlDocument)}
          setContent={handleChangeHtml}
        />
      </Box>
      <Box marginTop="20px">
        <Typography
          style={{ fontSize: "1.2rem", color: "#1a5aa3", marginBottom: "10px" }}
        >
          Thèmes :
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignContent="center"
          height="100%"
        >
          <TextField
            id="category"
            style={{ ...textFieldStyle, width: "40%", height: "100%" }}
            select
            label="Nouveau thème"
            // InputProps={{ shrink: 'true', classes: { root: classes.textFieldRoot, focused: classes.textFieldRootAlt, input: classes.textFieldRootAlt2 } }}
            // InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
            // SelectProps={{ classes: { root: classes.selectProps } }}

            value={""}
            onChange={handleChange("category")}
          >
            {eventCategories
              .filter((category) => category !== "")
              .filter(
                (category) =>
                  extractCategories(formData) === null ||
                  !extractCategories(formData).includes(category)
              )
              .map((category) => (
                <MenuItem key={category.toUpperCase()} value={category}>
                  {category}
                </MenuItem>
              ))}
          </TextField>
          <Box display="flex">
            {formData.categories &&
              extractCategories(formData).map((category, index) => (
                <DisplayCategory
                  key={index}
                  handleSelectionClick={handleSelectionClick}
                  index={index}
                  category={category}
                />
              ))}
          </Box>
        </Box>
        <Box marginTop="20px">
          <Typography
            style={{
              fontSize: "1.2rem",
              color: "#1a5aa3",
              marginBottom: "10px",
            }}
          >
            Partage :
          </Typography>
          {isPublicationForm ? (
            <SalonPublicationForm
              formData={publicationData}
              setFormData={setPublicationData}
            />
          ) : null}
          {isPublicationForm ? (
            <Box>
              <StyledButton onClick={handleForm}>Sauvegarder</StyledButton>
              <StyledButton onClick={handleFormCancel}>Annuler</StyledButton>
            </Box>
          ) : (
            <StyledButton onClick={handleForm}>Ajouter un lien</StyledButton>
          )}
          <Box bgcolor={colorGray} height="30vw" marginLeft="0vw">
            <DataGridPro
              rowHeight={80}
              localeText={localeText}
              onClick={() => {}}
              rows={rows}
              columns={columns}
              pageSize={5}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SalonForm;

const extractCategories = (formData) => {
  if (formData && formData.categories) {
    return formData.categories.split("|");
  }
  return null;
};

const DisplayCategory = ({ index, category, handleSelectionClick }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "250px",
        borderRadius: "10px",
        padding: "2px",
        marginLeft: "4px",
        backgroundColor: grey[200],
        alignItems: "center",
      }}
    >
      <div>{index + 1}:</div>
      <div style={{ width: "180px", textAlign: "center" }}>{category}</div>
      <CancelIcon
        onClick={handleSelectionClick(index)}
        style={{ marginLeft: "10px", fontSize: "20px", color: grey[500] }}
      />
    </div>
  );
};

const StyledButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "#1a5aa3",
  color: "#FFFFFF",
  height: "32px",
  borderRadius: "20px",
  fontSize: "0.85rem",
  marginBottom: "10px",
  marginLeft: "10px",
  "&:hover": {
    backgroundColor: grey[700],
  },
});
