/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompanyAccount = /* GraphQL */ `
  mutation CreateCompanyAccount(
    $input: CreateCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    createCompanyAccount(input: $input, condition: $condition) {
      id
      fullName
      nbMaxMembers
      category
      memberShips {
        nextToken
        __typename
      }
      documentHtml
      web
      state
      description
      hashList
      imageId
      type
      lemonwayId
      promotionProgram
      abonnement
      imageExt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyAccount = /* GraphQL */ `
  mutation UpdateCompanyAccount(
    $input: UpdateCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    updateCompanyAccount(input: $input, condition: $condition) {
      id
      fullName
      nbMaxMembers
      category
      memberShips {
        nextToken
        __typename
      }
      documentHtml
      web
      state
      description
      hashList
      imageId
      type
      lemonwayId
      promotionProgram
      abonnement
      imageExt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyAccount = /* GraphQL */ `
  mutation DeleteCompanyAccount(
    $input: DeleteCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    deleteCompanyAccount(input: $input, condition: $condition) {
      id
      fullName
      nbMaxMembers
      category
      memberShips {
        nextToken
        __typename
      }
      documentHtml
      web
      state
      description
      hashList
      imageId
      type
      lemonwayId
      promotionProgram
      abonnement
      imageExt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode(
    $input: CreatePromoCodeInput!
    $condition: ModelpromoCodeConditionInput
  ) {
    createPromoCode(input: $input, condition: $condition) {
      id
      category
      codeChain
      inceptionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode(
    $input: UpdatePromoCodeInput!
    $condition: ModelpromoCodeConditionInput
  ) {
    updatePromoCode(input: $input, condition: $condition) {
      id
      category
      codeChain
      inceptionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode(
    $input: DeletePromoCodeInput!
    $condition: ModelpromoCodeConditionInput
  ) {
    deletePromoCode(input: $input, condition: $condition) {
      id
      category
      codeChain
      inceptionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSalon = /* GraphQL */ `
  mutation CreateSalon(
    $input: CreateSalonInput!
    $condition: ModelSalonConditionInput
  ) {
    createSalon(input: $input, condition: $condition) {
      id
      formateurId
      organisateurId
      title
      subtitle
      htmlDocument
      image
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSalon = /* GraphQL */ `
  mutation UpdateSalon(
    $input: UpdateSalonInput!
    $condition: ModelSalonConditionInput
  ) {
    updateSalon(input: $input, condition: $condition) {
      id
      formateurId
      organisateurId
      title
      subtitle
      htmlDocument
      image
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSalon = /* GraphQL */ `
  mutation DeleteSalon(
    $input: DeleteSalonInput!
    $condition: ModelSalonConditionInput
  ) {
    deleteSalon(input: $input, condition: $condition) {
      id
      formateurId
      organisateurId
      title
      subtitle
      htmlDocument
      image
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMemberShip = /* GraphQL */ `
  mutation CreateMemberShip(
    $input: CreateMemberShipInput!
    $condition: ModelmemberShipConditionInput
  ) {
    createMemberShip(input: $input, condition: $condition) {
      id
      formateurId
      companyAccountId
      administratorPrivileges
      accountantPrivileges
      editorPrivileges
      communicationPrivileges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMemberShip = /* GraphQL */ `
  mutation UpdateMemberShip(
    $input: UpdateMemberShipInput!
    $condition: ModelmemberShipConditionInput
  ) {
    updateMemberShip(input: $input, condition: $condition) {
      id
      formateurId
      companyAccountId
      administratorPrivileges
      accountantPrivileges
      editorPrivileges
      communicationPrivileges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMemberShip = /* GraphQL */ `
  mutation DeleteMemberShip(
    $input: DeleteMemberShipInput!
    $condition: ModelmemberShipConditionInput
  ) {
    deleteMemberShip(input: $input, condition: $condition) {
      id
      formateurId
      companyAccountId
      administratorPrivileges
      accountantPrivileges
      editorPrivileges
      communicationPrivileges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      from
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      from
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      from
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFormateur = /* GraphQL */ `
  mutation CreateFormateur(
    $input: CreateFormateurInput!
    $condition: ModelFormateurConditionInput
  ) {
    createFormateur(input: $input, condition: $condition) {
      id
      documentHtml
      cognitoId
      prenom
      isFollowed {
        nextToken
        __typename
      }
      nom
      url
      state
      imageId
      pixelCropWidth
      pixelCropHeight
      pixelCropX
      pixelCropY
      hasPhoto
      photoHash
      note
      nbAvis
      youtube
      linkedin
      intro
      markdown
      hashList
      attributes {
        nextToken
        __typename
      }
      cycles {
        nextToken
        __typename
      }
      publications {
        nextToken
        __typename
      }
      publicationList
      documentPublications {
        nextToken
        __typename
      }
      mailingList {
        nextToken
        __typename
      }
      specialites
      sanboxLemonwayId
      lemonwayId
      companyAccountId
      promotionProgram
      abonnement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFormateur = /* GraphQL */ `
  mutation UpdateFormateur(
    $input: UpdateFormateurInput!
    $condition: ModelFormateurConditionInput
  ) {
    updateFormateur(input: $input, condition: $condition) {
      id
      documentHtml
      cognitoId
      prenom
      isFollowed {
        nextToken
        __typename
      }
      nom
      url
      state
      imageId
      pixelCropWidth
      pixelCropHeight
      pixelCropX
      pixelCropY
      hasPhoto
      photoHash
      note
      nbAvis
      youtube
      linkedin
      intro
      markdown
      hashList
      attributes {
        nextToken
        __typename
      }
      cycles {
        nextToken
        __typename
      }
      publications {
        nextToken
        __typename
      }
      publicationList
      documentPublications {
        nextToken
        __typename
      }
      mailingList {
        nextToken
        __typename
      }
      specialites
      sanboxLemonwayId
      lemonwayId
      companyAccountId
      promotionProgram
      abonnement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFormateur = /* GraphQL */ `
  mutation DeleteFormateur(
    $input: DeleteFormateurInput!
    $condition: ModelFormateurConditionInput
  ) {
    deleteFormateur(input: $input, condition: $condition) {
      id
      documentHtml
      cognitoId
      prenom
      isFollowed {
        nextToken
        __typename
      }
      nom
      url
      state
      imageId
      pixelCropWidth
      pixelCropHeight
      pixelCropX
      pixelCropY
      hasPhoto
      photoHash
      note
      nbAvis
      youtube
      linkedin
      intro
      markdown
      hashList
      attributes {
        nextToken
        __typename
      }
      cycles {
        nextToken
        __typename
      }
      publications {
        nextToken
        __typename
      }
      publicationList
      documentPublications {
        nextToken
        __typename
      }
      mailingList {
        nextToken
        __typename
      }
      specialites
      sanboxLemonwayId
      lemonwayId
      companyAccountId
      promotionProgram
      abonnement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClientAttribute = /* GraphQL */ `
  mutation CreateClientAttribute(
    $input: CreateClientAttributeInput!
    $condition: ModelClientAttributeConditionInput
  ) {
    createClientAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClientAttribute = /* GraphQL */ `
  mutation UpdateClientAttribute(
    $input: UpdateClientAttributeInput!
    $condition: ModelClientAttributeConditionInput
  ) {
    updateClientAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClientAttribute = /* GraphQL */ `
  mutation DeleteClientAttribute(
    $input: DeleteClientAttributeInput!
    $condition: ModelClientAttributeConditionInput
  ) {
    deleteClientAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGatewayEvents = /* GraphQL */ `
  mutation CreateGatewayEvents(
    $input: CreateGatewayEventsInput!
    $condition: ModelGatewayEventsConditionInput
  ) {
    createGatewayEvents(input: $input, condition: $condition) {
      id
      channel
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGatewayEvents = /* GraphQL */ `
  mutation UpdateGatewayEvents(
    $input: UpdateGatewayEventsInput!
    $condition: ModelGatewayEventsConditionInput
  ) {
    updateGatewayEvents(input: $input, condition: $condition) {
      id
      channel
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGatewayEvents = /* GraphQL */ `
  mutation DeleteGatewayEvents(
    $input: DeleteGatewayEventsInput!
    $condition: ModelGatewayEventsConditionInput
  ) {
    deleteGatewayEvents(input: $input, condition: $condition) {
      id
      channel
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPresentCards = /* GraphQL */ `
  mutation CreatePresentCards(
    $input: CreatePresentCardsInput!
    $condition: ModelPresentCardsConditionInput
  ) {
    createPresentCards(input: $input, condition: $condition) {
      id
      clientReceiverId
      clientReceiverEmail
      clientDonorId
      amount
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePresentCards = /* GraphQL */ `
  mutation UpdatePresentCards(
    $input: UpdatePresentCardsInput!
    $condition: ModelPresentCardsConditionInput
  ) {
    updatePresentCards(input: $input, condition: $condition) {
      id
      clientReceiverId
      clientReceiverEmail
      clientDonorId
      amount
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePresentCards = /* GraphQL */ `
  mutation DeletePresentCards(
    $input: DeletePresentCardsInput!
    $condition: ModelPresentCardsConditionInput
  ) {
    deletePresentCards(input: $input, condition: $condition) {
      id
      clientReceiverId
      clientReceiverEmail
      clientDonorId
      amount
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFormateurAttribute = /* GraphQL */ `
  mutation CreateFormateurAttribute(
    $input: CreateFormateurAttributeInput!
    $condition: ModelFormateurAttributeConditionInput
  ) {
    createFormateurAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFormateurAttribute = /* GraphQL */ `
  mutation UpdateFormateurAttribute(
    $input: UpdateFormateurAttributeInput!
    $condition: ModelFormateurAttributeConditionInput
  ) {
    updateFormateurAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFormateurAttribute = /* GraphQL */ `
  mutation DeleteFormateurAttribute(
    $input: DeleteFormateurAttributeInput!
    $condition: ModelFormateurAttributeConditionInput
  ) {
    deleteFormateurAttribute(input: $input, condition: $condition) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCredit = /* GraphQL */ `
  mutation CreateCredit(
    $input: CreateCreditInput!
    $condition: ModelCreditConditionInput
  ) {
    createCredit(input: $input, condition: $condition) {
      id
      clientId
      category
      amount
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCredit = /* GraphQL */ `
  mutation UpdateCredit(
    $input: UpdateCreditInput!
    $condition: ModelCreditConditionInput
  ) {
    updateCredit(input: $input, condition: $condition) {
      id
      clientId
      category
      amount
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCredit = /* GraphQL */ `
  mutation DeleteCredit(
    $input: DeleteCreditInput!
    $condition: ModelCreditConditionInput
  ) {
    deleteCredit(input: $input, condition: $condition) {
      id
      clientId
      category
      amount
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      cognitoId
      prenom
      follows {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      credits {
        nextToken
        __typename
      }
      newsletterConsentDate
      suscribeStatus
      CGUVersion
      nom
      sanboxLemonwayId
      lemonwayId
      TVAid
      emailId
      email {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
        __typename
      }
      attributes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      cognitoId
      prenom
      follows {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      credits {
        nextToken
        __typename
      }
      newsletterConsentDate
      suscribeStatus
      CGUVersion
      nom
      sanboxLemonwayId
      lemonwayId
      TVAid
      emailId
      email {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
        __typename
      }
      attributes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      cognitoId
      prenom
      follows {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      credits {
        nextToken
        __typename
      }
      newsletterConsentDate
      suscribeStatus
      CGUVersion
      nom
      sanboxLemonwayId
      lemonwayId
      TVAid
      emailId
      email {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
        __typename
      }
      attributes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmailTag = /* GraphQL */ `
  mutation CreateEmailTag(
    $input: CreateEmailTagInput!
    $condition: ModelEmailTagConditionInput
  ) {
    createEmailTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmailTag = /* GraphQL */ `
  mutation UpdateEmailTag(
    $input: UpdateEmailTagInput!
    $condition: ModelEmailTagConditionInput
  ) {
    updateEmailTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmailTag = /* GraphQL */ `
  mutation DeleteEmailTag(
    $input: DeleteEmailTagInput!
    $condition: ModelEmailTagConditionInput
  ) {
    deleteEmailTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmail = /* GraphQL */ `
  mutation CreateEmail(
    $input: CreateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    createEmail(input: $input, condition: $condition) {
      id
      clientId
      email
      formateurOwnerId
      pekoiaClient
      source
      suscribedStatus
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmail = /* GraphQL */ `
  mutation UpdateEmail(
    $input: UpdateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    updateEmail(input: $input, condition: $condition) {
      id
      clientId
      email
      formateurOwnerId
      pekoiaClient
      source
      suscribedStatus
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmail = /* GraphQL */ `
  mutation DeleteEmail(
    $input: DeleteEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    deleteEmail(input: $input, condition: $condition) {
      id
      clientId
      email
      formateurOwnerId
      pekoiaClient
      source
      suscribedStatus
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      eventId
      key
      question
      grade
      comment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      eventId
      key
      question
      grade
      comment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      eventId
      key
      question
      grade
      comment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPublication = /* GraphQL */ `
  mutation CreatePublication(
    $input: CreatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    createPublication(input: $input, condition: $condition) {
      id
      index
      formateurId
      organisateurId
      salonId
      type
      imageId
      imageExt
      imageTitle
      url
      ogTitle
      ogImage
      category
      activityType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePublication = /* GraphQL */ `
  mutation UpdatePublication(
    $input: UpdatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    updatePublication(input: $input, condition: $condition) {
      id
      index
      formateurId
      organisateurId
      salonId
      type
      imageId
      imageExt
      imageTitle
      url
      ogTitle
      ogImage
      category
      activityType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePublication = /* GraphQL */ `
  mutation DeletePublication(
    $input: DeletePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    deletePublication(input: $input, condition: $condition) {
      id
      index
      formateurId
      organisateurId
      salonId
      type
      imageId
      imageExt
      imageTitle
      url
      ogTitle
      ogImage
      category
      activityType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventsLogs = /* GraphQL */ `
  mutation CreateEventsLogs(
    $input: CreateEventsLogsInput!
    $condition: ModelEventsLogsConditionInput
  ) {
    createEventsLogs(input: $input, condition: $condition) {
      id
      type
      value
      key
      handled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventsLogs = /* GraphQL */ `
  mutation UpdateEventsLogs(
    $input: UpdateEventsLogsInput!
    $condition: ModelEventsLogsConditionInput
  ) {
    updateEventsLogs(input: $input, condition: $condition) {
      id
      type
      value
      key
      handled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventsLogs = /* GraphQL */ `
  mutation DeleteEventsLogs(
    $input: DeleteEventsLogsInput!
    $condition: ModelEventsLogsConditionInput
  ) {
    deleteEventsLogs(input: $input, condition: $condition) {
      id
      type
      value
      key
      handled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      modelId
      isLinkedToModel
      formateurId
      cycleId
      isLiked {
        nextToken
        __typename
      }
      organisateurId
      documentId
      state
      title1
      title2
      langue
      categories
      categoriesCode
      niveau
      prix
      date
      duree
      duration
      linkUrl
      type
      urls
      format
      nbMax
      nbReserved
      attendingNb
      outil
      outilUrl
      tags
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      eTagProcess
      htmlText {
        id
        modelId
        content
        HtmlCategory
        createdAt
        updatedAt
        __typename
      }
      address
      lat
      lng
      password
      liveStatus
      replayCategory
      replayWeTransferUrl
      replayKey
      replayStatus
      futurReplay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      modelId
      isLinkedToModel
      formateurId
      cycleId
      isLiked {
        nextToken
        __typename
      }
      organisateurId
      documentId
      state
      title1
      title2
      langue
      categories
      categoriesCode
      niveau
      prix
      date
      duree
      duration
      linkUrl
      type
      urls
      format
      nbMax
      nbReserved
      attendingNb
      outil
      outilUrl
      tags
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      eTagProcess
      htmlText {
        id
        modelId
        content
        HtmlCategory
        createdAt
        updatedAt
        __typename
      }
      address
      lat
      lng
      password
      liveStatus
      replayCategory
      replayWeTransferUrl
      replayKey
      replayStatus
      futurReplay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      modelId
      isLinkedToModel
      formateurId
      cycleId
      isLiked {
        nextToken
        __typename
      }
      organisateurId
      documentId
      state
      title1
      title2
      langue
      categories
      categoriesCode
      niveau
      prix
      date
      duree
      duration
      linkUrl
      type
      urls
      format
      nbMax
      nbReserved
      attendingNb
      outil
      outilUrl
      tags
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      eTagProcess
      htmlText {
        id
        modelId
        content
        HtmlCategory
        createdAt
        updatedAt
        __typename
      }
      address
      lat
      lng
      password
      liveStatus
      replayCategory
      replayWeTransferUrl
      replayKey
      replayStatus
      futurReplay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCycle = /* GraphQL */ `
  mutation CreateCycle(
    $input: CreateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    createCycle(input: $input, condition: $condition) {
      id
      formateurId
      organisateurId
      title
      status
      replayStatus
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      nbMax
      attendingNb
      prix
      events {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCycle = /* GraphQL */ `
  mutation UpdateCycle(
    $input: UpdateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    updateCycle(input: $input, condition: $condition) {
      id
      formateurId
      organisateurId
      title
      status
      replayStatus
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      nbMax
      attendingNb
      prix
      events {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCycle = /* GraphQL */ `
  mutation DeleteCycle(
    $input: DeleteCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    deleteCycle(input: $input, condition: $condition) {
      id
      formateurId
      organisateurId
      title
      status
      replayStatus
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      nbMax
      attendingNb
      prix
      events {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStates = /* GraphQL */ `
  mutation CreateStates(
    $input: CreateStatesInput!
    $condition: ModelStatesConditionInput
  ) {
    createStates(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStates = /* GraphQL */ `
  mutation UpdateStates(
    $input: UpdateStatesInput!
    $condition: ModelStatesConditionInput
  ) {
    updateStates(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStates = /* GraphQL */ `
  mutation DeleteStates(
    $input: DeleteStatesInput!
    $condition: ModelStatesConditionInput
  ) {
    deleteStates(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFollows = /* GraphQL */ `
  mutation CreateFollows(
    $input: CreateFollowsInput!
    $condition: ModelFollowsConditionInput
  ) {
    createFollows(input: $input, condition: $condition) {
      id
      clientId
      formateurId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFollows = /* GraphQL */ `
  mutation UpdateFollows(
    $input: UpdateFollowsInput!
    $condition: ModelFollowsConditionInput
  ) {
    updateFollows(input: $input, condition: $condition) {
      id
      clientId
      formateurId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFollows = /* GraphQL */ `
  mutation DeleteFollows(
    $input: DeleteFollowsInput!
    $condition: ModelFollowsConditionInput
  ) {
    deleteFollows(input: $input, condition: $condition) {
      id
      clientId
      formateurId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLikes = /* GraphQL */ `
  mutation CreateLikes(
    $input: CreateLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    createLikes(input: $input, condition: $condition) {
      id
      clientId
      eventId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLikes = /* GraphQL */ `
  mutation UpdateLikes(
    $input: UpdateLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    updateLikes(input: $input, condition: $condition) {
      id
      clientId
      eventId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLikes = /* GraphQL */ `
  mutation DeleteLikes(
    $input: DeleteLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    deleteLikes(input: $input, condition: $condition) {
      id
      clientId
      eventId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      title
      clientId
      eventId
      eventImageKey
      eventImageETag300
      isReplay
      date
      eventDate
      expiryDate
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      title
      clientId
      eventId
      eventImageKey
      eventImageETag300
      isReplay
      date
      eventDate
      expiryDate
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      title
      clientId
      eventId
      eventImageKey
      eventImageETag300
      isReplay
      date
      eventDate
      expiryDate
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganisateur = /* GraphQL */ `
  mutation CreateOrganisateur(
    $input: CreateOrganisateurInput!
    $condition: ModelOrganisateurConditionInput
  ) {
    createOrganisateur(input: $input, condition: $condition) {
      id
      nom
      publications {
        nextToken
        __typename
      }
      note
      nbAvis
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganisateur = /* GraphQL */ `
  mutation UpdateOrganisateur(
    $input: UpdateOrganisateurInput!
    $condition: ModelOrganisateurConditionInput
  ) {
    updateOrganisateur(input: $input, condition: $condition) {
      id
      nom
      publications {
        nextToken
        __typename
      }
      note
      nbAvis
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganisateur = /* GraphQL */ `
  mutation DeleteOrganisateur(
    $input: DeleteOrganisateurInput!
    $condition: ModelOrganisateurConditionInput
  ) {
    deleteOrganisateur(input: $input, condition: $condition) {
      id
      nom
      publications {
        nextToken
        __typename
      }
      note
      nbAvis
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProcessController = /* GraphQL */ `
  mutation CreateProcessController(
    $input: CreateProcessControllerInput!
    $condition: ModelProcessControllerConditionInput
  ) {
    createProcessController(input: $input, condition: $condition) {
      id
      name
      jsonState
      lastUpdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProcessController = /* GraphQL */ `
  mutation UpdateProcessController(
    $input: UpdateProcessControllerInput!
    $condition: ModelProcessControllerConditionInput
  ) {
    updateProcessController(input: $input, condition: $condition) {
      id
      name
      jsonState
      lastUpdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProcessController = /* GraphQL */ `
  mutation DeleteProcessController(
    $input: DeleteProcessControllerInput!
    $condition: ModelProcessControllerConditionInput
  ) {
    deleteProcessController(input: $input, condition: $condition) {
      id
      name
      jsonState
      lastUpdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocumentHtml = /* GraphQL */ `
  mutation CreateDocumentHtml(
    $input: CreateDocumentHtmlInput!
    $condition: ModelDocumentHtmlConditionInput
  ) {
    createDocumentHtml(input: $input, condition: $condition) {
      id
      modelId
      events {
        nextToken
        __typename
      }
      content
      HtmlCategory
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocumentHtml = /* GraphQL */ `
  mutation UpdateDocumentHtml(
    $input: UpdateDocumentHtmlInput!
    $condition: ModelDocumentHtmlConditionInput
  ) {
    updateDocumentHtml(input: $input, condition: $condition) {
      id
      modelId
      events {
        nextToken
        __typename
      }
      content
      HtmlCategory
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocumentHtml = /* GraphQL */ `
  mutation DeleteDocumentHtml(
    $input: DeleteDocumentHtmlInput!
    $condition: ModelDocumentHtmlConditionInput
  ) {
    deleteDocumentHtml(input: $input, condition: $condition) {
      id
      modelId
      events {
        nextToken
        __typename
      }
      content
      HtmlCategory
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      eventId
      cycleId
      giftCard
      receiverEmail
      clientId
      sandboxId
      lemonwayId
      lemonwayCardId
      lemonwayExpiration
      lemonwayMaskedNumber
      lemonwayType
      status
      promoStatus
      scheduledTask
      scheduledDate
      createDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      eventId
      cycleId
      giftCard
      receiverEmail
      clientId
      sandboxId
      lemonwayId
      lemonwayCardId
      lemonwayExpiration
      lemonwayMaskedNumber
      lemonwayType
      status
      promoStatus
      scheduledTask
      scheduledDate
      createDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      eventId
      cycleId
      giftCard
      receiverEmail
      clientId
      sandboxId
      lemonwayId
      lemonwayCardId
      lemonwayExpiration
      lemonwayMaskedNumber
      lemonwayType
      status
      promoStatus
      scheduledTask
      scheduledDate
      createDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessageContent = /* GraphQL */ `
  mutation CreateMessageContent(
    $input: CreateMessageContentInput!
    $condition: ModelMessageContentConditionInput
  ) {
    createMessageContent(input: $input, condition: $condition) {
      id
      category
      content
      hash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessageContent = /* GraphQL */ `
  mutation UpdateMessageContent(
    $input: UpdateMessageContentInput!
    $condition: ModelMessageContentConditionInput
  ) {
    updateMessageContent(input: $input, condition: $condition) {
      id
      category
      content
      hash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessageContent = /* GraphQL */ `
  mutation DeleteMessageContent(
    $input: DeleteMessageContentInput!
    $condition: ModelMessageContentConditionInput
  ) {
    deleteMessageContent(input: $input, condition: $condition) {
      id
      category
      content
      hash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      category
      status
      date
      contextKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      category
      status
      date
      contextKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      category
      status
      date
      contextKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSendMessage = /* GraphQL */ `
  mutation CreateSendMessage(
    $input: CreateSendMessageInput!
    $condition: ModelSendMessageConditionInput
  ) {
    createSendMessage(input: $input, condition: $condition) {
      id
      messageContentId
      destFormateurId
      destFormateurTag
      destClientId
      destClientTag
      priority
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSendMessage = /* GraphQL */ `
  mutation UpdateSendMessage(
    $input: UpdateSendMessageInput!
    $condition: ModelSendMessageConditionInput
  ) {
    updateSendMessage(input: $input, condition: $condition) {
      id
      messageContentId
      destFormateurId
      destFormateurTag
      destClientId
      destClientTag
      priority
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSendMessage = /* GraphQL */ `
  mutation DeleteSendMessage(
    $input: DeleteSendMessageInput!
    $condition: ModelSendMessageConditionInput
  ) {
    deleteSendMessage(input: $input, condition: $condition) {
      id
      messageContentId
      destFormateurId
      destFormateurTag
      destClientId
      destClientTag
      priority
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
