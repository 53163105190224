
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { colorTaupeClair } from '../AppStyle'
import { colorGray } from '../AppStyle'
import Rating from '@mui/material/Rating'
import { useSelector } from 'react-redux'
import { getFullDate } from '../components/general'

import {grey} from '@mui/material/colors'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'
import { API, graphqlOperation } from 'aws-amplify'
import { listEvents } from '../graphql/queries'


const singleElementWidth = "12vw"


const EspaceAnalyse = ({ nbLeads = 0, nbConferences = 0, nbParticipants = 0, montantDons = 0, nbFollowers = 0, nbAvis = 0, noteMoyenne = 0 }) => {
    let note = 0
    let avis = 0
    let followers = 0

    const conferenceData = useSelector(state => state.eventsReducer)?.data
        .filter(event => event.state === "published")
        .sort((a, b) => b.date - a.date)

    const cycleData = useSelector(state => state.cyclesReducer)?.data

    const currentTime = Date.now()/1000

    const user = useSelector(state => state.dataReducer)

    const upcomingConferenceData = conferenceData.filter(
        event => {
            return ((event.date + (event.duree) * 60) > currentTime)
        }
    )

    const replaysData = useSelector(state => state.eventsReducer)?.data
        .filter(event => event.state === "published")
        .sort((a, b) => a.date - b.date)
        .filter(
            event => {
                return ((event.date + (event.duree) * 60) < currentTime)
            }
        )

    if (typeof (user?.followers) === 'number')
        followers = user?.followers

    if (typeof (user?.note) === 'number')
        note = user?.note

    if (typeof (user?.nbAvis) === 'number')
        avis = user?.nbAvis

    const participants = conferenceData
        .map(event => {
            let particip = 0
            if (typeof (event?.attendingNb) === 'number')
                particip = event?.attendingNb
            return particip
        })
        .reduce((a, b) => a + b, 0)

    const montantCA = conferenceData
        .map(event => {
            let attendingNb = 0
            let prix = 0
            if (typeof (event?.attendingNb) === 'number')
                attendingNb = event?.attendingNb
            if (typeof (event?.prix) === 'number')
                prix = event.prix
            return prix * attendingNb
        })
        .reduce((a, b) => a + b, 0)



    return (



        <Box >

            <Typography style={{
                marginTop: "2vw",
                marginBottom: "2vw",
                color: '#1a5aa3',

            }} variant="h1" component="h1">{'votre tableau de bord conférencier'.toUpperCase()}</Typography>
            <Box width='500px'>
            <Typography variant="h3" style={{  marginBottom: "1.5vw", fontSize: "0.9rem" }}>Depuis votre inscription sur Pekoia:</Typography>

            </Box>
           

            <Box display="flex" padding="15px" bgcolor={colorTaupeClair}>
                <ElementTableau amount={nbLeads} label="Nombre de leads" />
                <ElementTableau amount={conferenceData.length - upcomingConferenceData.length} label="Conférences animées" />
                <ElementTableau amount={participants} label="Places vendues" />
                <ElementTableau euros={true} amount={montantDons} label="de Dons aux associations généré" />
                <ElementTableau euros={true} amount={montantCA} label="de chiffre d'affaires généré" />
                <ElementTableau amount={followers} label="Followers" />
                <ElementSpecial nbAvis={avis} noteMoyenne={note} />

            </Box>
            <Typography marginTop="5px">
                Pour davantage d'informations veuillez nous contacter : contact@pekoia.com
            </Typography>
            <Box>
                <Typography style={{
                    marginTop: "2vw",
                    marginBottom: "2vw",
                    color: "#1a5aa3"

                }} variant="h1" component="h1">{'suivi des ventes'.toUpperCase()}</Typography>
            </Box>
            <Box >
                <Typography style={{
                    marginTop: "2vw",
                    marginBottom: "2vw"
                }} component="h1">{'Conférences'.toUpperCase()}</Typography>
                {upcomingConferenceData.length === 0 && <Box padding="10px">
                    <Typography>
                        Aucune conférence en promotion
                    </Typography>
                </Box>}
                {upcomingConferenceData.length > 0 && <React.Fragment><DisplayEvent events={upcomingConferenceData} /></React.Fragment>}
            </Box>

            <Box>
            <Typography style={{
                    marginTop: "2vw",
                    marginBottom: "2vw"
                }} component="h1">{'Cycles'.toUpperCase()}</Typography>
                {cycleData.length === 0 && <Box padding="10px">
                    <Typography>
                        Aucun cycles en promotion
                    </Typography>
                </Box>}
                {cycleData.length > 0 && <React.Fragment><DisplayEvent events={cycleData.map((cycle) => { return {...cycle}})} /></React.Fragment>}
            </Box>
            <Box>
                <Typography style={{
                    marginTop: "2vw",
                    marginBottom: "2vw"
                }} component="h1">{'Conférences à la demande'.toUpperCase()}</Typography>
                {replaysData.length === 0 && <Box padding="10px">
                    <Typography>
                        Aucune conférences à la demande en promotion
                    </Typography>
                </Box>}
                {replaysData.length > 0 && <React.Fragment><DisplayEvent events={replaysData}/></React.Fragment>}
            </Box>

        </Box>

    )

}

export default EspaceAnalyse


const DisplayEvent = ({ events }) => {
    const [nearest, setNearest] = useState(null)

    const getNearestDate = async (cycle) => {
        const res = (await API.graphql(graphqlOperation(listEvents, { filter: { cycleId: { eq: cycle.id }}})))
        let nearest = null;
        for (const elem of res.data.listEvents.items) {
            if (nearest === null) {
                nearest = elem.date
            } else if (elem.date < nearest) {
                nearest = elem.date
            }
        }
        setNearest(nearest)
    }

    return (<Box width="100%" border={`1px solid ${grey[300]}`} padding="0 20px 20px 20px">
        <Box height="70px" display="flex" flexDirection="row" alignContent="center" alignItems="center" justifyContent="space-between" width="100%">

            <Box flex={2}>
                <Typography style={{ fontWeight: 700 }}>
                    titre
                </Typography>

            </Box>
            <Box flex={1}>
                <Typography style={{ fontWeight: 700 }}>
                    date
                </Typography>

            </Box>

            <Box flex={1} >
                <Typography style={{ fontWeight: 700 }}>
                    ventes
                </Typography>

            </Box>
            <Box width="70px">

            </Box>
        </Box>

        {events.map((event, index) => {

            let nbReserved = 0

            if (typeof (event.nbReserved) === 'number')
                nbReserved = event.nbReserved






            const capacite = event.nbMax - nbReserved

            let nbAttending = 0

            if (typeof (event?.attendingNb) === 'number')
                nbAttending = event?.attendingNb



            const percentage = nbAttending / capacite * 100



            return (<Box height="50px" key={index} display="flex" flexDirection="row" alignContent="center" alignItems="center" justifyContent="space-between" width="100%">

                <Box flex={2}>
                    <Typography>
                        {event.title1 !== undefined ? event.title1 : event.title}
                    </Typography>

                </Box>
                <DisplayDate event={event}/>
                {/* <Box flex={1}>
                    <Typography>
                        {event.date ? getFullDate(event.date)?.dateShort : getFullDate(nearest)?.dateShort}
                    </Typography>

                </Box> */}

                <Box flex={1}>
                    <Typography>
                        {nbAttending}
                    </Typography>

                </Box>
                <Box width="70px">
                    <Box height="40px" width="40px">
                        <CircularProgressbar value={percentage} text={`${percentage}%`} />
                    </Box>
                </Box>



            </Box>)
        })}
    </Box>)
}

const DisplayDate = ({event}) => {
    const [nearest, setNearest] = useState(null)

    const getNearestDate = async (cycle) => {
        const res = (await API.graphql(graphqlOperation(listEvents, { filter: { cycleId: { eq: cycle.id }}})))
        let nearest = null;
        for (const elem of res.data.listEvents.items) {
            if (nearest === null) {
                nearest = elem.date
            } else if (elem.date < nearest) {
                nearest = elem.date
            }
        }
        setNearest(nearest)
    }

    useEffect(() => {
        getNearestDate(event);
    })

    return <Box flex={1}>
        <Typography>
            {event.date ? getFullDate(event.date)?.dateShort : getFullDate(nearest)?.dateShort}
        </Typography>
    </Box>
}

const ElementTableau = ({ euros, label, amount }) => {
    return (<Box margin="0.5vw" padding="1vw" width={singleElementWidth} bgcolor={colorGray}>
        <Typography variant="body1" style={{ fontWeight: "bold", textAlign: "center", fontSize: "1rem" }}>
            {euros ? amount + "€" : amount}
        </Typography>
        <Typography variant="body1" style={{ marginTop: "1vw", textAlign: "center", fontSize: "0.8rem", lineHeight: "1.2rem" }}>
            {label}
        </Typography>

    </Box>)
}
const ElementSpecial = ({ nbAvis = 0, noteMoyenne }) => {
    return (<Box margin="0.5vw" padding="1vw" width={singleElementWidth} bgcolor={colorGray}>

        <Typography variant="body1" style={{ fontWeight: "bold", textAlign: "center", fontSize: "1rem" }}>
            {nbAvis}
        </Typography>
        <Typography variant="body1" style={{ marginTop: "1vw", textAlign: "center", fontSize: "0.8rem", lineHeight: "1.2rem" }} >
            Note moyenne
        </Typography>
        <Typography style={{ marginTop: "1vw", textAlign: "center", fontSize: "0.8rem", lineHeight: "1.2rem" }}>
            ({nbAvis} avis)
        </Typography>
        <Rating

            name="half-rating-read"
            defaultValue={noteMoyenne}
            precision={0.5}
            size="small"
            readOnly
        />


    </Box>)
}
