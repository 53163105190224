import React from 'react'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HelpIcon from '@mui/icons-material/Help';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Avatar from '@mui/material/Avatar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Auth } from "aws-amplify"
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setMenuPage } from "../../features/navigation/nevigationSlice"

const logoutHandler = () => {
    Auth.signOut()
        .then(data => console.log('signed OUT'))
}



const useStyles = makeStyles((theme) => ({
    paper: {
        border: `1px solid ${grey[300]}`,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
    },
    small: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    }
}));

const Menu = ({ open, anchorEl, data, setAnchorEl }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const menuItems = [
    {
        label: 'Conditions générales',
        icon: <ReceiptIcon />,
        clickHandler: logoutHandler
    },
    {
        label: 'Support',
        icon: <HelpIcon />,
        clickHandler: logoutHandler
    
    },
    {
        label: 'Déconnexion',
        icon: <ExitToAppIcon />,
        clickHandler: logoutHandler
    }
    ]


    return (< Popper open={open} anchorEl={anchorEl} style={{ boxShadow: '7px 7px 13px 6px rgba(102,90,101,0.28)' }}>

        <Box width='350px' display="flex" flexDirection="column" padding='15px' className={classes.paper}>
            <Typography style={{ fontWeight: "bold", marginBottom: '15px', color: grey[700] }}>
                {data?.prenom}{" "}{data?.nom}
            </Typography>
            <Divider variant="middle" />
            {menuItems.map(item => <MenuItem key={item?.label} item={item} />)}
        </Box>


    </Popper >)
}

export default Menu

const MenuItem = ({ item }) => {
    const classes = useStyles();
    return (<StyledItem  onClick={item.clickHandler}>
        <Box paddingRight="15px">


            <Avatar style={{ backgroundColor: grey[200], color: grey[600] }} className={classes.small}>
                {item.icon}
            </Avatar>
        </Box>


        <Typography style={{ fontSize: '0.9rem', padding: '10px 0 10px 0' }}>
            {item.label}
        </Typography>
    </StyledItem>)
}

const StyledItem = styled.button`
display: flex;
flex-direction: row;
align-items: center;
padding: 5px 0 5px 0;
border: 0px solid;
background-color: white;
outline: none;
cursor: pointer;
&:hover {
    background-color: ${grey[100]};
}
`;



