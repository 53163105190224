import { Badge, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";

const Dot = ({ color }) => {
	return (<Box width="15px" height="15px" marginTop="5px">
		<svg viewBox="0 0 150 150" >
			<circle cx={75} cy={75} r={60} fill={color} />
		</svg>
	</Box>)
}

const LemonwayStatus = ({ lemonwayId }) => {
  return (
    <Box display="flex" flexDirection="row" width="100%">
      <Typography variant="h6"  marginRight="10px" >
        Statut de votre enregistrement Lemonway
      </Typography>
      <Dot color={lemonwayId ? 'green' : 'red'}/>
      <Typography marginTop="6px" marginLeft="30px">
        {lemonwayId ? "Vous pouvez publier des conférences payantes sur Pekoia" : "Vous pouvez seulement publier des conférences gratuites sur Pekoia"}
      </Typography>
    </Box>
  );
}

export default LemonwayStatus