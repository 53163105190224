import React from 'react'
import { Link } from 'react-router-dom'

// style={{border: '2px solid red'}}

const Header = ({discoverButtonHandler}) => {
  return (
    <>
      {/* Navigation 3 */}
      <nav className="pt-10 pb-10 text-center text-xl-left navigation_3 bg-dark" >
        <div className="container px-xl-0" >
          <div className="row align-items-cenrter" >
            <div className="col-xl-2">
              <Link to="/" className="link img_link">
                <img src="uploads/logobackofficeblanc.svg" alt="Logo" className="img-fluid" data-aos="fade-down" data-aos-delay="0" />
              </Link>
            </div>
            <div className="mt-10 mb-10 my-xl-0 col-xl-8 d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center lh-40" data-aos="fade-down" data-aos-delay="250">
              <a target="_blank" href="https://pekoia.stonly.com/kb/fr?1631261052697&lang=fr" className="link mr-15 color-bg-light">
                Aide  à la prise en main du back office
              </a>
              <div  className="btn action-1 sm my-0 mx-150" onClick={()=>discoverButtonHandler('connect')}>
                Connexion
              </div>
            </div>
            <div className="col-xl-2 text-xl-right socials bg-dark" data-aos="fade-down" data-aos-delay="500">
              <Link to="#" className="link mr-15 f-18 color-bg-light">
                <i className="fab fa-twitter">
                </i>
              </Link>
              <Link to="#" className="link mx-15 f-18 color-bg-light">
                <i className="fab fa-facebook-square">
                </i>
              </Link>
            </div>
          </div>
        </div>
      </nav>
      {/* Navigation 6 */}
      <nav className="lh-40 text-center navigation_6 bg-action-1 pt-0 pb-2">
        <div className="container px-xl-0">
          <div className="row align-items-center">
            <div className="mb-10 mb-md-0 col-lg-3 col-md-4 text-md-left">
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
export default Header
