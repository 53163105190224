import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Button, Input } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { blueGrey, orange, purple, green, blue, red } from '@mui/material/colors';

import { DateTime } from 'luxon'

import { Storage } from "aws-amplify"
import Box from '@mui/material/Box'
import React from 'react'
import { ColorButton2 } from "../components/BO-style"

import fileRegexList from "./helpers/regexList"


export const colorBlue = "#273370"
export const colorYellow = "#F7B643"

export const dureeMap = [
{
  duree: 15,
  label: '15mn'
},
{
  duree: 30,
  label: '30mn'
},
{
  duree: 60,
  label: '1h'
},
{
  duree: 90,
  label: '1h30'
}]


const transformedRegexList = fileRegexList
                            .split('\n')
                            .filter(textString=>(textString != ''))
                            .map(textString=>new RegExp(`^https?:\/\/(www.)?(${textString})\/?.+$`, 'm'))

const StyledButton = withStyles({
  root: {
    background: colorBlue,
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: "2vw",
    width: "2vw",
    padding: "0.5vw",
    '&:hover': {
      backgroundColor: blue[800],
      color: '#FFF'
    }
  },
  label: {
  },
})(Button);

export const nullConference = () => {
  const refDate = new Date('January 1, 1970 00:00:00')
  const offsetInMs = Math.trunc(Date.now() / 3600000 / 24) * 3600000 * 24
  const today = new Date(refDate.setMilliseconds(offsetInMs))
  const nextWeek = new Date(today.setMinutes(7 * 24 * 60 + 11 * 60))

  return (
    {
      title1: "",
      title2: "",
      format: 'WEBINAR',
      images: null,
      organisateur: null,
      langue: 'Français',
      date: nextWeek,
      duree: 60,
      prix: 10,
      nbMax: 40,
      outil: 'ZOOM',
      outilUrl: '',
      url: null,
      password: null,
      checked: false,
      formateurId: '',
      category: '',
      categories: '',
      niveau: 'TOUS',
      categoriesCode: '0',
      futurReplay: false,
    })
}

export const nullImage = () => {

  return (
    {

    })
}


export const getFullDate = (epoch) => {

 const addZero = (number) =>{
   if(number < 10)
     return '0'+number
   return number + ''
 }

   const dt = DateTime.fromISO('1970-01-01T00:00:00Z')
   const newDate = dt.plus({ seconds: epoch })

   const superShort = addZero(newDate.setLocale('fr-FR').day )+ '/'+ addZero(newDate.setLocale('fr-FR').month)

   
   return ({
       luxonDate: newDate,
       time: newDate.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false }),
       date: newDate.setLocale('fr-FR').toLocaleString(DateTime.DATE_MED).replace('.',''),
       dateShort: newDate.setLocale('fr-FR').toLocaleString(DateTime.DATE_SHORT),
       dateDDMM:   superShort  ,
       weekDay: newDate.setLocale('fr-FR').toLocaleString(DateTime.DATE_HUGE)
   })
 }






export const getDate = (epoch) => {

  let newEpoch = epoch

  if (typeof(epoch) === "string")
    newEpoch = 0 

  const dt = DateTime.fromISO('1970-01-01T00:00:00Z')
  const newDate = dt.plus({ seconds: epoch })
  const date = new Date(newDate.toISO())
  return date
}

export function getModalStyle() {
  const top = 25;

  return {
    top: `${top}%`,
  };
}

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '0px',
    outline: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  paper2: {
    position: 'absolute',
    width: "40vw",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
    border: '0px solid #000',
    boxShadow: "5px 5px 20px 0px #cacacauserfuDatauserData",
    padding: theme.spacing(2, 4, 3),
  },
  deleteButton: {
    color: "white",
    marginLeft: "30vw",
    width: "8vw",
    height: "3vw",
    backgroundColor: red[400],
    '&:hover': {
      backgroundColor: red[800],
      color: '#FFF'
    }
  }

}), { index: 1 })

export const saveImage = (imageData, userId, conferenceId) => {


  if (imageData != null) {



    const imagesSet = [
      {
        ext: 'S',
        data: imageData.compressedS
      },
      {
        ext: 'L',
        data: imageData.compressedL
      }
    ]



    if (imagesSet.find((image) => image.ext === 'L').data) {
      imagesSet.forEach((image) => {

        const fileName = image.ext + conferenceId
        const fileExt = image.data.name.split('.').pop()


        Storage.put(`${userId}/conferences/${fileName}.${fileExt}`, image.data, { level: 'private', contentType: `image/${fileExt}` }).then(
          (e) => {

          }
        ).catch(
          (e) => {
          }
        )

      })

    }
  }
}

export const ShowImage = ({ conferenceImageData, size = "compressedS" }) => {

  const image = URL.createObjectURL(conferenceImageData[size])

  return (<Box>
    <img src={image} alt="" />
  </Box>

  )
}

export const ImageUploadButton = ({ setSelectFile }) => {


  const handleUploadClick = event => {
    const file = event.target.files[0]

    setSelectFile({ selectedFile: file })

  }

  return (
    <ColorButton2
      component="label">
      <Input accept="image/*"
        style={{ display: 'none' }}
        id="contained-button-file"
        name="upload-photo"
        type="file"
        onChange={handleUploadClick} />
      <AddAPhotoIcon />
    </ColorButton2>

  )




}


export const updateConferenceData = (conferenceData, setConferenceData, newData) => {

  const confereceId = newData.id
  const modifiedConference = conferenceData.find(conference => conference.id === confereceId)
  modifiedConference = newData
  setConferenceData(modifiedConference)

}

export const localeText = {
  footerRowSelected: (count) =>
  count !== 1
    ? `${count.toLocaleString()} lignes sélectionnées`
    : `${count.toLocaleString()} ligne sélectionnée`,
  footerTotalRows: 'total:',
  columnMenuFilter: 'Filtrer',
  columnMenuHideColumn: 'Cacher',
  columnMenuUnsort: 'Non trié',
  columnMenuSortAsc: 'Trier Asc',
  columnMenuSortDesc: 'Trier Des',
  filterPanelAddFilter: 'Nouveau Filtre',
  filterPanelDeleteIconLabel: 'Enlever',
  filterPanelOperators: 'Opérateurs',
  filterPanelOperatorAnd: 'Et',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colonnes'
}


// regex for accepted URL
export const regexList = [
  /^https:\/\/(www.)?(lefigaro.fr)\/.+$/m,
  /^https:\/\/(www.)?(lemonde.fr)\/.+$/m,
  /^https:\/\/(www.)?(lequipe.fr)\/.+$/m,
  /^https:\/\/(www.)?(youtube.com)\/.+$/m,
  /^https:\/\/(www.)?(philomag.com)\/.+$/m
].concat(transformedRegexList)


export const cleanText = (text) => {

  const subText1 = "&#039;"
  const subText2 = "&quot;"
  const subText3 = "&#39;"
  const subText4 = "&#x27;"

  if (text) {
    const tempText1 = text.replaceAll(subText1, `'`)
    const tempText2 = tempText1.replaceAll(subText2, `"`)
    const tempText3 = tempText2.replaceAll(subText3, `'`)
    const tempText4 = tempText3.replaceAll(subText4, `'`)

    return (tempText4)

  }

  return ''




}

export const cutText = (text, size) => {
  if (text.length >= size)
    return text.substring(0, size) + " ..."
  return text
}


export const langues = [
  {
    value: 'Français',
    label: 'Zoom',
  },
  {
    value: 'Allemand',
    label: 'Google Meet',
  },
  {
    value: 'Anglais',
    label: 'Microsoft Teams',
  },
  {
    value: 'Arabe',
    label: 'Microsoft Teams',
  },
  {
    value: 'Chinois',
    label: 'Microsoft Teams',
  },
  {
    value: 'Espagnol',
    label: 'Microsoft Teams',
  },
  {
    value: 'Ialien',
    label: 'Microsoft Teams',
  },
  {
    value: 'Japonais',
    label: 'Microsoft Teams',
  },
  {
    value: 'Néerlandais',
    label: 'Microsoft Teams',
  },
  {
    value: 'Portuguais',
    label: 'Microsoft Teams',
  },
  {
    value: 'Russe',
    label: 'Microsoft Teams',
  }
]

export const PublishSingleButton = withStyles((theme) => ({
  root: {
      color: theme.palette.getContrastText(purple[500]),
      padding: "5px",
      minHeight: 0,
      minWidth: "100px",
      borderRadius: 0,
      backgroundColor: blueGrey[200],
      color: theme.palette.getContrastText(blueGrey[200]),
      '&:hover': {
          backgroundColor: blueGrey[300],
      },
  },
}))(Button)


