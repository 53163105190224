import { createSlice } from '@reduxjs/toolkit'

export const cyclesSlice = createSlice({
    name: 'cycles',
    initialState: {
        loading: "true",
        totalCount: 0,
        data: [],
        rows: []
    },

    reducers: {
        addCycle: (state, action) => {
            return ({
                loading: "false",
                data: state.data.concat(action.payload),
                totalCount: state.totalCount + 1
            })
        },

        setCycles: (state, action) => {

           

         
            return ({
                loading: "false",
                data: action.payload,
                totalCount: action.payload.length,
                rows: state.rows
            })
        },
        setCyclesRows: (state, action) => {

        
            return ({
                loading: "false",
                data: state.data,
                totalCount: state.totalCount,
                rows: action.payload,
            })
        },
        switchCycles: (state, action) => {

            
      
        }

    }

})

export const { addCycle, setCycles,  switchCycles, setCyclesRows } = cyclesSlice.actions

export default cyclesSlice.reducer