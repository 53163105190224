
import React, {  useState, useEffect } from "react"
import  { Amplify, Auth, I18n } from "aws-amplify"
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { Authenticator } from 'aws-amplify-react';
import awsConfig from "./aws-exports"
import BOHeader from './components/BOHeader'
import SignIn from "./components/customSignIn"
import Box from '@mui/material/Box'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


import { ConfirmProvider } from 'material-ui-confirm'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './AppStyle'


Amplify.configure(awsConfig)



const AlwaysOn = (props) => {



    const { authState } = props




    return <Box>
        <Router>
            <Routes>
                <Route exact path="/" caseSensitive={false} element= {(authState === 'signedIn') && <BOHeader />}/>
            </Routes>
        </Router>


    </Box>
}

function BackOffice() {
    const [authState, setAuthState] = useState(null)
    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const [isAuthenticated, userHasAuthenticated] = useState(false)
    void authState
    void isAuthenticated

    useEffect(() => {
        onLoad()
    }, [])

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
        }
        catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        setIsAuthenticating(false);
    }


    return !isAuthenticating && <React.Fragment>

        <CssBaseline />
        
        
        {/* <StyledEngineProvider injectFirst> */}
            <ThemeProvider theme={theme}>
                <ConfirmProvider>
                    <Authenticator hideDefault={true} onStateChange={setAuthState}>
                        <AlwaysOn />
                        <SignIn />

                    </Authenticator>

                </ConfirmProvider>

            </ThemeProvider>
        {/* </StyledEngineProvider> */}
    </React.Fragment>;
}

I18n.setLanguage('fr');


export default BackOffice



