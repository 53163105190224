import makeStyles from '@mui/styles/makeStyles';
import { LightenDarkenColor } from 'lighten-darken-color'
import Button, { ButtonProps } from '@mui/material/Button'
import withStyles from '@mui/styles/withStyles';
import { indigo, grey, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles'


export const colorBlue = "#273370"
export const colorYellow = "#F7B643"
export const colorGray = "#FAFAFA"
export const colorDarkBlue = "#1b234e"


const useStyles = makeStyles((theme) => ({
  
    // root: {
    //     padding: "0px",
    //     width: "100vw",
    //     flexGrow: 1,
    //     backgroundColor: "white"
    // },
    hashList: {
        fontSize: "0.9rem",
        width: "20vw",
        height:"2.5vw"

    },
    intro: {
        fontSize: "0.8rem",
        width: "40vw"
    },
    modal00root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            marginTop:"2vw",
            fontSize: "0.2rem",
            backgroundColor: "#FCFCFA"
        },
    },
    rootHeader: {
        width: "100vw",
        flexGrow: 1,

    },
    modalTitle: {
        ...theme.typography.body1,

        fontSize: "1.2rem",
        fontWeight: "bold",


    },

    // content: {
    //     height: "50vw",
    //     width: "20vw",
    //     padding: "0.25vw",
    //     display: "flex",
    //     justifyContent: "start",
    //     alignItems: "flex-start"


    // },

    header: {
        background: "#FAF9F6",
        display: "flex",
        boxShadow: "5px 10px"
    }
    ,
    sidebar: {
        border: "Opx solid",
        outline: "none",
        width: "6vw",
        paddingTop: "0vw",

    },

    appBar: {
        background: theme.palette.primary.dark,
        display: "flex",
        padding: "0px",
        boxShadow: "5px 10px"

    },
    logoContainer: {
        display: "flex",
        height: "5vw",

    },
    // headerLogoContainer: {
    //     display: "flex",
    //     marginRight: "6vw"
    // },
    logoTitle: {
        width: "9vw",
        color: LightenDarkenColor( colorYellow, 0),
        fontSize: "0.8rem",
        position: "relative",
        left: "0.75vw",
        top: "0.50vw",
        marginRight: "2vw"

    },
    tableauContainer: {
        display: "flex"
    },
    tableauElement: {
        margin: "0.2vw",
        padding: "1vw",
        width: "8vw",
        backgroundColor: colorGray
    },
    tableauElementValue: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "1rem",
    },
    tableauMainContainer: {
        width: "65vw",
        fontSize: "1rem",
        marginLeft: "2vw",
        marginRight: "2vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: LightenDarkenColor(colorGray, 10)
    },
    tableauContextual: {
        cursor: "pointer",
        height: "32vw",
        marginTop: "2vw",
        marginLeft: "0.25vw",
        marginRight: "3vw",
        display: "flex",
        justifyContent: "start",
        flexDirection: "column",
        alignItems: "center",
        paddingRight: "1.5vw",
        backgroundColor: theme.palette.secondary.light
        
    },
    tableauElementText: {
        marginTop: "1vw",
        textAlign: "center",
        fontSize: "0.8rem",
        lineHeight: "1.2rem"

    },

    menuItemSelected: {
        backgroundColor: 'red'

    }
    ,
    // menuButton: {
    //     width: "8vw",
    //     marginTop: "1vw",
    //     fontSize: "0.9rem",
    //     paddingLeft: "1vw",
    //     color: theme.palette.primary.dark,
    //     textAlign: "start",
    //     opacity: 0.8,
    //     "&:hover": {
    //         opacity: 1,
    //         color: theme.palette.primary.dark,
    //         fontWeight: "bold",
    //         width: "8vw",


    //     },
    //     menuSelection: {

    //         "&:hover": {
    //             backgroundColor: colorYellow,
    //         }

    //     }
    // },

    margin2:{
        width: "10vw"
    },

    title: {
        color: "#273370",
        fontSize: "1.3rem",
        marginLeft: "3vw",
        marginBottom: "2vw"

    },
    indicator: {
        backgroundColor: "white",
    },
    headerIndicator: {
        backgroundColor: "#FAF9F6",
    },

    drawer: {
        position: "relative",
        top: "5vw"
    }

}), { index: 1 })



export default useStyles

export const buttonColor = grey[500]

export const ColorButton = styled(Button)(({theme}) => ({
        color: buttonColor,
        backgroundColor: "inherit",
        '&:hover': {
            backgroundColor: "inherit"
    },
}))



export const ColorButton2= styled(Button)((theme) => ({
    
        color : indigo[300],
        boxShadow: "none",
        backgroundColor: "white",
        '&:hover': {
            boxShadow: "none",
            backgroundColor: "white",
            color : indigo[800]
           
        }
    
}))

export const ColorButtonUpload = withStyles((theme) => ({
    root: {
        padding: "5px",
        boxShadow: "none",
        border: '1px solid',
        borderRadius: '10px',
        backgroundColor: "inherit",
        color : indigo[800],
        '&:hover': {
            boxShadow: "none",
            backgroundColor: indigo[100],
            
           
        },
    },
}))(Button)

export const ColorButtonUploadOff = withStyles((theme) => ({
    root: {
        padding: "5px",
        boxShadow: "none",
        border: '1px solid',
        borderRadius: '10px',
        backgroundColor: "inherit",
        color : grey[300],
        '&:hover': {
            cursor: "default",
            boxShadow: "none",
            backgroundColor: 'white',
        },
    },
}))(Button)