import React, { useState, useEffect, useRef } from 'react';
import { Box } from "@mui/system";
import { Alert, Button, Snackbar, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { createNewCompanyAccount, createNewMembership } from './helpers/company';
import { API, graphqlOperation } from 'aws-amplify';
import { listInvitations, listMemberShips } from '../graphql/queries';
import { createMemberShip, deleteInvitation } from '../graphql/mutations';
import { Auth } from "aws-amplify";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Badge from '@mui/material/Badge';

const EspaceMenu = ({ fetchData, user, setIsInde, isInde }) => {
	const [open, setOpen] = useState(false)
	const [message, setMessage] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [typeOfConection, setTypeOfConection] = useState()
	const data = useSelector(state => state.dataReducer)

	useEffect(() => {
		if (data?.companies?.length && data.companies[0]?.lemonwayId){
			setTypeOfConection("Organisme");
			setIsInde(false);
			setOrganism();
		} else if (data.lemonwayId){
			setTypeOfConection("independant");
			setIndependant();
			setIsInde(true);
		} else {
			setTypeOfConection("to determine")
		}
		if (data.companies) {
			setCompanyName(data.companies[0]?.fullName)
		}
	}, [data])

	const handleClose = () => {
		setOpen(false)
	}

	const setIndependant = () => {
		setMessage("Vous êtes désormais connecté en tant que conférencier indépendant, continuez votre parcours de mise en ligne de conférence en navigant sur le menu vertical de gauche")
		setIsInde(true)
		setOpen(true)
	}

	const checkAllInfo = async () => {
		let memberships = await API.graphql(graphqlOperation(listMemberShips, { filter: { formateurId: { eq: data.id } } }))
		let newUser = await Auth.currentAuthenticatedUser()
		memberships = memberships.data.listMemberShips.items

		if (memberships.length === 0) {
			const invitations = await API.graphql(graphqlOperation(listInvitations, { filter: { email: { eq: newUser.attributes.email } } }))
			if (invitations.data.listInvitations.items.length !== 0) {
				const inviteMembership = await API.graphql(graphqlOperation(listMemberShips, { filter: { formateurId: { eq: invitations.data.listInvitations.items[0].from }}}))
				if (inviteMembership.data.listMemberShips.items.length === 0) {
					return;
				}
				const input = {
					companyAccountId: inviteMembership.data.listMemberShips.items[0].companyAccountId,
					formateurId: data.id,
					administratorPrivileges: false,
					accountantPrivileges: false,
					editorPrivileges: false,
					communicationPrivileges: false,
				}
				const newMembership = await API.graphql(graphqlOperation(createMemberShip, { input: input }))
				setIsInde(false)
				setMessage("Vous êtes désormais connecté en tant qu'organisme, continuez votre parcours de mise en ligne de conférence en navigant sur le menu vertical de gauche")
				setOpen(true)
			} else {
				const newCompany = await createNewCompanyAccount({
					nbMaxMembers: 10,
					category: 'STANDARD'
				})
				const newMembership = await createNewMembership({
					formateurId: data.id,
					companyAccountId: newCompany.data.createCompanyAccount.id,
					administratorPrivileges: true
				})
				setIsInde(false)
				setMessage("Vous êtes désormais connecté en tant qu'organisme, continuez votre parcours de mise en ligne de conférence en navigant sur le menu vertical de gauche")
				setOpen(true)
			}
		} else {
			setIsInde(false)
			setMessage("Vous êtes désormais connecté en tant qu'organisme, continuez votre parcours de mise en ligne de conférence en navigant sur le menu vertical de gauche")
			setOpen(true)
		}
	}

	const setOrganism = () => {
		checkAllInfo()
	}

	return (
		<div>
			<Box>
				<Box>
					<Box>
						<Typography marginBottom='0px' marginTop='30px' style={{ fontWeight: 700, fontSize: '1.2rem' }}>
							BIENVENUE DANS LE BACK OFFICE DE PEKOIA
						</Typography>
					</Box>
					<Box display="flex" flexDirection="row">
						<Typography style={{ fontWeight: 400, textAlign: "start", fontSize: '1.1rem' }}>
							Vous êtes connectés en tant 
						</Typography>
						<Typography marginLeft="4px" style={{ fontWeight: 400, textAlign: "start", fontSize: '1.1rem' }}>
							{isInde ? "que conférencier indépendant." : data && data.companies[0] && data.companies[0].fullName ? `que membre de l'organisme : ${companyName}` : "que membre d'un organisme"}
						</Typography>
					</Box>
					<Box>
						<Typography marginTop="30px" marginBottom='20px' style={{ fontWeight: 300, textAlign: "start", fontSize: '0.9rem' }}>
							Ce back office est ouvert à tous les conférenciers ou tous les organismes proposant des conférences.
						</Typography>
					</Box>
					<Box>
						<Typography marginBottom='20px' style={{ fontWeight: 300, textAlign: "start", fontSize: '0.9rem' }}>
							- Il permet de créer votre offre de conférences qui sera proposée sur le front office de Pekoia (www.pekoia.com).
						</Typography>
					</Box>
					<Box>
						<Typography marginBottom='20px' style={{ fontWeight: 300, textAlign: "start", fontSize: '0.9rem' }}>
							- Il permet aussi de récolter le fruit de votre travail grâce à la brique de paiement intégrée dans le cas de conférences payantes.
						</Typography>
					</Box>
					<Box>
						<Typography marginBottom='20px' style={{ fontWeight: 300, textAlign: "start", fontSize: '0.9rem' }}>
							- Il permet également de vous simplifier la tâche en matière de gestion des inscriptions : mails de confirmation, mails de rappel 24h et 1h avant la conférence ainsi que l'enquête de satisfaction sont envoyés automatiquement.
						</Typography>
					</Box>
				</Box>
				{typeOfConection ==="to determine" ?
					<Box display='flex' width='100%' justifyContent='space-evenly'>
						<Box style={{ width: '310px' }}>
							<StyledBadge badgeContent={<CheckCircleOutlineIcon/>} invisible={!isInde} style={{ width: '100%'}}>
								<StyledButton onClick={setIndependant} disabled={isInde} sx={{ '&:disabled': { backgroundColor: "#d6d6d6", color: '#000000', border: '2px solid', borderColor: "#1a5aa3"  } }}>
									<Box>
										<Typography>Entrée</Typography>
										<Typography style={{ fontWeight: 700 }}>Conférencier indépendant</Typography>
									</Box>
								</StyledButton>
							</StyledBadge>
							<img src='https://www.images.pekoia.com/image-500.04712f599465e951a575ab036a3c01f85.jpg' width='100%' style={{ marginTop: '10px', marginBottom: '10px' }} />
							<Typography style={{ fontSize: '0.9rem' }}>Vous êtes un auto-entrepreneur, enseignant, journaliste, écrivain...</Typography>
						</Box>
						<Box style={{ width: '310px' }}>
							<StyledBadge badgeContent={<CheckCircleOutlineIcon/>} invisible={isInde} style={{ width: '100%'}}>
								<StyledButton onClick={setOrganism} disabled={!isInde} sx={{ '&:disabled': { backgroundColor: "#d6d6d6", color: '#000000', border: '2px solid', borderColor: "#1a5aa3" } }} >
									<Box>
										<Typography>Entrée</Typography>
										<Typography style={{ fontWeight: 700 }}>Organisme</Typography>
									</Box>
								</StyledButton>
							</StyledBadge>
							<img src='https://www.images.pekoia.com/image-500.055c27bce1aa78d302e728a6dcf978c4e.jpg' width='100%' style={{ marginTop: '10px', marginBottom: '10px' }} />
							<Typography style={{ fontSize: '0.9rem' }}>Vous êtes une association, un think tank, une université du temps libre, un musée, une entreprise...</Typography>
						</Box>
					</Box> : null}
			</Box>
			<Snackbar
				open={open}
				autoHideDuration={10000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'left'}}
			>
				<Alert onClose={handleClose} severity='success'>
					{message}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default EspaceMenu

const StyledButton = styled(Button)({
	textTransform: 'none',
	backgroundColor: "#778ca3",
	color: '#FFFFFF',
	borderRadius: '15px',
	fontSize: '0.7rem',
	marginTop: '20px',
	marginBottom: '10px',
	minHeight: '70px',
	width: '100%',
	'&:hover': {
		backgroundColor: "#3867d6", // Replace with the color you want on hover
	  },
})

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 5,
    top: 25,
		color: '#1a5aa3'
  },
}));