import React from 'react'
import ReactMarkdown from 'react-markdown'
import ReactDom from 'react-dom'
import styled from 'styled-components'

const MarkdownStyles = styled.div`
	& > h1 {
		font-size: 22px;
	}
	
	& > h2 {
		font-size: 20px;
	}

    & > h3 {
		font-size: 20px;
	}

    & > h4 {
		font-size: 15px;
        margin-bottom: 15px;
	}
	
	
	& > p {
		font-size: 15px;
	}
	
`

const textContent = `#	Politique de Confidentialité	Juin 2021



**Politique de confidentialité**

Dernière mise à jour : 23/06/2021
## **Préambule**

Cette Politique de Confidentialité s’adresse aux Utilisateurs de la PEKOIA et a pour objectif de les informer sur la manière dont leurs informations personnelles peuvent être collectées et traitées.

L’Opérateur propose un service d’intermédiation par lequel des professionnels et des particuliers (*ci-après « **les Apprenants** »)* pourront s’inscrire à des Événements proposés par des experts intervenant dans le cadre d’un Événement (*ci-après « **les Organisateurs** »)* 

Le respect de la vie privée et des données à caractère personnel est pour l’Opérateur une priorité, raison pour laquelle nous nous engageons à traiter celles-ci dans le plus strict respect de la loi Informatique et Libertés du 6 janvier 1978 modifiée et du Règlement (UE) général sur la protection des données du 27 avril 2016 (*ci-après « RGPD »*)*.*

En tout état de cause, nous nous engageons à respecter les deux (2) principes essentiels suivants :

- L’Utilisateur reste maître de ses données à caractère personnel ;
- Les données sont traitées de manière transparente, confidentielle et sécurisée.


## **Article 1. Définitions**

- « **Apprenant** » : désigne toute personne ayant le statut de professionnel ou de consommateur, qui recherche un Événement sur la Plateforme. 
- **« Compte »** : désigne l’interface hébergée sur la Plateforme dans laquelle est regroupé l’ensemble des données fournies par l’Organisateur ou l’Apprenant et lui permettant d’accéder aux Services. L’accès au Compte se fait grâce aux Identifiants. 

- **« Conditions Commerciales »** : désigne les Conditions commerciales applicables aux transactions réalisées via la Plateforme et pouvant, le cas échéant, compléter, voire remplacer (en cas de lacune ou de contradiction), les Conventions d’Evénement.  

- **« Conditions Générales d’Utilisation** » ou « **CGU** » : désigne les conditions contractuelles mises à disposition sur la Plateforme afin d’encadrer l’utilisation de celle-ci et des Services par tout Utilisateur.    

- « **Conditions Générales de Services** ou **CGS » :** désigne les conditions contractuelles encadrant la fourniture de Services de la Plateforme par l’Opérateur aux Organisateurs.  

- **« Convention d’Evénement » :** désigne la Convention, fournie par l’Organisateur, encadrant l’inscription à un Événement et la tenue de ce dernier.** 


- **« Événement »** : désigne toute intervention, privée ou publique, gratuite ou payante, préenregistrée ou instantanée, physique ou à distance, proposée par un Organisateur sur la Plateforme. L’Evénement peut être virtuel et avoir lieu par un outil de conférence à distance ou avoir lieu physiquement, dans le cadre d’un évènement organisé.  

- « **Identifiants** » : désigne l’adresse e-mail de l’Organisateur ou de l’Apprenant et le mot de passe défini par ce dernier, nécessaires à l’accès à son Compte sur la Plateforme.   

- **« Intervenant »** : désigne la personne physique intervenant pour animer oralement l’Evénement. L’Intervenant peut être l’Organisateur lui-même ou intervenir pour le compte d’un Organisateur, intervenant alors comme conférencier Intervenant.

- **« Organisateur »** : désigne la personne physique ou morale proposant un Evénement sur la Plateforme. 

- **« Outil CRM » :** désigne l’ensemble de fonctionnalités de la Plateforme permettant à l’Organisateur de promouvoir ses Evénements en exploitant notamment sa propre base de données, enrichie au moyen de la communauté d’Utilisateurs de la Plateforme.

- « **Parties** » : au pluriel, désigne ensemble l’Opérateur et l’Utilisateur. Au singulier, désigne une seule des deux Parties. 

- **« Plateforme »** : désigne la plateforme en ligne accessible à l’adresse [www.pekoia.com](http://www.pekoia.com)*.* La Plateforme regroupe l’ensemble des pages web, Services et fonctionnalités proposés aux Utilisateurs.  

- « **Prestataire de Services de Paiement** » ou « **PSP** »: désigne la société, détentrice d’un agrément bancaire, fournissant, par l’intermédiaire de l’Opérateur, des services de paiement aux Organisateurs afin de leur permettre d’encaisser les paiements des Apprenants. Le PSP est LEMONWAY, société par actions simplifiée dont le siège social est situé au 8 rue du Sentier, 75002 Paris, France, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 500 486 915 (« **Lemonway** » ), agréée le 24 décembre 2012 par l’Autorité de Contrôle Prudentiel et de Résolution (« ACPR », France, site internet http://acpr.banque-france.fr/), 4 place de Budapest CS 92459, 75436 Paris, en qualité d’Etablissement de Paiement hybride.

- **« Services » :** désigne l’ensemble des services proposés par l’Opérateur aux Utilisateurs par l’intermédiaire de la Plateforme et notamment la mise en relation des Organisateurs et des Apprenants. 

- « **Utilisateur** » : désigne toute personne qui accède et navigue sur la Plateforme, qu’il soit Organisateur, Organisme d’Evénement, Apprenant ou simple internaute.

## **Article 2. Identité du responsable de traitement**

- **L’Opérateur en tant que responsable de traitement**

La société PEKOIA, société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 508 763 950  dont le siège social est sis 13 bis avenue de la Motte Picquet 75007 (ci-après « PEKOIA » ou « ***l’Opérateur*** ») aura le statut de responsable de traitement pour tous les traitements relatifs à la mise à disposition de sa Plateforme ainsi qu’à la mise en relation de l’Apprenant et de l’Organisateur dès leur inscription sur la Plateforme.

L’Opérateur est Responsable de traitement pour les finalités décrites à l’article 6 de la présente Politique de Confidentialité. 

- **L’Opérateur en tant que sous-traitant**

L’Organisateur sera seul responsable des traitements de données directement liés à l’organisation et l’animation des Événements. Dans ce cadre, l’Opérateur pourra simplement, le cas échéant, intervenir comme sous-traitant de l’Organisateur, notamment dans le cadre de la promotion des Événements. 

#### **En savoir plus**

***Rappel légal** :*

*Le **responsable du traitement** est, au sens de la loi Informatique et Libertés et du RGPD, la personne qui détermine les moyens et les finalités du traitement.* 

*Lorsque deux responsables du traitement ou plus déterminent conjointement les finalités et les moyens du traitement, ils sont les **responsables conjoints du traitement** (ou co-responsables).*

*Le **sous-traitant** est une personne traitant des données à caractère personnel pour le compte du responsable du traitement, il agit sous l’autorité du responsable du traitement et sur instruction de celui-ci.*

Ceci précisé, la présente Politique de Confidentialité concerne les traitements de données réalisés par l’Opérateur en sa qualité de responsable de traitement ou de sous traitant. 

**Les Utilisateurs qui souhaiteraient avoir des renseignements sur les opérations de traitement effectuées par les Organisateurs devront solliciter ces derniers.**

## **Article 3. Coordonnées de notre responsable à la protection des données** 

Nous sommes à votre disposition pour répondre à toutes les demandes, y compris d’exercice de droits, relatives à vos données à caractère personnel.

Vous pouvez nous joindre :

- Soit par courriel à l’adresse suivante : contact@pekoia.com ;
- Soit par courrier : PEKOIA SAS, 13 bis avenue de la Motte-Picquet, 75007 Paris.


## **Article 4. Collecte & origine des données**

Dans le cadre de la fourniture des Services, l’Opérateur collecte et traite des données à caractère personnel relatives à ses Utilisateurs lors de votre inscription, lors de l’utilisation des Services et lors de vos échanges avec le service client.

Dans tous les cas, vous êtes informés des finalités pour lesquelles vos données sont collectées par nos soins via les différents formulaires de collecte de données en ligne, les courriels qui vous seraient adressés, des notifications sur la Plateforme ou bien encore via notre Charte de gestion des Cookies. 

#### **En savoir plus**

*Les données sur les Utilisateurs collectées par l’Opérateur sont traitées conformément aux finalités prévues lors de la collecte dans le respect du [Référentiel relatif aux traitements de données à caractère personnel mis en œuvre aux fins de gestion des activités commerciales](https://www.cnil.fr/sites/default/files/atoms/files/referentiel-gestion-commerciale.pdf) édité par la CNIL.*

*Lorsque cela est nécessaire, nous nous engageons, selon les cas, à recueillir votre consentement et/ou à vous permettre de vous opposer à l’utilisation de vos données pour certaines finalités, comme par exemple, pour déposer des cookies tiers sur vos terminaux (téléphone mobile, ordinateur, tablette) à des fins de mesure d’audience de notre Plateforme et pour vous proposer des offres commerciales et publicités ciblées en fonction de vos centres d’intérêts.* 

## **Article 5. Consentement**

Dans le cadre du respect des principes de protection des données dès la conception et de protection des données par défaut, l’Opérateur s’assure que les consentements nécessaires ont bien été recueillis lors de l’inscription des Utilisateurs. 


## **Article 6. Finalités et bases légales des traitements**

Lorsque la base légale utilisée pour les opérations de traitements réalisée par l’Opérateur repose sur la poursuite d’un intérêt légitime, les personnes concernées ont la possibilité, sur simple demande, d’obtenir des informations complémentaires relatives à la mise en balance des intérêts. 

Vos données à caractère personnel sont collectées par l’Opérateur pour assurer :
### **La gestion, l’exploitation et la mise à disposition de la Plateforme et de ses Services**

#### **En savoir plus**

***Détails du traitement***

*La gestion, l’exploitation et la mise à disposition de la Plateforme et de ses Services couvrent ces traitements :*

- *L’inscription des Apprenants sur la Plateforme ;*
- *La mise à disposition de la Plateforme et de ses fonctionnalités ;*
- *La mise en relation avec les Organisateurs ;* 
- *L’amélioration constante de la Plateforme ;*
- *Le dépôt de cookies et autres traceurs dont vous pouvez retrouver les détails dans notre Charte de gestion des Cookies.*


***Bases légales***

***Contractuelle** : le traitement est nécessaire à l’exécution des Services prévue dans les Conditions Générales d’Utilisation (CGU) de la Plateforme et dans le contrat encadrant les relations entre l’Opérateur et chaque Organisateur.* 

*Votre **Consentement** lorsqu’il est requis.* 

- **La gestion et suivi des inscriptions à des Événements suite à la mise en relation entre les Apprenants et les Organisateurs**

Ce traitement est mis en œuvre à titre principal par l’Organisateur, qui est autonome dans la gestion de l’Evénement et donc des traitements de données associés. 

#### **En savoir plus**

***Détails du traitement***

*La gestion et le suivi des inscriptions à des Événements dans le cadre de la mise en relation entre les Apprenants et les Organisateurs couvre ces traitements :*

- *Gestion de l’Achat et facturation ;*
- *La fourniture de l’Outil CRM* 
- *Exécution de l’événement*
- *Service après vente*

***Bases légales***

***Contractuelle** : le traitement est nécessaire à la tenue de l’Evénement, encadrée tant par la Convention d’Evénement que par les Conditions Commerciales de la Plateforme.* 


### **La gestion du paiement des Services et des Événements**

Ce traitement est mis en œuvre à titre principal par le PSP, qui agit sur instructions de l’Organisateur.

#### **En savoir plus**

***Détails du traitement***

*La gestion du paiement des Services couvre ces traitements :*

- *Le paiement des Services et des inscriptions aux Événements ;* 
- *La mise en œuvre d’une procédure de KYC à l’égard des Organisateurs.* 

***Bases légales***

***Contractuelle :** le traitement est nécessaire à la tenue de l’Evénement, encadrée tant par la Convention d’Evénement que par les Conditions Commerciales de la Plateforme.*

***Intérêt légitime** : l’intérêt légitime à s’assurer du bon déroulé des opérations comptables réalisées par l’Opérateur.* 

### **L’envoi de la newsletter**

#### **En savoir plus**

***Détails du traitement***

*Ce traitement couvre les envois de newsletters aux Utilisateurs.* 

***Base légale***

*Votre **consentement*** 


### **La gestion des demandes de droits issus du RGPD et de la loi Informatique et Libertés modifiée**

#### **En savoir plus**

***Détails du traitement***

*Ce traitement couvre l’ensemble des opérations nécessaires au suivi des demandes de droits adressées à l’Opérateur (qualification de la demande, investigations, réalisation d’opérations techniques spécifiques…).* 

***Base légale***

***Obligation légale** : l’obligation légale découle du RGPD et de la Loi Informatique et Libertés.*

## **Article 7. Données traitées**

Le caractère obligatoire ou facultatif des données personnelles collectées et les éventuelles conséquences d'un défaut de réponse sont indiqués lors de leur(s) collecte(s) sur les formulaires associés.

Vous pouvez consulter le détail des données à caractère personnel que nous sommes susceptibles d’avoir sur vous ci-après.

#### **Détail des données collectées**

***NB : le détail des informations fournies ci-après n’a pas vocation à être exhaustif et vise avant tout à informer les Utilisateurs sur les catégories de données que l’Opérateur est susceptible de traiter.***

***Pour la gestion, l’exploitation et la mise à disposition de la Plateforme et de ses Services :***

- *Données à caractère personnel relatives à l’identité de l’Utilisateur : Nom, prénom, adresse postale, adresse e-mail et numéro de téléphone ;*
- *Données relatives à la navigation de l’Utilisateur sur la Plateforme via les cookies.*

***Pour la gestion et suivi des Inscriptions à des Événements dans le cadre de la mise en relation entre les Apprenants et les Organisateurs***

- *Données à caractère personnel relatives à l’identité de l’Utilisateur : nom, prénom, adresse postale, adresse e-mail et numéro de téléphone, numéro de déclaration d’activité ;*

*Identifiants de connexion des Utilisateurs : adresse e-mail et le mot de passe ;*

- *Données relatives au suivi de la relation commerciale : historique des inscriptions à des Événements et avis des Apprenants ;*
- *Données de connexion et d’appareil : adresse IP, dates et heures d’accès au service, données sur le matériel informatique ou téléphonique, données associées à l’utilisation de l’appareil, identifiants uniques, remontées de bug ou encore informations issues de cookies « techniques ».*

***Pour la gestion de l’exécution des Événements :***

- *Données à caractère personnel relatives à l’identité de l’Utilisateur : nom, prénom, adresse postale, adresse e-mail et numéro de téléphone ; numéro de déclaration d’activité ;*

*Identifiants de connexion des Utilisateurs : adresse e-mail et le mot de passe ;*

- *Données relatives au suivi de la relation commerciale : documents administratifs et financiers relatifs à la Formation (si l’événement est une formation) ; correspondances avec le Service Utilisateurs ;*
- *Données de connexion et d’appareil : adresse IP, dates et heures d’accès au service, données sur le matériel informatique ou téléphonique, données associées à l’utilisation de l’appareil, identifiants uniques, remontées de bug ou encore informations issues de cookies « techniques ».*

***Pour la gestion du paiement des Services :***

**Ces données sont visibles uniquement pour le PSP**

- *Données relatives aux moyens de paiement : numéro de carte bancaire, date de fin de validité de la carte bancaire, cryptogramme visuel ;*
- *Données relatives à la transaction : numéro de la transaction, détail de la Commande ;*
- *Données relatives aux paiements de la Commande : modalités de règlement, factures.*

***Pour l’envoi de la newsletter de la Plateforme*** 

- *Données relatives à votre identité : nom, prénom, adresse postale, adresse e-mail* 

***Pour la gestion des demandes de droit issus du RGPD et de la loi Informatique et Libertés modifiée, les données susceptibles d'être traitées sont les suivantes :***

- *Données relatives à votre identité : nom, prénom, adresse postale, adresse e-mail et numéro de téléphone. Une copie d'un titre d'identité ou équivalent peut être conservée aux fins de preuve de l'exercice d'un droit d'accès, de rectification ou d'opposition ou pour répondre à une obligation légale.*
- *Données relatives à votre demande d’exercice des droits.* 

En tout état de cause l’Opérateur s’engage à traiter l’ensemble des données collectées de manière conforme au RGPD et à la loi Informatique et Libertés.

## **Article 8. Traitement de vos Données par les Organisateurs** 

### **8.1 Responsabilité générale** 
Les Organisateurs sont autonomes dans l’organisation et l’animation des Événements, l’Opérateur intervenant comme simple intermédiaire technique. 

Les Organisateurs sont donc seuls responsables des traitements mis en œuvre à cette occasion. Tout Utilisateur souhaitant avoir de plus amples informations à ce sujet est invité à contacter l’Organisateur concerné. 
### **8.2 Concernant l’utilisation de l’Outil CRM**

L’Organisateur restera responsable des traitements de données mis en œuvre dans le cadre de son utilisation de l’Outil CRM, l’Opérateur intervenant en principe en tant que simple sous-traitant, sous réserve de cas de figure particuliers, notamment dans l’hypothèse où L’Organisateur aurait souscrit à des Services Marketing. 

Pour promouvoir ses Evénements, L’Organisateur pourra importer sur l’Outil CRM sa propre base de données, distincte de la base des Utilisateurs de la Plateforme. 

En utilisant cette fonctionnalité, L’Organisateur garantit que la base de données fournie est bien conforme à la Réglementation applicable et que ladite base de données a été constituée licitement, en collectant, le cas échéant, le consentement des personnes concernées. 

L’Organisateur est informé que dans un tel cas de figure, l’Opérateur ne fusionnera pas la base de données de l’Organisateur avec celle constituée par les Utilisateurs de la Plateforme. Ce n’est que si la personne prospectée par L’Organisateur, se créé un compte sur la Plateforme pour assister à un Événement que ses données, fournies pour la création d’un compte et profiter des Services de la Plateforme, seront intégrées à la base de données des Utilisateurs de la Plateforme. 

## **Article 9. Destinataires de vos données**

Dans la limite de leurs attributions respectives et pour les finalités rappelées à l’article 6, les principales personnes qui seront susceptibles d’avoir accès à vos données sont les suivantes :

- Le personnel habilité des différents services de l’Opérateur (le personnel habilité des services commercial, informatique, marketing, et client) ;

- Le Prestataire de Services de Paiement ;

- Le cas échéant, le personnel habilité de nos sous-traitants ;

- Le cas échéant, les juridictions concernées, médiateurs, experts-comptables, commissaires aux comptes, avocats, huissiers, sociétés de recouvrement de créances ;

- Les tiers susceptibles de déposer des cookies sur vos terminaux (ordinateurs, tablettes, téléphones portables…) lorsque vous y consentez (pour plus de détail, consultez notre Charte de gestion des Cookies*.


Vos données à caractère personnel ne sont ni communiquées, ni échangées, ni vendues ni louées sans votre consentement exprès préalable conformément aux dispositions légales et réglementaires applicables.


## **Article 10. Transfert de données hors Union Européenne**

Par principe, l’Opérateur traite vos données au sein de l’Union européenne. 

Néanmoins, au regard de la nature de son activité, et sous réserve de vous en informer préalablement, l’Opérateur pourra être amené à réaliser des transferts de vos données en dehors de l’Union Européenne. Dans ce cas, l’Opérateur vous indiquera les mesures prises afin de contrôler ce transfert et de s’assurer du respect de sa conformité.


## **Article 11. Durée de conservation des données**

Nous conservons vos données uniquement le temps nécessaire pour les finalités poursuivies, telles que décrites à l’article 6. 

A titre indicatif, cliquez ci-dessous pour plus de détails sur les durées légales de conservation.

#### **En savoir plus**


- **La mise à disposition de la Plateforme, de ses fonctionnalités** Les données collectées pour les traitements relatifs à l’accès à la Plateforme, et la fourniture des Services sont conservées pendant toute la durée de la relation contractuelle avec l’Opérateur augmentée de trois (3) ans. Elles sont par la suite stockées en archivage intermédiaire pendant cinq (5) ans dans un objectif de preuve. Dans le cadre du respect des obligations légales incombant aux hébergeurs, les données de nature à permettre l'identification de quiconque ayant contribué à la création de contenu sont conservées pendant une durée d’un (1) an à compter de l’opération.
- **L’intermédiation avec l’Organisateur choisi** Les données collectées pour les traitements relatifs, l’intermédiation et la fourniture des services sont conservées pendant toute la durée de la relation contractuelle avec l’Opérateur. Elles sont par la suite stockées pendant cinq (5) ans dans un objectif de preuve. 
- **La gestion et le suivi de la relation commerciale dans le cadre des Inscriptions aux Événements** Les données collectées pour les traitements relatifs à l’accès à la Plateforme, et la fourniture des Services sont conservées pendant toute la durée de la relation contractuelle avec l’Opérateur augmentée de trois (3) ans. Elles sont par la suite stockées en archivage intermédiaire pendant cinq (5) ans dans un objectif de preuve. Les documents commerciaux liés à chaque transaction sont conservés pendant 10 ans à l’issue de l’exercice en cours (L123-22 Code de commerce). 
- **Le paiement des Inscriptions à des Événements**Les données de paiement sont conservées par le PSP. Afin de connaître la durée de conservation de ces données, il convient de consulter la politique de confidentialité de ce dernier. 
- **Envoi de newsletters** Les données utilisées dans le cadre de l’envoi de newsletter sont conservées tant que l’Utilisateur ne se désinscrit pas de la liste de diffusion ou jusqu’à ce qu’il retire son consentement. 
- **Gestion des demandes d’exercice de droit** Les données relatives à la gestion des demandes de droit sont conservées pour toute la durée nécessaire au traitement de la demande. Elles sont par la suite archivées pour la durée de prescription pénale applicable en archivage intermédiaire.
- **Gestion du bon fonctionnement de la Plateforme** Les cookies et autres traceurs commerciaux peuvent être déposés sur le terminal de l’Utilisateur pour une durée maximale de treize (13) mois. Au-delà de ce délai, les données de fréquentation brutes associées à un identifiant sont soit supprimées soit anonymisées.Les informations collectées par l'intermédiaire de cookies et traceurs sont conservées pour une durée de vingt-cinq (25) mois. Au-delà de ce délai, ces données sont supprimées, ou anonymisées.

## **Article 12. Fourniture de services de paiement par le Prestataire de Service de Paiement**

## **12.1 Finalités du traitement** 

L’Apprenant souhaitant s’inscrire à un Événement auprès d’un Organisateur doit utiliser des services de paiement. 

Les services de paiement nécessaires à la Plateforme sont fournis par le Prestataire de Services de Paiement choisi par l’Opérateur pour réaliser ses services de paiement.

Le Prestataire de Services de Paiement, pour fournir les services de paiement, est légalement tenu de réaliser des contrôles anti-blanchiment, de lutte contre le financement contre le terrorisme et de lutte contre la fraude. 

Par conséquent, le Prestataire de Services de Paiement de l’Opérateur traite les données des Apprenants et des Organisateurs pour : 

### **La fourniture des services de paiement.**

#### **En savoir plus**

***Base légale***

***Contractuelle** : le traitement est nécessaire au paiement de l’inscription à des Événements.*

### **La mise en œuvre des procédures de contrôle**

La mise en œuvre d’une procédure dite de « KYC » sur les Organisateurs, concourant au respect de ses obligations de lutte contre le blanchiment et le financement du terrorisme (LCB-FT).
##

#### **En savoir plus**

***Base légale***

***Obligation légale** : traitement des données nécessaire à la lutte contre la fraude, le financement du terrorisme et le blanchiment au terme d’une obligation légale qui incombe au Prestataire de Services de Paiement en tant que fournisseur de services de paiement.*   

L’Utilisateur souhaitant avoir plus d’information sur les traitements mis en œuvre par le PSP est invité à consulter sa politique de confidentialité. 

## **Article 13. Vos droits**

Conformément à la Loi Informatique et Libertés et au RGPD, vous disposez des droits suivants  [(](https://www.cnil.fr/fr/comprendre-vos-droits)[en savoir plus](https://www.cnil.fr/fr/comprendre-vos-droits)[)](https://www.cnil.fr/fr/comprendre-vos-droits) :
#### droit d’accès [(]( l )[article 15 RGPD)]( https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article15), de rectification [(]( l )[article 16 RGPD)](https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article16), de mise à jour, de complétude de vos données

#### droit à l’effacement (ou « droit à l’oubli ») de vos données à caractère personnel [(]( l )[article 17 RGPD)](https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article17), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite

#### droit de retirer à tout moment votre consentement [(article 7 RGPD](https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre2#Article7))

#### droit à la limitation du traitement de vos données [(]( l )[article 18 RGPD)](https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article18)

#### droit d’opposition au traitement de vos données [(]( l )[article 21 RGPD)](https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article21)

#### droit à la portabilité des données que vous nous avez fournies, lorsque vos données font l’objet de traitements automatisés fondés sur votre consentement ou sur un contrat [(]( l )[article 20 RGPD)](https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article20)

#### droit de ne pas faire l’objet d’une décision fondée exclusivement sur un traitement automatisé [(article 22 du RGPD](https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article22))

#### droit de définir le sort de vos données après votre mort et de choisir que nous communiquions (ou non) vos données à un tiers que vous aurez préalablement désigné [(]( l )[article 85 LIL)](https://www.cnil.fr/fr/la-loi-informatique-et-libertes#article85). En cas de décès et à défaut d’instructions de votre part, nous nous engageons à détruire vos données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.

Vous pouvez exercer vos droits :

- par courriel à PEKOIA SAS, 13 bis avenue de la Motte-Picquet, 75007 Paris;
- par courrier à *contact@pekoia.com* 

Enfin, vous pouvez également introduire une réclamation auprès des autorités de contrôle et notamment de la ([CNIL](https://www.cnil.fr/fr)) ou de toute autre autorité compétente.


## **Article 14. Données de connexion et cookies**

Nous faisons usage pour le bon fonctionnement de la Plateforme et des Services de données de connexion (date, heure, adresse Internet, protocole de l’ordinateur du visiteur, page consultée) et des cookies (petits fichiers enregistrés sur votre ordinateur) permettant de vous identifier, de mémoriser vos consultations, et de bénéficier de mesures et statistiques d’audience, notamment relatives aux pages consultées.

En ce sens, l’Opérateur a rédigé une Charte de gestion des Cookies afin de vous informer plus spécifiquement de leur utilisation.

#### **En savoir plus**

*En naviguant sur notre Plateforme, vous acceptez que l’Opérateur installe ce type de cookies dits « techniques » qui ont pour finalité exclusive de permettre ou faciliter la communication par voie électronique de votre équipement terminal avec notre site, en facilitant la gestion et la navigation sur celui-ci.*

*Notre accès aux informations stockées dans votre équipement terminal ou l'inscription d'informations dans ce dernier se fera donc uniquement dans les cas suivants :*

- *Permettre ou faciliter la communication par voie électronique ;*
- *Lorsque cela s’avère nécessaire à la fourniture de notre service de communication en ligne à votre demande expresse.*

*Si le navigateur le permet, vous pouvez désactiver à tout moment ces cookies, en suivant la procédure indiquée par ce navigateur. Cependant, l’Opérateur vous informe qu’une telle désactivation peut avoir pour conséquence de ralentir et/ou perturber l’accès au site internet.*



## **Article 15. Réseaux sociaux** 

Lors de la navigation sur la Plateforme, les Utilisateurs disposent de la possibilité de cliquer sur les icônes dédiées aux réseaux sociaux Facebook, LinkedIn, Twitter, Instagram. 

Les réseaux sociaux permettent d'améliorer la convivialité de la Plateforme et aident à sa promotion via les partages.

Lorsque les Utilisateurs utilisent ces boutons, l’Opérateur peut avoir accès à des informations personnelles que les Utilisateurs auraient indiquées comme publiques et accessibles depuis leurs profils Facebook, LinkedIn, Twitter ou Instagram. Cependant, l’Opérateur ne crée ni n’utilise aucune base de données indépendante de Facebook, LinkedIn ou Twitter, Instagram et n’exploite aucune donnée relevant de votre vie privée par ce biais.

#### **En savoir plus**

Afin de limiter les accès de tiers à vos informations personnelles présentes sur Facebook, LinkedIn, Twitter et Instagram, nous vous invitons à paramétrer vos profils et/ou la nature de vos publications via les espaces dédiés sur les médias sociaux dans le but d’en limiter l’audience.

## **Article 16. Sécurité**

L’Opérateur respecte le RGPD et la loi Informatique et Libertés en matière de sécurité et de confidentialité de vos données.

Nous mettons en œuvre toutes les mesures techniques et organisationnelles nécessaires afin d’assurer la sécurité de nos traitements de données à caractère personnel et la confidentialité des données que nous collectons.

A ce titre, nous prenons toutes les précautions utiles, au regard de la nature des données et des risques présentés par les traitements pour en préserver la sécurité et, notamment, empêcher que les données soient déformées, endommagées, ou que des tiers non autorisés y aient accès (protection physique des locaux, procédés d’authentification des personnes accédant aux données avec accès personnel et sécurisé via des Identifiants et mots de passe confidentiels, protocole https sécurisé, journalisation et traçabilité des connexions, chiffrement de certaines données…). 


PEKOIA - Tous droits réservés 2021

`

const Confidentialite = () => {

    console.log('@CONF')

 
    return (<div className="px-100">
        <MarkdownStyles>
            <ReactMarkdown>
                {textContent}
            </ReactMarkdown>
                
        </MarkdownStyles>

    </div>)
}

export default Confidentialite