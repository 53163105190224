import React from "react"

const TeachingItem = (props) => {
  const {icon, delayTime, title, description} = props
  return (
    <div className="mb-40 col-md-6 col-lg-4 d-flex align-items-baseline" data-aos="fade-down" data-aos-delay={delayTime}>
      <div className="ml-15 w-30 mr-15 flex-shrink-0 text-lg-center icon">
        <i className={`${icon} color-white f-40`}>
        </i>
      </div>
      <div className="inner">
        <div className="mb-20 f-14 semibold text-white text-uppercase sp-20 title pl-20">
          {title}
        </div>
        <div className="op-7 text-adaptive color-white f-18">
          {description}
        </div>
      </div>
    </div>
  )
}
export default TeachingItem
