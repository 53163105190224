
import { sanboxPush } from '../../components/helpers/fetchers'
import store from "../../redux/store"
import { lemonwayId } from '../helpers/migration'

import { useEffect } from 'react'



const callBack = (DBupdateFormateur, fetchData, user) => (LemonwayResponse) => {


  console.log('@CALL-BACK')
  console.log(LemonwayResponse)





  const id = LemonwayResponse?.account?.id
  const legalId = LemonwayResponse?.legalAccount?.id
  const data = store.getState().dataReducer
  if (id) {
    

    DBupdateFormateur({ id: data.id, [lemonwayId]: id }, user, fetchData)
  }
  if (legalId) {
    

    DBupdateFormateur({ id: data.id, [lemonwayId]: legalId }, user, fetchData)
  }
}



export const createIndividualAccount = (data, DBupdateFormateur, fetchData, user) => {

  const formateurData = store.getState().dataReducer
  

  const sandBoxData = {
    payload: data,
    serviceUrl: '/accounts/individual/',
    method: 'POST',
    claim: 'NULL',
    id: formateurData.id
  }


  const APIResponse = sanboxPush(sandBoxData, callBack(DBupdateFormateur, fetchData, user))


}

export const createCompanyAccount = (data, DBupdateFormateur, fetchData, user) => {

  const formateurData = store.getState().dataReducer
  

  const sandBoxData = {
    payload: data,
    serviceUrl: '/accounts/legal/',
    method: 'POST',
    claim: 'NULL',
    id: formateurData.id
  }


  const APIResponse = sanboxPush(sandBoxData, callBack(DBupdateFormateur, fetchData, user))


}






export const getAccountDetails = (id, formateurId, dataSetter) => {

  const sandBoxData = {
    serviceUrl: `/accounts/${id}`,
    method: 'GET',
    id: formateurId,
    claim: id
  }








  const APIResponse = sanboxPush(sandBoxData, dataSetter)

}


export const getDocuments = (id, formateurId, dataSetter) => {




  const sandBoxData = {
    serviceUrl: `/accounts/${id}/documents`,
    method: 'GET',
    id: formateurId,
    claim: id
  }
 

  const APIResponse = sanboxPush(sandBoxData, dataSetter)

}

export const uploadDocument = (formateurId, id, name, buffer, code, callback) => {

  const data = {
    name: name,
    type: code,
    buffer: buffer
  }

  const sandBoxData = {
    serviceUrl: `/accounts/${id}/documents/upload`,
    method: 'POST',
    payload: data,
    claim: id,
    id: formateurId
  }

  


  



  const APIResponse = sanboxPush(sandBoxData, (lemonwayResponse) => {


    
    if (lemonwayResponse)
    callback({...lemonwayResponse.uploadDocument, type:code})
  })

}


