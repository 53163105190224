import React, { useEffect, useState, useRef } from "react";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import youtubeAPI from "./youtubeAPI";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { buttonColor } from "../components/BO-style";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import TextEditor from "./helpers/sunTextEditor";
import { blueGrey } from "@mui/material/colors";
import { cleanHtml } from "../components/SVGIcons/helpers";

const nbMaxLetters = 750;

const videosValidRegex = [
  {
    regex:
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/g,
  },
];

const linkedinValidRegex = [
  { regex: /(((?:https?:)?\/\/)?www.linkedin.com\/in\/(.+)\/)/ },
];

const FormateurDescForm = (props) => {
  var { descFormData, setDescFormData } = props;
  const data = useSelector((state) => state.dataReducer);
  const [youtube, setYoutube] = useState(null);
  if (!youtube) youtubeAPI(setYoutube, "hNaWhVnnp4M");

  const [newDocumentHtml, setNewDocumentHtml] = useState(null);

  useEffect(() => {
    setDescFormData({ ...descFormData, documentHtml: newDocumentHtml });
    // modalStoreHook[1]({ ...modalStoreHook[0], documentHtml: newDocumentHtml })
  }, [newDocumentHtml]);

  useEffect(() => {
    setDescFormData({
      documentHtml: data.documentHtml,
    });
  }, [data]);

  const classes = useStyles(props);

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        width="55vw"
        bgcolor="white"
        padding="2vw"
      >
        <form className={classes.root} noValidate autoComplete="off">
          <Box display="flex" flexDirection="column">
            <Box>
              <Box marginTop="10px" height="50px">
                <div
                  style={{
                    position: "relative",
                    bottom: "0.5vw",
                    padding: "1vw",
                    color: "#505050",
                  }}
                >
                  Présentez-vous en valorisant vos expériences. Pensez à mettre
                  en gras les élèments clés de votre parcours.{" "}
                </div>
              </Box>
              <Box marginTop="20px">
                {descFormData && (
                  <TextEditor
                    content={
                      descFormData
                        ? cleanHtml(descFormData.documentHtml)
                          ? cleanHtml(descFormData.documentHtml)
                          : ""
                        : ""
                    }
                    setContent={setNewDocumentHtml}
                    setNewDocumentHtml={setNewDocumentHtml}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default FormateurDescForm;

const StyledButton = withStyles({
  root: {
    borderRadius: 3,
    backgroundColor: "white",
    outline: "none",
    boxShadow: "none",
    border: 0,
    color: "purple",
    height: "2vw",
    width: "2vw",
    padding: "0.5vw",
    "&:hover": {
      backgroundColor: "white",
      color: "red",
      boxShadow: "none",
    },
  },
  label: {},
})(Button);

const useStyles = makeStyles(
  (theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "14vw",
        fontSize: "0.2rem",
      },
    },
    margin2: {
      margin: theme.spacing(1),
      width: "29vw",
      fontSize: "1.2rem",
      color: "gray",
      backgroundColor: "#efefef",
      padding: "1vw",
      borderRadius: "0.5vw",
    },
    margin: {
      margin: theme.spacing(1),
      width: "29vw",
      fontSize: "0.8rem",
      backgroundColor: "#efefef",
      padding: "1vw",
      borderRadius: "0.5vw",
    },
    imageMargin2: {
      position: "relative",
      margin: theme.spacing(1),
      width: "16vw",
      fontSize: "1.0rem",
      bottom: "9vw",
      height: "7vw",
      color: "yellow",
      background: "linear-gradient(#00000090, #000000c0)",
      padding: "0vw 1vw 0vw 1vw",
    },
    imageMargin: {
      position: "relative",
      bottom: "9vw",
      height: "7vw",
      margin: theme.spacing(1),
      width: "16vw",
      fontSize: "1.0rem",
      color: "yellow",
      background: "linear-gradient(#00000090, #000000c0)",
      padding: "0vw 1vw 0vw 1vw",
    },
    button2: {
      background: "#CACACA",
      borderRadius: "0.5vw",
      border: 0,
      color: "white",
      height: 48,
      margin: "1vw",
      "&:hover": {
        backgroundColor: "#CACACA",
        color: "#white",
      },
    },
    button: {
      background: "#AAAACA",
      borderRadius: "0.5vw",
      border: 0,
      color: "white",
      height: 48,
      margin: "1vw",
      "&:hover": {
        backgroundColor: "#8A8ACA",
        color: "#white",
      },
    },
    label: {
      textTransform: "capitalize",
    },
    modalTitle: {
      padding: "2vw 1vw 1vw 1vw",
      ...theme.typography.body1,
      backgroundColor: blueGrey[100],
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    imageTitle1: {
      ...theme.typography.body1,
      fontSize: "1.1rem",
      fontWeight: "bold",
      textAlign: "left",
      color: "#ffeaa7",
    },
    imageTitle2: {
      ...theme.typography.body1,
      marginTop: "0.5vw",
      fontSize: "0.8rem",
      textAlign: "left",
      fontWeight: "bold",
    },
    multiline: {
      fontSize: "0.9rem",
      width: "25.5vw",
    },
  }),
  { index: 1 }
);
