import {imageStyle} from './helpers'

const linkedinIconString = (href) => {
    return(`<div style="background-color:aliceblue><a el="nofollow" target="_blank" href="${href}" class="_linkedinIcon"><img ${imageStyle}
    src="https://pekoia.com/static/linkedin-9c59f8cbba16a2345d67c8b5f7db91c1.jpg"
    alt=""></a></div>`)
    // return(`<div><a  class="_linkedinIcon" rel="nofollow" target="_blank" href="${href}">${baseSVG}</a></div>`)

}

export default linkedinIconString


