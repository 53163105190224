import { API, graphqlOperation } from "aws-amplify";
import { createCompanyAccount, updateCompanyAccount, createMemberShip, updateMemberShip, deleteCompanyAccount, deleteMemberShip } from "../../graphql/mutations";

export const createNewCompanyAccount = (companyAccount) => {
  const input = {
    ...companyAccount
  }
  return (API.graphql(graphqlOperation(createCompanyAccount, { input: input })))
}

export const updateCompanyAccountFunc = (companyAccount) => {
  const input = {
    ...companyAccount
  }
  return (API.graphql(graphqlOperation(updateCompanyAccount, { input: input })))
}

export const deleteCompanyAccountById = (companyAccount) => {
  const input = {
    ...companyAccount
  }
  return (API.graphql(graphqlOperation(deleteCompanyAccount, { input: input })))
}

export const createNewMembership = (membership) => {
  const input = {
    ...membership
  }
  return (API.graphql(graphqlOperation(createMemberShip, { input: input })))
}

export const updateMembershipFunc = (membership) => {
  const input = {
    ...membership
  }
  return (API.graphql(graphqlOperation(updateMemberShip, { input: input })))
}

export const deleteMemberShipById = (membership) => {
  const input = {
    ...membership
  }
  return (API.graphql(graphqlOperation(deleteMemberShip, { input: input })))
}
