import React, { useState, useEffect, useRef } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import useStyles from '../style/textFields';
import Collapse from '@mui/material/Collapse';
import ImageUpload from '../helpers/imageUpload';
import { blueGrey } from '@mui/material/colors';
import { Box } from "@mui/system";
import { ImageUploadButton } from "../general";
import CancelIcon from '@mui/icons-material/Cancel';
import { fakeHashList } from '../helpers/fakeDB';
import { buttonColor } from "../BO-style";
import { useSelector } from 'react-redux';

const nbMaxLettersIntro = 500
const nbMaxLettersHashList = 20
const nbMaxHash = 6

const webValidRegex = [
	{ regex: /(http(s):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/ }
]

const CompanyForm = (props) => {
	var { formData, setFormData, companyData } = props
	const data = useSelector(state => state.dataReducer)
	const company = data.companies && data.companies[0]
	const classes = useStyles(props)
	const [content, setContent] = useState('')
	// const [currentHash, setCurrentHash] = useState(null)
	const [openWeb, setOpenWeb] = useState(false)
	const [webError, setWebError] = useState(false)
	const [webSize, setWebSize] = useState('1.5rem')
	const [hashTable, setHashTable] = useState([])
	const [selectFile, setSelectFile] = useState(null)
	const userData = data ? data : { nom: "", prenom: "", intro: "" }
	const { web = "" } = userData
	const inputRef = useRef()

	useEffect(() => {
		if (companyData) {
			setFormData({
				id: companyData.id,
				fullName: companyData.fullName,
				description: companyData.description,
				web: companyData.web,
				type: companyData.type,
				hashList: companyData.hashList,
			})
			setHashTable(companyData.hashList ? companyData.hashList.split('|') : [])
		}
	}, [data])

	const handleKeyPress = (event, key) => {
		if (event.key === "Enter") {
			if (hashTable.length < nbMaxHash) {
				const selectedHash = content
				if (!hashTable.includes(selectedHash)) {
					const newHashTable = hashTable.concat(selectedHash)
					setHashTable(newHashTable)
					setFormData({ ...formData, hashList: newHashTable.join('|') })
				}
			}
			inputRef.current.focus()
			setContent('')
		}
	}

	const handleChange = (key) => {
		return (e) => {
			const value = e.target.value
			if (key === "hashList") {
				setContent(value)
			}
			if (key === 'web') {
				const result = webValidRegex.find((node) => e.target.value.search(node.regex) === 0)
				if (result || (e.target.value === '')) {
					setWebError(false)
				}
				else {
					setWebError(true)
				}
			}
			const target = {
				...formData,
				[key]: value
			}
			setFormData(target)
		}
	}

	const getNbLetters = (field) => {
		if ((formData && formData[field]) || (formData && formData[field] === '')) {
			const text = formData[field]
			if (!text || text === '')
				return 0
			return text.length
		}
		if (data && data[field]) {
			const text = data[field]
			if (!text || text === '')
				return 0
			return text.length
		}
		return 0
	}

	const handleIndex = (index) => {
		const nbMaxHash = 10
		if (hashTable.length < nbMaxHash) {
			const selectedHash = mockAPI(content)[index] && mockAPI(content)[index].hash
			if (!hashTable.includes(selectedHash)) {
				const newHashTable = hashTable.concat(selectedHash)
				setHashTable(newHashTable)
				setFormData({ ...formData, hashList: newHashTable.join('|') })
			}
		}
		inputRef.current.focus()
		setContent('')
	}

	const handleClickHash = index => (event) => {
		const newHashTable = JSON.parse(JSON.stringify(hashTable))
		newHashTable.splice(index, 1)
		setHashTable(newHashTable)
		setFormData({ ...formData, hashList: newHashTable.join('|') })
	}

	return (
		<Box display="flex" flexDirection="column" width="50vw" padding="2vw">
			<Box display="flex" flexDirection="column">
				<Box display="flex" flexDirection="row" height="18vh">
					<Box padding="1vw" paddingTop="2vw" flex={2} borderBottom="1px solid" bgcolor={blueGrey[100]}>
						<div className={classes.modalTitle}>
							Le logo de l'organisme
						</div>
					</Box>
					<Box display="flex" height="18vh" bgcolor="#fff">
						<ImageUploadButton setSelectFile={setSelectFile} />
						<ImageUpload data={data.companies[0]} tempImageStore={formData?.imageStore ? formData.imageStore : data.companies[0].imageStore} setTempImageStore={(x) => setFormData({ ...formData, imageStore: x })} selectFile={selectFile} />
					</Box>
				</Box>
				<form className={classes.modal00root} noValidate autoComplete="off">
					<Box flexDirection="column" marginTop="1vw" display="flex">
						<Box flexDirection="row" display="flex" alignItems="center" justifyContent="space-between" >
							<TextField
								label="Nom de l'organisme:"
								variant='filled'
								value={formData ? formData.fullName ? formData.fullName : '' : ''}
								onChange={handleChange("fullName")}
								sx={{
									width: "100%"
								}}
							/>
							<Box marginLeft="10px">
								<TextField
									variant='filled'
									error={webError}
									label="Site web:"
									value={formData ? formData.web ? formData.web : '' : ''}
									onChange={handleChange("web")}
									sx={{ width: "300px"}}
								/>
							</Box>
						</Box>
						<Box marginTop="1vw" display="flex" flexDirection="column">
							<TextField
								variant='filled'
								title="Décrivez vous de manière brève, en vous limitant à 250 caractères"
								label="Courte présentation :"
								value={formData ? formData.description ? formData.description : '' : ''}
								multiline
								rows={3}
								// maxRows={3}
								onChange={handleChange("description")}
								InputProps={{ shrink: 'true', classes: { root: classes.textFieldRoot, focused: classes.textFieldRootAlt, input: classes.textFieldRootAlt2 } }}
								InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
								SelectProps={{ classes: { root: classes.selectProps } }}

								onInput={(e) => {
									e.target.value = e.target.value.substring(0, nbMaxLettersIntro)
								}}
							/>
							<Box position="relative" bottom="0.10vw" color="gray" fontSize="0.7rem" textAlign="right">
								{getNbLetters("intro") + "/" + nbMaxLettersIntro}
							</Box>
						</Box>
						<Box marginBottom='20px'>
							<FormControl variant='filled' sx={{ minWidth: 200 }}>
								<InputLabel>Type d'organisme</InputLabel>
								<Select
									label="Type d'organisme"
									value={formData ? formData.type ? formData.type : '' : ''}
									onChange={handleChange("type")}
									sx={{
										width: 250
									}}
								>
									<MenuItem value={'ASSOCIATION'}>Association</MenuItem>
									<MenuItem value={'MUSEE'}>Musée</MenuItem>
									<MenuItem value={'THINKTANK'}>Think tank</MenuItem>
									<MenuItem value={'SALLE'}>Salle de spectacles</MenuItem>
									<MenuItem value={'UNIVERSITE'}>Université</MenuItem>
									<MenuItem value={'AUTRE'}>Autre</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Box marginTop="0vw">
							<Box display="flex" flexDirection="row" width="300px" >
								<Box width="250px">
									<Box width="250px" >
										<TextField
											variant='filled'
											id="hashList"
											onKeyDown={(event) => handleKeyPress(event)}
											inputProps={{
												style: { width: '200px' }
											}}
											inputRef={inputRef}
											label="Ajoutez une spécialité et validez"
											value={content}
											onChange={handleChange("hashList")}

											onInput={(e) => {
												e.target.value = e.target.value.substring(0, nbMaxLettersHashList)
											}}
											sx={{
												width: 250
											}}
										/>
									</Box>
									<Box color="gray" fontSize="0.7rem" textAlign="right">
										{content ? content.length : 0}{"/" + nbMaxLettersHashList}
									</Box>
								</Box>
								<Box marginLeft="8vw" border="1px solid" color="#6a6a6a" marginTop="-2vw" borderColor={content !== '' && (mockAPI(content).length > 0) ? "#cacaca" : "white"} padding="0.5vw">
									{formData && mockAPI(formData.hashList).map(
										(elem, index) => <HashLine key={index} elem={elem} index={index} handleIndex={handleIndex} hashTable={hashTable} />
									)}
								</Box>
							</Box>
						</Box>
						<Box width='600px'>
							<ShowHash hashTable={hashTable} handleClickHash={handleClickHash} />
						</Box>
					</Box>
					<br />
				</form>
			</Box>
		</Box>
	);
}

export default CompanyForm

const HashLine = ({ elem, index, handleIndex, hashTable }) => {
	const formatNumber = (number) => {
		const log = Math.trunc(Math.log10(number)) - 1
		const mult = Math.pow(10, log)
		if (number < 1000)
			return (number)
		if (number < 1000000)
			return (Math.trunc(number / mult) * mult / 1000 + 'k')
		return ('----')
	}
	return (
		<Box display="flex" flexDirection="row" >
			<div onClick={() => {
				if (hashTable.length < nbMaxHash)
					handleIndex(index)
			}} style={{ cursor: "default", marginLeft: "1vw", width: "12vw" }}>{elem.hash}</div>
			<div style={{ cursor: "default", width: "3vw", fontWeight: "bold" }}>{formatNumber(elem.nb)}</div>

		</Box>
	)
}

const mockAPI = (input) => {
	if (!input)
		return []
	const length = input.length
	const list = fakeHashList
	const result = list
		.filter(elem => (elem.hash.toLowerCase().substring(0, length) === input.toLowerCase()))
		.sort((b, a) => a.nb - b.nb)
		.filter((elem, idx) => idx < 4)
	return (result)
}

const ShowHash = ({ hashTable, handleClickHash }) => {
	const maxNbLetters = 15

	const cut = (hash) => {
		if (hash && (hash.length > maxNbLetters))
			return hash.substring(0, maxNbLetters) + '...'
		return hash
	}

	return (
		<Box display="flex" flexDirection="row" flexWrap="wrap" width="400px" height="90px" alignContent="center" alignItems="center">
			{hashTable.map((hash, index) => {
				return (<Box maxwidth="7vw" margin="2px" height="25px" key={index} display="flex" flexDirection="row" alignContent="center" alignItems="center">
					<Box
						textAlign="center"
						color="#808080"
						title={hash}
						whiteSpace="nowrap"
						display="flex" flexDirection="row" alignContent="center" alignItems="center"
						padding="0 20px 0 20px"
						height="100%"
						margin="2px"
						borderRadius="12px"
						bgcolor="#dadada" key={index}>
						{cut(hash)}
					</Box>
					<Box position="relative" bottom="5px" right="18px" >
						<CancelIcon style={{ fontSize: "0.7vw", color: "#aaaaaa" }} onClick={handleClickHash(index)} />
					</Box>
				</Box>)
			})}
		</Box>
	)
}
