import { Box, Typography } from "@mui/material";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ResetTvIcon from "@mui/icons-material/ResetTv";
import LockIcon from "@mui/icons-material/Lock";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

const Tarifs = () => {
  return (
    <Box display="flex" flexDirection="column" marginTop="40px">
      <Box display="flex" flexDirection="row">
        <Box bgcolor="#eb9c34" width="16px" marginRight="24px" />
        <Typography style={{ fontSize: "2rem", fontWeight: "bold" }}>
          NOS COMMISSIONS PAR TRANSACTION
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        margin="40px"
        alignItems="center"
      >
        <Box
          width="15%"
          bgcolor="#1c1d1f"
          height="400px"
          margin="20px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="20px"
        >
          <Typography
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              color: "white",
              textAlign: "center",
            }}
          >
            Commissions Pekoia
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          width="80%"
          marginTop="20px"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" margin="1rem">
              <Typography
                style={{
                  color: "#eb9c34",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                Direct
              </Typography>
              <CoPresentIcon
                style={{
                  color: "#eb9c34",
                  fontSize: "2.5rem",
                  marginLeft: "1rem",
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography>10% + 30ct HT</Typography>
              <Typography>(cycle)</Typography>
              <Typography>12% + 30ct HT</Typography>
              <Typography>(conférence seule)</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" margin="1rem">
              <Typography
                style={{
                  color: "#eb9c34",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                Replay
              </Typography>
              <ResetTvIcon
                style={{
                  color: "#eb9c34",
                  fontSize: "2.5rem",
                  marginLeft: "1rem",
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography>30% + 30ct HT</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" margin="1rem">
              <Typography
                style={{
                  color: "#eb9c34",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                Privatisation
              </Typography>
              <LockIcon
                style={{
                  color: "#eb9c34",
                  fontSize: "2.5rem",
                  marginLeft: "1rem",
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography>Au cas par cas</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" margin="1rem">
              <Typography
                style={{
                  color: "#eb9c34",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                Abonnement
              </Typography>
              <SubscriptionsIcon
                style={{
                  color: "#eb9c34",
                  fontSize: "2.5rem",
                  marginLeft: "1rem",
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography>Ouverture en 2025</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        bgcolor="#d6d6d6"
        padding="20px"
        marginLeft="60px"
        marginRight="60px"
      >
        <Typography
          style={{
            fontSize: "1.3rem",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Principes de tarification :
        </Typography>
        <Typography>
          - Le tarif de la conférence est fixé librement par l'organisateur. La
          conférence pouvant être gratuite ou payante.
        </Typography>
        <Typography>
          - Le chiffres d'affaires des conférences, déduction faite des
          commissions de Pekoia, est disponible sur le compte de paiement
          Lemonway de chaque conférencier ou organisateur de conférence. Cet
          argent est libérable pour un virement sur son compte bancaire 7 jours
          après la conférence ou après la fin d'un cycle de conférences.
        </Typography>
      </Box>
    </Box>
  );
};

export default Tarifs;
