import React from 'react'
import { Link } from 'react-router-dom'

const Button = (props) => {
  const {title, size, ...rest} = props

  return (
    <Link to="#" className={`btn action-1 ${size}`} {...rest}>
      {title}
    </Link>
  )
}
export default Button
