// dynamoDB data associated to a userID

import { createSlice } from '@reduxjs/toolkit'

export const formateurDataSlice = createSlice({
    name: 'formateurData',
    initialState: {},

    reducers: {
        addImageToStore: (state, action) => {state.newImageStore = {...state.newImageStore, [action.payload.name] : action.payload.content}},
        setImage: (state, action) => {state.imageStore = action.payload},
        setFormateurData: (state, action) => ({...state, ...action.payload}) ,
        insertPublication: (state, action) => {

            const publicationList = JSON.parse(state.publicationList)

            const originIndex = parseInt(action.payload.originIndex)
            const destinationIndex = parseInt(action.payload.destinationIndex)
            const positionOrigin = publicationList.indexOf(originIndex)
            const positionDestination = publicationList.indexOf(destinationIndex)
            publicationList.splice(positionOrigin, 1)
            publicationList.splice(positionDestination, 0, originIndex)
            state.publicationList = JSON.stringify(publicationList)
            state.modified = true


        },
        addLemonwayDocument: (state, action) =>{

            state.documents = state.documents.concat(action.payload)
   
        },
        deletePublication: (state, action) => {
            const publicationList = JSON.parse(state.publicationList)
            const id = parseInt(action.payload.id)
            const newPublcationList = publicationList
                .filter(publication => publication != id)
            state.publicationList = JSON.stringify(newPublcationList)


        },
        uploadPublicationList: (state) => {

            state.modified = false


        }
    }
})

export const { setFormateurData, insertPublication, uploadPublicationList, deletePublication , addLemonwayDocument, setImage, addImageToStore} = formateurDataSlice.actions




export default formateurDataSlice.reducer

