import {parseFragment, serialize} from 'parse5'
import twitchIconString from './twitch'
import youtubeIconString from './youtube'
import linkedinIconString from './linkedin'
import instagramIconString from './instagram'

export const imageStyle = `style="width:30px;height:30px;padding:3px;border-radius:8px"`
export const externalPageImageStyle = `style="width:300px;height:200px;padding:3px;border-radius:8px;object-fit:cover;"`
export const externalPageContainerStyle = `style="width:30px;height:30px;padding:3px;border-radius:8px"`
export const componentStyle= `style="width:300px;"`
export const titleStyle= `style="font-size:0.8rem;color:#7f8c8d;line-height:0.9rem;font-family:Montserrat"`
export const pageUrlStyle= `style="font-weight:600;line-height:1.2rem;font-family:Montserrat"`



const findElementsByClassName = (xml, classNames) =>{

    const found = xml.attrs?.find(elem => elem.name === 'class' && classNames.includes(elem.value))
  
 

    let result = found ? [xml] : []

   // console.log(result)

    

    
    if (xml.childNodes)
    {
        xml.childNodes.forEach(node => {
            const childFind = findElementsByClassName(node, classNames)
            result = result.concat(childFind)

        })
    }

    
    return result


    


    

}

const cleanBeforeSave = (html) =>{

   

    const xml = parseFragment(html)
    const elemsMap = [{
        'value' : '_twitchIcon',
        'htmlConstructor' : (href) => `<twitchicon href='${href}'/>` 
    },
    {
        'value' : '_linkedinIcon',
        'htmlConstructor' : (href) => `<linkedinicon href='${href}'/>` 
    },
    {
        'value' : '_youtubeIcon',
        'htmlConstructor' : (href) => `<youtubeicon href='${href}'/>` 
    },
    {
        'value' : '_instagramIcon',
        'htmlConstructor' : (href) => `<instagramicon href='${href}'/>` 
    }]


   
    const findNodes = findElementsByClassName(xml, ['_linkedinIcon','_twitchIcon','_youtubeIcon','_instagramIcon'])


    findNodes.forEach(node =>{





        const className = node.attrs.find(attr=>attr.name === 'class').value
        const href = node.attrs.find(attr=>attr.name === 'href').value
        const htmlConstructor = elemsMap.find(elem => elem.value === className).htmlConstructor
        const htmlString = htmlConstructor(href)
        const newXML =  parseFragment(htmlString).childNodes[0]
       
        newXML.parentNode = node.parentNode
        
        
        const oldChildNode = node.parentNode.childNodes.find(thisNode => thisNode === node)
        const index = node.parentNode.childNodes.indexOf(oldChildNode)
        node.parentNode.childNodes[index] = newXML
        // node.parentNode.childNodes = newchildNodes
       
     
    })

   return(serialize(xml))


}

export const cleanHtml = (html) => {


   

    if (!html)
        return ('')

    const iconFilters = [{
        regex: /<twitchicon href="([^<]+)"><\/twitchicon>/,
        icon: twitchIconString
    },
    {
        regex: /<linkedinicon href="([^<]+)"><\/linkedinicon>/,
        icon: linkedinIconString
    },
    {
        regex: /<youtubeicon href="([^<]+)"><\/youtubeicon>/,
        icon: youtubeIconString
    },
    {
        regex: /<instagramicon href="([^<]+)"><\/instagramicon>/,
        icon: instagramIconString
    }]


    iconFilters.forEach(elem => {

        for (let i = 0; i < 50; i++) {

            const findTwitch = html.match(elem.regex)
            if (findTwitch) {
                const newHtml = html.replace(findTwitch[0], elem.icon(findTwitch[1]))
                html = newHtml

            }

        }






    })
 
  
    return html



}



export default cleanBeforeSave