// Modal handling input of a new publication



import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {Grid} from 'react-loader-spinner'
import Box from '@mui/material/Box'
import { Input, styled, ToggleButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase';
import Button from "@mui/material/Button";
import { blueGrey } from '@mui/material/colors';
import {regexList, cleanText, cutText} from "../general"
import {ColorButton2} from "../BO-style"

import ConstructionIcon from '@mui/icons-material/Construction';

import fetchOpenGraph from "../helpers/fetchers"



const types = [
  {
    icon: <ConstructionIcon/>,
    title: "Conférences",
    key: "CONFERENCE",
  },
  {
    icon: <ConstructionIcon/>,
    title: "À lire",
    key: "READ",
  },
  {
    icon: <ConstructionIcon/>,
    title: "À voir",
    key: "SEE",
  },
  {
    icon: <ConstructionIcon/>,
    title: "À faire",
    key: "DO",
  },
  {
    icon: <ConstructionIcon/>,
    title: "À écouter",
    key: "LISTEN",
  },
]

const SalonPublicationForm = (props) => {
    var { formData, setFormData } = props

    const classes = useStyles(props);

    const [loading, setLoading] = useState(false)
    const [openGraphData, setOpenGraphData] = useState(null)
    const [publicationType, setPublicationType] = useState(null)
    const [imageBase64, setImageBase64] = useState(null)
    const [selected, setSelected] = useState(-1)
    const [blob, setBlob] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const [siteName, setSiteName] = useState(null)
    const [publish, setPublish] = useState(null)

    useEffect(() => {
  		var found = false
        if (inputValue.length > 0) {
          regexList.forEach(regex => {
            const find = inputValue.match(regex)
            if (find) {
              found = true
              setLoading(true)
              fetchOpenGraph(inputValue)
                .then(data => {
                    setLoading(false)
                    setPublish(find[0])
                    setSiteName(find[2])
                    setOpenGraphData({ ...data, siteUrl: inputValue })
                    setInputValue('')
                    setPublicationType(1)
                    // const publication = {
                    //     type: "opengraph",
                    //     url: inputValue,
                    //     ...data
                    // }
                    setFormData({...formData, type: "opengraph", url: inputValue, ...data})
                })
            }
          })
			if (found === false) {
				setPublicationType(2)
			}
		}
    }, [inputValue, blob])

    const handleUploadClick = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {
            // not implemented yet
            //setSpinner(true)
        }
        reader.onloadend = () => {
            const data = reader.result
            setImageBase64(data)
            setFormData({...formData, base64Image: data})
            setBlob(file)
        }
        setPublicationType(2)
    }

    const handleSubmission = (event) => {

    }

    const handleSelected = (idx) => {
      if (selected != idx) {
        setSelected(idx)
        setFormData({...formData, activityType: types[idx].key})
      } else {
        setSelected(-1)
      }
    }

    const handleInputChange = (event) => {
        var lines = event.target.value.split(/\n/).length
        if (event.target.value.length < 200 && lines <= 3)
            setInputValue(event.target.value)
    }


    return (
      <div className="App" >
        <Box display="flex" flexDirection="column" bgcolor="white" marginBottom="10px">
            <Box borderBottom="1px solid" borderRadius="8px">
                <div className={classes.modalTitle}>
                    Ajouter une publication
                </div>
            </Box>
            <Box>
                <form className={classes.root}>
                    <InputBase 
                        className={inputValue != '' ? classes.margin : classes.margin2}
                        placeholder="Copiez-collez le lien (url) vers votre publication ..."
                        label="ThemeProvider"
                        title="Copiez-colez le lien vers un article, une vidéo youtube ou vimeo, ou toute autre publication que vous souhaitez mettre en avant dans votre page intervenant"
                        multiline
                        value={inputValue}
                        rowsmax={3}
                        onChange={(event) => handleInputChange(event)} />
                </form>
                {loading && <MyLoader />}
                {!loading && (publicationType === 1) && <Extract openGraphData={openGraphData} siteName={siteName} />}
                {!loading && (publicationType === 2) && <ImagePublication formData={formData} setFormData={setFormData} setPublish={setPublish} imageBase64={imageBase64} blob={blob} url={inputValue}/>}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center">
              {types.map((elem, idx) => 
              <Box key={idx} style={{ margin: "10px", width: "90px", height: "105px", display: "flex", alignItems: "center", flexDirection: "column"}}>
                <StyledButton value={elem.title} selected={idx === selected ? true : false} onClick={() => handleSelected(idx)}>{elem.icon}</StyledButton>
                <Typography>{elem.title}</Typography>
              </Box>)}
            </Box>
            {publicationType !== null && publicationType !== 1 ?
            <Box border="1px solid" display="flex" flexDirection="row" bgcolor="white" padding="1vw" borderRadius="0.5vw">
                <Box textAlign="left" title="Si vous n'avez pas de lien vers une de vos publications, vous pouvez mettre en avant ici une photo avec une titre.">
                    <Typography color="#c41212">
                        Ce site Internet ne nous permet pas de créer de vignette, veuillez sélectionner une image d'illustration de votre publication.
                    </Typography>
                </Box>
                <Box flex={2}>
                </Box>
                <ColorButton2
                    variant="contained"
                    component="label">
                    <Input accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        name="upload-photo"
                        type="file"
                        onChange={handleUploadClick} />
                        <AddAPhotoIcon onClick={handleSubmission} />
                </ColorButton2>
            </Box> : <></>}
        </Box>
      </div>
    );
}


const MyLoader = () => {
    return (<Box height="20vw" display="flex" flexDirection="row" alignItems="center" justifyContent="center" >
        <Grid color="gray" height="50" width="50" />
    </Box>)
}



export default SalonPublicationForm

const StyledButton = styled(ToggleButton)({
  width: "65px",
  height: "65px",
  backgroundColor: "#f0f0f0",
  borderRadius: "20px",
  outlineColor: "white",
  color: "5b5b5b",
  '&:hover': {
		backgroundColor: "#1a5aa3",
    color: 'white',
	},
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: "#1a5aa3",
    color: 'white',
  },
})

const ImagePublication = (props) => {
    const { blob, imageBase64, setPublish, setFormData, formData, url } = props
    const classes = useStyles(props);
    const [imageValue, setImageValue] = useState('')
    const handleInputChange = (event) => {
        var lines = event.target.value.split(/\n/).length
    
        if (event.target.value.length < 50 && lines <= 3)
            setImageValue(event.target.value)
        setPublish(true)
        const publication = {
            type: "image",
            blob: blob,
            url: url,
            imageTitle: event.target.value,
        }
        setFormData({...formData, ...publication})
      }

    return (<Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
        <img style={{ width: "16vw", objectFit: "cover", marginTop: "5px" }} src={imageBase64} alt="" />
        {imageBase64 ? <form className={classes.root}>
            <InputBase
                style={{ color: '#ffffff' }}
				className={imageValue != '' ? classes.margin : classes.margin2}
                placeholder="Titre de la publication"
                label="ThemeProvider"
                multiline
                value={imageValue}
                rowmax={2}
                onChange={(event) => handleInputChange(event)} />
        </form> : <></>}
    </Box>)
}

const Extract = (props) => {
    const { openGraphData, siteName } = props
    const classes = useStyles(props);
    const { ogImage, ogDescription, ogTitle } = openGraphData
    const cleanDescription = cleanText(ogDescription)
    const cleanTitle = cleanText(ogTitle)

    if (!openGraphData || (openGraphData.ogTitle === null))
        return null

    return (<Box width="100%" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center">
        <Box display="flex" flexDirection="column" margin="1vw">
            <img style={{ height: "18vw", objectFit: "cover", width: "25vw" }} src={ogImage}></img>
            <div style={{
                position: "relative",
                left: "0vw",
                // bottom: "7vw",
                width: "25vw",
                // height: "7vw",
                background: "linear-gradient(#00000090, #000000c0)",
                color: "white"
            }}>
                <div style={{ padding: "0.5vw" }}>
                    <div className={classes.imageTitle1}>
                        {siteName.toUpperCase()}
                    </div>
                    <div className={classes.imageTitle2}>
                        {cutText(cleanTitle, 120)}
                    </div>
                </div>
            </div>
        </Box>
    </Box>)
}

const useStyles = makeStyles((theme) => ({

    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '5ch',
            fontSize: "0.8rem"
        },
    },
    margin2: {
        marginTop: "5px",
        // margin: theme.spacing(1),
        width: "100%",
        fontSize: "1.1rem",
        color: "black",
        backgroundColor: "#efefef",
        padding: "1vw",
        borderRadius: "0.5vw"
    },
    margin: {
        marginTop: "5px",
        width: "100%",
        fontSize: "0.8rem",
        backgroundColor: "#efefef",
        padding: "1vw",
        borderRadius: "0.5vw"
    },
    imageMargin2: {
        position: "relative",
        // margin: theme.spacing(1),
        width: "16vw",
        fontSize: "1.0rem",
        // bottom: "8.5vw",
        height: "5vw",
        color: "white",
        background: "linear-gradient(#000000f2, #000000f2)",
        padding: "0vw 1vw 0vw 1vw",
    },
    imageMargin: {
        position: "relative",
        // bottom: "7vw",
        height: "5vw",
        // margin: theme.spacing(1),
        width: "16vw",
        fontSize: "1.0rem",
        color: "lightyellow",
        background: "linear-gradient(#000000f2, #000000f2)",
        padding: "0vw 1vw 0vw 1vw",
    },
    button2: {
        background: '#CACACA',
        borderRadius: "0.5vw",
        border: 0,
        color: 'white',
        height: 48,
        margin: "1vw",
        '&:hover': {
            backgroundColor: "#CACACA",
            color: '#white'
        }
    },
    button: {
        background: '#AAAACA',
        borderRadius: "0.5vw",
        border: 0,
        color: 'white',
        height: 48,
        margin: "1vw",
        '&:hover': {
            backgroundColor: "#8A8ACA",
            color: '#white'
        }
    },
    label: {
        textTransform: 'capitalize',
    },
    modalTitle: {
        padding: "2vw 1vw 2vw 1vw",
        backgroundColor: blueGrey[100],
        ...theme.typography.body1,
        fontSize: "1.2rem",
        fontWeight: "bold",
        borderRadius: "8px",
    },
    imageTitle1: {
        ...theme.typography.body1,
        fontSize: "1.1rem",
        fontWeight: "bold",
        textAlign: "left",
        color: "#ffeaa7"

    },
    imageTitle2: {
        ...theme.typography.body1,
        marginTop: "0.5vw",
        fontSize: "0.8rem",
        textAlign: "left",
        fontWeight: "bold"
    }
}), { index: 1 });
