import {imageStyle} from './helpers'




const linkedinIconString = (href) => {
    return(`<a el="nofollow" target="_blank" href="${href}" class="_youtubeIcon"><img ${imageStyle} 
    src="https://pekoia.com/static/youtube-63aa7994a8cc00ea56b53bbfcef748b3.jpg"
    alt=""></a>`)
   //  return(`<div ><a  class="_youtubeIcon" rel="nofollow" target="_blank" href="${href}">${baseSVG}</a></div>`)

}

export default linkedinIconString


