export const importedcanvasDraw = (imageRef, canvasRef) => {

   
    
    const minSize = Math.min(imageRef.current.naturalWidth, imageRef.current.naturalHeight)
    const x = Math.max(0, (imageRef.current.naturalWidth - minSize) / 2)
    const y = Math.max(0, (imageRef.current.naturalHeight - minSize) / 2)

    const NbPixels = 600

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    canvas.width = NbPixels
    canvas.height = NbPixels

    ctx.drawImage(
        imageRef.current,
        x, y, minSize, minSize,
        0, 0, NbPixels, NbPixels
    )
}

export const importedcanvasSecondDraw  = (imageRef, canvasRef, cropData) => {
    
    const NbPixels = 600

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    canvas.width = NbPixels
    canvas.height = NbPixels

    ctx.drawImage(
        imageRef.current,
        cropData.x, cropData.y, cropData.width, cropData.height,
        0, 0, NbPixels, NbPixels
    )
}