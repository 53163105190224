const dRef = new Date('2021/01/01')
const targetSize = 12
const m = "0123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz";

 


function num_to_sxg(n) {
    let s = "";
    while (n>0) {
      const d = n % m.length;
      s =  m[d] + s
      n = (n-d)/m.length;
    }
    return s
   }

export const randomId = () =>{
    const d = new Date ()

const n = (d.getTime() - dRef.getTime())
let code = num_to_sxg(n)


while (code.length < targetSize){
    const rdm = Math.floor(Math.random() * m.length)
    code =  m[rdm] + code
}

let i = 0
let finalCode=''
while (i < targetSize){
    finalCode = finalCode + code[i]
    if ((((i+1) % 4) === 0) && (i<8))
        finalCode = finalCode + '-'
    i = i + 1
}

return finalCode
}


